import React, { useState, useEffect, useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Media from 'react-media';
import {
  piper,
  landing,
} from '../../../assets/illustrations/illustrationRepository';
import mediaQueries from '../../../utils/mediaQueries';
import { ButtonPrimary, ButtonSecondary } from '../components/Buttons';
import PromoCodeContext from '../../../contexts/PromoCodeContext';
import sanitizePlans from '../../CreateAccount/sanitizePlans';
import routes from '../../../utils/constants/routes';

const fade = keyframes`
  0% {
    opacity: 0
  }
  2% {
    opacity: 1
  }
  30% {
    opacity: 1
  }
  35%, 100% {
    opacity: 0
  }
`;

const Section = styled.section`
  background: ${({ theme }) => theme.colors.white};
  padding: 24px 28px 40px 28px;
  ${({ isSpanish }) => isSpanish
    && `
    padding-left: 22px;
    padding-right: 22px;
  `}
  @media ${mediaQueries.lgPlusUp} {
    display: grid;
    padding-top: 86px;
    padding-bottom: 100px;
  }
`;
const Header = styled.div``;
const Title = styled.h3`
  color: ${({ theme }) => theme.colors.black3E};
  font-size: 40px;
  font-weight: bold;
  line-height: 54px;
  text-align: center;
`;
const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.black3E};
  font-size: 19px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  margin: 0;
  margin-top: 4px;
`;
const Piper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 38px;
  margin-top: 40px;
  @media ${mediaQueries.lgPlusUp} {
    justify-self: center;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    margin-top: 54px;
  }
`;
const PiperDialogList = styled.div`
  display: flex;
  justify-content: center;
  @media ${mediaQueries.lgPlusUp} {
    order: 2;
  }
`;
const ListContainer = styled.div`
  width: 295px;
  height: 183px;
  position: relative;
  @media ${mediaQueries.lgPlusUp} {
    width: 484px;
    height: 182px;
  }
`;
const PiperDialogContainer = styled.div`
  position: absolute;
  opacity: 0;
  animation: ${fade} 12s linear infinite;
  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 4s;
  }
  &:nth-child(3) {
    animation-delay: 8s;
  }
`;
const PiperDialog = styled.img``;
const PiperImageContainer = styled.div`
  position: relative;
  justify-self: center;
  width: 280px;
  @media ${mediaQueries.lgPlusUp} {
    width: 350px;
    left: 0;
    order: 1;
  }
`;
const PiperImage = styled.img`
  width: 100%;
`;
const Description = styled.div`
  margin-top: 36px;
  @media ${mediaQueries.lgPlusUp} {
    justify-self: center;
    text-align: center;
    max-width: 1008px;
    margin-top: 48px;
  }
`;
const DescriptionText = styled.div`
  color: ${({ theme }) => theme.colors.gray6};
  font-size: 16px;
  line-height: 22px;
  &:nth-child(2) {
    margin-top: 32px;
    @media ${mediaQueries.lgPlusUp} {
      margin-top: 0;
    }
  }
  @media ${mediaQueries.lgPlusUp} {
    font-size: 18px;
    line-height: 25px;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 24px;
  @media ${mediaQueries.lgPlusUp} {
    flex-wrap: nowrap;
    margin-top: 46px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  &:nth-child(2) {
    margin-top: 20px;
    @media ${mediaQueries.lgPlusUp} {
      margin-top: 0;
      margin-left: 32px;
    }
  }
  @media ${mediaQueries.lgPlusUp} {
    width: max-content;
  }
`;
const PiperSection = () => {
  const intl = useIntl();
  const history = useHistory();
  const { PromoCode } = useContext(PromoCodeContext);
  const [trialDays, setTrialDays] = useState(0);
  const [dialogs, setDialogs] = useState({
    dialog1: {
      mobile: landing.piperDialogs.dialog1.mobile,
      desktop: landing.piperDialogs.dialog1.desktop,
    },
    dialog2: {
      mobile: landing.piperDialogs.dialog2.mobile,
      desktop: landing.piperDialogs.dialog2.desktop,
    },
    dialog3: {
      mobile: landing.piperDialogs.dialog3.mobile,
      desktop: landing.piperDialogs.dialog3.desktop,
    },
    dialog4: {
      mobile: landing.piperDialogs.dialog4.mobile,
      desktop: landing.piperDialogs.dialog4.desktop,
    },
  });

  useEffect(() => {
    if (intl.locale === 'es') {
      setDialogs({
        dialog1: {
          mobile: landing.piperDialogs.dialog1.mobileEs,
          desktop: landing.piperDialogs.dialog1.desktopEs,
        },
        dialog2: {
          mobile: landing.piperDialogs.dialog2.mobileEs,
          desktop: landing.piperDialogs.dialog2.desktopEs,
        },
        dialog3: {
          mobile: landing.piperDialogs.dialog3.mobileEs,
          desktop: landing.piperDialogs.dialog3.desktopEs,
        },
        dialog4: {
          mobile: landing.piperDialogs.dialog4.mobileEs,
          desktop: landing.piperDialogs.dialog4.desktopEs,
        },
      });
    } else if (intl.locale === 'en') {
      setDialogs({
        dialog1: {
          mobile: landing.piperDialogs.dialog1.mobile,
          desktop: landing.piperDialogs.dialog1.desktop,
        },
        dialog2: {
          mobile: landing.piperDialogs.dialog2.mobile,
          desktop: landing.piperDialogs.dialog2.desktop,
        },
        dialog3: {
          mobile: landing.piperDialogs.dialog3.mobile,
          desktop: landing.piperDialogs.dialog3.desktop,
        },
        dialog4: {
          mobile: landing.piperDialogs.dialog4.mobile,
          desktop: landing.piperDialogs.dialog4.desktop,
        },
      });
    }
  }, [intl]);

  useEffect(() => {
    const [monthlyPlan] = sanitizePlans(PromoCode);
    setTrialDays(monthlyPlan.trialDuration);
  }, [PromoCode]);
  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <Section isSpanish={intl.locale === 'es'}>
          <Header>
            <Title>
              <FormattedMessage
                id="landing.piper.title"
                defailedMessage="Meet Piper,"
              />
            </Title>
            <Subtitle>
              <FormattedMessage
                id="landing.piper.subtitle"
                defailedMessage="your trusty social media {lineBreak}assistant!"
                values={{
                  lineBreak: matches.lgPlusDownLess ? <br /> : '',
                }}
              />
            </Subtitle>
          </Header>
          <Piper>
            <PiperDialogList>
              <ListContainer>
                <PiperDialogContainer>
                  <PiperDialog
                    src={
                      matches.lgPlusDownLess
                        ? dialogs.dialog1.mobile
                        : dialogs.dialog1.desktop
                    }
                  />
                </PiperDialogContainer>
                <PiperDialogContainer>
                  <PiperDialog
                    src={
                      matches.lgPlusDownLess
                        ? dialogs.dialog2.mobile
                        : dialogs.dialog2.desktop
                    }
                  />
                </PiperDialogContainer>
                <PiperDialogContainer>
                  <PiperDialog
                    src={
                      matches.lgPlusDownLess
                        ? dialogs.dialog3.mobile
                        : dialogs.dialog3.desktop
                    }
                  />
                </PiperDialogContainer>
              </ListContainer>
            </PiperDialogList>
            <PiperImageContainer>
              <PiperImage src={piper.PiperMeet} alt="Piper" />
            </PiperImageContainer>
          </Piper>
          <Description>
            <DescriptionText>
              <FormattedMessage
                id="landing.piper.description1"
                defaultMessage="Whether you’re a freelancer working solo or part of a small business team, Piper is at your side."
              />
            </DescriptionText>
            <DescriptionText>
              <FormattedMessage
                id="landing.piper.description2"
                defaultMessage="He offers insights, reveals trends, and turns industry data into actionable tasks. Piper keeps learning – from you, your followers, and experts in the field.  He delivers sound advice and key metrics to elevate your brand."
              />
            </DescriptionText>
          </Description>
          <ButtonsContainer>
            <ButtonContainer>
              <ButtonPrimary
                largeText={intl.locale === 'es'}
                largeTextEnglish={intl.locale === 'en'}
                onClick={() => history.push(routes.CREATE_ACCOUNT)}
              >
                {trialDays > 0 ? (
                  <FormattedMessage
                    id={
                      matches.lgPlusDownLess
                        ? 'landing.promotional.button2.trial.mobile'
                        : 'landing.promotional.button2.trial'
                    }
                    defaultMessage="Start your free {trialDays}-day trial!"
                    values={{
                      trialDays,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="plan.pro.without.trial"
                    defaultMessage="Create your account"
                  />
                )}
              </ButtonPrimary>
            </ButtonContainer>
            <ButtonContainer>
              <ButtonSecondary onClick={() => history.push(routes.TOOLS)}>
                <FormattedMessage id="k.learnMore" deafMessage="Learn more" />
              </ButtonSecondary>
            </ButtonContainer>
          </ButtonsContainer>
        </Section>
      )}
    </Media>
  );
};

export default PiperSection;
