/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { AppBar as MuiAppBar, IconButton, Button as BaseButton } from '@material-ui/core';
import { container, noMarginBlock, redCircle } from '../../../styled/mixins';
import GlobalTheme from '../../../styled/GlobalTheme';
import colors from '../../../styled/colors';
import AnalyticsLink from '../../buttons/AnalyticsLink';
import AnalyticsAnchor from '../../buttons/AnalyticsAnchor';

export const optionSmallHeight = 25;
export const optionHeight = 56;
export const animationDuration = '0.25s';

export const AppBar = styled(MuiAppBar)`
  && {
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);

    ${GlobalTheme.breakpoints.down('sm')}{
      box-shadow: 0px 2px ${(props) => (props.isAppIOS ? '1px' : '16px')} rgba(0, 0, 0, 0.15);
    }
  }
`;

export const MenuBody = styled.div`
  background: ${(props) => props.theme.colors.grayE9};
  padding: 8px 16px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
`;

export const MenuBox = styled.li`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent ${(props) => props.theme.colors.grayE9} transparent;
  margin-left: 16px;
  z-index: 2;
`;

export const CustomAnalyticsLink = styled(AnalyticsLink)`
  &.active  {
    border-bottom: 4px solid ${(props) => props.theme.colors.black};
    margin-bottom: -4px;
  }
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  ${({ hint }) => hint && css`
    &:before {
      content: "${hint}";
      position: absolute;
      bottom: 8px;
      right: 0;
      left: 0;
      text-align: center;
      font-size: 10px;
    }
  `}
`;

export const CustomAnalyticsAnchor = styled(AnalyticsAnchor)`
  &.active  {
    border-bottom: 4px solid ${(props) => props.theme.colors.black};
    margin-bottom: -4px;
  }
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  ${({ hint }) => hint && css`
    &:before {
      content: "${hint}";
      position: absolute;
      bottom: 8px;
      right: 0;
      left: 0;
      text-align: center;
      font-size: 10px;
    }
  `}
`;

export const MobileAnalyticsLink = styled(AnalyticsLink)`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  padding-left: max(32px, env(safe-area-inset-left));
  padding-right: max(32px, env(safe-area-inset-right));
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  transition: all 0.3s ease;
  & button {
    padding: 0;
  }
  &.active,
  &:hover {
    background: ${(props) => props.theme.colors.secondaryDark};
  }
`;

export const MobileAnalyticsAnchor = styled(AnalyticsAnchor)`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  padding-left: max(32px, env(safe-area-inset-left));
  padding-right: max(32px, env(safe-area-inset-right));
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  transition: all .3s ease;
  & button {
    padding: 0;
  }
  &.active,
  &:hover  {
    background: ${(props) => props.theme.colors.secondaryDark};
  }
`;

export const TabletLogo = styled.div`
  display: none;
  position: relative;
  align-content: center;
  padding-top: 12px;
  ${GlobalTheme.breakpoints.down('lg')} {
    display: inline-flex;
  }
`;

export const UserLogoContainer = styled.div`
  justify-content: space-around;
  display: inline-flex;
  align-items: center;
`;

export const LinksCentered = styled.div`
  display: inline-flex;
  justify-content: 'center';
  align-items: center;
  ${GlobalTheme.breakpoints.down('lg')} {
    display: none;
  }
`;

export const AuthenticatedLogo = styled.div`
  display: inline-flex;
  position: relative;
  align-content: center;
  padding-top: 12px;
`;

export const Toolbar = styled.div`
  ${container};
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  min-height: 64px;
`;

export const BigLogo = styled.img`
  object-fit: none;
  ${GlobalTheme.breakpoints.down('lg')} {
    display: none;
  }
`;

export const SloganText = styled.span`
  color: ${({ theme }) => theme.colors.black3E};
  font-size: 18px;
  line-height: 25px;
  margin-left: 20px;
  ${GlobalTheme.breakpoints.down('lg')} {
    display: none;
  }
`;

export const PiperLogo = styled.img`
  object-fit: none;
  margin-top: ${(props) => props.marginTop || '-5px'};
  margin-right: 10px;
`;

export const PiperLogoText = styled.img`
  object-fit: none;
`;

export const MenuBreakpoint = 'lg';

export const LinkContainer = styled.div`
  display: inline-flex;
  align-items: center;
  ${GlobalTheme.breakpoints.down(MenuBreakpoint)} {
    display: none;
  }
`;

export const MenuButton = styled(IconButton)`
  && {
    display: none;
    img {
      transform: scale(1.54);
    }
    ${GlobalTheme.breakpoints.down(MenuBreakpoint)} {
      display: block;
    }
  }
`;

export const HelpButton = styled(BaseButton)`
  && {
    height: 30px;
    padding: 0px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 2px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    ${GlobalTheme.breakpoints.down('lg')} {
      display: none;
    }
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
`;

export const Avatar = styled.img`
  width: ${(props) => ((props.size) ? props.size : '24px')};
  height: ${(props) => ((props.size) ? props.size : '24px')};
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
  margin-left: 10px;
  margin-top: 5px;
  transition: 0.3s ease-in-out;
  margin-right: 5px;
`;

export const Shader = styled.div`
  display: none;
  ${GlobalTheme.breakpoints.down('lg')} {
    display: block;
  }
`;

export const OptionContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryDark};
  width: 100%;
  height: ${(props) => (props.small ? optionSmallHeight : optionHeight)}px;
  padding: ${(props) => (props.small ? '0' : '15px 0')};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${(props) => props.hideDown && `
    ${GlobalTheme.breakpoints.down(props.hideDown)} {
      display: none;
    }
  `}
`;

export const OptionHeader = styled.h2`
  ${noMarginBlock}
  font-size: 20px;
  text-transform: uppercase;
  color: ${colors.black};
`;

export const OptionHint = styled.h2`
  ${noMarginBlock}
  font-size: 12px;
  color: ${colors.black};
  font-weight: 400;
  margin-left: 8px;
`;

export const TotalMessageBox = styled.div`
  position: absolute;
  right: -5px;
  top: -3px;
  z-index: 2;
  padding-left: ${(props) => props.theme.space}px;
  ${GlobalTheme.breakpoints.down('lg')} {
    right: 2px;
    top: 2px;
  }
  &.mobileUnreadBox {
    position: relative;
  }
`;

export const TotalMessage = styled.div`
  ${redCircle}
`;

export const DropdownMenu = styled.div`
  background: ${colors.secondary};
  overflow: hidden;
  transition: max-height ${animationDuration} ease-out;
  &.enter {
    max-height: calc(100vh - 64px);
    overflow-y: scroll;
  }
  &.exit {
    max-height: 0px;
  }
`;

export const HelpLink = styled.a``;

export const MobileAnalyticsIntercom = styled(AnalyticsLink)`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  transition: all 0.3s ease;
  & button {
    padding: 0;
  }
  &:hover {
    background: ${(props) => props.theme.colors.secondaryDark};
  }
`;

export const WarningErrorBoostPost = styled.div`
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: ${colors.red};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: '!';
    color: ${colors.white};
    position: absolute;
    font-size: 12px;
  }
  ${({ isDesktop }) => isDesktop && `
    position: absolute;
    right: -5px;
    top: -3px;
    z-index: 2;
  `}
`;
