const es = {
  'login.button': 'Iniciar sesión',
  'login.createAccount.text': '¿Aún no tienes una cuenta?',
  'login.facebook.button': 'Iniciar sesión con Facebook',
  'login.forgotPassword': '¿Olvidaste tu contraseña?',
  'login.header': '¡Hola otra vez!',
  'login.rememberMe.text': 'Recuérdame',
  'login.resetPassword': 'Restablecer contraseña',
  'login.resetPassword.description': 'Escriba el correo electrónico que registró en Social Piper. Recibirás un enlace para crear una nueva contraseña. ',
  'login.resetPassword.helpSupport': 'Si tienes algún problema, contáctanos en  <a>support@socialpiper.com</a> o usa nuestro chat en vivo.',
  'login.resetPassword.succes.alert': 'Se envió el correo electrónico para restablecer la contraseña',
  'login.signup.link': 'Regístrate',
  'login.apple.button': 'Iniciar con Apple',
  'signin.apple.button': 'Iniciar Sesión con Apple',
  'signup.apple.button': 'Regístrate con Apple',
  'login.signup.link.app': '¿NO TIENES UNA CUENTA?',
  'login.expirderModal.tip': '¡Vaya! ¡Has estado fuera por un tiempo! Por favor, inicia sesión de nuevo.',
};
export default es;
