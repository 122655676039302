const es = {
  'trialEnds.modal.hasPayment': '¡Activa tu plan de Social Piper! Cambia tu método de pago*',
  'trialEnds.modal.notPayment': 'Ingresa un método de pago para activar tu plan con Social Piper',
  'trialEnds.modal.title': 'Tu periodo de prueba ha finalizado',
  'trialEnds.modal.subtitle1': 'No te pierdas tu acceso a todos estos beneficios:',
  'trialEnds.modal.subtitle2': 'No desaproveches los mejores momentos para publicar en tus redes esta semana:',
  'trialEnds.modal.subtitle3': 'No desaproveches la oportunidad de llegar hasta',
  'onlyPro.modal.message': '¡Oh no! La app solo está disponible para usuarios con planes Pro y Business.',
  'onlyPro.create.modal.message': '¡Oh no! Aún no tienes cuenta en Social Piper; crea una en sólo unos minutos en socialpiper.com',
  'update.session.modal.message': 'Para poder validar tu nuevo correo, necesitas volver a iniciar sessión.',
  'trialEnds.modal.benefits.benefit1': '<s>Mejor día y hora</s> para publicar',
  'trialEnds.modal.benefits.benefit2': '<s>Miles de diseños</s> listos para usarse o editar',
  'trialEnds.modal.benefits.benefit3': 'Guía inteligente para <s>invertir en publicidad</s>',
  'trialEnds.modal.benefits.benefit4': '<s>Contestar mensajes</s> de todas tus redes en un solo lugar ',
  'trialEnds.modal.audience.people': 'personas',
  'trialEnds.modal.audience.text': 'creando una campaña publicitaria con ayuda de Piper.',
  'trialEnds.modal.hasPayment.text': '* El método de pago que ingresaste no es válido o bien no tienes metodo de pago disponible. Suscríbete y cambia o agrega otro metodo de pago.',
  'modal.android.disabled': 'Para continuar usando Social Piper, por favor abre tu navegador web y ve a www.socialpiper.com',
};

export default es;
