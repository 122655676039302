import gql from 'graphql-tag';

export const retrieveTwitterTimeline = gql`
  query {
    retrieveTwitterTimeline
  }
`;

export const tweets = gql`
  query tweets($pagination: Pagination!, $orderBy: POSTS_ORDER_BY) {
    tweets(pagination: $pagination, orderBy: $orderBy) {
      id
      message
      imageUrl
      createdTime
      replies
      favorites
      retweets
      unreadCommentsCount
      attachmentType
    }
  }
`;

export default {};
