const es = {
  'notification.reconnect.facebook.instagram.title': '¡Excelentes noticias!',
  'notification.reconnect.facebook.instagram.subtitle': '¡En Social Piper ya puedes publicar directamente en Instagram!',
  'notification.reconnect.facebook.instagram.advice': 'Por favor reconecta las cuentas de redes sociales de tu negocio (Facebook e Instagram) y tu configuración quedará actualizada.',
  'notification.reconnect.facebook.instagram.button': 'Re-conectar',
  'notification.reconnect.facebook.instagram.link': 'Lo haré más tarde',
  'notification.reconnect.facebook.title': '¡Tenemos una gran mejora que te permitirá publicar directamente en tu cuenta de Instagram para empresa!',
  'notification.reconnect.facebook.advice': 'Para que esta mejora funcione, deberás volver a conectar tu cuenta de Facebook antes de conectar  tu cuenta de Instagram para empresa.',
};

export default es;
