export const SECONDARY = 'secondary';
export const PRIMARY = 'primary';
export const LARGE = 'l';
export const MEDIUM = 'm';
export const L_FONT_SIZE = '24px';
export const M_FONT_SIZE = '16px';
export const L_LINE_HEIGHT = '36px';
export const M_LINE_HEIGHT = '24px';
export const OUTLINED = 'outlined';
export const CONTAINED = 'contained';
