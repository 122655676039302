import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ROUTES from '../../../utils/constants/routes';
import PromoCodeContext from '../../../contexts/PromoCodeContext';
import useGetPlansInfo from '../../../hooks/useGetPlansInfo';
import GlobalTheme from '../../../styled/GlobalTheme';
import roles from '../../../utils/constants/roles';
import sanitizePlans from '../sanitizePlans';
import PlanDetails from './PlanDetails';
import PlanOption from './PlanOption';
import { ModalTable } from '../../../components/CompareTable';
import { colors } from '../../../styled/theme';
import { UserSettingsContext } from '../../../contexts/UserSettingsContext';
import { COUNTRY_MX } from '../../../utils/constants/globals';

const Container = styled.div`
  position: relative;
  margin: ${(props) => (props.isViewPro ? '0 125px;' : '0;')}
  ${GlobalTheme.breakpoints.down('sm')} {
    margin: 0;
  }
`;

const PlansContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 32px;
  width: 100%;
  opacity: ${({ showDetails }) => (showDetails ? '0.25' : '1')};
  ${GlobalTheme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const ShowComparativeTableBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  ${GlobalTheme.breakpoints.up('sm')} {
    display: none;
  }
  @media (max-width: 320px)   {
    margin-left: -42px;
  }
`;
const ShowComparativeTable = styled.a`
  cursor: pointer;
  color: ${colors.primary};
  display: flex;
  align-items: center;
  text-decoration: underline;
`;

const PlansSelector = ({
  planSelected, setPlanSelected, isMonthly, hasSelector, isModal, isOptIn,setForcedPromo
}) => {
  const intl = useIntl();
  const location = useLocation();
  const { PromoCode } = useContext(PromoCodeContext);
  const plansInfo = useGetPlansInfo();
  const [priceY, setPriceY] = useState(0);
  const [priceM, setPriceM] = useState(0);
  const [trialDaysM, setTrialDaysM] = useState(0);
  const [trialDaysY, setTrialDaysY] = useState(0);
  const [currencyCode, setCurrencyCode] = useState('');
  const [showDetails, setShowDetails] = useState('');
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [bizPrice, setBizPrice] = useState('99');
  const { pathname } = location;
  const [isViewPro, setIsViewPro] = useState(false);

  useEffect(() => {
    const [monthlyPlan, yearlyPlan] = sanitizePlans(PromoCode);
    if(PromoCode?.code !== "FULL_MNGT"){
      setPriceM(monthlyPlan.realPrice);
      setPriceY(yearlyPlan.realPrice);
      if (!isOptIn) {
        setTrialDaysM(monthlyPlan.trialDuration ? monthlyPlan.trialDuration : 0);
        setTrialDaysY(yearlyPlan.trialDuration ? yearlyPlan.trialDuration : 0);
      } else {
        let question = (monthlyPlan.trialDuration && !PromoCode.isPromoAttached && PromoCode.isValid);
        setTrialDaysM(question ? monthlyPlan.trialDuration : 0);
        question = (yearlyPlan.trialDuration && !PromoCode.isPromoAttached && PromoCode.isValid);
        setTrialDaysY(question ? yearlyPlan.trialDuration : 0);
      }
      setCurrencyCode(monthlyPlan.currencyCode);
    }
  }, [PromoCode]);

  useEffect(() => {
    switch (currencyCode) {
      case 'USD':
        setBizPrice('99');
        break;
      case 'EUR':
        setBizPrice('99');
        break;
      case 'GBP':
        setBizPrice('99');
        break;
      default:
        setBizPrice('99');
        break;
    }
  }, [currencyCode]);

  const proPrice = () => (
    isMonthly ? intl.formatMessage({
      id: 'planOption.price.monthly',
      defaultMessage: '{cur} {price} month',
    }, {
      price: priceM,
      cur: currencyCode,
    }) : intl.formatMessage({
      id: 'planOption.price.yearly',
      defaultMessage: '{cur} {price} year',
    }, {
      price: priceY,
      cur: currencyCode,
    })
  );

  useEffect(() => {
    if (pathname === ROUTES.CREATE_ACCOUNT_PRO) {
      setIsViewPro(true);
    }
  }, []);

  return (
    <Container isViewPro={isViewPro}>
      {showDetails && (
        <PlanDetails
          title={plansInfo[showDetails].title}
          features={plansInfo[showDetails].features}
          price={showDetails === 'pro' ? proPrice() : intl.formatMessage({
            id: 'k.free',
            defaultMessage: 'Free',
          })}
          details={plansInfo[showDetails].details}
          comingSoon={plansInfo[showDetails].comingSoon}
          recommended={plansInfo[showDetails].recommended}
          color={plansInfo[showDetails].color}
          setShowDetails={setShowDetails}
          detailsDescription={plansInfo[showDetails].detailsDescription}
        />
      )}
      <PlansContainer
        showDetails={showDetails}
      >
        <PlanOption
          title={plansInfo.pro.title}
          description={plansInfo.pro.description}
          features={plansInfo.pro.features}
          price={isMonthly ? priceM : priceY}
          isMonthly={isMonthly}
          planId="pro"
          onClick={() => {
            setForcedPromo("");
            setPlanSelected(roles.PRO);
          }}
          selected={planSelected === roles.PRO}
          recommended
          color={plansInfo.pro.color}
          details={plansInfo.pro.details}
          detailsDescription={plansInfo.pro.detailsDescription}
          setShowDetails={setShowDetailsModal}
          hasSelector={hasSelector}
          currencyCode={currencyCode}
          trialDays={isMonthly ? trialDaysM : trialDaysY}
          isModal={isModal}
        />
        <PlanOption
          title={plansInfo.business.title}
          description={plansInfo.business.description}
          features={plansInfo.business.features}
          price={isMonthly ? bizPrice : (bizPrice * 12)}
          isMonthly={isMonthly}
          planId="business"
          onClick={() => {
            setForcedPromo("FULL_MNGT");
            setPlanSelected(roles.BUSINESS_MAIN);
          }}
          selected={planSelected === roles.BUSINESS_MAIN}
          recommended
          color={plansInfo.business.color}
          details={plansInfo.business.details}
          detailsDescription={plansInfo.business.detailsDescription}
          setShowDetails={setShowDetailsModal}
          hasSelector={hasSelector}
          currencyCode={currencyCode}
        />
      </PlansContainer>
      <ShowComparativeTableBox>
        <ShowComparativeTable
          onClick={() => setShowDetailsModal(true)}
        >
          <FormattedMessage
            id="k.plan.allFeatures"
            defaultMessage="See all plans features"
          />
        </ShowComparativeTable>
      </ShowComparativeTableBox>
      <ModalTable
        open={showDetailsModal}
        setOpen={setShowDetailsModal}
      />
    </Container>

  );
};

PlansSelector.propTypes = {
  planSelected: PropTypes.string,
  setPlanSelected: PropTypes.func,
  isMonthly: PropTypes.bool.isRequired,
  hasSelector: PropTypes.bool,
  isModal: PropTypes.bool,
  isOptIn: PropTypes.bool,
};

PlansSelector.defaultProps = {
  planSelected: roles.PRO,
  setPlanSelected: () => { },
  hasSelector: false,
  isModal: false,
  isOptIn: false,
};

export default PlansSelector;
