import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { AnalyticsContext, constants } from '../contexts/AnalyticsContext';
import routesDictionary from '../utils/analytics/routesDictionary';

export default function useCallGA() {
  const { pathname } = useLocation();
  const { dataLayerPush } = useContext(AnalyticsContext);
  /**
   * callgaData: Call Google Analytics for EPA data arquitechture
   * @summary This function creates an event with labels and takes an Object as argument
   * @param {string} title - [optionalArg]
   * @param {string} category - [optionalArg]
   * @param {string} pagePath - [optionalArg] - Harcoded URL given to analytics
   * @param {string} action - [required] - Google Analytics action
   * @param {string} label - [required] - This is the information we want to track,
   * We must place a 'from':'/section' with the name of the section and more data
   * If It could be relevant
   * @returns {event} Returns an React.GA event https://github.com/react-ga/react-ga#reactgaeventargs
  */

  const callGA = (gaData = {}, to) => {
    // we want yto make a direct call to SET
    if (gaData.task && gaData.task === constants.SET) {
      dataLayerPush(gaData);
    } else {
      const {
        title: gaTitle, category, action, label, event: gaEvent,
      } = gaData;
      const pagePath = gaData.pagePath || pathname;
      const path = pagePath.slice(1).replace(/[&/\\#,+()$~%.'":*?<>{}-]/g, ' ');
      const title = path.charAt(0).toUpperCase() + path.slice(1);
      const splitRoute = `/${pathname.split('/')[1]}`;

      const routeFrom = routesDictionary[splitRoute] || pathname;
      const routeTo = to && routesDictionary[to];
      const pathTitle = gaTitle || title || '';
      const dataCategory = category || routeFrom || 'defaultCategory';
      const dataAction = action || routeTo || 'defaultAction';
      const dataLabel = label || '';
      const event = gaEvent || 'action';
      dataLayerPush({
        pagePath,
        pathTitle,
        dataCategory,
        dataAction,
        dataLabel,
        event,
      });
    }
  };
  return callGA;
}

export {
  constants,
};
