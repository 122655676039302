import gql from 'graphql-tag';

export const getBoostBudgetRecommendation = gql`
  query getBoostBudgetRecommendation($socialNetwork: SOCIAL_NETWORK){
    budgetRecommendation(socialNetwork: $socialNetwork){
      amount,
      targetSize,
      bounds {
        min
        max
      }
      currencyCode
      usdExchangeRate
    }
  }
`;

export const getBudgetReach = gql`
  query budgetReach($budget: Float!, $socialNetwork: SOCIAL_NETWORK){
    budgetReach(budget: $budget, socialNetwork: $socialNetwork)
  }
`;

export const getBestTimeToPostBySM = gql`
  query getLastWeekAudienceSize($socialNetwork:SOCIAL_NETWORK, $timezone: String){
    getLastWeekAudienceSize( socialNetwork: $socialNetwork, timezone: $timezone){
      count,
      isoDate,
    }
  }
`;
