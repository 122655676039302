import * as Sentry from '@sentry/browser';
import { authAppSync } from '../auth';
import {
  createFacebookPost,
  createInstagramPost,
  createTwitterPost,
  createLinkedinPost,
  saveMediaPostVideo,
  createFacebookVideoBoost,
  createFacebookAdCreativeVideo,
  createFacebookAdVideo,
  createInstagramStorieScheduler,
  publishVideo,
  updateMediaContent,
  deleteSocialMedia,
} from '../../graphql/mutations';
import {
  getContent,
  getPostStatus,
  validatePostVideoInstagram,
} from '../../graphql/queries';
import {
  ENV,
  ENVIRONMENTS,
} from '../../utils/constants/globals';

export default class PublishProvider {
  static sendSentryFailureMessage(error, sn) {
    try {
      Sentry.captureException(error, {
        tags: {
          section: 'PUBLISH',
          sn,
        },
      });
    } catch {
      // can't log the exception
    }
  }

  // eslint-disable-next-line max-len
  static async post2Facebook(caption, publishNow, scheduleTime, boostInfo, mediaIds = [], sessionId) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    const {
      minStartDate,
      ...boostingSettings
    } = boostInfo;
    const variables = {
      message: caption,
      published: publishNow,
      scheduleTime: !publishNow ? scheduleTime : null,
      mediaType: 'image',
      mediaIds,
      boostingSettings,
      sessionId,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: createFacebookPost,
        variables,
      });
      const { data } = response;
      if (data.createFacebookPost.published) {
        result.message = 'success';
        result.success = true;
        result.data = {
          ...data.createFacebookPost,
        };
      }
      if (data.createFacebookPost?.errors?.length > 0) {
        result.message = JSON.stringify(data.createFacebookPost.errors);
        this.sendSentryFailureMessage(new Error(result.message), 'FB_IMG');
      }
    } catch (err) {
      this.sendSentryFailureMessage(err, 'FB_IMG');
      result.message = err.message;
    }
    return result;
  }

  static async post2Instagram(caption, imageIds = [], scheduledTime, publishNow, sessionId) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    const variables = {
      message: caption,
      mediaIds: imageIds,
      published: true,
      sessionId,
    };
    if (!publishNow) {
      variables.scheduleTime = scheduledTime;
      variables.published = false;
    }
    try {
      const response = await authAppSync.client.mutate({
        mutation: createInstagramPost,
        variables,
      });
      const payload = response.data.createInstagramPost;
      if (!payload.errors) {
        result.message = 'success';
        result.success = true;
        result.data = {
          payload,
        };
      } else {
        this.sendSentryFailureMessage(new Error('IG_POST'), 'IG_IMG');
        result.message = 'Error Instagram post';
      }
    } catch (err) {
      this.sendSentryFailureMessage(err, 'IG_IMG');
      result.message = err.message;
    }
    return result;
  }

  // eslint-disable-next-line max-len
  static async schedulerInstagramStorie(caption, idMediaIG, scheduledTime, previewURL, namePreview, defaultMessageStorie) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    const variables = {
      message: caption,
      mediaId: idMediaIG,
      published: false,
      scheduleTime: scheduledTime,
      previewURL,
      namePreview,
      defaultMessageStorie,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: createInstagramStorieScheduler,
        variables,
      });
      const payload = response.data.createInstagramStorieScheduler;
      if (!payload.errors) {
        result.message = 'success';
        result.success = true;
        result.data = {
          payload,
        };
      } else {
        this.sendSentryFailureMessage(new Error('IG_STORY_ERROR'), 'IG_STORY');
        result.message = 'Error Instagram post';
      }
    } catch (err) {
      this.sendSentryFailureMessage(err, 'IG_STORY');
      result.message = err.message;
    }
    return result;
  }

  static async post2Twitter(caption, imageIds = [], scheduledTime, publishNow, sessionId) {
    let result = {
      text: '',
      success: false,
      data: null,
    };
    const variables = {
      text: caption,
      imageIds,
      sessionId,
    };

    if (!publishNow) {
      variables.scheduleTime = scheduledTime;
    }
    try {
      const response = await authAppSync.client.mutate({
        mutation: createTwitterPost,
        variables,
      });
      if (response.data.tweet) {
        result = {
          message: 'success',
          success: true,
          data: response.data,
        };
      } else {
        this.sendSentryFailureMessage(new Error('TW_ERROR'), 'TW_IMG');
      }
    } catch (err) {
      this.sendSentryFailureMessage(err, 'TW_IMG');
      result.message = err.message;
    }
    return result;
  }

  // eslint-disable-next-line max-len
  static async post2Linkedin(caption, imageIds, scheduledTime, publishNow, boostInfo = {}, sessionId) {
    const result = {
      text: '',
      success: false,
      data: null,
    };
    const {
      minStartDate,
      ...boostingSettings
    } = boostInfo;
    const variables = {
      data: {
        message: caption,
        mediaIds: imageIds,
        boostingSettings,
        scheduleTime: !publishNow ? scheduledTime : null,
        sessionId,
      },
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: createLinkedinPost,
        variables,
      });
      const { data } = response;
      if (data.createLinkedinPost) {
        result.message = 'success';
        result.success = true;
        result.data = data;
      } else {
        this.sendSentryFailureMessage(new Error('LI_ERROR'), 'LI_IMG');
        result.message = 'cant publish';
      }
    } catch (err) {
      this.sendSentryFailureMessage(new Error('LI_ERROR'), 'LI_IMG');
      result.message = err.message;
    }
    return result;
  }

  static async getContentImageType(imageId) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    const variables = {
      imageId,
    };
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: getContent,
          variables,
        });
      } else {
        response = {
          data: {
            getContent: false,
          },
        };
      }
      if (response.data.getContent) {
        result.message = 'success';
        result.success = true;
        result.data = response.data;
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async getPostStatus(contentId) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    const variables = {
      contentId,
    };
    try {
      const response = await authAppSync.client.query({
        query: getPostStatus,
        fetchPolicy: 'network-only',
        variables,
      });
      if (response.data.getPostStatus) {
        result.message = 'success';
        result.success = true;
        result.data = response.data.getPostStatus;
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async saveMediaVideo(urlVideo, nameFile, duration, dimension) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    const variables = {
      urlVideo,
      nameFile,
      duration,
      dimension,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: saveMediaPostVideo,
        variables,
      });
      if (response.data.saveMediaPostVideo.id) {
        result.message = 'success';
        result.success = true;
        result.data = response.data.saveMediaPostVideo.id;
      }
    } catch (err) {
      Sentry.captureException(err);
      result.success = false;
      result.message = err.message;
    }
    return result;
  }

  static async createFacebookAdVideo(urlVideo) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: createFacebookAdVideo,
        variables: {
          urlVideo,
        },
      });
      result.success = true;
      result.message = 'success';
      result.data = response.data.createFacebookAdVideo;
    } catch (err) {
      this.sendSentryFailureMessage(new Error('FB_AD_ERROR'), 'FB_AD_VIDEO');
      result.message = err.message;
    }
    return result;
  }

  static async createFacebookAdCreativeVideo(minImgURL, adVideoId) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: createFacebookAdCreativeVideo,
        variables: {
          minImgURL,
          adVideoId,
        },
      });
      result.success = true;
      result.message = 'success';
      result.data = response.data.createFacebookAdCreativeVideo;
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  // eslint-disable-next-line max-len
  static async createFacebookVideoBoost(socialMediaContentId, boostingSettings, adCreativeId) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: createFacebookVideoBoost,
        variables: {
          socialMediaContentId,
          boostingSettings,
          adCreativeId,
        },
      });
      result.success = response.data.createFacebookVideoBoost.success;
      if (response.data.createFacebookVideoBoost.success) {
        result.message = 'success';
        result.success = true;
        result.data = response.data.createFacebookVideoBoost.success;
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async publishVideoFacebook(
    id, message, publishNow, scheduleTime, previewImg, namePreviewImg, socialNetwork,
  ) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    const variables = {
      id,
      message,
      publishNow,
      scheduleTime,
      previewImg,
      namePreviewImg,
      socialNetwork,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: publishVideo,
        variables,
      });
      if (response.data.createPostVideo.isValid) {
        result.message = 'success';
        result.success = true;
        // result.data = response.data.createPostVideo;
        // result.data = response.data.createFacebookPostVideo;
      } else {
        this.sendSentryFailureMessage(new Error('FB_VIDEO_ERROR'), 'FB_VIDEO');
      }
    } catch (err) {
      this.sendSentryFailureMessage(new Error('FB_AD_ERROR'), 'FB_VIDEO');
      result.message = err.message;
      result.success = false;
    }
    return result;
  }

  static async publishVideoInstagram(
    id, message, publishNow, scheduleTime, previewImg, namePreviewImg, socialNetwork,
  ) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    const variables = {
      id,
      message,
      publishNow,
      scheduleTime,
      previewImg,
      namePreviewImg,
      socialNetwork,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: publishVideo,
        variables,
      });

      if (response.data.createPostVideo.isValid) {
        result.message = 'success';
        result.success = true;
        // result.data = response.data.createPostVideo.containerId;
      }
    } catch (err) {
      this.sendSentryFailureMessage(new Error('IG_VIDEO_ERROR'), 'IG_VIDEO');
      result.message = err.message;
      result.success = false;
    }
    return result;
  }

  static async getStatusPostInstagram(id) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    const variables = { id };
    try {
      const response = await authAppSync.client.query({
        query: validatePostVideoInstagram,
        fetchPolicy: 'no-cache',
        variables,
      });
      if (response.data.validatePostVideoInstagram) {
        result.message = 'success';
        result.success = response.data.validatePostVideoInstagram.isValid;
        result.data = response.data.validatePostVideoInstagram.code;
      }
    } catch (err) {
      this.sendSentryFailureMessage(new Error('IG_VIDEO_ERROR'), 'IG_VIDEO');
      result.success = false;
      result.message = err.message;
      result.data = -1;
    }
    return result;
  }

  static async publishVideo(data) {
    const {
      id,
      message,
      publishNow,
      scheduleTime,
      previewImg,
      namePreviewImg,
      socialNetwork,
      haveBoostFacebook,
      dataBoostFacebook,
      haveBoostLinkedin,
      dataBoostLinkedin,
      sessionId,
    } = data;
    const result = {
      message: '',
      success: false,
      data: null,
    };
    const variables = {
      id,
      message,
      publishNow,
      scheduleTime,
      previewImg,
      namePreviewImg,
      socialNetwork,
      haveBoostFacebook,
      dataBoostFacebook,
      haveBoostLinkedin,
      dataBoostLinkedin,
      sessionId,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: publishVideo,
        variables,
      });
      if (response.data.createPostVideo.isValid) {
        result.message = 'success';
        result.success = true;
        result.data = response.data.createPostVideo;
        result.isVideo = true;
      }
    } catch (err) {
      this.sendSentryFailureMessage(new Error('PUBLISH_VIDEO_ERROR'), 'PUBLISH_VIDEO_VIDEO');
      result.message = err.message;
      result.success = false;
    }
    return result;
  }

  static async updateSocialMedia(mediaId, isVideo) {
    const result = {
      message: '',
      success: false,
      data: null,
    };

    const variables = { mediaId, isVideo };
    try {
      const response = await authAppSync.client.mutate({
        mutation: updateMediaContent,
        variables,
      });
      result.success = response.data.updateSocialMedia.success;
    } catch (err) {
      result.message = err.message;
      result.success = false;
    }
    return result;
  }

  static async deleteSocialMedia(mediaId, type) {
    const result = {
      message: '',
      success: false,
      data: null,
    };

    const variables = { mediaId, type };
    try {
      const response = await authAppSync.client.mutate({
        mutation: deleteSocialMedia,
        variables,
      });
      result.success = response.data.deleteSocialMedia.success;
    } catch (err) {
      result.message = err.message;
      result.success = false;
    }
    return result;
  }
}
