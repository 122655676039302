import gql from 'graphql-tag';

export const comments = gql`
  query comments($socialMediaContentId: ID!, $pagination: Pagination!, $orderBy: String) {
    comments(socialMediaContentId: $socialMediaContentId, pagination: $pagination, orderBy: $orderBy) {
      id
      from
      commentText
      createdTime
      read
      ownComment
      avatar
      likes
      likeIt
      sticker
      suggestedResponse
      media {
        type
        url
      }
      replyComments {
        id
        from
        commentText
        createdTime
        read
        ownComment
        avatar
        likes
        likeIt
        sticker
        suggestedResponse
        media {
          type
          url
        }
      }
    }
  }
`;

export default {};
