import PropTypes from "prop-types";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { language4ai, socials4ai } from "../graphql/queries/openAI.query";
import {
  MissingConfig,
  appsConnectedParser,
  isLengthZero,
  parseGender,
} from "../hooks/useAIConfig";
import { AudienceProvider, UserProvider } from "../services/entities";
import BusinessProvider from "../services/entities/BusinessProvider";

const initialState = {
  language: "",
  appsConnected: [],
  company: {
    logo: "",
    name: "",
    location: "",
    categories: [],
  },
  facebookConfig: null,
  linkedinConfig: null,
};

export const AIConfigContext = createContext();

export default function ({ children }) {
  const intl = useIntl();
  const initialValues = initialState;
  /** @type {[{ language: string, company: Record<'name' | 'location' | 'categories', | 'logo', any> | null, appsConnected: string[], linkedinConfig: { gender: string, topics: string[], clientCountries: string[], ageRange: number[] } | null, facebookConfig: { gender: string, topics: string[], clientCountries: string[], ageRange: number[] } | null }, any]} */
  const configState = useState(initialValues);
  const [missingConfig, setMissingConfig] = useState(MissingConfig.None);
  const [isFetching, setIsFetching] = useState(true);
  const [config, setConfig] = configState;

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!isFetching) {
      if (config?.appsConnected?.length === 0) {
        setMissingConfig(MissingConfig.NoAppsConnected);
        return;
      }

      if (
        isLengthZero(config?.company?.name) ||
        isLengthZero(config?.company?.location) ||
        isLengthZero(config?.company?.categories)
      ) {
        setMissingConfig(MissingConfig.NoCompanyInfo);
        return;
      }

      setMissingConfig(MissingConfig.None);
    }
  }, [config, isFetching]);

  const refetch = useCallback(() => {
    (async () => {
      try {
        setIsFetching(true);

        let auxConfig = Object.create({
          ...config,
        });

        const [
          appsConnectedResponse,
          languageResponse,
          businessResponse,
          businessCategoriesResponse,
        ] = await Promise.all([
          UserProvider.fetch(socials4ai, true),
          UserProvider.fetchSettings(language4ai, false),
          BusinessProvider.fetch(),
          BusinessProvider.fetchCategories(),
        ]);

        // setting language
        if (languageResponse.success) {
          const { language: bkdLocale } = languageResponse.data;

          console.log("hola?", bkdLocale);

          auxConfig = {
            ...auxConfig,
            language: bkdLocale,
          };
        }

        // setting social network connected
        if (appsConnectedResponse.success) {
          const rawConnAccounts =
            appsConnectedResponse?.data?.connectedAccounts;
          const appsConnected = appsConnectedParser(rawConnAccounts);

          auxConfig = {
            ...auxConfig,
            // language,
            appsConnected,
          };

          if (appsConnected?.includes("Facebook")) {
            const facebookResponse = await AudienceProvider.fetch(
              intl,
              undefined,
              false
            );
            if (
              facebookResponse.success &&
              facebookResponse.data?.interests &&
              facebookResponse.data?.locations
            ) {
              auxConfig = {
                ...auxConfig,
                facebookConfig: {
                  topics: facebookResponse.data?.interests.map((t) => t.name),
                  clientCountries: facebookResponse.data?.locations.map(
                    (l) => l.label
                  ),
                  gender: parseGender(facebookResponse.data?.gender),
                  ageRange: facebookResponse.data?.ages,
                },
              };
            }
          }

          if (appsConnected?.includes("Linkedin")) {
            const linkedinResponse = await AudienceProvider.fetch(
              intl,
              undefined,
              true
            );

            if (
              linkedinResponse.success &&
              linkedinResponse.data?.linkedinInterests &&
              linkedinResponse.data?.linkedinLocations
            ) {
              auxConfig = {
                ...auxConfig,
                linkedinConfig: {
                  topics: linkedinResponse.data?.linkedinInterests.map(
                    (t) => t.label
                  ),
                  clientCountries: linkedinResponse.data?.linkedinLocations.map(
                    (l) => l.label
                  ),
                  gender: parseGender(linkedinResponse.data?.gender),
                  ageRange: linkedinResponse.data?.ages,
                },
              };
            }
          }
        }

        // setting business information
        if (businessResponse.success && businessCategoriesResponse.success) {
          const businessCategories = businessCategoriesResponse?.data?.map(
            (/** @type {{ label: string; }} */ val) => val.label
          );

          auxConfig = {
            ...auxConfig,
            company: {
              logo: businessResponse.data?.logoUrl,
              name: businessResponse.data?.companyName,
              location:
                businessResponse.data?.location &&
                JSON.parse(businessResponse.data?.location).label,
              categories: businessCategories,
            },
          };
        }

        setConfig(auxConfig);
      } catch (err) {
        console.log(err);
      } finally {
        setIsFetching(false);
      }
    })();
  }, []);

  const autoLabelPrompt = (base = "") => {
    if (base) {
      return `${base}. Generate one post about my place called ${
        config.company.name
      }, and my tags are ${config.company.categories.join(", ")}`;
    }
    return `Generate one post about my place called ${
      config.company.name
    }, and my tags are ${config.company.categories.join(", ")}`;
  };

  const autoImagePrompt = (base = "") => {
    if (base) {
      return `${base}, ${config.company.categories.join(
        ", "
      )}, high quality photograpy`;
    }
    return `${config.company.categories.join(" ")}, high quality photograpy`;
  };

  return (
    <AIConfigContext.Provider
      value={{
        missingConfig,
        refetch,
        config,
        autoImagePrompt,
        autoLabelPrompt,
      }}
    >
      {children}
    </AIConfigContext.Provider>
  );
}

AIConfigContext.propTypes = {
  children: PropTypes.node.isRequired,
};