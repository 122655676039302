import gql from 'graphql-tag';

export const dismissAppTouchInfo = gql`
  mutation dismissAppTouchInfo($action: String) {
    dismissAppTouchInfo(action: $action) {
        success
        info
        errorMessage
    }
  }
`;

export default {
  dismissAppTouchInfo,
};
