import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { desktopLoggedFooterButtons } from './footerDictionaries';
import FooterElement from './FooterElement';
import FullSizeLogo from '../Logos/FullSizeLogo';

const FooterWrapper = styled.nav`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const CustomFooterElement = styled(FooterElement)`
  && {
    font-size: 14px;
    & .elementText {
      font-size: 14px;
    }
  }
`;

export default function DesktopLoggedFooter() {
  const intl = useIntl();
  return (
    <FooterWrapper>
      <FullSizeLogo />
      { desktopLoggedFooterButtons.map((element) => (
        <CustomFooterElement
          element={typeof element === 'function' ? element(intl.locale) : element}
          to={element.to}
          gaData={element.gaData}
          title={element.title}
          rel={element.rel}
          alt={element.alt}
          className={element.className}
        />
      ))}
    </FooterWrapper>
  );
}
