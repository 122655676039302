import * as Sentry from '@sentry/browser';
import { formatISO } from 'date-fns';
import { authAppSync } from '../auth';
import {
  facebookPosts,
  instagramPosts,
  tweets,
  getLinkedinPosts,
} from '../../graphql/queries';
import {
  facebookPostsMock,
  instagramPostsMock,
  twitterPostsMock,
  linkedinPostsMock,
} from '../../mocks/comments';
import { SOCIAL_MEDIA } from '../../views/Community/PostComments/constants';
import {
  ENVIRONMENTS,
  ENV,
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
  LINKEDIN,
  STORIES_IG,
  TIKTOK,
} from '../../utils/constants/globals';
import {
  deleteFacebookPost,
  deleteTweet,
  deleteInstagramPost,
  rescheduleFacebookPost,
  rescheduleInstagram,
  rescheduleTweet,
  editFacebookPost,
  editTweet,
  editInstagramPost,
  deleteLinkedinPost,
  editLinkedinPost,
  rescheduleLinkedinPost,
  rescheduleInstagramStorie,
  editTiktokPost,
} from '../../graphql/mutations';

const getSNPayload = (socialNetwork, data) => {
  let res = data;
  if (socialNetwork === SOCIAL_MEDIA.instagram || socialNetwork === SOCIAL_MEDIA.linkedin) {
    res = data.map((x) => ({ ...x, reactions: x.likes }));
  }
  if (socialNetwork === SOCIAL_MEDIA.twitter) {
    res = data.map((x) => ({
      ...x,
      image: x.imageUrl,
      publishTime: x.createdTime,
      reactions: x.favorites,
      commentsNumber: x.replies,
    }));
  }
  return res;
};
export default class PostProvider {
  static async fetchBy(socialNetwork, pagination, orderBy) {
    let query;
    let queryName;
    let mock;
    let result;
    try {
      switch (socialNetwork) {
        case SOCIAL_MEDIA.facebook:
          query = facebookPosts;
          queryName = 'facebookPosts';
          mock = facebookPostsMock;
          break;
        case SOCIAL_MEDIA.instagram:
          query = instagramPosts;
          queryName = 'instagramPosts';
          mock = instagramPostsMock;
          break;
        case SOCIAL_MEDIA.twitter:
          query = tweets;
          queryName = 'tweets';
          mock = twitterPostsMock;
          break;
        case SOCIAL_MEDIA.linkedin:
          query = getLinkedinPosts;
          queryName = 'getLinkedinPosts';
          mock = linkedinPostsMock;
          break;
        default:
          break;
      }
      if (ENV !== ENVIRONMENTS.local) {
        result = await authAppSync.client.query({
          query,
          fetchPolicy: 'network-only',
          variables: {
            pagination,
            orderBy,
          },
        });
      } else {
        result = mock;
      }
      const { data } = result;
      const posts = data[queryName];

      result = {
        message: 'success',
        success: true,
        data: getSNPayload(socialNetwork, posts),
      };
    } catch (err) {
      Sentry.captureException(err);
      result = {
        message: err.message,
        success: false,
        data: null,
      };
    }
    return result;
  }

  static async deletePost(socialNetwork, id, force) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let deleteMutation;
      switch (socialNetwork) {
        case FACEBOOK:
          deleteMutation = deleteFacebookPost;
          break;
        case TWITTER:
          deleteMutation = deleteTweet;
          break;
        case LINKEDIN:
          deleteMutation = deleteLinkedinPost;
          break;
        default:
          deleteMutation = deleteInstagramPost;
      }
      if (ENV !== ENVIRONMENTS.local) {
        const variables = {
          contentId: parseInt(id, 10),
        };
        if (force && socialNetwork === FACEBOOK) {
          variables.force = force;
        }
        const response = await authAppSync.client.mutate({
          mutation: deleteMutation,
          variables,
        });
        result = {
          message: 'success',
          success: true,
          data: [],
        };
        if (socialNetwork === FACEBOOK) {
          result = {
            message: response.data.deleteFacebookPost.message,
            success: response.data.deleteFacebookPost.success,
            data: response.data.deleteFacebookPost.data,
          };
          return result;
        }
        if (socialNetwork === LINKEDIN) {
          result = {
            message: response.data.deleteLinkedinPost.message,
            success: response.data.deleteLinkedinPost.success,
            data: response.data.deleteLinkedinPost.data,
          };
          return result;
        }
      } else {
        result = {
          message: 'success',
          success: true,
          data: [20],
        };
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async reschedulePost(socialNetwork, id, postingOption, scheduleDate) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let variables;
      let rescheduleMutation;
      const contentId = parseInt(id, 10);
      if (postingOption === 'now') {
        variables = {
          contentId,
          postNow: true,
        };
      } else {
        variables = {
          contentId,
          postNow: false,
          scheduleTime: formatISO(scheduleDate),
        };
      }
      switch (socialNetwork) {
        case FACEBOOK:
          rescheduleMutation = rescheduleFacebookPost;
          break;
        case TWITTER:
          rescheduleMutation = rescheduleTweet;
          break;
        case INSTAGRAM:
          rescheduleMutation = rescheduleInstagram;
          break;
        case STORIES_IG:
          rescheduleMutation = rescheduleInstagramStorie;
          break;
        case TIKTOK:
          rescheduleMutation = editTiktokPost;
          break;
        default:
          rescheduleMutation = rescheduleLinkedinPost;
          break;
      }

      await authAppSync.client.mutate({
        mutation: rescheduleMutation,
        variables,
      });
      result = {
        message: 'success',
        success: true,
        data: [],
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async editPost(socialNetwork, post, message) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const contentId = parseInt(post.id, 10);
      let mutation;
      switch (socialNetwork) {
        case FACEBOOK:
          mutation = editFacebookPost;
          break;
        case TWITTER:
          mutation = editTweet;
          break;
        case INSTAGRAM:
          mutation = editInstagramPost;
          break;
        case LINKEDIN:
          mutation = editLinkedinPost;
          break;
        case TIKTOK:
          mutation = editTiktokPost;
          break;
        default:
          return result;
      }
      await authAppSync.client.mutate({
        mutation,
        variables: {
          message,
          contentId,
        },
      });
      result = {
        message: 'success',
        success: true,
        data: [],
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
