import React from 'react';

const contextObject = {
  canUseTemplates: false,
  setCanUseTemplates: () => {},
  canUseEditor: false,
  setCanUseEditor: () => {},
};

const PermissionsContext = React.createContext(contextObject);

export default PermissionsContext;
