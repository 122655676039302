import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { colors } from '../styled/theme';
import styled from 'styled-components';
import GlobalTheme from '../styled/GlobalTheme';

const ComingSoonText = styled.span`
  font-size: 8px;
  color: ${colors.gray02};
  width: 15%;
  text-align: center;
  margin-left: 4px;
  ${GlobalTheme.breakpoints.down('sm')} {
    text-align: left;
  }
  @media (max-width: 480px) {
    text-align: center;
  }
`;

// Function for plans info
export default function useGetPlansInfo() {
  const intl = useIntl();
  const basic = {
    title: intl.formatMessage({
      id: 'settings.billing.planInformation.litePlan.title',
      defaultMessage: 'Lite Plan',
    }),
    description: intl.formatMessage({
      id: 'pricing.plan.description.lite',
      defaultMessage:
        'Our limited plan, where you can use our basic functions to post in social media.',
    }),
    features: [
      intl.formatMessage({
        id: 'pricing.plan.lite.content1',
        defaultMessage: '10 posts per month.',
      }),
      intl.formatMessage({
        id: 'pricing.plan.lite.content2',
        defaultMessage: 'Use Piper recommendation templates.',
      }),
      intl.formatMessage({
        id: 'pricing.plan.lite.content3',
        defaultMessage: 'Access via web.',
      }),
    ],
    detailsDescription: intl.formatMessage({
      id: 'planOption.basic.details.description',
      defaultMessage: 'Your Basic account allows you to:',
    }),
    details: [
      intl.formatMessage({
        id: 'planOption.basic.details.connect',
        defaultMessage:
          'Connect your Facebook, Instagram and Twitter business accounts (1 for each social network).',
      }),
      intl.formatMessage({
        id: 'planOption.basic.details.recommended',
        defaultMessage:
          'Use graphic content suggested by Piper or upload your own content',
      }),
      intl.formatMessage({
        id: 'planOption.basic.details.whenToPost',
        defaultMessage: 'Know when and where to post thanks to Piper.',
      }),
      intl.formatMessage({
        id: 'planOption.basic.details.comments',
        defaultMessage: 'Answer comments on your social networks.',
      }),
      intl.formatMessage({
        id: 'planOption.basic.details.messages',
        defaultMessage: 'Access your Facebook Messenger messages.',
      }),
      intl.formatMessage({
        id: 'planOption.basic.details.competitors',
        defaultMessage:
          'Monitor your competitors (You can add up to 1 competitor on Facebook and 1 on Instagram).',
      }),
      intl.formatMessage({
        id: 'planOption.basic.details.editPosts',
        defaultMessage: 'Manage and edit your posts in calendar view.',
      }),
    ],
    color: colors.lightPurple,
  };
  const pro = {
    title: intl.formatMessage({
      id: 'k.proPlan',
      defaultMessage: 'Pro Plan',
    }),
    description: intl.formatMessage({
      id: 'pricing.plan.description.pro',
      defaultMessage: 'Take advantage of Social Piper’s full power & features, and make your business thrive in social media.',
    }),
    features: [
      intl.formatMessage({
        id: 'pricing.plan.pro.content1',
        defaultMessage: 'Access +1000 templates ready to use.',
      }),
      intl.formatMessage({
        id: 'pricing.plan.pro.content2',
        defaultMessage: 'Access to Social Piper Academy.',
      }, {
        values: '',
      }),
      intl.formatMessage({
        id: 'pricing.plan.pro.content3',
        defaultMessage: 'Create advertising campaigns.',
      }),
      intl.formatMessage({
        id: 'pricing.plan.pro.content4',
        defaultMessage: 'Monitor your competitors.',
      }),
      intl.formatMessage({
        id: 'pricing.plan.pro.content5',
        defaultMessage: 'Access via web and mobile app.',
      }),
    ],
    detailsDescription: intl.formatMessage({
      id: 'planOption.pro.details.description',
      defaultMessage:
        'It includes all the Lite plan features, plus you will be able to:',
    }),
    details: [
      intl.formatMessage({
        id: 'planOption.pro.details.publish',
        defaultMessage: 'Publish with no limits.',
      }),
      intl.formatMessage({
        id: 'planOption.pro.details.templates',
        defaultMessage: 'Access thousands of templates.',
      }),
      intl.formatMessage({
        id: 'planOption.pro.details.editor',
        defaultMessage: 'Use the online graphic editor.',
      }),
      intl.formatMessage({
        id: 'planOption.pro.details.ads',
        defaultMessage: 'Create Facebook ad boosts.',
      }),
      intl.formatMessage({
        id: 'planOption.pro.details.budget',
        defaultMessage: 'Keep control of your boosts budget.',
      }),
      intl.formatMessage({
        id: 'planOption.pro.details.competitors',
        defaultMessage: 'Add as many competitors as you want.',
      }),
    ],
    recommended: true,
    color: colors.lightBlue,
  };
  const business = {
    title: intl.formatMessage({
      id: 'newPricing.full.managment.title',
    }),
    description: intl.formatMessage({
      id: 'newPricing.plan.business.info',
    }),
    features: [
      intl.formatMessage({
        id: 'newPricing.plan.business.content1',
      }),
      intl.formatMessage({
        id: 'newPricing.plan.business.content3',
      }),
      intl.formatMessage({
        id: 'newPricing.plan.business.content4',
      }),
    ],
    detailsDescription: intl.formatMessage({
      id: 'planOption.business.details.description',
      defaultMessage: 'It includes everything from the Basic and Pro Plan, plus:',
    }),
    details: [
      intl.formatMessage({
        id: 'planOption.business.details.multiuser',
        defaultMessage: 'Multi-user access for your team.',
      }),
      intl.formatMessage({
        id: 'planOption.business.details.connect',
        defaultMessage: 'Connect more than one Facebook, Instagram, and Twitter account.',
      }),
      intl.formatMessage({
        id: 'planOption.business.details.approval',
        defaultMessage: 'Content and boost review and approval.',
      }),
    ],
    comingSoon: false,
    color: colors.orange,
  };
  return {
    basic,
    pro,
    business,
  };
}
