import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../buttons';
// eslint-disable-next-line import/no-cycle
import { LayoutContext } from '../../contexts/LayoutContext';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import { WebViewContext } from '../../contexts/WebViewContext';
import { colors } from '../../styled/theme';

const StyledButton = styled(Button)`
  && {
    font-size: 16px;
    color: ${({ filled }) => (filled ? 'white' : colors.green)};
    background: ${({ filled }) => (filled ? colors.green : 'white')};
    padding: 8px;
    &:hover {
      background: ${({ filled }) => (filled ? colors.green : 'white')};
    }
  }
`;

const BecomeProButton = ({
  className,
  filled,
  children,
}) => {
  const { setShowBecomeProModal, setShowModalSubAppsModal } = useContext(LayoutContext);
  const { dataLayerPush } = useContext(AnalyticsContext);
  const { isApp } = useContext(WebViewContext);
  const location = useLocation();
  const manageBecomeProModal = () => {
    dataLayerPush({
      pagePath: location.pathname,
      pathTitle: 'Pro Offer Modal',
      dataAction: 'Become Pro Click',
      event: 'action',
    });
    setShowBecomeProModal(true);
  };
  return (
    <StyledButton
      className={className}
      onClick={() => {
        if (isApp) {
          setShowModalSubAppsModal(true);
        } else {
          manageBecomeProModal();
        }
      }}
      filled={filled}
    >
      {children || (
        <FormattedMessage
          id="k.upgrade"
          defaultMessage="Upgrade!"
        />
      )}
    </StyledButton>
  );
};

BecomeProButton.propTypes = {
  className: PropTypes.string,
  filled: PropTypes.string,
  children: PropTypes.instanceOf(Object),
};

BecomeProButton.defaultProps = {
  className: '',
  filled: '',
  children: false,
};

export default BecomeProButton;
