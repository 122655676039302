import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Media from 'react-media';
import mediaQueries from '../../../utils/mediaQueries';
import { SantanderChallenge } from '../../../assets/logos/logoRepository';
import { StarWhiteIcon } from '../../../assets/icons/iconRepository';

const Wrapper = styled.section`
  background: linear-gradient(90deg, #FF1D25 0%, #662D8B 100%);
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 38px;
`;
const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 314px;
  & > * {
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IconContainer = styled.div`
  display: flex;
  &:nth-child(1) {
    margin-right: 4px;
  }
  &:nth-child(3) {
    margin-left: 4px;
  }
`;
const Icon = styled.img``;
const Title = styled.h3`
  font-size: 25px;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
`;
const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  margin: 0;
  margin-top: 4px;
`;
const BadgeContainer = styled.div`
  margin-top: 4px;
`;
const Badge = styled.img``;

const AwardSection = () => {
  const intl = useIntl();
  return (
    <Wrapper>
      <Content>
        <TitleContainer>
          <IconContainer>
            <Icon src={StarWhiteIcon} alt="Star Icon" />
          </IconContainer>
          <Title>
            <FormattedMessage
              id="landing.awardSection.title"
              defaultMessage="2021 WINNER"
            />
          </Title>
          <IconContainer>
            <Icon src={StarWhiteIcon} alt="Star Icon" />
          </IconContainer>
        </TitleContainer>
        <Subtitle>
          <FormattedMessage
            id="landing.awardSection.subtitle"
            defaultMessage="WORLD’S BEST SOCIAL MEDIA PLATFORM FOR SMALL BUSINESSES"
          />
        </Subtitle>
        <BadgeContainer>
          <Badge src={SantanderChallenge} />
        </BadgeContainer>
      </Content>
    </Wrapper>
  );
};

export default AwardSection;
