import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../../styled/theme';
import { AMEX } from '../../../../utils/constants/settings';
import cards from '../../../../assets/cards/cardsRepository';
import { paragraph } from '../../../../styled/mixins';
import GlobalTheme from '../../../../styled/GlobalTheme';

const StyledCardDetails = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 290px;
`;

const CardCol = styled.div`
  display: flex;
  align-items: center;
`;

const CardBrandImg = styled.img`
  height: 30px;
  width: 55px;
  object-fit: contain;
  object-position: center;
  margin-right: 5px;
`;

const CardField = styled.p`
  ${paragraph}
  font-size: 18px;
  color: ${colors.text02};
  margin-right: 16px;
`;

const ExpField = styled(CardField)`
  ${GlobalTheme.breakpoints.down('md')}{
    display: none;
  }
`;

const MobileExpField = styled(CardField)`
  display: none;
  ${GlobalTheme.breakpoints.down('md')}{
    display: block;
  }
`;

const CardDetails = ({ card }) => {
  const getBrandLogo = () => {
    if (card.brand === AMEX) {
      return cards.amex;
    }
    return cards[card.brand.toLowerCase()];
  };
  return (
    <StyledCardDetails>
      <CardCol>
        <CardBrandImg src={getBrandLogo(card)} alt={card.brand} />
        <CardField>
          {card.name.split(' ')[0]} **{card.last4}
          <MobileExpField>
            <FormattedMessage
              id="settings.billing.billingInformation.card.expirationAbbr"
              defaultMessage="Exp"
            />
            {` ${card.exp}`}
          </MobileExpField>
        </CardField>
      </CardCol>
      <CardCol>
        <ExpField>
          <FormattedMessage
            id="settings.billing.billingInformation.card.expirationAbbr"
            defaultMessage="Exp"
          />
          {` ${card.exp}`}
        </ExpField>
      </CardCol>
    </StyledCardDetails>
  );
};

CardDetails.propTypes = {
  card: PropTypes.instanceOf(Object).isRequired,
};

export default CardDetails;
