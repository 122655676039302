const es = {
  'landing.instagram.header.intro.text': 'para',
  'landing.instagram.header.actions.action1': 'Programa historias',
  'landing.instagram.header.actions.action2': 'Publica imagen o video',
  'landing.instagram.header.actions.action3': 'Descubre cuándo publicar',
  'landing.instagram.header.title': 'Dale superpoderes al Instagram de tu negocio',
  'landing.instagram.header.subtitle': 'Programa o publica tus historias, videos o imágenes desde Social Piper a todas las redes de tu negocio',
  'landing.instagram.header.cta.button': 'Inicia tu prueba de {daysTrial} días',
  'landing.instagram.header.cta.button.help.text': 'Sin riesgos, puedes cancelar cuando quieras',
  'landing.instagram.quote.text': 'Instagram ayuda al 80% de los consumidores a tomar una decisión de compra de un producto o servicio (...) En un año (2017-2018), hubo un aumento del +80% en el tiempo de visualización de videos.',
  'landing.instagram.quote.source': 'Digimind,2019',
  'landing.instagram.actions.title': 'Con Social Piper la mejor <s>herramienta para Instagram</s> puedes:',
  'landing.instagram.action1.title': '<s>Programar</s> y publicar inmediatamente tus historias',
  'landing.instagram.action1.description': 'Solo en Social Piper puedes programar una Historia de Instagram, o bien publica inmediatamente a las historias de Instagram de tu negocio.',
  'landing.instagram.action2.title': 'Publicar <s>simultáneamente</s> a todas tus redes',
  'landing.instagram.action2.description': 'Olvídate de ir a cada red a publicar tu contenido.  Con Social Piper publica tus imágenes , videos o historias simultáneamente a todas tus redes en un solo click. ',
  'landing.instagram.action3.title': 'Recibir <s>recomendaciones</s> de cuándo publicar',
  'landing.instagram.action3.description': 'Con nuestra I.A.  representada por Piper, recibe los mejores días y horas para publicar en las redes de tu negocio, para llegar a más personas. ',
  'landing.instagram.action4.title': 'Publicar videos o imágenes <s>optimizados</s> para tus redes',
  'landing.instagram.action4.description': 'A través de Social Piper, tus videos o imágenes estarán optimizados para que tus clientes puedan ver más rápido tus contenidos.',
  'landing.instagram.action5.title': '<s>Agregar</s> tu logo a tus publicaciones facilmente',
  'landing.instagram.action5.description': 'No te preocupes teniendo que cargar tu logo por todos lados, con Social Piper lo puedes poner facilmente en todas tus publicaciones, con un solo clic. ',
  'landing.instagram.simulation.title': '<h>Es muy fácil,</h> ve cómo:',
  'landing.instagram.simulation.steps.step1.text': 'Elige tu contenido y escribe tu texto',
  'landing.instagram.simulation.steps.step2.text': 'Selecciona en qué redes publicar',
  'landing.instagram.simulation.steps.step3.text': 'Ve la mejor recomendación para publicar o elige la tuya',
  'landing.instagram.simulation.steps.step4.text': '¡Listo! ¡Tu contenido en tus redes!',
  'landing.instagram.relax.cta.text': 'Todo el contenido de tu negocio en Instagram fácil y rápido.',
  'landing.instagram.relax.cta.button': 'Inicia tu prueba ahora',
  'landing.instagram.moreactions.title': 'También en <s>Social Piper puedes:</s>',
  'landing.instagram.moreactions.action1.description': 'Usar + 3,000 plantillas o editar tu contenido',
  'landing.instagram.moreactions.action2.description': 'Publica videos o imágenes optimizados para tus redes',
  'landing.instagram.moreactions.action3.description': 'Ve los mejores horarios para tu negocio',
  'landing.instagram.moreactions.action4.description': 'Responde mensajes y comentarios de todas tus redes en un solo lugar',
  'landing.instagram.moreactions.cta.button': 'Inicia tu prueba gratis de {daysTrial} días',
  'landing.instagram.moreactions.cta.description': 'Prueba Social Piper sin riegos, puedes cancelar cuando quieras.',
};

export default es;
