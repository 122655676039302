import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReduceFormatted from './ReduceFormatted';
import routes, {
  ACADEMY_ROUTE,
} from '../../../utils/constants/routes';
import { MEDIA_LIBRARY_TITLE } from '../../../utils/constants/settings';
import {
  SettingsIcon,
  LogoutIcon,
  HelpIcon,
  AcademyIcon,
} from '../../../assets/icons/iconRepository';

export const menuDictionaryWithAuth = (totalUnread, intl, hasError) => [
  {
    title: <ReduceFormatted languageKey="k.scheduler" />,
    gaData: { category: 'Main navbar', action: 'Scheduler', label: '(button)(navbar)' },
    to: routes.SCHEDULER,
    hasError,
  },
  {
    title: <ReduceFormatted languageKey="k.boostManager" />,
    gaData: { category: 'Main navbar', action: 'Boost Manager', label: '(button)(navbar)' },
    to: routes.BOOST_MANAGER,
  },
  {
    title: <ReduceFormatted languageKey="k.metrics.title" />,
    gaData: { category: 'Main navbar', action: 'Metrics', label: '(button)(navbar)' },
    to: routes.METRICS,
  },
  {
    title: <ReduceFormatted languageKey="k.creativityCenter" />,
    gaData: { category: 'Main navbar', action: MEDIA_LIBRARY_TITLE, label: '(button)(navbar)' },
    to: routes.MEDIA_LIBRARY,
  },
  {
    title: <ReduceFormatted languageKey="k.community" />,
    gaData: { category: 'Main navbar', action: 'Community', label: '(button)(navbar)' },
    to: routes.COMMUNITY,
    unreads: totalUnread,
  },
];

export const menuDictionaryWithoutAuth = [
  {
    title: <ReduceFormatted languageKey="k.pricing" />,
    gaData: { action: 'Pricing', label: '(nav bar)' },
    to: routes.PRICING,
  },
  {
    title: <ReduceFormatted languageKey="k.login" />,
    gaData: { action: 'Log in', label: '(nav bar)' },
    to: routes.LOGIN,
  },
  {
    title: <ReduceFormatted languageKey="k.signup" />,
    gaData: { action: 'Lets get started', label: '(button)(nav bar)' },
    to: routes.CREATE_ACCOUNT,
  },
];

export const navBarIconsDictionary = (intl) => [
  {
    title: <ReduceFormatted languageKey="k.settings" />,
    gaData: { action: 'Settings', label: '(button)(navBar)(menuItem)' },
    to: routes.SETTINGS,
    icon: SettingsIcon,
  },
  {
    title: <FormattedMessage id="k.academy" defaultMessage="Academy" />,
    gaData: { action: 'Academy', label: '(button)(navBar)(menuItem)' },
    href: `${ACADEMY_ROUTE}/${intl.locale}`,
    icon: AcademyIcon,
  },
  {
    title: <FormattedMessage id="k.help" defaultMessage="Help" />,
    gaData: { action: 'Help', label: '(button)(navBar)(menuItem)' },
    href: 'https://help.socialpiper.com',
    icon: HelpIcon,
  },
  {
    title: <ReduceFormatted languageKey="k.logout" />,
    gaData: { action: 'Logout', label: '(button)(navBar)(menuItem)' },
    to: routes.LOGOUT,
    icon: LogoutIcon,
  },
];

export const fullMobileMenuDictionaryWithAuth = (totalUnread) => [
  ...menuDictionaryWithAuth(totalUnread),
  ...navBarIconsDictionary,
];
