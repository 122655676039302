import {
  endOfDay, endOfWeek, startOfDay, startOfWeek,
} from 'date-fns';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

export const AICalendarContext = createContext();

export default function ({ children }) {
  const [AIPosts, setAIPosts] = useState([]);
  const [useAI, setUseAI] = useState(false);
  const [type, setType] = useState('Week');
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [captionPrompt, setCaptionPrompt] = useState('');
  const [imagePrompt, setImagePrompt] = useState('');

  const updatePost = (newPost, x = AIPosts) => AIPosts.map((post) => (post.id === newPost.id ? newPost : post));

  const getPosts = (from, to, x = AIPosts) => AIPosts.filter((post) => moment(post.scheduleTime).isBetween(moment(from), moment(to)));

  const getSelectedPost = () => {
    const fn = (x) => x
      .map((y) => {
        if (Array.isArray(y)) {
          return fn(y);
        } if (y.id === selectedPostId) {
          return y;
        }
      })
      .filter(Boolean);

    return fn(AIPosts)[0];
  };

  const getPostsByDay = (day) => {
    const from = startOfDay(new Date(day));
    const to = endOfDay(new Date(day));
    const posts = getPosts(from, to);

    return {
      day,
      posts,
    };
  };

  const getPostsByWeek = (week) => {
    const response = {
      from: startOfWeek(new Date(week)),
      to: endOfWeek(new Date(week)),
      posts: [],
    };
    for (let i = 0; i < 7; i++) {
      const postsByDay = getPostsByDay(
        moment(response.from).add(i, 'days').toISOString(),
      );
      if (postsByDay.posts.length) response.posts.push(postsByDay);
    }
    return response;
  };

  const getPostsByMonth = () => {
    const response = [];
    let weekNumber = 0;
    let postsByWeek = [];
    do {
      postsByWeek = getPostsByWeek(
        moment().add(weekNumber, 'weeks').toISOString(),
      );
      if (postsByWeek.posts.length) response.push(postsByWeek);
      weekNumber += 1;
    } while (postsByWeek.posts.length || weekNumber < 2);

    return response;
  };

  return (
    <AICalendarContext.Provider
      value={{
        AIPosts,
        setAIPosts,
        updatePost,
        getPosts,
        useAI,
        setUseAI,
        type,
        setType,
        captionPrompt,
        setCaptionPrompt,
        imagePrompt,
        setImagePrompt,
        selectedPostId,
        setSelectedPostId,
        getSelectedPost,
        getPostsByDay,
        getPostsByMonth,
        getPostsByWeek,
      }}
    >
      {children}
    </AICalendarContext.Provider>
  );
}

AICalendarContext.propTypes = {
  children: PropTypes.node.isRequired,
};
