const en = {
  'tools.header.title': 'Tools',
  'tools.header.description': 'User-friendly tools {lineBreak}and tailored tips let {lineBreak}you easily create, {lineBreak}or dramatically {lineBreak}improve, your {lineBreak}social media {lineBreak}presence',
  'tools.toolsSection.tool1.title': 'Create',
  'tools.toolsSection.tool1.description': '<bText>Piper will show you how to use our powerful Post Editor.</bText> {lineBreak}With free creative designs at your fingertips, and Piper’s help creating professional looking posts, getting started is a snap.',
  'tools.toolsSection.tool2.title': 'Post',
  'tools.toolsSection.tool2.description': '<bText>Our simple scheduler lets you post to all your social accounts at one time.</bText> {lineBreak}Piper will tell you when your customers are most active. You can follow his lead, or set your own publishing schedule.',
  'tools.toolsSection.tool3.title': 'Blast off!',
  'tools.toolsSection.tool3.description': '<bText>One click boosting.<bText> {lineBreak}With Piper’s insights you can easily reach larger audiences to grow your business. Piper will suggest the wisest spend for your social media marketing.',
  'tools.toolsSection.tool4.title': 'Connect',
  'tools.toolsSection.tool4.description': 'Reply to comments across all platforms from one dashboard.<bText>Increase engagement and connect with your audience in a flash.<bText>',
  'tools.toolsSection.helpText': 'Get tailored tips to help your business grow!',
  'tools.features.instagram.title': 'Instagram, {lineBreak}supercharged!',
  'tools.features.instagram.title.mobile': 'Instagram, {lineBreak}supercharged!',
  'tools.features.instagram.description': 'Schedule with ease,  or publish immediately to Stories. No delay. Images and videos are automatically optimized for speedy delivery.',
  'tools.features.time.title': 'Time-saving tricks:',
  'tools.features.time.description': 'Wheee!  Post  stories, images, and videos to all your social profiles WITHIN SECONDS, simultaneously.',
  'tools.features.betTime.title': 'Better, Better, Best',
  'tools.features.bestTime.description': 'With Piper’s tips and tricks you’ll grow reach and engagement',
  'tools.features.bestTime.description2': 'Feedback from Piper and insights from experts help you stay at the top of your game.',
  'tools.features.boost.title': 'Boost for success!',
  'tools.features.boost.description': 'Piper helps you manage your ad spending to reach your biggest audience:',
  'tools.features.boost.description2': '“You have $45 left and could reach a target of 10,000 users with a boost post tonight.“',
};

export default en;
