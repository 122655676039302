import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog as MuiDialog } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import propTypes from 'prop-types';
import styled from 'styled-components';
import GlobalTheme from '../../../styled/GlobalTheme';
import { CloseOutlineIcon } from '../../../assets/icons/iconRepository';
import { Icon } from '../ColoredIcon';
import colors from '../../../styled/colors';
import UpgradeSection, { NOT_REACHED, CURRENT, FINISHED } from './UpgradeSection';
import ConfirmPlan from './components/ConfirmPlan';
import SelectPlan from './components/SelectPlan';
import {
  SELECT_PLAN_STAGE,
  PAYMENT_DETAILS_STAGE,
  CONFIRM_STAGE,
  SUBSCRIPTION_ERROR,
  STAGE_NAMES,
  ROUTE_BASE,
} from './constants';
import routes from '../../../utils/constants/routes';
import PaymentDetails from './components/PaymentDetails';
import { BillingProvider } from '../../../services/entities';
import SubscriptionConfirmation from './components/SubscriptionConfirmation';
import { AnalyticsContext } from '../../../contexts/AnalyticsContext';
import RocketLoader from '../RocketLoader';

const StyledModal = styled(MuiDialog)`
&& {
  .MuiPaper-root{
    min-width: 100%;
  }
  .MuiDialog-paperScrollPaper {
    max-height: 100%;
    height: inherit;
  }
  ${GlobalTheme.breakpoints.up('lg')} {
    .MuiPaper-root{
      max-height: ${({ activeStage }) => (activeStage >= SUBSCRIPTION_ERROR ? '530px' : '768px')};
      min-width: 788px;
    }
  }
}
`;

const GreenBanner = styled.div`
  background: ${colors.green};
  width: 100%;
  color: ${colors.white};
  padding: 10px;
  position: relative;
`;

const UpgradeTitle = styled.h3`
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  margin: auto;
  text-align: center;
`;

const CloseIcon = styled(Icon)`
&& {
  position: absolute;
  right: 16px;
  color: white;
  top: 50%;
  transform: translateY(-50%);
}
`;

const Stage = styled.div`
  width: 100%;
  height: 100%;
`;

const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 30px 0;
  padding: 0 20px;
`;

const BodyContainer = styled.div`
  width: 100%;
  padding: 0 40px;
  ${GlobalTheme.breakpoints.down('sm')} {
    height: auto;
  }
`;

const LoaderContainer = styled.div`
  max-width: 90%;
  text-align: center;
  margin: 0 auto 0;
`;
const LoaderTitle = styled.p`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  ${GlobalTheme.breakpoints.up('sm')} {
    font-size: 48px;
  }
`;
const LoaderText = styled.p`
  font-size: 14px;
  ${GlobalTheme.breakpoints.up('sm')} {
    font-size: 20px;
  }
`;

const ProOfferModal = ({ open, setOpen, toSettings }) => {
  const history = useHistory();
  const [isMonthly, setIsMonthly] = useState(true);
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [activeStage, setActiveStage] = useState(SELECT_PLAN_STAGE);
  const { dataLayerPush } = useContext(AnalyticsContext);
  const [analyticsRoute, setAnalyticsRoute] = useState(ROUTE_BASE + STAGE_NAMES[SELECT_PLAN_STAGE]);

  const stages = [
    {
      id: 1,
      key: 'proOfferModal.stage.one',
      defaultMessage: 'Select your plan',
    },
    {
      id: 2,
      key: 'proOfferModal.stage.two',
      defaultMessage: 'Payment details',
    },
    {
      id: 3,
      key: 'proOfferModal.stage.three',
      defaultMessage: 'Confirm',
    },
  ];

  const handleClose = (refresh) => {
    dataLayerPush({
      pagePath: analyticsRoute,
      pathTitle: 'Pro Offer Modal',
      dataAction: 'Close Offer Modal',
      event: 'action',
    });
    setActiveStage(SELECT_PLAN_STAGE);
    if (refresh) {
      window.location.reload();
      setOpen(false);
      return;
    }
    if (toSettings) {
      history.push(routes.SETTINGS_BILLING);
      setOpen(false);
      return;
    }
    setOpen(false);
  };
  const continueAction = (overrideAction) => {
    dataLayerPush({
      pagePath: analyticsRoute,
      pathTitle: 'Pro Offer Modal',
      dataAction: overrideAction || 'Continues Offer Modal',
      event: 'action',
    });
    setAnalyticsRoute(ROUTE_BASE + STAGE_NAMES[activeStage]);
  };
  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const response = await BillingProvider.fetchPaymentMethods();
      if (!response.success) return;
      setPaymentMethods(response.data);
    };

    if (open) {
      const pagePath = ROUTE_BASE + STAGE_NAMES[activeStage];

      dataLayerPush({
        pagePath,
        pathTitle: 'Pro Offer Modal',
        dataAction: 'View Offer Modal',
        event: 'pv',
      });
      setAnalyticsRoute(pagePath);
      fetchPaymentMethods();
    }
  }, [open]);

  const getStage = (id) => {
    if (activeStage < id) return NOT_REACHED;
    if (activeStage > id) return FINISHED;
    return CURRENT;
  };

  return (
    <StyledModal
      open={open}
      fullScreen={window.innerWidth <= GlobalTheme.breakpoints.values.sm}
      activeStage={activeStage}
    >
      {activeStage < SUBSCRIPTION_ERROR ? (
        <>
          <GreenBanner>
            <UpgradeTitle>
              <FormattedMessage
                id="k.subscribe"
                defaultMessage="Subscribe!"
              />
            </UpgradeTitle>
            <CloseIcon src={CloseOutlineIcon} color={colors.white} size="20px" onClick={() => handleClose()} />
          </GreenBanner>
          <Stage>
            <SectionContainer>
              {stages.map((stage) => (
                <UpgradeSection
                  processState={getStage(stage.id)}
                  key={stage.id}
                  stage={stage.id}
                >
                  <FormattedMessage
                    id={stage.key}
                    defaultMessage={stage.defaultMessage}
                  />
                </UpgradeSection>
              ))}
            </SectionContainer>
            <BodyContainer>
              {activeStage === SELECT_PLAN_STAGE && (
                <SelectPlan
                  setActiveStage={setActiveStage}
                  isMonthly={isMonthly}
                  setIsMonthly={setIsMonthly}
                  continueAction={continueAction}
                />
              )}
              {activeStage === PAYMENT_DETAILS_STAGE && (
                <PaymentDetails
                  paymentMethods={paymentMethods}
                  setPaymentMethods={setPaymentMethods}
                  setActiveStage={setActiveStage}
                  continueAction={continueAction}
                />
              )}
              {activeStage === CONFIRM_STAGE && (
                <ConfirmPlan
                  paymentMethods={paymentMethods}
                  isMonthly={isMonthly}
                  setActiveStage={setActiveStage}
                  continueAction={continueAction}
                  loading={loading}
                  setLoading={setLoading}
                />
              )}
            </BodyContainer>
          </Stage>
        </>
      ) : (
        <SubscriptionConfirmation
          activeStage={activeStage}
          handleClose={handleClose}
          setActiveStage={setActiveStage}
          continueAction={continueAction}
        />
      )}
      {(loading) && (
        <RocketLoader>
          <LoaderContainer>
            <LoaderTitle>
              <FormattedMessage
                id="proOfferModal.error.loader.title"
              />
            </LoaderTitle>
            <LoaderText>
              <FormattedMessage
                id="proOfferModal.error.loader.text"
                values={{
                  bold: (chunks) => (<b>{chunks}</b>),
                }}
              />
            </LoaderText>
          </LoaderContainer>
        </RocketLoader>
      )}
    </StyledModal>
  );
};

ProOfferModal.propTypes = {
  open: propTypes.bool.isRequired,
  setOpen: propTypes.func.isRequired,
};

export default ProOfferModal;
