const en = {
  'socialLoginError.already-connected': 'Your account is already associated to another SocialPiper account',
  'socialLoginError.contactUs': 'For more information contact us at <strong>support@socialpiper.com</strong>',
  'socialLoginError.error': 'Your account was not connected.',
  'socialLoginError.facebook-text': 'This social network is already connected to a Social Piper profile. Please check your account or contact support@socialpiper.com.',
  'socialLoginError.instagram-text': "Make sure your Instagram account is a business account, and it's associated to the same Facebook account you connected to SocialPiper",
  'socialLoginError.title': 'There was a problem connecting your social profile',
  'socialLoginError.twitter-text': 'There was an error connecting your Twitter account',

  'socialLogin.error.page.title.one': 'You didn\'t connect your business page!',
  'socialLogin.error.page.title.two': 'The {network} profile you want to connect is already linked to {email}',
  'socialLogin.error.page.title.three': 'Oh no! Your business\'s Facebook page could not be connected.',
  'socialLogin.error.page.title.four': 'You did not grant all the necessary permissions!',
  'socialLogin.error.page.title.five': 'Oh no! You canceled the process.',

  'socialLogin.error.page.subtitle': 'This is your first and most important step.',

  'socialLogin.error.page.description': 'Your information is safe, we are an application approved by {network} and we take care of the privacy of your data.',

  'socialLogin.error.page.boxHelp.title': 'What can I do now?',

  'socialLogin.facebook.error.page.boxHelp.item.one': 'If you don\'t have a business page, <TextLink>create one for free now</TextLink> and try again.',
  'socialLogin.facebook.error.page.boxHelp.item.two': 'If you already have one but can\'t find it, you may not have permissions to manage it. Check with your team, and ask them to give you these accesses. <TextLink>Review this guide</TextLink> and try again.',

  'socialLogin.instagram.error.page.boxHelp.item.one': 'If your profile is not for business, <TextLink>convert it now in a few minutes by following these steps.</TextLink>',
  'socialLogin.instagram.error.page.boxHelp.item.two': 'Remember that during the conversion process, you will also need to link your Instagram profile to a Facebook business page.',

  'socialLogin.already.error.page.boxHelp.item.one': 'We have notified the user that you want to connect this account. Please contact the user and log in with the corresponding account.',

  'socialLogin.error.page.boxHelp.item.one': 'This may be due to the quality of the internet connection, a temporary problem, etc. Please try again.',
  'socialLogin.permissions.error.page.boxHelp.item.one': 'It is essential that during the process you just did to connect your Facebook page to Social Piper, all the switches are active (in blue).',

  'socialLogin.error.page.button.tryAgain': 'Try again',
  'socialLogin.error.page.button.tryLater': 'I will try later',

  'socialLogin.error.page.contacthelp.text.one': '<bold>Need help?</bold> Chat with us or contact us at support@socialpiper.com',
  'socialLogin.error.page.contacthelp.text.two': 'If you need help to unlink the profile to the current account, chat with us or to the email suppor@socialpiper.com',
  'socialLogin.error.page.contacthelp.text.three': '<bold>If the problem persists</bold> chat with us or write to us at support@socialpiper.com',
};

export default en;
