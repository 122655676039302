const es = {
  'tools.header.title': 'Herramientas',
  'tools.header.description': 'Con herramientas {lineBreak}fáciles de usar y {lineBreak}consejos {lineBreak}personalizados, {lineBreak}podrás crear  o {lineBreak}mejorar {lineBreak}drásticamente tu {lineBreak}presencia en redes {lineBreak}sociales',
  'tools.toolsSection.tool1.title': 'Crear',
  'tools.toolsSection.tool1.description': '<bText>Piper te mostrará cómo utilizar nuestro eficaz editor.</bText> {lineBreak}Con diseños gratuitos  y la ayuda de {lineBreak}Piper podrás crear publicaciones profesionales, arrancar es muy fácil.',
  'tools.toolsSection.tool2.title': 'Publicar',
  'tools.toolsSection.tool2.description': '<bText>Nuestro calendario te permite publicar en todas tus redes sociales al mismo tiempo.</bText> {lineBreak}Piper te dirá cuándo tus clientes están más activos. Puedes seguir su recomendación o crear tu propio calendario de publicación. ',
  'tools.toolsSection.tool3.title': '¡A despegar!',
  'tools.toolsSection.tool3.description': '<bText>Crea anuncios en un sólo clic.</bText> {lineBreak}Con los conocimientos de Piper, puedes llegar fácilmente a un público más amplio y hacer crecer tu negocio. Piper te sugerirá la inversión más inteligente para tu presupuesto de marketing',
  'tools.toolsSection.tool4.title': 'Conectar',
  'tools.toolsSection.tool4.description': 'Responde a los comentarios en todas las plataformas desde un sólo lugar. <bText> {lineBreak}Incrementa la interacción y conecta con tu mercado rápidamente.</bText>',
  'tools.toolsSection.helpText': '¡Obtén consejos personalizados para que tu negocio crezca!',
  'tools.features.instagram.title': '¡Instagram, {lineBreak}recargado! ',
  'tools.features.instagram.title.mobile': '¡Instagram, {lineBreak}supercargado! ',
  'tools.features.instagram.description': 'Programa fácilmente o publica de inmediato en tus Historias. ¡Sin esperar! Las imágenes y los videos se optimizan automáticamente cuando publicas o programas.',
  'tools.features.instagram.description.mobile': 'Programa fácilmente o publica de inmediato en tus Historias. Las imágenes y los videos se optimizan automáticamente cuando publicas o programas.',
  'tools.features.time.title': 'Trucos para {lineBreak}ahorrar tiempo:',
  'tools.features.time.description': '¡Guau! Publica historias, imágenes y videos en todos sus perfiles EN SEGUNDOS.',
  'tools.features.bestTime.title': '¡La magia de la mejora!',
  'tools.features.bestTime.description': 'Con los consejos y trucos de Piper, aumenta el alcance y comunicación con tus seguidores y clientes potenciales.',
  'tools.features.bestTime.description2': 'La guía de Piper y sus expertos te ayudarán a llegar y mantenerse en la cima.',
  'tools.features.boost.title': '¡Apuesta por el éxito!',
  'tools.features.boost.description': 'Piper te ayuda a administrar tu inversión en anuncios para llegar a un público mayor:',
  'tools.features.boost.description2': '"Te quedan $900 y podrías alcanzar 10,000 usuarios más con un anunció esta noche".',
};

export default es;
