const en = {
  'notification.reconnect.facebook.instagram.title': 'Great news!',
  'notification.reconnect.facebook.instagram.subtitle': 'In Social Piper you can now publish directly on Instagram!',
  'notification.reconnect.facebook.instagram.advice': 'Please re-connect your business social media accounts (Facebook and Instagram) and your configuration will be updated.',
  'notification.reconnect.facebook.instagram.button': 'Re-connect',
  'notification.reconnect.facebook.instagram.link': 'Nice, but I’ll do it later.',
  'notification.reconnect.facebook.title': 'We have a very big improvement that will allow you to post directly to your Instagram Business Account!',
  'notification.reconnect.facebook.advice': 'To make this improvement work, you will need to re-connect your Facebook Account before connecting your Instagram Business Account.',
};

export default en;
