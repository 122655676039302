import styled from 'styled-components';
import {
  DatePicker as MuiDatePicker,
  TimePicker as MuiTimePicker,
} from '@material-ui/pickers';
import colors from './colors';
import { SearchIcon, CalendarIcon } from '../assets/icons/iconRepository';
import { input, select, datePicker } from './mixins';

export const Select = styled.select`
  ${select}
`;

export const SearchInput = styled.input`
  ${input}
  background: ${colors.white} url(${SearchIcon}) no-repeat 0 0;
  padding-left: 40px;
  background-position: calc(8px) center;
  ${(props) => props.fullWidth && `
    width:100%;
  `}
  ${(props) => props.rightIcon && `
    background-position: calc(100% - 8px) center;
    padding-right: 35px;
    padding-left: ${props.theme.space}px;
  `}
`;

export const DatePicker = styled(MuiDatePicker)`
  ${datePicker}
  && {
    position: relative;
    &:before{
      display: ${({ noIcon }) => (noIcon ? 'none' : 'block')};
      content: '';
      position: absolute;
      z-index: 1;
      margin: auto 0;
      top: 0;
      bottom: 0;
      left: 8px;
      width: 24px;
      height: 24px;
      background-image: url('${CalendarIcon}');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .MuiInput-root{
      input{
        padding-left: ${({ noIcon }) => (noIcon ? '4px' : '40px')};
      }
    }
  }
`;

export const TimePicker = styled(MuiTimePicker)`
  ${datePicker}
`;

export const Loading = styled.div`
  &,
  &:before,
  &:after {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
    overflow: visible;
  }
  & {
    color: ${colors.black};
    font-size: 10px;
    margin-left: 25px;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }
  &:before {
    left: -15px;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &:after {
    left: 15px;
  }
`;
