import gql from 'graphql-tag';

export const getGrowUpPages = gql`
  query getGrowUpPages($interval: INTERVAL!){
    getGrowUpPages(interval: $interval) {
        data
        status
    }
  }
`;

export const getPiperScoreHistoric = gql`
  query getPiperScoreHistory($interval: INTERVAL!){
    getPiperScoreHistory(interval: $interval) {
        data
        status
    }
  }
`;

export const getPostsPerformanceHistoric = gql`
  query getPostsAnalytics ($interval: INTERVAL!){
    getPostsAnalytics(interval: $interval) {
        status
        data
    }
  }
`;

export const getLikesCommentsHistoric = gql`
  query getComentsLikesHistoric ($interval: INTERVAL!){
    getComentsLikesHistoric(interval: $interval) {
        status
        data
    }
  }
`;
export const getPagesPerformance = gql`
  query getPagesPerformance ($interval: INTERVAL!){
    getPagesPerformance(interval: $interval) {
        status
        data
    }
  }
`;

export default {};
