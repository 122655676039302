import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { colors } from '../../../../../styled/theme';
import { paragraph } from '../../../../../styled/mixins';
import { TrashCanIcon } from '../../../../../assets/icons/iconRepository';
import cards from '../../../../../assets/cards/cardsRepository';
import GlobalTheme from '../../../../../styled/GlobalTheme';
import { AMEX } from '../../../../../utils/constants/settings';
import mediaQueries from '../../../../../utils/mediaQueries';

const Wrapper = styled.div`
  width: 100%;
  padding: 0 32px;
  padding-top: 12px;
  ${({ isDefaultPayment, theme }) => isDefaultPayment && `
    background: ${theme.colors.screenBackground};
  `}
  @media ${mediaQueries.lgPlusDownLess} {
    padding: 8px 16px 0;
  }
  ${GlobalTheme.breakpoints.down('sm')} {
    padding: 12px 16px 0;
  }
`;
const CardRadioContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
  @media ${mediaQueries.mdUp} {
    margin-bottom: 16px;
  }
  @media ${mediaQueries.lgPlusUp} {
    margin-bottom: 18px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    margin: auto 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid ${(props) => (props.disabled ? colors.gray90 : colors.primaryLighter)};
    box-sizing: border-box;
  }
  &:after {
    content: '';
    position: absolute;
    display: ${(props) => (props.selected ? 'block' : 'none')};
    width: 8px;
    height: 8px;
    margin: auto 0;
    left: 4px;
    border-radius: 100%;
    background: ${colors.primaryLighter};
  }
`;

const CardCol = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  &:first-child{
    padding-left: 24px;
  }
  &:hover .tooltip {
    display: block;
  }
`;

const CardBrandImg = styled.img`
  height: 30px;
  width: 55px;
  object-fit: contain;
  object-position: center;
  margin-right: 8px;
`;

const CardField = styled.p`
  ${paragraph}
  font-size: 18px;
  color: ${colors.text02};
  margin-right: 16px;
`;

const ExpField = styled(CardField)`
  ${({ isDefaultPayment }) => isDefaultPayment && `
    margin-right: 40px;
  `}
  ${GlobalTheme.breakpoints.down('md')}{
    display: none;
  }
`;

const MobileExpField = styled(CardField)`
  display: none;
  ${GlobalTheme.breakpoints.down('md')}{
    display: block;
  }
`;

const DeleteButton = styled.a`
  cursor: pointer;
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('${TrashCanIcon}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DefaultLabel = styled.p`
  ${paragraph}
  font-size: 12px;
  font-weight: 600;
  color: ${colors.speechBubble};
  margin-bottom: 4px;
`;

const ToolTipContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: none;
  filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.15));
  position: absolute;
  width: 232px;
  max-width: 232px;
  top: -75px;
  ${({ isSpanish }) => isSpanish && `
    top: -86px;
  `}
  &:after {
    content: '';
    background: ${({ theme }) => theme.colors.white};
    width: 40px;
    height: 16px;
    position: absolute;
    bottom: -15px;
    left: 40%;
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    filter: drop-shadow(0px 2px 16px rgba(0,0,0,0.15));
  }
`;
const Content = styled.div`
  padding: 10px 14px;
`;
const Text = styled.div`
  color: ${({ theme }) => theme.colors.black3E};
  font-size: 14px;
  line-height: 19px;
  text-align: center;
`;

const ToolTip = ({ className }) => {
  const intl = useIntl();
  const { locale } = intl;
  return (
    <ToolTipContainer
      className={className}
      isSpanish={locale === 'es'}
    >
      <Content>
        <Text>
          <FormattedMessage
            id="settings.billing.billingInformation.tooltip.text"
            defaultMessage="Click to change this card as your default method payment"
          />
        </Text>
      </Content>
    </ToolTipContainer>
  );
};

ToolTip.propTypes = {
  className: PropTypes.string,
};

ToolTip.defaultProps = {
  className: '',
};

const CardRadio = ({
  card,
  handleClickCard,
  handleDeleteCard,
  hasMoreOnePayment,
}) => {
  const getBrandLogo = () => {
    if (card.brand === AMEX) {
      return cards.amex;
    }
    return cards[card.brand.toLowerCase()];
  };
  return (
    <Wrapper isDefaultPayment={card.isDefaultPayment}>
      {card.isDefaultPayment && (
        <DefaultLabel>
          <FormattedMessage
            id="k.default"
            defaultMessage="Default"
          />
        </DefaultLabel>
      )}
      <FlexContainer>
        <CardRadioContainer
          selected={card.isDefaultPayment}
          onClick={() => handleClickCard(card)}
        >
          <CardCol>
            <CardBrandImg src={getBrandLogo()} alt={card.brand} />
            <CardField>
              {card.name.split(' ')[0]} **{card.last4}
              <MobileExpField>
                <FormattedMessage
                  id="settings.billing.billingInformation.card.expirationAbbr"
                  defaultMessage="Exp"
                />
                {` ${card.exp}`}
              </MobileExpField>
            </CardField>
            <ToolTip className="tooltip" />
          </CardCol>
          <CardCol>
            <ExpField isDefaultPayment={card.isDefaultPayment}>
              <FormattedMessage
                id="settings.billing.billingInformation.card.expirationAbbr"
                defaultMessage="Exp"
              />
              {` ${card.exp}`}
            </ExpField>
          </CardCol>
        </CardRadioContainer>
        {hasMoreOnePayment && !card.isDefaultPayment && (
          <DeleteButton
            onClick={() => handleDeleteCard(card)}
          />
        )}
      </FlexContainer>
    </Wrapper>
  );
};

CardRadio.propTypes = {
  card: PropTypes.instanceOf(Object).isRequired,
  handleClickCard: PropTypes.func.isRequired,
  handleDeleteCard: PropTypes.func.isRequired,
  hasMoreOnePayment: PropTypes.bool.isRequired,
};

export default CardRadio;
