import gql from 'graphql-tag';

export const addCreditCard = gql`
  mutation addCreditCard($input: BillingInfo!) {
    addCreditCard(input: $input) {
      success
      errorMessage
    }
  }
`;

export const setDefaultPaymentMethod = gql`
  mutation setDefaultPaymentMethod($token: String!) {
    setDefaultPaymentMethod(token: $token) {
      success
      errorMessage
    }
  }
`;

export const deletePaymentMethod = gql`
  mutation deletePaymentMethod($token: String!) {
    deletePaymentMethod(token: $token) {
      success
      info
      errorMessage
    }
  }
`;

export const createSubscription = gql`
  mutation createSubscriptionV2($id: ID!, $promoCodeId: Int, $disableTrial: Boolean, $atOnboarding: Boolean) {
    createSubscriptionV2(id: $id, promoCodeId: $promoCodeId, disableTrial:$disableTrial, atOnboarding: $atOnboarding) {
      subscriptionInfo {
        userId
        subcriptionId
        planName
        planStatus
        planStartDate
        planPrice
        isMonthly
        isScheduled
        planEndDate
        onTrial
        promoCode
      }
      payment {
        lastPaymentDate
        nextPaymentDate
        paymentAmount
      }
    }
  }
`;

export const cancelSubscription = gql`
  mutation cancelSubscription {
    cancelSubscription{
      status
      subscriptionInfo{
        userId
        subcriptionId
        planName
        planStatus
        planStartDate
        planPrice
        isMonthly
        isScheduled
        planEndDate
        onTrial
        promoCode   
      }
    }
  }
`;

export const createSubscriptionIOS = gql`
  mutation createSubscriptionIOS($id: ID!, $isMonthly: Boolean) {
    createSubscriptionIOS(id: $id, isMonthly: $isMonthly) {
      subscriptionInfo {
        userId
        subcriptionId
        planName
        planStatus
        planStartDate
        planPrice
        isMonthly
        isScheduled
        planEndDate
        onTrial
        promoCode
      }
    }
  }
`;

export const validateFounds = gql`
  mutation validateFounds($trialDuration: Int){
    validateFounds(trialDuration: $trialDuration) {
      success
      errorMessage
      info
    }
  }
`;

export const createSubscriptionAndroid = gql`
mutation createSubscriptionAndroid($token: String, $responseCode: Int, $debugMessage: String, $sku: String, $period: String, $periodTrial: String, $price: String, $currency: String) {
  createSubscriptionAndroid(token: $token, responseCode: $responseCode, debugMessage: $debugMessage, sku: $sku, period: $period, periodTrial: $periodTrial, price: $price, currency: $currency) {
    subscriptionInfo {
      subcriptionId
    }
  }
}
`;
