import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  STORE_QUERY_STRING,
  CONFIG_FLOW_KEY,
  FIRST_LOCATION_KEY,
} from '../utils/constants/settings';
import { PublicRoutes, AuthRoutes } from '../utils/constants/routes';

const QueryStringContext = React.createContext();
const QueryStringProvider = ({ children }) => {
  const [queryVars, setQueryVars] = useState({});
  const [queryRdy, setQueryRdy] = useState(false);
  const [firstLocation, setFirstLocation] = useState('firstLocation');
  const getQueryStringVal = (val) => (queryVars[val]);

  const cleanObj = (tempObj) => {
    const qry2Store = {};
    Object.keys(STORE_QUERY_STRING).forEach((key) => {
      if (STORE_QUERY_STRING[key]) {
        if (tempObj[key] !== undefined && tempObj[key] !== null) {
          qry2Store[key] = tempObj[key];
        }
      }
    });
    return qry2Store;
  };

  const removeQueryStringVal = (removeKey) => {
    let storedValues = window.localStorage.getItem(CONFIG_FLOW_KEY);
    try {
      storedValues = JSON.parse(storedValues) || {};
    } catch {
      storedValues = {};
    }
    if (storedValues
      && Object.keys(storedValues).length > 0
      && storedValues.constructor === Object) {
      delete storedValues[removeKey];
      const striJs = JSON.stringify(storedValues);
      window.localStorage.setItem(CONFIG_FLOW_KEY, striJs);
    }
  };

  const fetchAndStoreVal = (tempVars) => {
    let qryVals = cleanObj(tempVars);
    let storedValues = window.localStorage.getItem(CONFIG_FLOW_KEY);
    try {
      storedValues = JSON.parse(storedValues) || {};
    } catch {
      storedValues = {};
    }
    if (storedValues
      && Object.keys(storedValues).length > 0
      && storedValues.constructor === Object) {
      qryVals = {
        ...storedValues,
        ...qryVals,
      };
    }
    const striJs = JSON.stringify(qryVals);
    setQueryVars(qryVals);
    window.localStorage.setItem(CONFIG_FLOW_KEY, striJs);
  };

  const isPublicRoute = (path) => {
    const t = Object.values(PublicRoutes).findIndex((pR) => pR.includes(path));
    if (t === -1) {
      return false;
    }
    return true;
  };

  const saveFirstLocation = (path, query) => {
    const lsFLK = window.localStorage.getItem(FIRST_LOCATION_KEY);
    if (!(
      isPublicRoute(path)
      || path.includes(AuthRoutes.SOCIAL_LOGIN_ERROR)
      || path.includes(AuthRoutes.SOCIAL_LOGIN_SUCCESS)
      || path.includes(AuthRoutes.DASHBOARD)
      || path.includes('/callback')
    )) {
      const newFLK = `${path}${query}`;
      if (newFLK !== lsFLK) {
        window.localStorage.setItem(FIRST_LOCATION_KEY, newFLK);
        setFirstLocation(newFLK);
      }
    } else if (lsFLK !== '' && lsFLK !== null) {
      setFirstLocation(lsFLK);
    }
  }
  const cleanFirstLocation = () => {
    window.localStorage.removeItem(FIRST_LOCATION_KEY);
  }

  useEffect(() => {
    const firstPath = window.location?.pathname;
    const search = window.location?.search;
    saveFirstLocation(firstPath, search);
    const urlParams = new URLSearchParams(search);
    const tempVars = { ...queryVars };
    urlParams.forEach((val, key) => {
      tempVars[key] = val;
    });
    fetchAndStoreVal(tempVars);
    setQueryRdy(!queryRdy);
  }, []);

  return (
    <QueryStringContext.Provider
      value={{
        firstLocation,
        cleanFirstLocation,
        queryRdy,
        getQueryStringVal,
        removeQueryStringVal,
      }}
    >
      {children}
    </QueryStringContext.Provider>
  );
};

QueryStringProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { QueryStringContext, QueryStringProvider };
