import gql from 'graphql-tag';

export const addCompetitorAccount = gql`
mutation addCompetitorAccount($id: ID!, $name: String!, $profileImage: String!, $username: String!){
    addCompetitorAccount(id: $id, name: $name, profileImage: $profileImage, username: $username){
      success
      id
      name
      picture
      instagramId
      username
    }
}
`;

export const removeCompetitorAccount = gql`
mutation removeCompetitorAccount($id: ID!){
    removeCompetitorAccount(id: $id){
      success
    }
}
`;

export const deleteInstagramPost = gql`
  mutation deleteInstagramPost($contentId: Int!) {
    deleteInstagramPost(contentId: $contentId)
  }
`;

export const replyInstagramComment = gql`
  mutation replyInstagramComment ($input: CommentInput!) {
    replyInstagramComment(input: $input) {
      id
      from
      commentText
      createdTime
      replyComments {
        id
        from
        commentText
        createdTime
        read
        userImage
      }
      read
      userImage
    }
  }
`;

export const deleteInstagramComment = gql`
  mutation deleteInstagramComment ($commentId: Int!) {
    deleteInstagramComment(commentId: $commentId)
  }
`;

export const editInstagramPost = gql`
  mutation editInstagramPost($contentId: ID!, $message: String, $scheduleTime: AWSDateTime, $imageUrl: String) {
    editInstagramPost(contentId: $contentId, message: $message, scheduleTime: $scheduleTime, imageUrl: $imageUrl)
  }
`;

export const fetchInstagramData = gql`
  mutation fetchInstagramData {
    fetchInstagramData
  }
`;

export const createInstagramPost = gql`
  mutation createInstagramPost ($message: String, $mediaIds: [String], $published: Boolean!, $scheduleTime: AWSDateTime, $sessionId: String ) {
    createInstagramPost(
      message: $message,
      published: $published,
      scheduleTime: $scheduleTime,
      mediaIds: $mediaIds,
      sessionId: $sessionId,
    ){
      id
      published
      errors {
        title
        message
      }
    }
  }
`;

export const createInstagramStorieScheduler = gql`
  mutation createInstagramStorieScheduler ($message: String, $mediaId: ID, $published: Boolean!, $scheduleTime: AWSDateTime, $previewURL: String, $namePreview: String, $defaultMessageStorie: String) {
    createInstagramStorieScheduler(
      message: $message,
      published: $published,
      scheduleTime: $scheduleTime,
      mediaId: $mediaId,
      previewURL: $previewURL,
      namePreview: $namePreview,
      defaultMessageStorie: $defaultMessageStorie,
    ){
      id
      published
      errors {
        title
        message
      }
    }
  }
`;

export const rescheduleInstagram = gql`
mutation rescheduleInstagram ($contentId: Int!, $scheduleTime: AWSDateTime, $postNow: Boolean) {
  rescheduleInstagram(contentId: $contentId, scheduleTime: $scheduleTime, postNow: $postNow)
}
`;

export const publishVideoInstagram = gql`
  mutation createInstagramPostVideo ($id: Int, $message: String, $publishNow: Boolean, $scheduleTime: AWSDateTime, $nameFile: String, $dimension: Dimesion, $previewImg: String, $namePreviewImg: String) {
    createInstagramPostVideo( data: {
      id: $id,
      message: $message
      publishNow: $publishNow
      scheduleTime: $scheduleTime
      nameFile: $nameFile
      dimension: $dimension
      previewImg: $previewImg
      namePreviewImg: $namePreviewImg
    }){
      isValid
      containerId
    }
  }
`;

export const activateInstagramPage = gql`
  mutation activateInstagramPage($igPageId: Int!) {
    activateInstagramPage(igPageId: $igPageId){
        success
        info
        errorMessage
    }
  }
`;

export const rescheduleInstagramStorie = gql`
mutation rescheduleInstagramStorie ($contentId: Int!, $scheduleTime: AWSDateTime, $postNow: Boolean) {
  rescheduleInstagramStorie(contentId: $contentId, scheduleTime: $scheduleTime, postNow: $postNow)
}
`;
