const { breakpointValues } = require('../styled/GlobalTheme');

const mediaQueries = {
  // mobile
  sm: `(max-width: ${breakpointValues.sm}px)`,
  smLandscape: `(max-width: ${breakpointValues.sm}px), (max-device-width: 1023px) and (orientation: landscape)`,
  // Tablet
  tablet: `(min-width: ${breakpointValues.md}px) and (max-width: ${
    breakpointValues.xl - 1
  }px)`,
  // Tablet portrait
  md: `(min-width: ${breakpointValues.md}px) and (max-width: ${
    breakpointValues.lg - 1
  }px)`,
  // Tablet landscape
  lg: `(min-width: ${breakpointValues.lg}px) and (max-width: ${
    breakpointValues.xl - 1
  }px)`,
  // Computer
  xl: `(min-width: ${breakpointValues.xl + 1}px) `,
  // Mobile
  smDown: `(max-width: ${breakpointValues.sm}px)`,
  // Tablet portrait
  mdDown: `(max-width: ${breakpointValues.md}px)`,
  mdDownLess: `(max-width: ${breakpointValues.md - 1}px)`,
  // Tablet landscape
  lgDown: `(max-width: ${breakpointValues.lg}px)`,
  // Computer
  xlDown: `(max-width: ${breakpointValues.xl}px) `,
  xlDownLess: `(max-width: ${breakpointValues.xl - 1}px) `,
  // Big Screens
  xxlDown: `(max-width: ${breakpointValues.xxl}px) `,
  // Mobile
  smUp: `(min-width: ${breakpointValues.sm}px)`,
  // Tablet portrait
  mdUp: `(min-width: ${breakpointValues.md}px)`,
  mdBetween: `(min-width: ${breakpointValues.md}px) and (max-width: ${breakpointValues.xlPlus - 1}px)`,
  // Tablet landscape
  lgUp: `(min-width: ${breakpointValues.lg}px)`,
  lgPlusDown: `(max-width: ${breakpointValues.lgPlus}px)`,
  lgPlusDownLess: `(max-width: ${breakpointValues.lgPlus - 1}px)`,
  lgPlusUp: `(min-width: ${breakpointValues.lgPlus}px)`,
  // Computer
  xlUp: `(min-width: ${breakpointValues.xl}px) `,
  xlPlusUp: `(min-width: ${breakpointValues.xlPlus}px) `,
  xlPlusDown: `(max-width: ${breakpointValues.xlPlus}px) `,
  xlPlusDownLess: `(max-width: ${breakpointValues.xlPlus - 1}px) `,
  // Big Screens
  xxlUp: `(min-width: ${breakpointValues.xxl}px) `,
};

export default mediaQueries;
