const en = {
  'proOfferModal.stage.one': 'Select your plan',
  'proOfferModal.stage.two': 'Payment details',
  'proOfferModal.stage.three': 'Confirm',
  'proOfferModal.paymentDetails.select': 'Select your payment method',
  'proOfferModal.paymentDetails.one': 'We will use your added payment method',
  'proOfferModal.paymentDetails.addPayment': 'Add another payment method',
  'proOfferModal.plan': 'Plan',
  'proOfferModal.paymentMethod': 'Payment Method',
  'proOfferModal.change.plan': 'Change Plan',
  'proOfferModal.change.paymentMethod': 'Change payment method',
  'proOfferModal.success.subtitle': 'Congrats! You have succesfully suscribe to Professional Plan!',
  'proOfferModal.success.text': 'We are excited to have you onboard! Keep rocking on Social Media!',
  'proOfferModal.error.subtitle': 'Your payment could not be processed.',
  'proOfferModal.error.text': 'Please try any of these options:',
  'proOfferModal.error.b1': 'Try again with the same payment method.',
  'proOfferModal.error.b2': 'Choose another payment method.',
  'proOfferModal.error.b3': 'Add a new payment method.',
  'proOfferModal.error.legend': '<bold>If the problem persists</bold> chat with us or write to us at <sp>support@socialpiper.com</sp>',
  'proOfferModal.error.loader.title': 'Processing your payment',
  'proOfferModal.error.loader.text': 'This may take a few minutes, please <bold>do not close this window.</bold>',
};

export default en;
