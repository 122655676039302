const adCampaignsMock = [
  {
    id: '50',
    linkedinAdId: '',
    lifeTimeBudget: 176523,
    startDateTime: '2020-12-24T22:15:00.000Z',
    endDateTime: '2020-12-31T22:15:00.000Z',
    type: 'piperRecommendationBudget',
    campaignStatus: 'pending',
    isExpired: false,
    imageUrl: 'https://www.imagen.com.mx/assets/img/IMAGEN_Tv.jpg',
    postMessage: 'Discover our new deals now!',
    statusFromLI: 'PENDING',
    effectiveStatus: 'pending',
    goal: 'ENGAGEMENT',
    insights: {
      reactions: 0,
      comments: 0,
      shares: 0,
      reach: '0',
      spend: '0',
      __typename: 'Insights',
    },
    __typename: 'Boosting',
  },
  {
    id: '40',
    linkedinAdId: '6186708877969',
    lifeTimeBudget: 176523,
    startDateTime: '2020-09-11T22:15:00.000Z',
    endDateTime: '2020-09-22T22:15:00.000Z',
    type: 'piperRecommendationBudget',
    campaignStatus: 'running',
    isExpired: false,
    imageUrl: 'https://www.imagen.com.mx/assets/img/IMAGEN_Tv.jpg',
    postMessage: 'Discover our new deals now!',
    statusFromLI: 'ACTIVE',
    effectiveStatus: 'active',
    goal: 'ENGAGEMENT',
    insights: {
      reactions: 3080,
      comments: 12,
      shares: 2,
      reach: '103050',
      spend: '223',
      __typename: 'Insights',
    },
    __typename: 'Boosting',
  },
  {
    id: '30',
    linkedinAdId: '6186708877969',
    lifeTimeBudget: 176523,
    startDateTime: '2020-09-08T22:15:00.000Z',
    endDateTime: '2020-09-09T22:15:00.000Z',
    type: 'piperRecommendationBudget',
    campaignStatus: 'running',
    isExpired: true,
    imageUrl: 'https://www.imagen.com.mx/assets/img/IMAGEN_Tv.jpg',
    postMessage: 'Hey there come join us!',
    statusFromLI: 'CANCELED',
    effectiveStatus: 'canceled',
    goal: 'ENGAGEMENT',
    insights: {
      reactions: 3080,
      comments: 12,
      shares: 2,
      reach: '103050',
      spend: '223',
      __typename: 'Insights',
    },
    __typename: 'Boosting',
  },
];

export default adCampaignsMock;
