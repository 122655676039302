import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Pipers from '../Pipers/Pipers';
import GlobalTheme from '../../styled/GlobalTheme';
import useHeight from '../../hooks/useHeight';

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.font.font2};
  color: ${({ theme }) => theme.colors.primaryLight};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin:${({ theme }) => theme.space * 4}px 0;
  display: flex;
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 16px;
  }
`;

const PiperWrapper = styled.figure`
  display: inline-block;
  width: 32px;
  margin: 0 ${({ theme }) => theme.space * 2}px 0 0;
`;

const CustomPiperHead = styled(Pipers.HeadBackground)`
  min-width: 32px;
  min-height: 32px;
`;

const Body = styled.span`
  display: flex;
  align-items: center;
  margin-top: ${(props) => (props.multiline ? 'calc(.11em - 6px)' : 'calc(8px - .22em)')};
`;

const PiperAdvice = ({ className, children }) => {
  const [elRef, height] = useHeight();

  return (
    <Wrapper className={className}>
      <PiperWrapper>
        <CustomPiperHead />
      </PiperWrapper>
      <Body ref={elRef} multiline={height > 35}>
        “{children}”
      </Body>
    </Wrapper>
  );
};

PiperAdvice.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  multiline: PropTypes.string,
};

PiperAdvice.defaultProps = {
  className: '',
  multiline: 'center',
};

export default PiperAdvice;
