import templates from './views/templates/en';
import pricing from './views/pricing/en';
import community from './views/community/en';
import createAccount from './views/create-account/en';
import onboarding from './views/onboarding/en';
import verifyEmail from './views/VerifyEmail/en';
import boost from './components/boost/en';
import addPaymentModal from './components/addPaymentModal/en';
import socialProfiles from './components/socialProfiles/en';
import trialEnds from './components/trialEnds/en';
import subscriptionModal from './components/subscriptionModal/en';
import cancelSubscriptionModal from './components/cancelSubscriptionModal/en';
import login from './views/login/en';
import dashboard from './views/dashboard/en';
import global from './global/en';
import boostManager from './views/boostManager/en';
import postFlow from './views/postFlow/en';
import socialLogin from './views/socialLogin/en';
import landing from './views/landing/en';
import successVerifyEmail from './views/successVerifyEmail/en';
import scheduler from './views/scheduler/en';
import settings from './views/settings/en';
import backendErrors from './backend/errors/en';
import promos from './backend/promos/en';
import tutorialModal from './components/tutorialModal/en';
import planOption from './components/planOption/en';
import proOfferModal from './components/proOfferModal/en';
import comparePlans from './components/compareDetails/en';
import newPricing from './views/newPricing/en';
import welcomeModal from './components/welcomeModal/en';
import inAppTouchModal from './components/inAppTouchModal/en';
import landingInstagram from './views/landingInstagram/en';
import tools from './views/tools/en';
import metrics from './views/metrics/en';

const en = {
  // Views
  ...boostManager,
  ...templates,
  ...pricing,
  ...community,
  ...onboarding,
  ...login,
  ...dashboard,
  ...postFlow,
  ...socialLogin,
  ...landing,
  ...scheduler,
  ...settings,
  ...verifyEmail,
  ...successVerifyEmail,
  ...metrics,
  // Components
  ...boost,
  ...addPaymentModal,
  ...socialProfiles,
  ...trialEnds,
  ...subscriptionModal,
  ...cancelSubscriptionModal,
  ...tutorialModal,
  ...planOption,
  ...proOfferModal,
  ...comparePlans,
  ...inAppTouchModal,
  // Globals
  ...global,
  ...createAccount,
  // Errors
  ...backendErrors,
  ...promos,
  ...newPricing,
  ...welcomeModal,
  ...landingInstagram,
  ...tools,
};

export default en;
