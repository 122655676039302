import Landing, {
  Promotional, MeetPiper, Testimonials, PiperSteps,
} from './Landing';

export {
  Promotional,
  MeetPiper,
  Testimonials,
  PiperSteps,
};

export default Landing;
