import gql from 'graphql-tag';

export const getClientsInformation = (attr = `
  ageUpperBound
  ageLowerBound
  location
  linkedinLocation
  linkedinInterests
  genders
  interests {
    facebookId
    name
  }
`) => gql`
  query getClientsInformation($language: LANGUAGE, $socialNetwork: SOCIAL_NETWORK) {
    getClientsInformation(language: $language, socialNetwork: $socialNetwork) {
      ${attr}
    }
  }
`;

export const getReachEstimate = gql`
  query reachEstimate($ageMax: Int, $ageMin: Int, $genderId: Int, $locations:[Location], $interests:[ClientInterest!]) {
    reachEstimate (ageMax: $ageMax, ageMin: $ageMin, genderId: $genderId, locations:$locations, interests:$interests) {
      targetSize
    }
  }
`;

export const getLinkedinReachEstimate = gql`
  query reachEstimateLinkedin($ageMax: Int, $ageMin: Int, $genderId: Int, $locations:[ClientInterest!], $interests:[ClientInterest!]) {
    reachEstimateLinkedin (ageMax: $ageMax, ageMin: $ageMin, genderId: $genderId, locations:$locations, interests:$interests) {
      targetSize
    }
  }
`;
