import { TRIAL_DURATION } from '../../../../utils/constants/settings';

const es = {
  'pricing.covid.offer': 'Oferta especial de apoyo por el COVID',
  'pricing.cicc.offer': 'Oferta CICC',
  'pricing.asem.offer': 'Oferta ASEM',
  'pricing.amai.offer': 'Oferta AMAI',
  'pricing.apren.offer': 'Oferta APREN',
  'pricing.covid.relief': 'Oferta en apoyo por el COVID',
  'pricing.COVIDPricingDisclaimer': '*Las comisiones por suscripción a Social Piper no serán cobradas durante el período de prueba. Puedes cancelar en cualquier momento. Las comisiones por concepto de publicidad podrían aplicarse. <a> Ver Términos de Servicio</a>.{lineBreak}1. Puedes activar la suscripción de tu cuenta después de finalizar tu período de prueba (o incluso antes, si así lo deseas).{lineBreak}2. El costo regular de suscripción mensual a Social Piper es {price}/mes.{lineBreak} 3. El costo regular de suscripción anual de Social Piper es {yearPrice}/año.',
  'pricing.hero.piperTip': '¡Pruébala ahora! Seré tu guía y te daré recomendaciones para que llegues a tus objetivos de negocio....',
  'pricing.onceYouActivate': 'Una vez que actives la suscripción de tu cuenta, te daremos <c>{price} a través de un reembolso a tu tarjeta de crédito.</c> Puedes usar este dinero para llegar a más público o cubrir otras necesidades de tu negocio.',
  'pricing.hero.price': 'Comienza tus {lineBreak}<strong>7 días</strong>{lineBreak}de prueba gratis*',
  'pricing.price': `Comienza tu período de prueba de ${TRIAL_DURATION} días gratis*`,
  'pricing.subtitle': 'La plataforma para marketing en redes sociales más rentable para pequeñas empresas... Las redes sociales son fáciles',
  'pricing.trial.cta': '¡Empezar mi prueba gratuita!',
  'pricing.trial.message': '<p><b>¡Obtén tu prueba gratuita de 7 días!</b> Pruébalo ahora y empieza a crecer tu negocio en redes sociales. Solo se cobrará <b>$99/mes</b> si continuas después de tu prueba gratuita.</p><p><b>Sin riesgos. Sin obligaciones.</b></p>',
  'pricing.trial.messageLong': '<p><b>Sin Riesgo. Sin Obligaciones.</b></p><p>Solo se cobrará <b>$99/mes</b> si continuas después de tu prueba gratuita.</p>',
  'pricing.trial.no-credit-card-required': 'Sin Riesgo. Sin Obligaciones.',
  'pricing.trial.noRisk': 'Sin Riesgo. Sin Obligaciones.',
  'pricing.trial.small': '* Los costos de suscripción de Social Piper NO se cobrarán durante el período de prueba gratuita. Se pueden aplicar tarifas / comisiones por campañas publicitarias.',
  'pricing.trial.title': '7 días de prueba gratuita*',
  'pricing.weWantToHelp': 'Deseamos ayudar a reactivar el negocio de las pequeñas empresas que han sido afectadas por la crisis. Es por esto que estamos reduciendo nuestro precio de suscripción.<sup>1</sup>',
  'pricing.youWillBeCharged': 'Se hará el cargo a tu forma de pago cuando actives la suscripción de tu cuenta.',
  'pricing.plan.description.lite': 'Nuestro plan limitado, donde puedes usar nuestras funciones básicas para publicar en redes sociales.',
  'pricing.plan.description.pro': 'Aprovecha todo el poder y funciones de Social Piper y haz que tu negocio crezca exponencialmente en redes sociales.',
  'pricing.plan.description.business': 'Todas nuestras funciones agrupadas para empresas o pequeñas agencias con más de una persona que se ocupan de las redes sociales o con varios productos.',
  'pricing.plan.pro.content1': 'Accede a +1000 templates listos para usar.',
  'pricing.plan.pro.content2': 'Acceso a Social Piper Academy. {values}',
  'pricing.plan.pro.content3': 'Crea campañas publicitarias.',
  'pricing.plan.pro.content4': 'Monitorea a tu competencia.',
  'pricing.plan.pro.content5': 'Acceso por web y app móvil.',
  'pricing.plan.business.content1': 'Conecta +1 negocio.',
  'pricing.plan.business.content2': 'Acceso multiusuario.',
  'pricing.plan.business.content3': 'Herramienta de aprobación de contenido.',
  'pricing.plan.business.content4': 'Acceso por web y app móvil.',
  'pricing.plan.lite.content1': '10 publicaciones por mes.',
  'pricing.plan.lite.content2': 'Acceso a los templates de Piper.',
  'pricing.plan.lite.content3': 'Acceso por web.',
  'pricing.plan.content1': 'Conecta FB, IG, y TW.',
  'pricing.plan.content2': 'Usa las plantillas recomendadas.',
  'pricing.plan.content3': '10 publicaciones al mes.',
  'pricing.plan.content4': 'Responde a comentarios.',
  'pricing.plan.content5': 'Usa y edita miles de plantillas.',
  'pricing.plan.content6': 'Convierte tus publicaciones en anuncios.',
  'pricing.plan.content7': 'Conecta más de 1 perfil en FB, IG, y TW.',
  'pricing.plan.content8': 'Acceso multiusuario',
  'pricing.plan.content9': 'Sistema de revisión y aprobación de contenidos y anuncios.',
  'pricing.plan.details.basic1': 'Conecta tu cuenta de negocio de Facebook, Instagram y Twitter (1 por cada red social).',
  'pricing.plan.details.basic2': 'Utiliza contenido gráfico sugerido por Piper, tu asistente personal, o sube tu propio contenido.',
  'pricing.plan.details.basic3': '¡Piper te recomienda cuándo y dónde publicar! Hazlo a una o múltiples redes sociales en 3 simples pasos (Hasta 10 publicaciones por mes).',
  'pricing.plan.details.basic4': 'Contesta rápida y fácilmente los comentarios en tus redes sociales.',
  'pricing.plan.details.basic5': 'Accede y contesta los mensajes privados del Facebook Messenger de tu negocio.',
  'pricing.plan.details.basic6': 'Monitorea a tu competencia y mantente al día sobre lo que hacen (Podrás agregar 1 competidor en Facebook y 1 en Instagram).',
  'pricing.plan.details.basic7': 'Administra las publicaciones que has agendado y fácilmente modifícalas en la vista de calendario.',
  'pricing.plan.details.caption.pro': 'Además de lo que incluye la cuenta Básica, también podrás:',
  'pricing.plan.details.pro1': 'Publicar sin límite a todas tus redes sociales conectadas.',
  'pricing.plan.details.pro2': 'Acceder ilimitadamente a miles de plantillas gráficas.',
  'pricing.plan.details.pro3': 'Editar diseños fácilmente en nuestra herramienta en línea.',
  'pricing.plan.details.pro4': 'Crear sin esfuerzo campañas publicitarias en Facebook y llegar a más personas.',
  'pricing.plan.details.pro5': 'Mantener control y visibilidad de tu presupuesto de campañas.',
  'pricing.plan.details.pro6': 'Agregar todo los competidores que desees para monitorear su actividad.',
  'pricing.plan.details.caption.business': 'Incluye todo el Plan Básico y Pro, además:',
  'pricing.plan.details.business1': 'Acceso Multiusuario para integrar a tu equipo de trabajo.',
  'pricing.plan.details.business2': 'Conección a más de un perfil de Facebook, Instagram y Twitter.',
  'pricing.plan.details.business3': 'Función de aprobación y revisión de contenido y campañas.',
};

export default es;
