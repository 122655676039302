export const PAYMENT_CONCEPT = {
  monthlyPlan: 'Monthly Plan',
  boostFees: '{prevMonth} Boost fees',
};

export const LENGTHS = [0.2, 0.6, 0.2];

export const AUDIENCE_SIZE = {
  facebook: {
    SMALL: 5000,
    MIDDLE: 3000250,
    BIG: 6000000,
  },
  linkedin: {
    SMALL: 5000,
    MIDDLE: 15000,
    BIG: 25000,
  },
};

export const DEBOUNCE_DELAY = 500;

export const MIN_AGE = 13;
export const MAX_AGE = 65;

export const AMEX = 'American Express';
export const ACTIVE_PLAN = 'COVID Relief Plan';

export const LOCATION_TYPES = {
  subneighborhood: 'city',
  neighborhood: 'city',
  city: 'city',
  region: 'region',
  country: 'country',
  country_group: 'country_group',
  zip: 'zip',
};

export const PLAN_STATUS = {
  SCHEDULED: 'not_started',
  TRIAL: 'trialing',
  TRIAL_OPTIN: 'trialingOptIn',
  ACTIVE: 'active',
  CANCELED: 'canceled',
  PAST_DUE: 'past_due',
  INCOMPLETE: 'incomplete',
};

export const PLAN_NAME = {
  BASIC: 'Basic',
};

export const PLAN_RECURRENCE = {
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
};

export const TRIAL_DURATION = 14;
export const MEDIA_LIBRARY_TITLE = 'Creative Center';
export const AGES_BOUNDS = [18, 55];
export const GENRE_BOTH = 3;

export const QUERY_KEYS = {
  tt: 'typeTrial',
};

export const QUERY_VALUES = {
  tt: 'OI',
};

export const STORE_QUERY_STRING = {
  [QUERY_KEYS.tt]: true,
};

export const CONFIG_FLOW_KEY = 'SP_APP_CONFIG';
export const FIRST_LOCATION_KEY = 'FLK';
