const en = {
  'landing.instagram.header.intro.text': 'for',
  'landing.instagram.header.actions.action1': 'Schedule IG Stories',
  'landing.instagram.header.actions.action2': 'Publish image or video',
  'landing.instagram.header.actions.action3': 'See the best times to post',
  'landing.instagram.header.title': 'Give superpowers to your Business Instagram',
  'landing.instagram.header.subtitle': 'With Social Piper you can schedule your stories, posts, videos, or images to all of your business social media profiles.',
  'landing.instagram.header.cta.button': 'Start Your {daysTrial}-Day Trial',
  'landing.instagram.header.cta.button.help.text': 'Risk-free, you can cancel at any time',
  'landing.instagram.quote.text': 'Instagram helps 80% of consumers to make a decision of purchase a product or service. (...)In one year (2017-2018), there was a + 80% increase in video consuming.',
  'landing.instagram.quote.source': 'Digimind, 2019',
  'landing.instagram.actions.title': 'With Social Piper, <s>the best tool for Instagram</s>, you can:',
  'landing.instagram.action1.title': '<s>Schedule</s> and publish your stories in seconds',
  'landing.instagram.action1.description': 'Only in Social Piper you can schedule or publish immediately to your Business Instagram Stories.',
  'landing.instagram.action2.title': 'Publish <s>at the same time</s> to the rest of your social media Business profiles',
  'landing.instagram.action2.description': 'Forget about publishing your content to each platform. With Social Piper, you can post your stories, images, and videos to all your business social profiles IN ONE CLICK simultaneously.',
  'landing.instagram.action3.title': 'Receive <s>recommendations</s> for the best times to post',
  'landing.instagram.action3.description': 'With our AI, Piper, you can see the best times and days to post on your social media business platforms, allowing you to reach more people and potential customers.',
  'landing.instagram.action4.title': 'Publish <s>optimized</s> videos or images on your Instagram Business Profile',
  'landing.instagram.action4.description': 'Through Social Piper, your videos or images will get optimized, so your clients can see your content faster.',
  'landing.instagram.action5.title': '<s>Add</s> your brand or logo to your posts easily',
  'landing.instagram.action5.description': 'Don’t worry about uploading your logo every time; With Social Piper, you can easily add it to your posts with a single click.',
  'landing.instagram.simulation.title': '<h>It\'s easy;</h> check it out:',
  'landing.instagram.simulation.steps.step1.text': 'Choose your content and add your text.',
  'landing.instagram.simulation.steps.step2.text': 'Select on which platforms to post.',
  'landing.instagram.simulation.steps.step3.text': 'See recommendations for the best time or choose your own.',
  'landing.instagram.simulation.steps.step4.text': 'Ready! Your content is live.',
  'landing.instagram.relax.cta.text': 'All the content for your business on Instagram is fast and easy.',
  'landing.instagram.relax.cta.button': 'Start Your {daysTrial}-Day Trial',
  'landing.instagram.moreactions.title': 'Also, in <s>Social Piper, you can:</s>',
  'landing.instagram.moreactions.action1.description': 'Use + 3,000 templates or edit your own content',
  'landing.instagram.moreactions.action2.description': 'Post videos or images optimized for all your business networks',
  'landing.instagram.moreactions.action3.description': 'Know the best time to post for your business',
  'landing.instagram.moreactions.action4.description': 'Answer all your messages or comments in one place',
  'landing.instagram.moreactions.cta.button': 'Start Your {daysTrial}-Day Trial',
  'landing.instagram.moreactions.cta.description': 'Try Social Piper risk-free, you can cancel at any time.',
};

export default en;
