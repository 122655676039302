const MasterCard = require('./mastercard.png');
const AmexCard = require('./amex.png');
const VisaCard = require('./visa.png');
const DiscoverCard = require('./discover.png');
const AppStore = require('./app-store.png');
const GooglePlay = require('./google-play.png');
export const StripeLogo = require('./stripe.png');
export const AllStripeCards = require('./all.png');

export default {
  mastercard: MasterCard,
  amex: AmexCard,
  visa: VisaCard,
  discover: DiscoverCard,
  googleplay: GooglePlay,
  appstore: AppStore,
};
