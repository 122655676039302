import { TRIAL_DURATION } from '../../../../utils/constants/settings';

const en = {
  'landing.banner.covidBanner': 'COVID Relief Offer. ',
  'landing.meetPiper.piperTip': 'Hi Ana, next monday at 11 am is the best time for your clients to interact with your post on Facebook',
  'landing.meetPiper.title': 'Meet Piper, your new assistant that will offer you the best recommendations for <r>your business\'s</r> social media.',
  'landing.meetPiper.boost': "“If you INVEST $23,{linebreak}you'll be able to reach{linebreak}almost 9,450 potential{linebreak}customers”",
  'landing.meetPiper.templates': '“These are the best TEMPLATES I{linebreak}selected today for your business”',
  'landing.meetPiper.schedule': '“SCHEDULE your post next{linebreak}Monday at 8:00PM to{linebreak}reach more customers”',
  'landing.meetPiper.audience': '“At Tuesday 7:00PM is{linebreak}when your AUDIENCE is{linebreak}most active”',
  'landing.meetPiper.tutorial': '“See our new TUTORIAL{linebreak}about how to manage{linebreak}effective comments”',
  'landing.piperSteps.startSection': '<strong>Social Piper is the </strong>all-in-one creation and marketing platform for growing your business on social media.',
  'landing.piperSteps.step1.content': 'We introduce you to ‘Piper’, your new Artificial Intelligence mascot who learns with you and recommends what works best in social media for your business.',
  'landing.piperSteps.step1.title': 'We are your guide!',
  'landing.piperSteps.step1.subtitle': '<strong>We help you</strong> throughout the entire social media process.',
  'landing.piperSteps.step2.content': 'Publish your images when your customers are most likely to use their social networks.',
  'landing.piperSteps.step2.title': 'Scheduling',
  'landing.piperSteps.step2.subtitle': 'Discover the <strong>right time to post.</strong>',
  'landing.piperSteps.step3.content': 'We have more than 300 plug-n-play, ready-to-use designs for your business, you can even edit them to your liking.',
  'landing.piperSteps.step3.title': 'Post',
  'landing.piperSteps.step3.subtitle': 'Gain access to <strong>THOUSANDS of unique designs</strong> to edit and publish with ease.',
  'landing.piperSteps.step4.content': 'Piper recommends the best amount to budget for your social media marketing. It’ll even let you know how many people you can reach.',
  'landing.piperSteps.step4.title': 'Budget',
  'landing.piperSteps.step4.subtitle': 'Not sure how much to <strong>budget for ads?</strong>',
  'landing.piperSteps.superTitle': 'Social Piper is the perfect all-in-one social media marketing solution, easy to use, thanks to artificial intelligence.',
  'landing.piperSteps.title': 'Create, Post & Grow',
  'landing.promotional.sectionTitle': 'Social media made easy, for business.',
  'landing.testimonial.author.1': '<p>Heather Batzel</p><strong>Batzel Law</strong>',
  'landing.testimonial.author.2': '<p>Regina Trillo</p><strong>Neminative</strong>',
  'landing.testimonial.author.3': '<p>Marco A. González</p><strong>FR Pro & Asociados</strong>',
  'landing.testimonial.author.4': '<p>Denise Agnic Iribarren</p><strong>REZO Propiedades</strong>',
  'landing.testimonial.author.5': "<p>Liza Cohen</p><p>Nature's Nosh</p>",
  'landing.testimonial.quote.1': 'SocialPiper makes marketing feel much more approachable and results achievable. For me, it has been a great first stop in building out my online presence.',
  'landing.testimonial.quote.2': 'SocialPiper provided a space where we could gather data, create and schedule posts, compare results, create and fund campaigns and create a social media strategy based on measurable data.',
  'landing.testimonial.quote.3': 'Social Piper has made my life easier, to be creative and constant in social networks. In short, it is order, creativity and self-management for your social networks.',
  'landing.testimonial.quote.4': 'Social Piper simplified my life! Marketing through its platform is very friendly. It has been an excellent experience and I recommend it with eyes closed!',
  'landing.testimonial.quote.5': 'As a solo business owner, it is nearly impossible to juggle all of the daily tasks of running a food brand. SocialPiper has allowed me to easily and conveniently outsource all of my social media management.',
  'landing.testimonial.purpose.1': 'for Law firms',
  'landing.testimonial.purpose.2': 'for Food business',
  'landing.testimonial.purpose.3': 'for Insurance Companies',
  'landing.testimonial.purpose.4': 'for Real State',
  'landing.testimonial.purpose.5': 'for Healthy Snack business',
  'landing.title': 'Social Media made easy, for business',
  'landing.promotional.title': 'AI + Social?',
  'landing.promotional.subtitle': 'The smartest and easiest way to manage {lineBreak}your social media!',
  'landing.promotional.description': 'Social Piper has integrated AI to create, schedule and manage all your business social posts like a true pro.',
  'landing.promotional.description2': "If you're running a business and want to be successful on social media, Social Piper helps you save time, money, and effort, so you can focus on what you do best: <bText>growing your business.</bText>",
  'landing.promotional.description1.mobile': 'Social Piper has integrated AI to create, schedule and manage all your business social posts like a true pro.',
  'landing.promotional.description2.mobile': "If you're running a business and want to be successful on social media, Social Piper helps you save time, money, and effort, so you can focus on what you do best: <bText>growing your business.</bText>",
  'landing.promotional.button.trial.mobile': 'Start your free {trialDays}-day trial!',
  'landing.promotional.button.trial': 'Start your free {trialDays}-day trial!',
  'landing.promotional.button2.trial.mobile': 'Start your free {trialDays}-day trial!',
  'landing.promotional.button2.trial': 'Start your free {trialDays}-day trial!',
  'landing.promotional.buttonInfo': 'Try Social Piper risk-free, <strong>you can cancel at any time.</strong>',
  'landing.promotional.buttonInfo.without.trial': '<strong>You can cancel at any time.</strong>',
  'landing.promotional.button.show.video': 'See how it works',
  'landing.pricing.title': 'Select a plan that works for your business',
  'landing.pricing.title.mobile': 'Select a plan that works for you',
  'landing.pricing.description': `Try Social Piper free for ${TRIAL_DURATION} days. No risk, cancel anytime.`,
  'landing.pricing.owner.title': 'Pro',
  'landing.pricing.owner.attr.templates': 'Access to +1000 templates',
  'landing.pricing.owner.attr.designTool': 'Design tool',
  'landing.pricing.owner.attr.schedule': 'Scheduling calendar',
  'landing.pricing.owner.attr.messages': 'Managing messages and comments',
  'landing.pricing.owner.attr.boostTool': 'Facebook and Instagram Advertising.',
  'landing.pricing.owner.attr.budgetTool': 'Budget management tool',
  'landing.pricing.owner.attr.competitors': 'Competition monitoring tool',
  'landing.pricing.owner.attr.user': 'Single user account',
  'landing.pricing.owner.attr.connection': 'Connect a single social media profile for Facebook, Instagram and Twitter.',
  'landing.pricing.owner.price.offer': 'COVID relief offer',
  'landing.pricing.owner.yearly.freeMonths': '2 MONTHS FREE',
  'landing.pricing.owner.normalPrice': 'Before USD <c></c>',
  'landing.pricing.smallTeams.title': 'Business',
  'landing.pricing.smallTeams.further': 'And...',
  'landing.pricing.smallTeams.attr.allOwner': 'Everything included in "Pro"',
  'landing.pricing.smallTeams.attr.multiuser': 'Multiple users for the same Social Piper Account.',
  'landing.pricing.smallTeams.attr.multipleConnections': 'Connect more than one social media profile for Facebook, Instagram and Twitter.',
  'landing.pricing.smallTeams.attr.approval': 'Review and approval workflows.',
  'landing.academy.banner.isHere': 'is here!',
  'landing.academy.banner.title': 'Become an expert with the experts',
  'landing.academy.banner.description': 'Social Piper\'s Academy helps your business reach its full potential through e-learning tools and content to ensure product mastery through skills training.',
  'landing.academy.banner.button': 'Let’s start learning!',
  'landing.app.banner.title': 'Business management on the go!',
  'landing.app.banner.paragraph1': 'With our app you can keep track of everything on the Go, bringing everything you love about Social Piper to the palm of your hand.',
  'landing.enjoy.superTitle': 'Enjoy the experience of using the best social media tool',
  'landing.enjoy.Title': 'You will see that Less is More!',
  'landing.enjoy.paragraph1': 'Social Piper is the platform that offers you unbeatable tools and a simple and automated process to make your business stand out on social media.',
  'landing.enjoy.paragraph2': 'Click on the video to see what Social Piper can do for your business.',
  'landing.whereToPost.title': 'Within seconds, post to:',
  'landing.whereToPost.linkinstagram': 'Learn more',
  'landing.social.instagram.description': '+ IG Stories',
  'landing.tooltip.instagram.title': 'The best tool to post on your Instagram Business page, where you can:',
  'landing.tooltip.instagramStories.title': 'With the Social Piper app you can:',
  'landing.tooltip.instagram.feature1': 'Upload photos or videos',
  'landing.tooltip.instagram.feature2': 'Post & Schedule directly to your profile.',
  'landing.tooltip.instagram.feature3': 'Publish or Schedule at the best times based on Piper recommendations.',
  'landing.tooltip.instagram.feature4': '+3,000 available templates and images ready to use.',
  'landing.tooltip.instagram.feature5': 'Easily edit and add your logo to your images.',
  'landing.tooltip.instagramStories.feature1': 'Upload photos or videos for your Stories.',
  'landing.tooltip.instagramStories.feature2': 'Post immediately',
  'landing.tooltip.instagramStories.feature3': 'And the best: only with Social Piper YOU CAN <s>Schedule an Instagram Story!</s>',
  'landing.tools.title': 'You have big dreams for your business, {lineBreak}we have the tools to reach them.',
  'landing.tools.subtitle': 'Easy to use, all in one place. Piper shows you how.',
  'landing.tools.title.mobile': 'You have big dreams for your business, {lineBreak}we have the tools to reach them.',
  'landing.tools.subtitle.mobile': 'Easy to use, all in one place. Piper shows you how.',
  'landing.tools.tool1.title': 'Post perfection.',
  'landing.tools.tool1.description': 'Use your own texts and images or let {lineBreak}“Piper” (our AI) create them for you.',
  'landing.tools.tool2.title': 'Easy and intelligent self-planning.',
  'landing.tools.tool2.description': 'Post strategically on all your social media using the {lineBreak}most Automatic and “smart” calendar on the market.',
  'landing.tools.tool3.title': 'Effortless interaction.',
  'landing.tools.tool3.description': 'Without going round and round. {lineBreak}Answer by yourself or let “Piper” answer all {lineBreak}comments from one place.',
  'landing.tools.tool4.title': 'Boost for success!',
  'landing.tools.tool4.description': 'Piper helps you manage your ad spending to reach your biggest audience.',
  'landing.piper.title': 'Meet Piper,',
  'landing.piper.subtitle': "Your business's smartest and most trusted social media friend!",
  'landing.piper.description1': 'Whether you’re a freelancer working solo or part of a small business team, Piper is at your side.',
  'landing.piper.description2': 'He offers insights, reveals trends, and turns industry data into actionable tasks. Piper keeps learning – from you, your followers, and experts in the field.  He delivers sound advice and key metrics to elevate your brand.',
  'landing.awardSection.title': '2021 WINNER',
  'landing.awardSection.subtitle': 'WORLD’S BEST SOCIAL MEDIA PLATFORM FOR SMALL BUSINESSES',
};

export default en;
