const es = {
  'inAppTouch.gretting.1': 'Nos gustaría hablar contigo.',
  'inAppTouch.gretting.2': '¡Hola {firstName}!',
  'inAppTouch.gretting.3': '¡Hola {firstName}!',
  'inAppTouch.gretting.4': '¿Tienes un cupón de descuento?',
  'inAppTouch.text.1': 'Hoy eres uno de los usuarios top, y nos interesa tener una <hl>breve charla de 15 minutos</hl> para conocer lo que no te gusta y lo que sí te gusta de Social Piper.',
  'inAppTouch.text.2': 'En las últimas horas detectamos que eres uno de los usuarios TOP en Social Piper por lo que nos interesa tener una <hl>charla de 15 minutos</hl> para conocer lo que no y sí te gusta de Social Piper.',
  'inAppTouch.text.3': 'Tu período de prueba está por terminar. <hl>Si contestas ahora</hl> nuestra encuesta recibirás',
  'inAppTouch.text.4': '¿Tienes un cupón de descuento?',
  'inAppTouch.subText.3': '30 días más completamente gratis.',
  'inAppTouch.cta.1': 'Agenda aquí tu llamada',
  'inAppTouch.cta.2': 'Agenda aquí tu llamada',
  'inAppTouch.cta.3': 'Ir a encuesta',
  'inAppTouch.cta.4': '¡Úsalo ahora!',
  'requireAttention.text': 'Una o mas de las redes sociales que conectaste requiere atención inmediata.',
  'requireAttention.cta': 'Solucionar ahora',
  'requireAttention.settings': '{warni} Esta conexión ha expirado; esto puede ser por un cambio de contraseña, la conexión de la red social a Social Piper ha estado en desuso, etc.',
};

export default es;
