const es = {
  'draft.post.title': 'Borradores ({totalCreations})',
  'myCreations.piperTip': 'Lo sentimos. No hay plantillas que coincidan con tu búsqueda. Prueba utilizando palabras o conceptos similares.',
  'myCreations.subtitle': 'Mis plantillas',
  'other.templates.title': 'Otras plantillas',
  'otherImages.piperTip': 'Lo sentimos. Las plantillas de Social Piper sólo están disponibles en la versión de escritorio o tableta de Social Piper',
  'otherImages.piperTip.searching': 'Empieza por buscar una palabra clave',
  'source.search.placeholder': 'Buscar ...',
  'templates.category.1': 'Panadería',
  'templates.category.label': 'Categoria:',
  'templates.goal.label': 'Filtra por objetivo de negocio:',
  'templates.goals.all': 'Todos',
  'templates.goals.awareness': 'Reconocimiento',
  'templates.goals.conversion': 'Conversión',
  'templates.goals.mixAndMatch': 'Mezclar y combinar',
  'templates.goals.tags.values.awareness': 'Reconocimiento',
  'templates.goals.tags.values.conversion': 'Conversión',
  'templates.goals.tags.values.mixAndMatch': 'Mezclar y combinar',
  'templates.header.lbutton': 'Cargar imagen',
  'templates.header.paragraph': '¿Quieres crear más plantillas?',
  'templates.header.paragraph.auto': '¿Quieres crear una publicación automática?',
  'templates.header.sbutton': '+ imagen',
  'templates.header.title': 'Centro Creativo',
  'templates.piper.advice': 'Selecciona una plantilla para publicarla',
  'templates.images.piper.advice': 'Selecciona una imagen para publicarla',
  'templates.piper.title': 'Piper te recomienda',
  'templates.piperTip': 'Lo sentimos. No hay plantillas que coincidan con tu búsqueda. Prueba utilizando palabras o conceptos similares.',
  'templates.search.placeholder': 'Buscar etiquetas',
  'templates.searchBar.tips.awareness': 'Muestra plantillas recomendadas para dar a conocer tu negocio en redes sociales, haciéndolo más llamativo para las personas que vean tus publicaciones.',
  'templates.searchBar.tips.conversion': 'El objetivo de estas plantillas es atraer más tráfico a tu tienda en línea, centrarse en las ventas y aumentar potencialmente tus ingresos.',
  'templates.searchBar.tips.mixAndMatch': '¿Buscas un equilibrio entre llegar a más personas e incentivar las ventas con tus mensajes? Estas plantillas pueden ayudarte a encontrarlo fácilmente.',
  'templates.searchBar.tips.all': 'Además de buscar contenido por palabras clave, también podrás filtrar el contenido por tu objetivo de negocio; estos objetivos van desde alcanzar a más personas a impulsar ventas con tus mensajes.',
  'templates.source.label': 'Fuente:',
  'templates.tab.myCreations': 'Tus creaciones',
  'templates.tab.otherImages': 'Fotografías',
  'templates.tab.spTemplates': 'Plantillas',
  'templates.tab.managed_group' : 'Grupo Angeles',
  'templates.content.remainingTags': '+{total} más',
  'templates.mediaCard.upgrade': 'Usa ésta y cientos de otras plantillas',
  'unsplash.photo.by': 'Foto por',
  'unsplash.photo.on': 'en',
};

export default es;
