import gql from 'graphql-tag';

export const getPaymentDates = (attr = `
  lastPaymentDate
  nextPaymentDate
  paymentAmount
`) => gql`
  query {
    lastPayment {
      ${attr}
    }
  }
`;

export const getPaymentMethods = (attr = `
  isDefaultPayment
  name
  brand
  last4
  exp
`) => gql`
  query getPaymentMethods {
    getPaymentMethods {
      id
      ${attr}
    }
  }
`;

export const getPaymentHistory = (attr = `
  date
  account {
    name
    brand
    last4
  }
  amount {
    value
    currency
  }
  receiptUrl
  concept
  typeCharge
`) => gql`
  query getPaymentHistory {
    paymentHistory {
      id
      ${attr}
    }
  }
`;

export const getPaymentHistoryApple = () => gql`
  query getPaymentHistoryApple {
    paymentHistoryApple {
      id
      date
      account {
        name
        brand
        last4
      }
      amount {
        value
        currency
      }
      receiptUrl
      concept
      typeCharge
    }
  }
`;
export const getPaymentHistoryAndroid = () => gql`
  query getPaymentHistoryAndroid {
    paymentHistoryAndroid {
      id
      date
      account {
        name
        brand
        last4
      }
      amount {
        value
        currency
      }
      receiptUrl
      concept
      typeCharge
      typeNotification
    }
  }
`;
