const today = new Date();
const getDate = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result.toISOString();
};

const SingleRecomendation = {
  facebook: {
    getLastWeekAudienceSize: [
      {
        count: 70,
        isoDate: getDate(today, 1),
      },
      {
        count: 60,
        isoDate: getDate(today, 2),
      },
      {
        count: 40,
        isoDate: getDate(today, 3),
      },
      {
        count: 100,
        isoDate: getDate(today, 4),
      },
      {
        count: 85,
        isoDate: getDate(today, 5),
      },
    ],
  },
  instagram: {
    getLastWeekAudienceSize: [
      {
        count: 70,
        isoDate: getDate(today, 1),
      },
      {
        count: 60,
        isoDate: getDate(today, 2),
      },
      {
        count: 40,
        isoDate: getDate(today, 3),
      },
      {
        count: 100,
        isoDate: getDate(today, 7),
      },
      {
        count: 85,
        isoDate: getDate(today, 5),
      },
    ],
  },
  twitter: {
    getLastWeekAudienceSize: [
      {
        count: 70,
        isoDate: getDate(today, 1),
      },
      {
        count: 60,
        isoDate: getDate(today, 2),
      },
      {
        count: 40,
        isoDate: getDate(today, 3),
      },
      {
        count: 100,
        isoDate: getDate(today, 4),
      },
      {
        count: 85,
        isoDate: getDate(today, 5),
      },
    ],
  },
  linkedin: {
    getLastWeekAudienceSize: [
      {
        count: 70,
        isoDate: getDate(today, 1),
      },
      {
        count: 60,
        isoDate: getDate(today, 2),
      },
      {
        count: 40,
        isoDate: getDate(today, 3),
      },
      {
        count: 100,
        isoDate: getDate(today, 4),
      },
      {
        count: 85,
        isoDate: getDate(today, 5),
      },
    ],
  },
};

export default SingleRecomendation;
