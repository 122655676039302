import { useContext } from 'react';
import RoleProvider from '../../services/entities/RoleProvider';
import RoleContext from '../../contexts/RolesContext';
import PermissionsContext from '../../contexts/PermissionsContext';
import QuotasContext from '../../contexts/QuotasContext';

const InitAppAccess = () => {
  const {
    setPublishQuota,
    setLimitPublishQuota,
    setCompetitorsQuota,
    setLimitCompetitorsQuota,
    setBoostQuota,
    setLimitBoostQuota,
  } = useContext(QuotasContext);
  const {
    setCanUseEditor,
    setCanUseTemplates,
  } = useContext(PermissionsContext);
  const { setRole, setEndDate } = useContext(RoleContext);

  const update = ({
    role, endDate, permissions, quotas,
  }) => {
    setPublishQuota(quotas.canPublish.usage);
    setLimitPublishQuota(quotas.canPublish.limit);
    setBoostQuota(quotas.canBoost.usage);
    setLimitBoostQuota(quotas.canBoost.limit);
    setCompetitorsQuota(quotas.canAddCompetitorFB.usage);
    setLimitCompetitorsQuota(quotas.canAddCompetitorFB.limit);
    setCanUseEditor(permissions.useEditor);
    setCanUseTemplates(permissions.useTemplates);
    setRole(role);
    setEndDate(new Date(endDate));
  };

  const updateRole = (role) => {
    setRole(role);
  };

  const fetchAndUpdate = async () => {
    const { success, data } = await RoleProvider.fetchRole();
    if (!success) return;
    update(data);
  };

  return {
    fetchAndUpdate,
    update,
    updateRole,
  };
};

export default InitAppAccess;
