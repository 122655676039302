import * as Sentry from '@sentry/browser';
import { authAppSync } from '../auth';
import { getInAppTouch } from '../../graphql/queries';
import { dismissAppTouchInfo } from '../../graphql/mutations';
import {
  ENV,
  ENVIRONMENTS,
} from '../../utils/constants/globals';

export default class InAppTouchProvider {
  static async fetchInAppTouchInfo() {
    let result = {
      message: '',
      success: false,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: getInAppTouch,
          fetchPolicy: 'network-only',
        });
        data = response.data.getInAppTouch;
      } else {
        data = {
          displayLimit: 0,
          displayCount: 0,
          messageIdAttached: 4,
        };
      }
      if (data.displayCount < data.displayLimit) {
        result = {
          message: 'success',
          success: true,
          data,
        };
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async dissmissInAppModal(action) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.mutate({
          mutation: dismissAppTouchInfo,
          variables: {
            action,
          },
        });
        data = response.data.dismissAppTouchInfo;
      } else {
        data = {
          success: true,
        };
      }
      if (data?.success) {
        result = {
          message: 'success',
          success: true,
        };
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
