import { ProfilePlaceholder } from '../../assets/icons/iconRepository';

export const postImages = [
  {
    source: 'https://cdn.forbes.com.mx/2019/10/starbucks.jpg',
    type: 'image',
  },
];
export const postImages2 = [
  {
    source: 'https://scontent.fmex11-1.fna.fbcdn.net/v/t1.0-9/p720x720/107662474_3119634791462059_1151250216905893481_o.jpg?_nc_cat=111&_nc_sid=3b2858&_nc_oc=AQm9eSOlFPn-yn2lf3ORmelD6sAK6Js4_NCoNtMmvU2GUcxLU4PRFYvcSizICasppZizbfm0hbtKKiDwk-RCa3Zi&_nc_ht=scontent.fmex11-1.fna&_nc_tp=6&oh=37e822aa52af694db35846d45e95bb6b&oe=5F2D935B',
    type: 'image',
  },
];
export const postImages3 = [
  {
    source: 'https://scontent.fmex11-1.fna.fbcdn.net/v/t1.0-9/107642659_642056043071302_8643419000247450195_n.jpg?_nc_cat=108&_nc_sid=3b2858&_nc_oc=AQlAf0YmT9IDDUX4jyTfPmF2CPYUIw7k2EZX9ylUPqjWnEjIKcTeGG-cTimUzNh_Omc0JCisdXlm7Qc4eMoEs8KJ&_nc_ht=scontent.fmex11-1.fna&oh=07fa0ec4486f7728f9e73fac70c5d14a&oe=5F2DA107',
    type: 'image',
  },
];

export const interactions = {
  commentsQty: {
    prevWeekQuantity: 100,
    quantity: 50,
  },
  impressions: {
    prevWeekQuantity: 876,
    quantity: 959,
  },
  likes: {
    prevWeekQuantity: 150,
    quantity: 200,
  },
  totalInteractions: {
    prevWeekQuantity: 130,
    quantity: 115,
  },
  followers: {
    prevWeekQuantity: 3700,
    quantity: 3984,
  },
  shares: {
    prevWeekQuantity: 0,
    quantity: 300,
  },
  reach: {
    prevWeekQuantity: 8800,
    quantity: 9300,
  },
};
export const interactions2 = {
  commentsQty: {
    prevWeekQuantity: 100,
    quantity: 50,
  },
  impressions: {
    prevWeekQuantity: 876,
    quantity: 959,
  },
  likes: {
    prevWeekQuantity: 150,
    quantity: 200,
  },
  totalInteractions: {
    prevWeekQuantity: 130,
    quantity: 115,
  },
  followers: {
    prevWeekQuantity: 23,
    quantity: 12,
  },
  shares: {
    prevWeekQuantity: 0,
    quantity: 200,
  },
  reach: {
    prevWeekQuantity: 6800,
    quantity: 6300,
  },
};
export const interactions3 = {
  commentsQty: {
    prevWeekQuantity: 50,
    boosted: 200,
    quantity: 300,
  },
  impressions: {
    prevWeekQuantity: 10,
    boosted: 7000,
    quantity: 8636,
  },
  likes: {
    prevWeekQuantity: 30,
    boosted: 333,
    quantity: 440,
  },
  totalInteractions: {
    prevWeekQuantity: 80,
    boosted: 900,
    quantity: 923,
  },
  followers: {
    prevWeekQuantity: 55,
    boosted: 10,
    quantity: 55,
  },
  shares: {
    prevWeekQuantity: 0,
    quantity: 300,
    boosted: 100,
  },
  reach: {
    prevWeekQuantity: 6800,
    quantity: 6300,
    boosted: 7300,
  },
};

export const client1 = {
  id: '12119',
  lastConnection: new Date('2020-06-29T19:46:45.000Z'),
  name: 'Noemi Lombrozo 1',
  avatar:
        'https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=2893130640808697&height=50&width=50&ext=1596053354&hash=AeTUcAFBIiLpw7jK',
  type: 'person',
};

const client2 = {
  id: '12120',
  lastConnection: new Date('2020-06-29T19:46:45.000Z'),
  name: 'Noemi Lombrozo 2',
  avatar:
        'https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=2893130640808697&height=50&width=50&ext=1596053354&hash=AeTUcAFBIiLpw7jK',
  type: 'person',
};

const client3 = {
  id: '12121',
  lastConnection: new Date('2020-06-29T19:46:45.000Z'),
  name: 'Noemi Lombrozo 3',
  avatar: ProfilePlaceholder,
  type: 'person',
};

export const me = {
  id: '12120',
  lastConnection: new Date('2020-06-29T19:47:21.000Z'),
  name: 'David Behar Page',
  avatar:
        'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/cp0/p50x50/103837041_690529628397611_4655042340837557142_n.jpg?_nc_cat=106&_nc_sid=dbb9e7&_nc_oc=AQnJLwBH5AT30bwgXovK3ifTMhRQH7d7eCBzJ7pGz-ptSNJhF6-jTVbDa9Yeu02kIj0&_nc_ht=scontent-iad3-1.xx&oh=e0b8ec7c610904cc52901bf2be00b08d&oe=5F214A64',
  type: 'user',
};

export const posts = [
  {
    id: '323',
    author: me,
    interactions,
    comments: [{
      from: client1,
      to: me,
      media: postImages,
      fromMe: false,
      id: '111',
      text: 'Super bad and incompetent service support. Absolutely horrible if you have some problems. NEVER',
      timestamp: new Date('2020-06-29T19:49:04.000Z'),
    }],
    media: postImages,
    content: `Outplay yourself.
    Roger Federer, Melbourne's defending champion and holder of 19 major titles, just beat his own record, by winning 20. #justdoit`,
    published: new Date('2020-06-29T19:49:04.000Z'),
    socialNetwork: 'instagram',
  },
  {
    id: '322',
    author: me,
    interactions: interactions2,
    media: postImages2,
    comments: [
      {
        from: client1,
        to: me,
        media: postImages,
        fromMe: false,
        id: '112',
        text: 'How is Kapernick working out for you now? "We have the right to fight back! " he quotes on twitter. hmm It is terribly wrong what happened to Mr. Floyd but Kapernick should NOT be encouraging with his comments like that.',
        timestamp: new Date('2020-06-29T19:48:04.000Z'),
      },
    ],
    content: 'Only you can cross your finish line. Congrats Kevin Hart for Breaking 26.2 at #TCSNYCMarathon    ',
    published: new Date('2020-06-29T19:48:04.000Z'),
    socialNetwork: 'facebook',
  },
  {
    id: '321',
    author: me,
    interactions: interactions3,
    media: postImages3,
    comments: [
      {
        from: client1,
        to: me,
        media: postImages,
        fromMe: false,
        id: '113',
        text:
                    'Have her design shoes for women! A womans line designed by a woman, what a novel idea!',
        timestamp: new Date('2020-10-29T19:48:04.000Z'),
      },
    ],
    content: `Shalane Flanagan.
    After years of medals earned on the world stage, and a grueling 9-month injury recovery, her legacy is cemented on the streets of NYC, as the first American woman in 40 years to win the #TCSNYCMarathon.
    Geoffrey Kamworor.
    Made his mark in the mud as a world cross country champion. Took to the track for titles won. Turned to the roads to face the world’s best in the marathon. And set a blistering pace on the concrete of NYC, to capture his first major marathon victory.
    Congratulations Shalane Flanagan and Geoffrey Kamworor on your triumphant breakthrough victories in NYC.`,
    published: new Date('2020-10-29T19:48:04.000Z'),
    socialNetwork: 'instagram',
  },
];

export const unreadInboxMessages = [
  {
    from: client3,
    to: me,
    media: postImages,
    fromMe: false,
    id: '212',
    text: 'How much does your product cost?',
    timestamp: new Date('2020-06-29T19:48:04.000Z'),
    read: true,
  },
  {
    from: client2,
    to: me,
    media: postImages,
    fromMe: false,
    id: '213',
    text: 'So, had no idea you are married to Orlando Bloom. You two are going to have SUCH a beautiful family! ❤️ All the best.',
    timestamp: new Date('2020-07-21T19:48:04.000Z'),
    read: false,
  },
  {
    from: client1,
    to: me,
    media: postImages,
    fromMe: false,
    id: '114',
    text: 'i love you SO MUCH. thank you for bringing love and light into our lives each and every time. ❤',
    timestamp: new Date('2020-06-29T19:48:04.000Z'),
    read: true,
  },
];

export default {};
