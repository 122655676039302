import gql from 'graphql-tag';

export const searchFacebookPages = gql`
  query facebookPageSearch($searchValue: String!) {
    facebookPageSearch(searchValue: $searchValue){
      id
      name
      link
      picture
    }
  }
`;

export const getFacebookPages2Setup = gql`
  query getFacebokPages {
    facebookPages{
      id
      name
      picture
      isSelected
    }
  }
`;
export const getIGPages2Setup = gql`
  query getIGPages {
    instagramPages{
      id
      name
      picture
      isSelected
    }
  }
`;

export const facebookCompetitorPages = gql`
  query facebookCompetitorPages{
    facebookCompetitorPages{
      id
      facebookId
      name
      link
      picture
    }
  }
`;

export const facebookConversations = gql`
  query facebookConversations($pagination: Pagination!) {
    facebookConversations(pagination: $pagination) {
      id
      from
      fromImage
      previewMessage {
        id
        message
        createdTime
        from
        read
        userImage
      }
    }
  }
`;

export const facebookConversationMessages = gql`
  query facebookConversationMessages($conversationId: Int!, $pagination: Pagination) {
    facebookConversationMessages(conversationId: $conversationId, pagination: $pagination) {
      id
      message
      createdTime
      from
      read
      userImage
      sticker
      media {
        type
        url
      }
    }
  }
`;

export const countNewCommentsMessages = gql`
  query countNewCommentsMessages {
    countNewCommentsMessages {
      messagesCount
      fbCommentsCount
      twRepliesCount
      igCommentsCount
      liCommentsCount
    }
  }
`;

export const facebookPosts = gql`
  query facebookPosts($pagination: Pagination, $orderBy: POSTS_ORDER_BY) {
    facebookPosts(pagination: $pagination, orderBy: $orderBy) {
      id
      publishTime
      image
      video
      attachmentType
      message
      shares
      reactions
      commentsNumber
      unreadCommentsCount
      campaignsCount
    }
  }
`;

export const getFacebookPageFanCountString = gql`
  query {
    facebookFetchFanCountString
  }
`;
