const en = {
  'draft.post.title': 'Drafts ({totalCreations})',
  'myCreations.piperTip': 'Sorry. There are no templates that match your search. Try using similar words or concepts.',
  'myCreations.subtitle': 'My templates',
  'other.templates.title': 'Other Templates',
  'otherImages.piperTip': 'Sorry. Social Piper Templates are currently only available on the desktop or tablet versions of Social Piper',
  'otherImages.piperTip.searching': 'Start by searching for a keyword',
  'source.search.placeholder': 'Search ...',
  'templates.category.1': 'Bakery',
  'templates.category.label': 'Category:',
  'templates.goal.label': 'Filter by business goal:',
  'templates.goals.all': 'All',
  'templates.goals.awareness': 'Awareness (Reach)',
  'templates.goals.conversion': 'Conversion',
  'templates.goals.mixAndMatch': 'Mix & Match',
  'templates.goals.tags.values.awareness': 'Awareness',
  'templates.goals.tags.values.conversion': 'Conversion',
  'templates.goals.tags.values.mixAndMatch': 'Mix and match',
  'templates.header.lbutton': 'Upload Image',
  'templates.header.paragraph': 'Want to create more templates?',
  'templates.header.paragraph.auto': 'Want to create more templates?',
  'templates.header.sbutton': 'Upload Image',
  'templates.header.title': 'Creative Center',
  'templates.piper.advice': 'Choose a template to post it',
  'templates.images.piper.advice': 'Choose an image to post it',
  'templates.piper.title': 'Piper recommends',
  'templates.piperTip': 'Sorry. There are no templates that match your search. Try using similar words or concepts.',
  'templates.search.placeholder': 'Search tags',
  'templates.searchBar.tips.awareness': 'Show recommended content to improve the awareness of your business on social networks, making it more attractive to people who see your posts.',
  'templates.searchBar.tips.conversion': 'The goal of these images is to drive more traffic to your online store, focused on sales, and potentially increase your income.',
  'templates.searchBar.tips.mixAndMatch': 'Are you looking for a balance between reaching more people and incentivizing the sales-oriented message? These templates could help you to find it easily.',
  'templates.searchBar.tips.all': 'In addition to searching content by keywords, you can also filter the content by your business goal; these goals range from reaching more people to driving sales with your messages.',
  'templates.source.label': 'Source:',
  'templates.tab.myCreations': 'My creations',
  'templates.tab.otherImages': 'Photos',
  'templates.tab.spTemplates': 'Templates',
  'templates.tab.managed_group' : 'Grupo Angeles',
  'templates.content.remainingTags': '+{total} more',
  'templates.mediaCard.upgrade': 'Use this and other hundreds of exclusive templates',
  'unsplash.photo.by': 'Photo by',
  'unsplash.photo.on': 'on',
};

export default en;
