const competitorsRecentActivityMock = [
  {
    data: {
      facebookRecentCompetitorsPosts: [{
        name: 'Intel',
        caption: 'Lenovo, #muylargoquenocabeeneldiv and the Jonas Brothers are celebrating creators everywhere with a special concert.  Watch tonight at 4PM/7PM ET',
        dateTime: '2021-01-20T17:02:07.000Z',
        mediaUrl: 'https://cdn.stg.socialpiper.com/public-content/174b0340-baf4-11ea-bf0b-dfcb991f39ab-22juniomedicalawareness.svg',
        mediaType: 'album',
        interactions: 33,
        network: 'facebook',
        profilePic: 'https://scontent-iad3-1.xx.fbcdn.net/v/t1.0-1/cp0/p50x50/118521736_10157243221741850_19095419556813004_o.png?_nc_cat=1&ccb=2&_nc_sid=dbb9e7&_nc_ohc=Xmj_jYPTo8gAX9kNc1J&_nc_ht=scontent-iad3-1.xx&_nc_tp=30&oh=5d778b245ad3fa660d5521eea0619d70&oe=5FF036A4',
        permalinkUrl: 'https://www.facebook.com/22707976849/posts/10157440261006850/',
        sharedLink: null,
        __typename: 'CompetitorPost',
      }],
    },
  },
  {
    data: {
      instagramRecentCompetitorsPosts: [
        {
          name: 'Intel',
          caption: 'Swipe to learn why BrittanySky relies on the premium audio and ultra-fast Intel Wi-Fi 6 on her HP #Spectrex360—verified on the new #IntelEvo platform.',
          dateTime: '2021-01-20T17:02:07.000Z',
          mediaUrl: 'https://cdn.stg.socialpiper.com/public-content/174b0340-baf4-11ea-bf0b-dfcb991f39ab-22juniomedicalawareness.svg',
          mediaType: 'carousel_album',
          interactions: 1590,
          network: 'instagram',
          profilePic: 'https://scontent-iad3-1.xx.fbcdn.net/v/t51.2885-15/118615029_125016772334563_5397625118813847536_n.jpg?_nc_cat=1&ccb=2&_nc_sid=86c713&_nc_eui2=AeFy1K1ZwPJlXBzt-k5Pr4bbzZ6eGp_A-G_Nnp4an8D4b42PB4FROCPukNtM-QL9Lt5DWIntVBKV3BAAfUJW-Vcx&_nc_ohc=pYfrjCzUds4AX-F5x1H&_nc_ht=scontent-iad3-1.xx&oh=2ad6d7aed06521311224ea4f843d9176&oe=5FF178D7',
          permalinkUrl: 'https://www.instagram.com/p/CIV99pJHoXZ/',
          __typename: 'CompetitorPost',
        },
      ],
    },
  },
];

export default competitorsRecentActivityMock;
