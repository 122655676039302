import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import colors from '../../../styled/colors';
import Dot from '../Dot';
import GlobalTheme from '../../../styled/GlobalTheme';
import { CheckBrownIcon } from '../../../assets/icons/iconRepository';

export const CURRENT = 'current';
export const NOT_REACHED = 'notReached';
export const FINISHED = 'finished';

const CustomText = styled.p`
  color: ${({ processState }) => {
    if (processState === NOT_REACHED) return colors.gray02;
    if (processState === CURRENT) return colors.green;
    return colors.black;
  }};
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  text-align: center;
  margin-block-start: 0px;
  margin-block-end: 0px;
  ${GlobalTheme.breakpoints.down('sm')} {
    font-size: 12px;
  }
`;

const TextContainer = styled.div`
  position: relative;
  padding: 8px;
  display: flex;
  align-items: center;
`;

const GreenRectangle = styled.div`
  position: absolute;
  background: ${colors.green};
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 112px;
  height: 4px;
  display: ${({ processState }) => (processState === CURRENT ? 'block' : 'none')};
  ${GlobalTheme.breakpoints.down('sm')} {
    width: 50px;
  }
`;

const UpgradeSection = ({ children, processState, stage }) => {
  const [dotColor, setDotColor] = useState(colors.grayE9);

  useEffect(() => {
    if (processState === NOT_REACHED) return setDotColor(colors.grayE9);
    return setDotColor(colors.secondary);
  }, [processState]);

  return (
    <>
      <TextContainer>
        <Dot backgroundColor={dotColor}>
          {processState === FINISHED ? <img src={CheckBrownIcon} alt="check" /> : stage}
        </Dot>
        <CustomText processState={processState}>
          { children }
        </CustomText>
        <GreenRectangle processState={processState} />
      </TextContainer>
    </>
  );
};

UpgradeSection.propTypes = {
  children: propTypes.func.isRequired,
  processState: propTypes.string,
  stage: propTypes.number.isRequired,
};

UpgradeSection.defaultProps = {
  processState: 'notReached',
};

export default UpgradeSection;
