/* eslint-disable react/destructuring-assignment */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useScrollToTop(scroll = true) {
  const location = useLocation();
  useEffect(() => {
    if (scroll)window.scrollTo(0, 0);
  }, [location.pathname]);
}

export default useScrollToTop;
