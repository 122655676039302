const en = {
  "scheduler.activity.label": "Audience activity",
  "scheduler.activity.less": "less active",
  "scheduler.activity.more": "more active",
  "scheduler.advancedDrawer.content.competitors": "Competitors posts",
  "scheduler.advancedDrawer.content.past": "Past posts",
  "scheduler.advancedDrawer.content.scheduled": "Scheduled posts",
  "scheduler.advancedDrawer.content.title": "Display content",
  "scheduler.advancedDrawer.profiles.title": "Display profiles",
  "scheduler.advancedDrawer.calendarView.title": "Calendar view",
  "scheduler.advancedDrawer.title": "Advanced Settings",
  "scheduler.advice":
    "For maximum impact, post at least once a day.{lineBreak}I’ve suggested optimal days/time when your audience is most active.",
  "scheduler.bestTimes.filter.label": "Show best times to post on",
  "scheduler.view.filter.label": "View by",
  "scheduler.competitors.heading": "Competitors Posts",
  "scheduler.deleteModal.confirm": "Yes, delete this Post",
  "scheduler.deleteModal.description":
    "You are about to delete your post. This action will delete the post from{lineBreak} your social network too.{lineBreak}Are you sure you want to delete the post?",
  "scheduler.deleteModal.title": "Delete your post",
  "scheduler.showDeleteModal.button": "Delete post",
  "scheduler.editPost": "Edit your post",
  "scheduler.editPost.label": "Title / Caption",
  "scheduler.eventCard.boost": "Boost it!",
  "scheduler.eventCard.scheduled": "Scheduled {hour}",
  "scheduler.eventCard.video.processing": "Preparing your post",
  "scheduler.eventCard.video.error.text":
    "“There was an error with your video.”",
  "scheduler.eventCard.image.error.text":
    "“There was an error with your image.”",
  "scheduler.eventCard.album.error.text":
    "“There was an error with your album.”",
  "scheduler.hero.createPost": "+ Create Post",
  "scheduler.hero.piper":
    "“My best recommendation to publish is set in dark green. Look for this spaces along the calendar.”",
  "scheduler.hero.piper.ipad":
    '"Best time to publish is marked in dark green. Look for it”',
  "scheduler.hero.piper.mobile":
    '"My best recommendation to publish is set in dark green. Look for this spaces on each day.”',
  "scheduler.piperRecommendation.title.afternoon": "Afternoon post",
  "scheduler.piperRecommendation.title.evening": "Evening post",
  "scheduler.piperRecommendation.title.morning": "Morning post",
  "scheduler.piperRecommendation.title.night": "Night post",
  "scheduler.piperRecommendation.title.monthView.afternoon": "Afternoon",
  "scheduler.piperRecommendation.title.monthView.evening": "Evening",
  "scheduler.piperRecommendation.title.monthView.morning": "Morning",
  "scheduler.piperRecommendation.title.monthView.night": "Night",
  "scheduler.piperRecommendation.button.create.post": "Create post",
  "scheduler.noleftposts.text":
    "You will no longer be able to schedule any more posts until {date}. Become a Pro and schedule without limits.",
  "scheduler.reschedule.modal.title": "Reschedule",
  "scheduler.boosts.heading": "Boosts",
  "scheduler.mobile.postNotFound": "You have no posts on this day. Create one!",
  "scheduler.mobile.postNotFound.dayBefore": "You have no posts on this day.",
  "scheduler.mobile.postNotFound.button": "Create a post for this day",
  "scheduler.mobile.competitors.empty":
    "Your competitors haven't posted today.",
  "scheduler.mobile.recommendations.notFound":
    "You have no recommendations on this day",
  "scheduler.mobile.tabPosts": "My posts",
  "scheduler.mobile.tabRecommendations": "Recomendations",
  "scheduler.mobile.tabCompetitors": "Competitors",
  "scheduler.mobile.post.header": "YOUR POST",
  "scheduler.mobile.boost": "Boost",
  "scheduler.mobile.processing.post.text": "Preparing your post",
  "scheduler.mobile.failed.post.text":
    "“There was an error scheduling your video. Dismiss it or Retry”",
  "scheduler.mobile.dropdown.edit": "Edit",
  "scheduler.mobile.dropdown.reschedule": "Reschedule",
  "scheduler.mobile.scheduled": "Scheduled",
  "scheduler.mobile.button.dismiss": "Dismiss post",
  "scheduler.mobile.button.retry": "Retry",
  "scheduler.mobile.boost.fail.description":
    "“The Boost linked to this post failed to upload. Please dismiss this notification and create a new boost”",
  "scheduler.mobile.button.dismiss.boost": "Dismiss Boost",
  "scheduler.cardDefault.title": "Connect your business social accounts",
  "scheduler.cardDefault.message": "So I can show you the best times to post.",
  "scheduler.cardDefault.cancelPosts": "Cancel",
  "scheduler.modals.isPublishing.title": "I'm scheduling your publications.",
  "scheduler.modals.isPublishing.subtitle":
    "This might take up to several minutes. {br} Please be patient.",
  "scheduler.modals.isRegerating.title": "I'm regenerating your content.",
  "scheduler.modals.isRegerating.subtitle":
    "This might take a couple of minutes. {br} Please be patient.",
  "scheduler.cardDefault.multiplePostStore": "Accept selected posts",
  "scheduler.cardDefault.selectAll": "Select all",
  "scheduler.cardDefault.regeneratePosts.day":
    "Generate new proposal for all day",
  "scheduler.cardDefault.regeneratePosts.week":
    "Generate new proposal for all week",
  "scheduler.cardDefault.regeneratePosts.month":
    "Generate new proposal for all month",
  "scheduler.competitors.message":
    "Tell me who your competitors or anybody you would like to watch for inspiration and I will show you a feed of their latest post.",
  "scheduler.postDetail.title": "Post details",
  "scheduler.postDetail.posted.text": "Posted in:",
  "scheduler.postDetail.scheduled.text": "Scheduled:",
  "scheduler.postDetail.rescheduled.button": "Reschedule",
  "scheduler.postDetail.engagement.text": "Engagement:",
  "scheduler.postDetail.boosting.text": "Boosting results:",
  "scheduler.postDetail.boosting.result.reach": "reach",
  "scheduler.postDetail.boosting.result.reactions": "reactions",
  "scheduler.postDetail.boosting.result.spent": "USD spent",
  "scheduler.postDetail.caption.label": "Caption",
  "scheduler.postDetail.caption.edit.button": "Edit caption",
  "scheduler.postDetail.caption.regenerate.button": "Generate again",
  "scheduler.postDetail.boost.button": "Boost",
  "scheduler.postDetail.deletepost.button": "Delete Post",
  "scheduler.postDetail.reviewed.button": "Ok. Reviewed",
  "scheduler.postDetail.declinereviewed.button": "Remove reviewed",
  "scheduler.postDetail.dismiss.button": "Dismiss post",
  "scheduler.postDetail.retry.button": "Retry  & publish now",
  "scheduler.postDetail.processing.text":
    "“There was an error preparing your video to {socialNetwork}. You can retry posting it or dismiss it.”",
  "scheduler.boost.card.duration.monthView":
    "Boosted post. {daysCount, plural, one {# day} other {# days}}.",
  "scheduler.postDetail.tiktok.delete.message":
    "If you want to delete this video, please do it from the TikTok application.",
  "scheduler.boostCard.processing.text": "Preparing your boost",
  "scheduler.boost.error.modal.title": "Your campaign could not be created.",
  "scheduler.boost.error.modal.description1":
    "Please, create your boost again from the Scheduler.",
  "scheduler.boost.error.modal.description2":
    "Please, review the post associated to this Boost.",
  "scheduler.post.detail.update.success": "Your post has been submitted.",
  "scheduler.post.detail.update.fail":
    "Your post could not be updated, please try again.",
  "scheduler.post.detail.deleted.success": "Post has been deleted.",
  "scheduler.post.detail.boost.deleted.success": "Boost has been deleted.",
  "scheduler.post.detail.boost.deleted.fail":
    "Your boost could not be updated, please try again.",
};

export default en;
