import * as Sentry from '@sentry/browser';
import moment from 'moment';
import { format } from 'date-fns';
import { authAppSync } from '../auth';
import {
  getBoostBudgetRecommendation,
  getBudgetReach,
  getBestTimeToPostBySM,
} from '../../graphql/queries';
import { ENV, ENVIRONMENTS } from '../../utils/constants/globals';
import { sleep } from '../../utils';
import budgetRecommendationMocked from '../../mocks/boost/budgetRecommendation.mock.json';
import budgetReachMocked from '../../mocks/boost/budgetReach.mock.json';
import audiencieSizeMock from '../../mocks/scheduler/SinglePiperRecommendation.mock';

export default class PiperRecommendationsProvider {
  static async fetchBudget(socialNetwork = 'facebook') {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: getBoostBudgetRecommendation,
          variables: {
            socialNetwork,
          },
        });
        data = response.data.budgetRecommendation;
      } else {
        data = budgetRecommendationMocked[socialNetwork];
      }

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchBudgetReach(budget, socialNetwork = 'facebook') {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: getBudgetReach,
          variables: {
            budget,
            socialNetwork,
          },
        });
        data = response.data.budgetReach;
      } else {
        data = budgetReachMocked;
      }

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchBestTimeToPostbySN(socialNetwork) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let response;
      const timezone = format(new Date(), 'XXX');
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: getBestTimeToPostBySM,
          variables: {
            socialNetwork,
            timezone,
          },
        });
      } else {
        response = {};
        response.data = audiencieSizeMock[socialNetwork];
        await sleep(2500);
      }
      const recommendations = response.data ? response.data.getLastWeekAudienceSize : [];
      const nextBestTimeToPost = this.findBestTimeToPost(recommendations);
      const realDate = new Date(nextBestTimeToPost);
      result = {
        message: 'success',
        success: true,
        data: realDate,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static findBestTimeToPost(bestTimeToPostR) {
    const utcNow = moment.utc().add(15, 'minutes');
    if (!bestTimeToPostR.length) {
      return moment().add(1, 'weeks');
    }

    for (let i = 0; i < bestTimeToPostR.length; i += 1) {
      const recommendationDT = moment(bestTimeToPostR[i].isoDate).add(1, 'weeks');
      if (recommendationDT.isAfter(utcNow)) {
        return recommendationDT;
      }
    }

    return moment(bestTimeToPostR[0].isoDate).add(2, 'weeks');
  }
}
