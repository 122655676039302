import gql from 'graphql-tag';

export const getTemplates = (attr = `
  name
`) => gql`
  query getTemplates($tagName: String, $pagination: Pagination, $orderBy: ORDER_BY, $language: LANGUAGE, $production: Boolean) {
    getTemplates(tagName: $tagName, pagination: $pagination, orderBy: $orderBy, language: $language, production: $production) {
      items {
        id
        name
        tags {
          ${attr}
          id
        }
        imageUrl
        imageUuid
        imageType
      }
      count
    }
  }
`;

export const getRecommTemplates = (attr = `
name
`) => gql`
query contentRecommendations($language: LANGUAGE) {
  contentRecommendations(language: $language) {
    id
    name
    tags {
      ${attr}
      id
    }
    imageUrl
    imageUuid
    source
    sourceUrl
    downloadLocation
    creator {
      name
      url
    }
  }
}
`;
