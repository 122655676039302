const es = {
  'verifyEmail.title': '¡Verifica tu correo electrónico!',
  'verifyEmail.verification.message': 'Te hemos enviado un correo de verificación a <e>{email}</e> Por favor, revisa tu correo electrónico y escribe el código aquí.',
  'verifyEmail.verification.resend.text': 'Si no recibiste este correo electrónico',
  'verifyEmail.verification.resend.text.disabled': 'Revisa tu correo electrónico ahora',
  'verifyEmail.verification.resend.button': 'Reenviar correo de verificación',
  'verifyEmail.support.text1': '¿No recibiste este correo? Revisa tu bandeja de correo no deseado. Si aún no lo recibes, <link>re-envia correo de verificación aqui.</link> {lineBreak}Recuerda que este correo puede tardar unos minutos en llegar.',
  'verifyEmail.support.text2': 'Si tu correo es incorrecto o está mal escrito, escríbenos a <link>{emailSupport}</link> o usa nuestro chat en vivo para ayudarte.',
  'verifyEmail.support.formLabel': 'CÓDIGO DE VERIFICACIÓN:',
};

export default es;
