import React from 'react';
import { FormattedMessage } from 'react-intl';
import { breakpointValues } from '../../../styled/GlobalTheme';
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
} from '../../../assets/icons/iconRepository';

const helpButton = (locale) => ({
  to: 'https://help.socialpiper.com/',

  gaData: { action: 'Help', label: '(footer)' },
  target: '_blank',
  title: (<FormattedMessage
    id="k.help"
    defaultMessage="Help"
  />),
});
const contactButton = (locale) => ({
  to: 'mailto:support@socialpiper.com',

  gaData: { action: 'Contact us', label: '(footer)' },
  target: '',
  title: (<FormattedMessage
    id="k.contactus"
    defaultMessage="Contact us"
  />),
});

export const getAboutUsUrl = (locale) => (
  `https://resources.socialpiper.com/${locale === 'en' ? 'about-us' : 'acerca-de-nosotros'}/`
);

const termsButton = {
  to: 'https://legal.socialpiper.com/social-piper-terms-of-service-2020-07-31/',
  gaData: { action: 'Terms of Service', label: '(footer)' },
  target: '_blank',
  title: (<FormattedMessage
    id="k.terms"
    defaultMessage="Terms of Service"
  />),
  lineHeight: 'normal',
};

const aboutUsButton = (locale) => ({
  to: getAboutUsUrl(locale),
  gaData: { action: 'About us', label: '(footer)' },
  target: '_blank',
  title: (<FormattedMessage
    id="k.about"
    defaultMessage="About Us"
  />),
});

const caPrivacyButton = {
  to: 'https://legal.socialpiper.com/social-piper-ca-privacy-policy-2020-08-03/',
  gaData: { action: 'CA Privacy Rights', label: '(footer)' },
  target: '_blank',
  title: (<FormattedMessage
    id="k.caPrivacyRights"
    defaultMessage="CA Privacy"
  />),
  lineHeight: 'normal',
};

const privacyButton = {
  to: 'https://legal.socialpiper.com/social-piper-privacy-policy-2020-08-03/',
  gaData: { action: 'Privacy Policy', label: '(footer)' },
  target: '_blank',
  title: (<FormattedMessage
    id="k.privacyPolicy"
    defaultMessage="Privacy Policy"
  />),
  lineHeight: 'normal',
};

const doNotSellInfoButton = {
  to: 'https://legal.socialpiper.com/social-piper-do-not-sell-my-personal-information-2020-08-03/',
  gaData: { action: 'Dont Sell Personal', label: '(footer)' },
  target: '_blank',
  lineHeight: 'normal',
  title: (<FormattedMessage
    id="k.dontSellPersonal"
    defaultMessage="Do Not Sell My Personal Information"
  />),
};

const thisIsSocialPiper = (locale) => ({
  to: `${getAboutUsUrl(locale)}`,
  gaData: { action: 'About the team', label: '(footer)' },
  target: '_blank',
  title: (<FormattedMessage
    id="k.thisIsSocialPiper"
    defaultMessage="About the team"
  />),
});

const whoWeAre = (locale) => ({
  to: `${getAboutUsUrl(locale)}`,
  gaData: { action: 'This is Social Piper', label: '(footer)' },
  target: '_blank',
  title: (<FormattedMessage
    id="k.whoWeAre"
    defaultMessage="Who we are"
  />),
});

const work = (locale) => ({
  to: `${getAboutUsUrl(locale)}#${locale === 'en' ? 'work' : 'trabaja'}`,
  gaData: { action: 'Join our team', label: '(footer)' },
  target: '_blank',
  title: (<FormattedMessage
    id="k.workInSocialPiper"
    defaultMessage="Join our team"
  />),
});

const workUs = (locale) => ({
  to: `${getAboutUsUrl(locale)}#${locale === 'en' ? 'work' : 'trabaja'}`,
  gaData: { action: 'Work', label: '(footer)' },
  target: '_blank',
  title: (<FormattedMessage
    id="k.workWithUs"
    defaultMessage="Work with us!"
  />),
});

const facebookButton = {
  to: 'https://www.facebook.com/socialpiper/',
  gaData: { action: 'Social Networks', label: '(footer)(facebook)' },
  target: '_blank',
  icon: FacebookIcon,
  rel: 'noreferrer',
  alt: 'Facebook',
};

const instagramButton = {
  to: 'https://www.instagram.com/SocialPiperInc',
  gaData: { action: 'Social Networks', label: '(footer)(instagram)' },
  target: '_blank',
  icon: InstagramIcon,
  rel: 'noreferrer',
  alt: 'Instagram',
};

const twitterButton = {
  to: 'https://www.twitter.com/SocialPiperInc',
  gaData: { action: 'Social Networks', label: '(footer)(twitter)' },
  target: '_blank',
  icon: TwitterIcon,
  rel: 'noreferrer',
  alt: 'Twitter',
};

const linkedinButton = {
  to: 'https://www.linkedin.com/company/socialpiper/',
  gaData: { action: 'Social Networks', label: '(footer)(linkedin)' },
  target: '_blank',
  icon: LinkedinIcon,
  rel: 'noreferrer',
  alt: 'Linkedin',
};

export const company = [
  termsButton,
  privacyButton,
  caPrivacyButton,
  doNotSellInfoButton,
];

export const about = [
  whoWeAre,
  workUs,
];

export const workWithUs = [
  contactButton,
  thisIsSocialPiper,
  work,
  helpButton,
];

const socialNetworks = [
  facebookButton,
  instagramButton,
  twitterButton,
  linkedinButton,
];

const copyrightWithAuth = {
  title: (<FormattedMessage
    id="k.copyright"
    defaultMessage="© Copyright {year} {lineBreak}All rights reserved"
    values={{
      year: new Date().getFullYear(),
      lineBreak: (window.innerWidth <= breakpointValues.md) ? <br /> : '',
    }}
  />),
};

export const desktopLoggedFooterButtons = [
  copyrightWithAuth,
  aboutUsButton,
  termsButton,
  privacyButton,
  caPrivacyButton,
  doNotSellInfoButton,
];

export const mobileLoggedFooterButtons = [
  termsButton,
  privacyButton,
  caPrivacyButton,
  doNotSellInfoButton,
];

export const mobileAppFooterButtons = [
  termsButton,
  privacyButton,
  caPrivacyButton,
  doNotSellInfoButton,
];

export const footerButtonsWithoutAuth = {
  company,
  workWithUs,
  socialNetworks,
};
