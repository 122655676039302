/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import InView from 'react-intersection-observer';
import Media from 'react-media';
import { FormattedMessage } from 'react-intl';
import GlobalTheme, { breakpointValues } from '../../../styled/GlobalTheme';
import Pipers from '../../../components/Pipers/Pipers';
import { heading3 } from '../../../styled/mixins';
import { colors } from '../../../styled/theme';

const ENTRANCE_DELAY = 0.2;

const MeetPiperStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 800px;
  margin: auto;
  padding: ${(props) => props.theme.space * 7}px ${(props) => props.theme.space * 2}px;
  box-sizing: border-box;
  ${GlobalTheme.breakpoints.down('sm')}{
    padding: 32px 16px;
  }
`;

const Title = styled.h2`
  ${heading3}
  font-size: 24px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 32px;
  ${GlobalTheme.breakpoints.down('md')}{
    font-size: 24px;
  }
`;

const RecommendationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RecommendationsRow = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => (justifyContent || 'center')};
  align-items: flex-start;
  max-width: 100%;
`;

const Recommendation = styled.div`
  opacity: 0;
  background: ${(props) => props.color || colors.primary};
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  color: white;
  font-family: ${({ theme }) => theme.font.font2};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  ${GlobalTheme.breakpoints.down('sm')} {
    font-size: 14px;
    line-height: 16px;
    padding: 12px;
  }
  margin: ${(props) => props.margin || '0'};
  position: relative;
  flex: ${({ flex }) => flex};
  align-self: ${({ alignSelf }) => alignSelf};
  &:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    ${GlobalTheme.breakpoints.down('sm')} {
      width: 16px;
      height: 16px;
    }
    background: ${(props) => props.color || colors.primary};
  }
  ${(props) => {
    switch (props.position) {
      case 'top':
        return css`
          margin-bottom: 32px;
          &:before{
            width: 30px;
            clip-path: polygon(0 0, 0% 100%, 100% 0);
            top: 100%;
            left: 0;
            right: 0;
            margin: auto;
          }
        `;
      case 'top-left':
        return css`
          margin-right: 8px;
          &:before{
            clip-path: polygon(100% 0, 0 0, 100% 100%);
            top: 100%;
            right: 40px;
          }
        `;
      case 'top-right':
        return css`
          margin-left: 8px;
          &:before{
            clip-path: polygon(100% 0, 0 0, 0 100%);
            top: 100%;
            left: 20px;
          }
        `;
      case 'left':
        return css`
          margin-right: 20px;
          &:before{
            clip-path: polygon(0 0, 0% 100%, 100% 100%);
            right: -19px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          ${GlobalTheme.breakpoints.down('sm')} {
            margin-right: 16px;
            &:before{
              clip-path: polygon(100% 0, 0 0, 0 100%);
              right: -15px;
              top: 20px;
              bottom: auto;
              margin: 0;
            }
          }
        `;
      case 'right':
        return css`
          margin-left: 20px;
          &:before{
            clip-path: polygon(100% 0, 0% 100%, 100% 100%);
            ${GlobalTheme.breakpoints.down('sm')} {
              clip-path: polygon(100% 0, 0 0, 100% 100%);
            }
            right: 100%;
            bottom: 20px;
          }
        `;
      case 'bottom-left':
        return css`
          margin-top: 32px;
          margin-right: 20px;
          &:before{
            clip-path: polygon(100% 0, 0% 100%, 100% 100%);
            right: 20px;
            bottom: 100%;
          }
        `;
      case 'bottom-right':
        return css`
          margin-top: 32px;
          margin-left: 20px;
          &:before{
            clip-path: polygon(0 0, 0% 100%, 100% 100%);
            left: 20px;
            bottom: 100%;
          }
        `;
      case 'bottom':
        return css`
          margin-top: 16px;
          &:before{
            clip-path: polygon(100% 100%, 100% 0, 0 100%);
            left: 40px;
            bottom: 100%;
          }
        `;
      default:
        break;
    }
  }}
  &.bounceIn {
    animation: bounceIn 1s;
    animation-delay: ${({ place }) => (place * ENTRANCE_DELAY || '0')}s;
    animation-fill-mode: forwards;
  }
`;

const RecommendationText = styled.p`
  margin: 0;
`;

const PiperHead = styled(Pipers.HeadBackground)`
  height: 120px;
  width: 120px;
  margin: 0 24px;
  ${GlobalTheme.breakpoints.down('sm')} {
    height: 70px;
    width: 70px;
    flex: 0 0 70px;
    margin: 0;
    align-self: center;
  }
`;

const MeetPiper = () => {
  const [hasAppeared, setHasAppeared] = useState(false);
  const handleIntersection = (event) => {
    setHasAppeared(hasAppeared || event);
  };
  return (
    <MeetPiperStyled>
      <Media queries={{
        mdDown: `(max-width: ${breakpointValues.md}px)`,
        smDown: `(max-width: ${breakpointValues.sm}px)`,
        }}
      >
        {(matches) => (
            <>
              <Title>
                <FormattedMessage
                  id="landing.meetPiper.title"
                  defaultMessage="Meet Piper, your new assistant that will offer you the best recommendations for <r>your business's</r> social media."
                  values={{
                    r: (...chunks) => (<b>{chunks}</b>),
                    lineBreak: <br />,
                    mobileLineBreak: matches.smDown ? <br /> : <></>,
                  }}
                />
              </Title>
              <InView threshold={matches.smDown ? 0.75 : 0.3} onChange={handleIntersection}>
                <RecommendationsContainer>
                  <RecommendationsRow
                    justifyContent={matches.smDown && 'flex-start'}
                  >
                    {matches.smDown && (
                      <Recommendation
                        color={colors.speechBubble}
                        position="top-left"
                        className={(hasAppeared) ? 'bounceIn' : ''}
                        place="0"
                        flex="0 0 50%"
                      >
                        <RecommendationText>
                          <FormattedMessage
                            id="landing.meetPiper.boost"
                            defaultMessage="“If you INVEST $23,{linebreak}you'll be able to reach{linebreak}almost 9,450 potential{linebreak}customers”"
                            values={{
                              linebreak: matches.smDown ? ' ' : <br />,
                            }}
                          />
                        </RecommendationText>
                      </Recommendation>
                    )}
                    <Recommendation
                      color={colors.lightPurple}
                      position={matches.smDown ? 'top-right' : 'top'}
                      className={(hasAppeared) ? 'bounceIn' : ''}
                      place="2"
                      flex={matches.smDown && '0 0 35%'}
                    >
                      <RecommendationText>
                        <FormattedMessage
                          id="landing.meetPiper.templates"
                          defaultMessage="“These are the best TEMPLATES I{linebreak}selected today for your business”"
                          values={{
                            linebreak: matches.smDown ? ' ' : <br />,
                          }}
                        />
                      </RecommendationText>
                    </Recommendation>
                  </RecommendationsRow>
                  <RecommendationsRow>
                    {matches.smDown ? (
                      <Recommendation
                        color={colors.brand5}
                        position="left"
                        className={(hasAppeared) ? 'bounceIn' : ''}
                        place="3"
                        flex="0 0 30%"
                      >
                        <RecommendationText>
                          <FormattedMessage
                            id="landing.meetPiper.schedule"
                            defaultMessage="“SCHEDULE your post next{linebreak}Monday at 8:00PM to{linebreak}reach more customers”"
                            values={{
                              linebreak: matches.smDown ? ' ' : <br />,
                            }}
                          />
                        </RecommendationText>
                      </Recommendation>
                    ) : (
                      <Recommendation
                        color={colors.speechBubble}
                        position="left"
                        className={(hasAppeared) ? 'bounceIn' : ''}
                        place="0"
                      >
                        <RecommendationText>
                          <FormattedMessage
                            id="landing.meetPiper.boost"
                            defaultMessage="“If you INVEST $23,{linebreak}you'll be able to reach{linebreak}almost 9,450 potential{linebreak}customers”"
                            values={{
                              linebreak: matches.smDown ? ' ' : <br />,
                            }}
                          />
                        </RecommendationText>
                      </Recommendation>
                    )}
                    <PiperHead />
                    <Recommendation
                      color={colors.orange}
                      position="right"
                      className={(hasAppeared) ? 'bounceIn' : ''}
                      place="4"
                      alignSelf={matches.smDown && 'flex-end'}
                      flex={matches.smDown && '0 0 35%'}
                    >
                      <RecommendationText>
                        <FormattedMessage
                          id="landing.meetPiper.audience"
                          defaultMessage="“At Tuesday 7:00PM is{linebreak}when your AUDIENCE is{linebreak}most active”"
                          values={{
                            linebreak: matches.smDown ? ' ' : <br />,
                          }}
                        />
                      </RecommendationText>
                    </Recommendation>
                  </RecommendationsRow>
                  <RecommendationsRow>
                    {!matches.smDown && (
                      <Recommendation
                        color={colors.brand5}
                        position="bottom-left"
                        className={(hasAppeared) ? 'bounceIn' : ''}
                        place="3"
                      >
                        <RecommendationText>
                          <FormattedMessage
                            id="landing.meetPiper.schedule"
                            defaultMessage="“SCHEDULE your post next{linebreak}Monday at 8:00PM to{linebreak}reach more customers”"
                            values={{
                              linebreak: matches.smDown ? ' ' : <br />,
                            }}
                          />
                        </RecommendationText>
                      </Recommendation>
                    )}
                    <Recommendation
                      color={colors.green}
                      position={matches.smDown ? 'bottom' : 'bottom-right'}
                      className={(hasAppeared) ? 'bounceIn' : ''}
                      place="1"
                      flex={matches.smDown && '0 0 50%'}
                    >
                      <RecommendationText>
                        <FormattedMessage
                          id="landing.meetPiper.tutorial"
                          defaultMessage="“See our new TUTORIAL{linebreak}about how to manage{linebreak}effective comments”"
                          values={{
                            linebreak: matches.smDown ? ' ' : <br />,
                          }}
                        />
                      </RecommendationText>
                    </Recommendation>
                  </RecommendationsRow>
                </RecommendationsContainer>
              </InView>
            </>
          )}
        </Media>
    </MeetPiperStyled>
  );
};
export default MeetPiper;
