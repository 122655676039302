import React from 'react';
import styled from 'styled-components';
import {
  testimonialAvatars,
} from '../../../assets/images/imageRepository';
import Testimonial from './Testimonial';
import { colors } from '../../../styled/theme';
import TinySliderCarousel from '../../../components/globals/TinySliderCarousel';
import mediaQueries from '../../../utils/mediaQueries';

const testimonials = [
  {
    purposeId: 'landing.testimonial.purpose.1',
    purpose: 'for Law firms',
    quoteId: 'landing.testimonial.quote.1',
    quote: 'SocialPiper makes marketing feel much more approachable and results achievable. For me, it has been a great first stop in building out my online presence.',
    authorId: 'landing.testimonial.author.1',
    author: 'Heather Batzel',
    company: 'Batzel Law',
    color: colors.green,
    textColor: colors.white,
    avatar: testimonialAvatars.avatar1,
  },
  {
    purposeId: 'landing.testimonial.purpose.2',
    purpose: 'for Food business',
    quoteId: 'landing.testimonial.quote.2',
    quote: 'SocialPiper provided a space where we could gather data, create and schedule posts, compare results, create and fund campaigns and create a social media strategy based on measurable data.',
    authorId: 'landing.testimonial.author.2',
    author: 'Regina Trillo',
    company: 'Neminative',
    color: colors.orange,
    textColor: colors.white,
    avatar: testimonialAvatars.avatar2,
  },
  {
    purposeId: 'landing.testimonial.purpose.3',
    purpose: 'for Insurance Companies',
    quoteId: 'landing.testimonial.quote.3',
    quote: 'Social Piper has made my life easier, to be creative and constant in social networks. In short, it is order, creativity and self-management for your social networks.',
    authorId: 'landing.testimonial.author.3',
    author: 'Marco A. González',
    company: 'FR Pro & Asociados',
    color: '#00B4CC',
    textColor: colors.white,
    avatar: testimonialAvatars.avatar3,
  },
  {
    purposeId: 'landing.testimonial.purpose.4',
    purpose: 'for Real State',
    quoteId: 'landing.testimonial.quote.4',
    quote: 'Social Piper simplified my life! Marketing through its platform is very friendly. It has been an excellent experience and I recommend it with eyes closed!',
    authorId: 'landing.testimonial.author.4',
    author: 'Denise Agnic Iribarren',
    company: 'REZO Propiedades',
    color: colors.brand5,
    textColor: colors.white,
    avatar: testimonialAvatars.avatar4,
  },
  {
    purposeId: 'landing.testimonial.purpose.5',
    purpose: 'for Healthy Snack business',
    quoteId: 'landing.testimonial.quote.5',
    quote: 'As a solo business owner, it is nearly impossible to juggle all of the daily tasks of running a food brand. SocialPiper has allowed me to easily and conveniently outsource all of my social media management.',
    authorId: 'landing.testimonial.author.5',
    author: 'Liza Cohen',
    company: "Nature's Nosh",
    color: colors.lightPurple,
    textColor: colors.white,
    avatar: testimonialAvatars.avatar5,
  },
];

const TestimonialsWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 40px 34px 30px 39px;
  @media ${mediaQueries.lgPlusUp} {
    padding: 95px 208px 50px 217px;
  }
`;

export default function Testimonials() {
  return (
    <TestimonialsWrapper>
      <TinySliderCarousel>
        {testimonials.map((testimonial, index) => (
          <Testimonial
            index={index}
            testimonial={testimonial}
          />
        ))}
      </TinySliderCarousel>
    </TestimonialsWrapper>
  );
}
