import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const publishVideoTiktok = gql`
  mutation createTiktokPostVideo ($id: Int, $message: String, $publishNow: Boolean, $scheduleTime: AWSDateTime, $nameFile: String, $dimension: Dimesion, $previewImg: String, $namePreviewImg: String) {
    createTiktokPostVideo( data: {
      id: $id,
      message: $message
      publishNow: $publishNow
      scheduleTime: $scheduleTime
      nameFile: $nameFile
      dimension: $dimension
      previewImg: $previewImg
      namePreviewImg: $namePreviewImg
    }){
      isValid
      socialMediaContentId
      containerId
    }
  }
`;

export const editTiktokPost = gql`
  mutation editTiktokPost($contentId: ID!, $message: String, $scheduleTime: AWSDateTime, $postNow: Boolean) {
    editTiktokPost(contentId: $contentId, message: $message, scheduleTime: $scheduleTime, postNow: $postNow)
  }
`;
