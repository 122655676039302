import { useEffect } from 'react';

/**
 * Hook that calls the callback function when a click is outside of the passed ref
 */
const useOutsideClick = (callback, ref) => {
  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref]);
};

export default useOutsideClick;
