export const AUTH0ACCOUNTPREFIXES = {
  EMAIL: 'email',
  FB: 'facebook',
  APPLE: 'apple',
};

export const getTypeOfAcc = (authId = '') => {
  let userAuth0Prefix;
  if (authId.match(/^[\d\w.]*$/)) {
    userAuth0Prefix = AUTH0ACCOUNTPREFIXES.APPLE;
  }
  if (authId.match(/^[\d\w]*$/)) {
    userAuth0Prefix = AUTH0ACCOUNTPREFIXES.EMAIL;
  }
  if (authId.match(/^[\d]*$/)) {
    userAuth0Prefix = AUTH0ACCOUNTPREFIXES.FB;
  }
  return `${userAuth0Prefix}`;
};