/* eslint-disable no-template-curly-in-string */
const es = {
  'k.seo.title': '{titlePage}: Planifica y gestiona las redes sociales de tu negocio.',
  'k.seo.keywords': 'plantilla de contenidos para redes sociales, software gestion redes sociales, calendario para publicar en redes sociales, templates de diseño, plan de redes sociales, manejo de redes sociales, plantillas de diseño, manejar redes sociales, plantillas para diseño, planificador de redes sociales, gestion de redes sociales, plantilla de calendario de contenido para las redes sociales, manejar redes sociales, como hacer crecer un negocio en instagram, social media empresa, marketing digital para pequenos negocios, que es social network, que es social media management, marketing digital para pequenos negócios, crear facebook empresa, que es social media en español, como hacer crecer tu negocio en instagram, que es social, crear cuenta facebook empresa, como crear una página de empresa en facebook, como crecer en instagram, empresas de social media en méxico, social media en espanol, como crear una cuenta publicitaria en facebook, crear página de empresa de facebook, como crecer tu negocio en instagram, cuenta publicitaria facebook, como crear una cuenta de empresa en facebook, como crecer instagram, tecnicas de cierre de ventas, como abrir una página de empresa en facebook, crear facebook de empresa',
  'k.seo.description': '¡Crea y planifica publicaciones atractivas, gestiónalas y crea campañas publicitarias! La solución todo en uno para tu redes sociales.',
  'k._days': 'días',
  'k._value': '{value}',
  'k.about': 'Acerca de nosotros',
  'k.about.link': 'ACERCA DE',
  'k.about.social': 'Acerca de Social Piper',
  'k.academy': 'Academy',
  'k.account': 'Cuenta',
  'k.adAccount': 'Cuenta publicitaria',
  'k.adCampaign': 'Campaña publicitaria',
  'k.add': 'Agregar',
  'k.addPaymentMethod': 'Añade método de pago',
  'k.all': 'Todos',
  'k.allrights': 'Todos los derechos reservados',
  'k.amount': 'Cantidad',
  'k.aprox': 'Aprox.',
  'k.audience': 'Público',
  'k.back': 'Atrás',
  'k.basicPlan': 'Lite Plan',
  'k.upgrade': '¡Mejora tu Plan!',
  'k.boost': 'Anuncios',
  'k.boosts': 'Anuncios',
  'k.boostBudget': 'Mi presupuesto',
  'k.boostIt': 'Promocionar Publicación',
  'k.boostItAgain': 'Promocionar otra vez',
  'k.boosting': 'Publicitar',
  'k.boostManager': 'Publicidad',
  'k.businessPlan': 'Business Plan',
  'k.creativityCenter': 'Centro Creativo',
  'k.cancel': 'Cancelar',
  'k.canceled': 'Cancelado',
  'k.cancelEdit': 'Cancelar Edición',
  'k.caPrivacyRights': 'CA Privacy',
  'k.card': 'Tarjeta',
  'k.change': 'Cambiar',
  'k.channel': 'Canal',
  'k.chooseAdAccount': 'Selecciona Cuenta Publicitaria',
  'k.close': 'Cerrar',
  'k.closereturn': 'Cerrar y regresar a Social Piper',
  'k.city': 'Ciudad',
  'k.comingSoon': 'Muy pronto',
  'k.comments': 'Comentarios',
  'k.community': 'Tu comunidad',
  'k.communityManagement': 'Tu comunidad',
  'k.communityManagement.auto': 'Gestión automática de mi comunidad',
  'k.company': 'Compañía',
  'k.competitors': 'Competidores',
  'k.myCompetitors': 'Mis Competidores',
  'k.confirm': 'Confirmar',
  'k.confirmBost': 'Confirmar campaña',
  'k.connect': 'Conectar',
  'k.connectSN': 'Conecta tus redes sociales',
  'k.continue': 'Continuar',
  'k.copyright': '® {year} Derechos reservados',
  'k.copyrightApp': '® {year} Derechos reservados',
  'k.address': '228 Hamilton Avenue, {lineBreak}3rd floor. Palo Alto, California, 94301, United States of America.',
  'k.address.noAuth': '228 Hamilton Avenue, 3rd floor. Palo Alto, CA, 94301',
  'k.country': 'País',
  'k.country_group': 'Región',
  'k.createPost': 'Publicar',
  'k.createAdAccount': 'Crear cuenta publicitaria',
  'k.defineAud': 'Define tu audiencia',
  'k.cta.freeTrial': 'Comienza tu prueba',
  'k.currency': 'Moneda',
  'k.custom': 'Personalizar',
  'k.customers': 'Clientes',
  'k.dashboard': 'Dashboard',
  'k.date': 'Fecha',
  'k.days': '{daysCount, plural, one {# day} other {# días}}',
  'k.daysLeft': '{daysCount, plural, one {Queda # día} other {Quedan # días}}',
  'k.trialEnd': 'Tu período de prueba ha terminado.',
  'k.default': 'Default',
  'k.defaultCard': 'Tarjeta predeterminada',
  'k.delete': 'Eliminar',
  'k.end': 'Terminar',
  'k.description': 'Descripción',
  'k.dropfile': 'Haz clic aquí para cargar un documento{br} PDF o arrástralo desde tu dispositivo.',
  'k.dropfile.label': '“Adicionalmente puedes subir un documento PDF con la información que desees que tome en cuenta para crear el contenido.”',
  'k.dropfile.success': 'Cargado con éxito',
  'k.dropfile.delete': 'Eliminar',
  'k.dropfile.bad.filetype': 'Intentaste subir un archivo incompatible. Por favor sube un archivo PDF.',
  'k.errors.pdfConflict': 'El PDF supera el tamaño máximo permitido o contiene demasiado texto.',
  'k.details.hide': 'Ocultar detalles',
  'k.details.show': 'Mostrar detalles',
  'k.moreFeatures': 'Ver más funciones',
  'k.seeMore': 'Ver más',
  'k.moreFeaturesIOS': 'ver todas las características del plan',
  'k.disableAccount': 'Borrar cuenta',
  'k.disconnect': 'Desconectar',
  'k.reconnect': 'Reconectar',
  'k.dontSellPersonal': 'Don\'t sell my info',
  'k.duration': 'Duración',
  'k.durationPlaceHolder': '__ días',
  'k.edit': 'Editar',
  'k.editDetails': 'Editar',
  'k.email': 'Correo Electrónico',
  'k.ends': 'Termina',
  'k.free': 'Gratis',
  'k.freeMonths': '{months, plural, one {# MES GRATIS} other {# MESES GRATIS}}',
  'k.freeTrial': 'Prueba {days} días gratis',
  'k.help': 'Ayuda',
  'k.contactus': 'Contáctanos',
  'k.here': 'aquí',
  'k.inbox': 'Mensajes directos',
  'k.interactions': 'Interacciones',
  'k.investInSocialPiper': 'Invierte en Social Piper',
  'k.invest': 'Invertir',
  'k.learnMore': 'Conoce más',
  'k.load.more': 'Ver más',
  'k.loading': 'Cargando',
  'k.login': 'Iniciar sesión',
  'k.logout': 'Cerrar sesión',
  'k.intercom.link.text': 'Soporte en vivo',
  'k.mainCta': 'Iniciar Prueba',
  'k.mainCtaPromoDefault': 'Iniciar Prueba',
  'k.mainCtaPromo': 'Empieza tu prueba de {trialDays} días',
  'k.mainCta.mobile': 'Empieza tu prueba',
  'k.mainCtaPromo.mobile': 'Empieza tu prueba',
  'k.mainCtaPromoDefault.mobile': 'Empieza a usar Social Piper',
  'k.mainDescription':
    '¡Bienvenido a Social Piper! La primera herramienta de gestión integral de social media para pequeños negocios.',
  'k.mainTitle':
    'Social Piper - Simplificando el social media para tu negocio.',
  'k.mediaLibrary': 'Centro Creativo',
  'k.metrics': 'Mis métricas <text>Últimos 30 días</text>', //
  'k.day': 'Día',
  'k.week': 'Semana',
  'k.month': 'Mes',
  'k.monthly': 'Mensual',
  'k.monthlyPlan': 'Plan Mensual',
  'k.more': 'Más',
  'k.myScheduler': 'Mi Calendario',
  'k.neighborhood': 'Colonia',
  'k.nevermindCancel': 'No importa, cancelar',
  'k.noThanks': 'No, gracias',
  'k.notNow': 'Ahora no',
  'k.numberUnit.million': '{number} millón',
  'k.numberUnit.thousand': '{number} mil',
  'k.ok': 'OK',
  'k.or': 'o',
  'k.otherLinks': 'Otros Links',
  'k.other': 'Otro',
  'k.password': 'Contraseña',
  'k.password.length': 'Al menos 8 caracteres de longitud.',
  'k.password.lowerCase': 'Letras minúsculas (a-z)',
  'k.password.numbers': 'Números (0-9)',
  'k.password.specialChar': 'caracteres (!@#$%^&*)',
  'k.password.typesChar':
    'Contiene al menos 3 de los siguientes 4 tipos de caracteres',
  'k.password.upperCase': 'Letras mayúsculas (A-Z)',
  'k.passwordHelper.title': 'Tu contraseña debe tener:',
  'k.password.3condition': 'Letras minúsculas, mayúsculas y números.',
  'k.pause': 'Pausar',
  'k.personalInfo': 'Información personal',
  'k.piperScore': 'Piper Score',
  'k.post': 'Publicación',
  'k.noPosts': 'No se encontraron o generaron publicaciones.',
  'k.posts': 'Publicaciones',
  'k.postComments': 'Comentarios de la Publicación',
  'k.postIt': 'Publícalo ahora',
  'k.preview': 'Previsualización',
  'k.pricing': 'Precios',
  'k.price.anually': 'USD ${price} anual',
  'k.price.month': 'USD ${price} mensual',
  'k.price.billed.annually': 'Anual',
  'k.price.billed.month': 'Mensual',
  'k.privacyPolicy': 'Política de privacidad',
  'k.proPlan': 'Pro Plan',
  'k.reach': 'Alcance',
  'k.reaction': 'Reacción',
  'k.reactions': 'Reacciones',
  'k.recommended': 'Recomendado',
  'k.mostPopular': 'Más Popular',
  'k.popular': 'Popular',
  'k.andmore': 'y más',
  'k.plan.allFeatures': 'Comparar planes',
  'k.readLess': 'Leer menos',
  'k.readMore': 'Leer más',
  'k.region': 'Estado',
  'k.remaining': 'Restante',
  'k.remove': 'Quitar',
  'k.required': 'Obligatorio',
  'k.invalid.number': 'Número inválido',
  'k.reschedule': 'Reprogramar',
  'k.reset': 'Reiniciar',
  'k.resets': 'Reiniciar',
  'k.run': 'Reactivar',
  'k.save': 'Guardar',
  'k.saveChanges': 'Guardar cambios',
  'k.saveContinue': 'Guardar y continuar',
  'k.scheduler': 'Calendario',
  'k.searchTag': 'Buscar',
  'k.select': 'Seleccionar',
  'k.selectAll': 'Seleccionar todo',
  'k.seeDetails': 'Ver detalles',
  'k.send': 'Enviar',
  'k.send.email': 'Enviar correo electrónico',
  'k.settings': 'Configuración',
  'k.settings.modal.title': 'Cuéntale a Piper acerca de:',
  'k.settings.modal.subtitle': 'Parametros',
  'k.shares': 'Compartidos',
  'k.show': 'Mostrar',
  'k.show.plan': 'Ver detalles de los Planes',
  'k.hide.plan': 'Ocultar detalles de los Planes',
  'k.signup': 'Regístrate',
  'k.signup.btn': '¡Regístrate!',
  'k.signup.now': '¡Regístrate ahora!',
  'k.getStarted': '¡Regístrate ahora!',
  'k.spend': 'Invertir',
  'k.spent': 'Consumido',
  'k.started': 'Inicia',
  'k.status': 'Estado',
  'k.strategy': 'Estrategia',
  'k.subneighborhood': 'Colonia',
  'k.subscribeOnePlan': 'Subscríbete a un plan',
  'k.subscribe': 'Suscríbete',
  'k.terms': 'Términos del servicio',
  'k.today': 'Hoy',
  'k.tomorrow': 'Mañana',
  'k.total': 'Total',
  'k.UpcomingPosts': 'Mis próximas publicaciones',
  'k.userProfile': 'Perfil de usuario',
  'k.viewAll': 'Ver todos',
  'k.viewMore': 'Ver más',
  'k.weAreHiring': 'Estamos contratando',
  'k.whatIsThis': '¿Qué es esto?',
  'k.workWithUs': '¡Únete a nosotros!',
  'k.auto-communityManager.noia': 'No usar a Piper',
  'k.auto-communityManager.auto': 'Dejar que Piper responda por mi',
  'k.auto-communityManager.suggest': 'Que Piper me sugiera que contestar',
  'k.auto-communityManager.suggest.tooltip': 'Cada vez que tenga qué responder un comentario en Social Piper, Piper te sugerirá una gran respuesta la cual podrás enviar o modificar a tu gusto antes de enviarla.',
  'k.auto-communityManager.auto.tooltip': 'Cada comentario que recibas en tus redes sociales, este será respondido automáticamente por la Inteligencia Artificial de Piper, a excepción de aquellos que Piper determine que no tengan un sentimiento positivo y requieran de tu intervención. Toma en cuenta que un comentario que Piper responda no podrá ser editado, por lo que si deseas cambiarlo tendrás que borrarlo y responderlos manualmente.',
  'k.answer': 'Respuesta',
  'k.question': 'Pregunta',
  'k.investInUs': 'Invierte en nosotros',
  'k.whoWeAre': 'Quienes somos',
  'k.workInSocialPiper': 'Únete a nosotros',
  'k.annually': 'Anual',
  'k.annually.save': 'Ahorra 2 meses',
  'k.yearly': 'Anual',
  'k.yearlyPlan': 'Plan Anual',
  'k.yes.change': 'Sí, cambiar',
  'k.zip': 'Zip',
  'k.infoEmail': 'info@socialpiper.com',

  'k.textPostInstagram': '¡Es hora de publicar!',
  'k.taskInstagram': 'Programaste esta publicación en Instagram:',
  'k.taskTiktok': 'Programaste esta publicación en Tiktok:',
  'k.thisIsSocialPiper': 'Nuestro equipo',
  'k.shareInstagram': 'Compartir en Instagram',
  'k.textCopied': '¡Texto Copiado!',

  'k.shareInstagramStepOne': 'Para publicar, primero:',
  'k.shareInstagramStepOneCopy': 'Copia el texto al portapeles',
  'k.shareInstagramStepTwo':
    'Da click en Compartir en Instagram para compartirlo a tus historias.',
  'k.shareInstagramStepTwoIOS':
    'Descarga la Imagen y compartela en Instagram, pega el texto en la descripción de tu publicación.',
  'k.textInstagramNotInstalledFirst':
    '¡Oh! Parece que no tienes instalada la app de Instagram. Para poder publicar, ',
  'k.textInstagramNotInstalledSecond':
    ' Es importante que después regreses a esta ventana para compartir tu publicación.',
  'k.textInstagramNotInstalledLink': 'descarga la app aquí.',
  'k.login.message.userexist':
    'Ya existe una cuenta con tu correo asociado. Inicia sesión con ese correo.',
  'k.title.modal': 'Detalles del Plan Pro',
  'k.login.message.captcha.error':
    'La página se volverá a cargar para mostrar un nuevo verificador.',
  'k.notification.box.title': '¡Guau guau!',
  'k.notification.box.message':
    'Para aprovechar Social Piper al máximo, complete esta información sobre su negocio:',
  'k.notification.box.message.meeting':
    '¿Quieres aprender a usar Social Piper para tu negocio? {lineBreak}Agenda tu asesoría personalizada.',
  'k.notification.message.firts':
    'Defina a sus clientes: dónde están, interés, género y edad.',
  'k.notification.message.second':
    'Establezca su nombre, ubicación y logotipo de su empresa.',
  'k.notification.message.third':
    'Configure su correo electrónico para contactarlo',
  'k.notification.message.fourth': 'Agenda tu asesoría con un Piper Expert',
  'k.notification.box.button': 'Configurar',
  'k.notification.box.button.schedule.meeting': 'Agendar',
  'k.notification.box.button.setup': 'Configurar',
  'k.register': 'Registrar',
  'boostManager.currencyInfo':
    'La moneda que se muestra está en {currency}. <spanI>Puedes cambiarlo en </spanI><link>Configuración de Mi Cuenta</link>',
  'boostManager.currencyAlert': ' *Tipo de cambio estimado a día de hoy',
  'k.notification.tiktok.step.one': 'Primero: <scp>Copia el texto al portapapeles</scp> Porque lo necesitarás más adelante.',
  'k.notification.tiktok.step.two': 'Abre tu aplicación móvil de TikTok, ve a la sección de bandeja de entrada y selecciona la notificación que indica que tu video está listo. Sigue los pasos en pantalla para terminar tu publicación.',
  'k.notification.tiktok.open.button': 'Abrir TikTok',
  'k.metrics.title': 'Métricas',
  'k.days.0': 'Lunes',
  'k.days.1': 'Martes',
  'k.days.2': 'Miercoles',
  'k.days.3': 'Jueves',
  'k.days.4': 'Viernes',
  'k.days.5': 'Sabado',
  'k.days.6': 'Domingo',
  'k.dontshowagain': 'No volver a mostrar'
};

export default es;
