const es = {
  'dashboard.competitors.filters.all': 'Todos los competidores',
  'dashboard.competitors.filters.instagram': 'Competidores de Instagram',
  'dashboard.competitors.filters.facebook': 'Competidores de Facebook',
  'dashboard.competitors.filters.images': 'Publicaciones con imágenes',
  'dashboard.competitors.noData': 'Dime quienes son tus competidores o alguien a quien te gustaría observar para tener inspiración. Aquí te mostraré tus últimas publicaciones.',
  'dashboard.competitors.connect': 'Agrega competidores',
  'dashboard.competitors.connect.sm': 'Agregar más',
  'dashboard.boostBudget.monthlyBudget': 'PRESUPUESTO MENSUAL',
  'dashboard.hero.welcomeMessage': 'Bienvenido a tu dashboard. Llegaste a muchos clientes esta semana. Tus seguidores aumentaron un 5%. ¡Sigue con el buen trabajo!',
  'dashboard.freeTrial.timeLeft': 'Te quedan {daysLeft} días de tu periodo de prueba',
  'dashboard.hero.createBoost': 'Crea una campaña publicitaria',
  'dashboard.hero.respondComments': 'Responde a comentarios',
  'dashboard.hero.schedulePost': 'Programa una publicación',
  'dashboard.hero.description': 'El Piper Score se forma tomando en cuenta la frecuencia en que inicias sesión, creas y programas publicaciones, realizas campañas publicitarias, si contestas mensajes y comentarios, y si actualizas la información de tu negocio y público.',
  'dashboard.hero.piperScore.footer': 'de 100',
  'dashboard.hero.whatPiperScore': '<p>Cómo funciona tu <strong>Piper Score</strong></p>',
  'dashboard.boostBudget.noData': 'Las campañas publicitarias convierten tus publicaciones en anuncios. Pueden ayudarte a llegar a más personas, ganar más seguidores y reacciones.',
  'dashboard.boostBudget.noData.cta': 'Establece tu presupuesto',
  'dashboard.metrics.noData': 'Cuando empieces a publicar, te diré como va tu desempeño, incluyendo cuántos ven tus publicaciones, cuantas reacciones (Me gusta, compartir, etc) y cuántos comentarios tienes.',
  'dashboard.mostActivePost.noData': 'Cuando empieces a publicar, detectaré tu publicación más activa, asi podrás crear una campaña publicitaria y llegar a más personas.',
  'dashboard.respondToComments.noData': 'Conecta las redes sociales de tu empresa para ver todos los comentarios recientes.',
  'dashboard.respondToComments.connect': 'Conecta tus redes sociales',
  'dashboard.postCard.interactions.description': '{interactions} interacciones',
  'dashboard.respondToComments.caughtUp': '¡Excelente! No tienes comentarios pendientes para responder. Sigue publicando para crear nuevas reacciones.',
  'dashboard.mostActivePost.title': 'Publicación más activa',
  'dashboard.respondToMessages.title': 'Mensajes directos',
  'dashboard.respondToMessages.caughtUp': '¡Genial! No tiene mensajes directos pendientes para responder. Sigue publicando para crear nuevas reacciones y comentarios.',
  'dashboard.respondToComments.title': 'Responde tus comentarios',
  'dashboard.suggestedTemplates.connect': 'Ver más plantillas',
  'dashboard.imageReady.title': 'Imágenes listas para publicar',
  'dashboard.suggestedTemplates.title.long': 'Plantillas sugeridas para tí',
  'dashboard.competitors.upgrade.title': '¡Agrega tantos competidores como quieras!',
  'dashboard.upcomingPosts.noData': 'No tienes más publicaciones próximas. {lineBreak}{lineBreak}¡Anímate y crea contenido nuevo!',
  'dashboard.piperScoreModal.tip': 'El Piper Score es tu mejor guía para saber qué tan buena es tu actividad en las redes sociales.',
  'dashboard.piperScoreModal.pargraph1': 'El <strongBlue>Piper Score</strongBlue> tiene en cuenta la frecuencia con la que tu:',
  'dashboard.piperScoreModal.pargraph2': 'Si sigues nuestras recomendaciones de Inteligencia Artificial, impulsadas por Piper, tu Puntaje puede aumentar más rápido y con pasos sencillos.',
  'dashboard.piperScoreModal.list1': 'Inicias sesión en Social Piper',
  'dashboard.piperScoreModal.list2': 'Creas y programas publicaciones',
  'dashboard.piperScoreModal.list3': 'Respondes a mensajes directos y comentarios de tus publicaciones.',
  'dashboard.piperScoreModal.list4': 'La cantidad de interacciones que obtienen tus publicaciones',
  'dashboard.piperScoreModal.list5': 'Mantienes actualizada la información de tu negocio.',
  'dashboard.budget.tooltip.text': 'Has alcanzado tu presupuesto mensual. <s>{edit}</s>',
  'dashboard.snackbar': 'Aún no has verificado tu correo electrónico. Si no has recibido un correo de verificación, reenviálo <h>{here}</h>',
  'dashboard.snackbar.success': 'Se envió el correo electrónico de verificación. Consulta tu correo electrónico.',
  'dashboard.categoryBanner.text': '<TipTitle>¿Qué tipo de negocio tienes?</TipTitle><TipBody>Esto me ayudará a encontrar plantillas adecuadas para tu negocio.</TipBody><TipNote>Nota: Puedes poner más de una.</TipNote>',
  'dashboard.download.notification.app.message': 'Todas las poderosas herramientas para crear y gestionar las redes sociales de tu negocio desde la aplicación oficial.',
  'dashboard.download.notification.app.download.button': 'Descarga la app',
  'dashboard.quickpost.modal.title': 'Publicación rápida',
  'dashboard.quickpost.modal.label.media': 'Imagen / video',
  'dashboard.quickpost.modal.upload.file.text': 'Sube una imagen o video',
  'dashboard.quickpost.modal.logo.checkbox.label': 'Agregar mi logo',
  'dashboard.quickpost.modal.label.caption': 'Texto',
  'dashboard.quickpost.modal.label.schedule': 'Hora de publicación',
  'dashboard.quickpost.modal.schedule.text': 'Esta es mi recomendación para los mejores momentos para publicar en cada plataforma basado en la actividad de tu audiencia',
  'dashboard.quickpost.modal.button.publish': 'Publicar',
  'dashboard.quickpost.modal.button.piperCreateOne.image': 'Deja que Piper te cree una imagen',
  'dashboard.quickpost.modal.button.piperCreateOne.caption': 'Deja que Piper te escriba un texto genial',
  'dashboard.quickpost.modal.button.regenerate.image': 'Generar nuevamente',
  'dashboard.quickpost.modal.button.upload.image': 'Subir',
  'dashboard.quickpost.modal.button.regenerate.caption': 'Generar nuevamente',
  'dashboard.quickpost.modal.button.postnow': 'Publicar ahora',
  'dashboard.quickpost.modal.caption.title': '“Escribe de qué quieres hablar en tu publicación y yo crearé un genial contenido para ti.”',
  'dashboard.quickpost.modal.caption.button': 'Aceptar',
  'dashboard.quickpost.modal.media.title': '“Describe la imagen que quieras usar para tu publicación y la crearé para ti.”',
  'dashboard.quickpost.modal.media.placeholder': 'Quiero una imagen que...',
  'dashboard.quickpost.modal.media.button': 'Aceptar',
  'dashboard.quickpost.modal.caption.placeholder': 'Quiero hablar sobre...',
  'dashboard.autoPostGenerator.title': 'Genera automáticamente un calendario de contenido',
  'dashboard.autoPostGenerator.day': 'Para hoy',
  'dashboard.autoPostGenerator.week': 'Próximos 7 días',
  'dashboard.autoPostGenerator.month': 'Próximos 30 días',
  'dashboard.autoPostGenerator.warning': 'Puede tomar hasta 5 minutos',
  'dashboard.postAdvancedGenerator.title': 'Crea una publicación',
  'dashboard.postAdvancedGenerator.manual': 'Manualmente',
  'dashboard.postAdvancedGenerator.automatic': 'Automáticamente',
  'dashboard.scheduler.modal.title':'Es posible darle una temática particular a lo que estoy a punto de crear, por ejemplo que tenga un tema navideño.',
  'dashboard.scheduler.modal.placeholder':'Considera un tema alusivo a la navidad.',
  'dashboard.scheduler.modal.submit':'Aceptar',
  'dashboard.scheduler.modal.skip':'Omitir',
};

export default es;
