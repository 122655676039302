import React from 'react';
import PropTypes from 'prop-types';
import { hotjar } from 'react-hotjar';
import AuthService from '../services/auth/AuthService';
import {
  ENVIRONMENTS,
  ENV,
  HOTJAR_ID,
  HOTJAR_VERSION,
} from '../utils/constants/globals';

export const HJContexts = React.createContext();
const HJProvider = ({ children }) => {
  const initHotJar = () => {
    // TODO Hotjar will be disabled for temporary issues 
    //  If you want reactivate just uncomment the following lines
    // if (ENV === ENVIRONMENTS.production) {
    //  hotjar.initialize(HOTJAR_ID, HOTJAR_VERSION);
    // } else {
      // this function only emulated the triger function on test stages
      // this was enable to support hj on
      // eslint-disable-next-line no-console
      window.hj = (trigger, label) => console.log('TriggerLaunch', trigger, label);
    // } 
  };

  const identifyHJUser = () => {
    const userId = AuthService.getAuthID() || '';
    if (window.hj) {
      try {
        window.hj('identify', userId, {
          lastSingInUpdate: new Date(),
        });
      } catch {
        // only if fail, no tail necesary
      }
    }
  };

  const identifyClarityUser = () => {
    const userId = AuthService.getAuthID() || '';
    if (window.cla) {
      try {
        if (userId !== '') {
          window.clarity('set', 'authId', userId);
        }
      } catch {
        // only if fail, no tail necesary
      }
    }
  };

  return (
    <HJContexts.Provider
      value={{
        identifyHJUser,
        initHotJar,
        identifyClarityUser,
      }}
    >
      {children}
    </HJContexts.Provider>
  );
};

HJProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HJProvider;
