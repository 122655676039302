import gql from 'graphql-tag';

export const saveUserImage = gql`
  mutation saveUserImage($originalName: String!, $name: String!, $folder: String!, $imageType: String!, $imageUuid: String!, $tags: [ID], $contentId: ID) {
    saveUserImage(input: {
      originalName: $originalName
      name: $name
      folder: $folder
      imageType: $imageType
      imageUuid: $imageUuid
      tags: $tags
      contentId: $contentId
    }) {
      id
      name
      imageUrl
      imageType
    }
  }
`;

export const saveUserImages = gql`
mutation saveUserImages($inputs: [ImageInput!]) {
  saveUserImages(inputs:$inputs) {
    id
    name
    imageUrl
    imageType
  }
}
`;

export const createHistoricTemplate = gql`
  mutation createHistoricTemplate ($templateId: Int, $templateIds: [Int]) {
    createHistoricTemplate (templateId:$templateId, templateIds: $templateIds)
  }
`;

export const deleteUserImage = gql`
  mutation deleteContent ($imageId: Int!) {
    deleteContent(imageId: $imageId)
  }
`;

export const saveEditorState = gql`
  mutation saveEditorState ($imageId: Int!, $state: String!, $imagesEditor: [EditorImagesInput]) {
    saveEditorState(imageId: $imageId, state: $state, imagesEditor:$imagesEditor) {
      success
    }
  }
`;

export const saveEditorSticker = gql`
  mutation saveEditorSticker ($name: String, $folder: String) {
    saveEditorSticker(name: $name, folder: $folder) {
      success
    }
  }
`;

export const publishVideo = gql`
  mutation createPostVideo ($id: Int, $message: String, $publishNow: [Boolean], $scheduleTime: [AWSDateTime], $previewImg: String, $namePreviewImg: String, $socialNetwork: [String], $haveBoostFacebook: Boolean, $dataBoostFacebook: String, $haveBoostLinkedin: Boolean, $dataBoostLinkedin: String, $sessionId: String) {
    createPostVideo( data: {
      id: $id,
      message: $message
      publishNow: $publishNow
      scheduleTime: $scheduleTime
      previewImg: $previewImg
      namePreviewImg: $namePreviewImg
      socialNetwork: $socialNetwork
      haveBoostFacebook: $haveBoostFacebook
      dataBoostFacebook: $dataBoostFacebook
      haveBoostLinkedin: $haveBoostLinkedin
      dataBoostLinkedin: $dataBoostLinkedin
      sessionId: $sessionId
    }){
      isValid
    }
  }
`;

export const updateMediaContent = gql`
  mutation updateSocialMedia ($mediaId: Int, $isVideo: Boolean) {
    updateSocialMedia(mediaId: $mediaId, isVideo: $isVideo){
      success
    }
  }
`;

export const deleteSocialMedia = gql`
  mutation deleteSocialMedia ($mediaId: Int, $type: String) {
    deleteSocialMedia(mediaId: $mediaId, type: $type){
      success
    }
  }
`;
