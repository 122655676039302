import gql from 'graphql-tag';

export const reSendVerificationMail = gql`
  mutation reSendVerificationMail {
    reSendVerificationMail {
      success
      info
      errorMessage
    }
  }
`;

export const verifyToken = gql`
  mutation validateVerificationToken($token: String!) {
    validateVerificationToken(token: $token) {
        success
        info
        errorMessage
    }
  }
`;
