const es = {
  'socialLoginError.already-connected': 'Tus perfiles de redes sociales ya se encuentran asociados a otra cuenta de Social Piper',
  'socialLoginError.contactUs': 'Para más información contáctanos en <strong>support@socialpiper.com</strong>',
  'socialLoginError.error': 'Tu cuenta no se pudo conectar',
  'socialLoginError.facebook-text': 'Tu red social ya está conectada a una cuenta de Social Piper. Por favor revisa tu cuenta o escríbenos a support@socialpiper.com',
  'socialLoginError.instagram-text': 'Asegurate de que tu cuenta de Instagram es una cuenta de negocio y que está asociada a la misma cuenta de Facebook que conectaste a Social Piper',
  'socialLoginError.title': 'Hubo un error al intentar conectar tu cuenta',
  'socialLoginError.twitter-text': 'Hubo un error al conectar tu cuenta de Twitter',

  'socialLogin.error.page.title.one': '¡No conectaste la página de tu negocio!',
  'socialLogin.error.page.title.two': 'El perfil de {network} que quieres conectar ya está vinculado a {email}',
  'socialLogin.error.page.title.three': '¡Oh no! no se pudo conectar la red de {network} de tu negocio',
  'socialLogin.error.page.title.four': '¡No otorgaste todos los permisos necesarios!',
  'socialLogin.error.page.title.five': '¡Oh no! Cancelaste el proceso.',

  'socialLogin.error.page.subtitle': 'Este es tu primer paso y el más importante.',

  'socialLogin.error.page.description': 'Tu información está segura, somos una aplicación aprobada por {network} y cuidamos la privacidad de tus datos.',

  'socialLogin.error.page.boxHelp.title': '¿Qué puedo hacer ahora?',

  'socialLogin.facebook.error.page.boxHelp.item.one': 'Si no tienes una página de negocio <TextLink>crea gratis una ahora</TextLink> y vuelve a intentar.',
  'socialLogin.facebook.error.page.boxHelp.item.two': 'En caso de que ya cuentes con una pero no la encuentras, es posible que no tengas permisos para administrarla. Consulta con tu equipo, y pide que te den estos accesos. <TextLink>Revisa esta guía</TextLink> e intenta nuevamente.',

  'socialLogin.instagram.error.page.boxHelp.item.one': 'Si tu perfil no es de negocio, <TextLink>conviértelo en unos minutos siguiendo estos pasos.</TextLink>',
  'socialLogin.instagram.error.page.boxHelp.item.two': 'Recuerda que durante el proceso de conversión, también necesitarás vincular tu perfil de Instagram a una página de negocio en Facebook.',

  'socialLogin.already.error.page.boxHelp.item.one': 'Hemos dado aviso al usuario que deseas conectar esta cuenta. Comunícate con él e inicia sesión con la cuenta correspondiente.',

  'socialLogin.error.page.boxHelp.item.one': 'Esto puede ser debido a la calidad de conexión a internet o un problema temporal. Te pedimos que lo intentes de nuevo.',

  'socialLogin.permissions.error.page.boxHelp.item.one': 'Es indispensable que durante el proceso que acabas de hacer para conectar tu página de Facebook a Social Piper, todos los interruptores estén activos (en color azul).',

  'socialLogin.error.page.button.tryAgain': 'Intentar nuevamente',
  'socialLogin.error.page.button.tryLater': 'Lo intentaré después',

  'socialLogin.error.page.contacthelp.text.one': '<bold>¿Necesitas ayuda?</bold> Escríbenos en el chat de esta página ó contáctanos en support@socialpiper.com',
  'socialLogin.error.page.contacthelp.text.two': 'Si necesitas ayuda para desvincular el perfil a la cuenta actual, escríbenos al chat o al correo support@socialpiper.com',
  'socialLogin.error.page.contacthelp.text.three': '<bold>Si el problema persiste,</bold> escríbenos en el chat de esta página o contáctanos en support@socialpiper.com',
};

export default es;
