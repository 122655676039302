import gql from 'graphql-tag';

export const authRedirect = gql`
  mutation authRedirect {
    createTwitterAuthRedirect {
      url
    }
  }
`;

export const createTwitterPost = gql`
  mutation tweet($text: String!, $imageId: String, $scheduleTime: AWSDateTime, $imageIds: [String], $sessionId: String) {
    tweet( 
      data: {
        text: $text,
        imageId: $imageId,
        scheduleTime: $scheduleTime,
        imageIds: $imageIds
        sessionId: $sessionId
      }
    )
  }
`;

export const deleteTweet = gql`
  mutation deleteTweet ($contentId: Int!) {
    deleteTweet(contentId: $contentId)
  }
`;

export const rescheduleTweet = gql`
  mutation rescheduleTweet ($contentId: Int!, $scheduleTime: AWSDateTime, $postNow: Boolean) {
    rescheduleTweet(contentId: $contentId, scheduleTime: $scheduleTime, postNow: $postNow)
  }
`;

export const replyTweet = gql`
  mutation replyTweet ($input: CommentInput!) {
    replyTweet(input: $input) {
      id
      from
      commentText
      createdTime
      replyComments {
        id
        from
        commentText
        createdTime
        read
        userImage
      }
      read
      userImage
    }
  }
`;

export const deleteTweetReply = gql`
  mutation deleteTweetReply ($commentId: Int!) {
    deleteTweetReply(commentId: $commentId)
  }
`;

export const editTweet = gql`
  mutation editTweet($contentId: ID!, $message: String, $scheduleTime: AWSDateTime, $imageUrl: String) {
    editTweet(contentId: $contentId, message: $message, scheduleTime: $scheduleTime, imageUrl: $imageUrl)
  }
`;

export const likeTComment = gql`
  mutation likeTComment($commentId: ID!) {
    likeTComment(commentId: $commentId)
  }
`;
