import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AuthService from '../services/auth/AuthService';
import { DevicesProvider } from '../services/entities';
import {
  ENVIRONMENTS,
  ENV,
} from '../utils/constants/globals';
import { initAndroidEventDispacher } from '../utils/AndroidEventDispacher';

const WebViewContext = React.createContext();
const WebViewProvider = ({ children }) => {
  const location = useLocation();
  const [playerId, setPlayerId] = useState('');
  const [isApp, setIsApp] = useState(undefined);
  const [isAppIOS, setIsAppIOS] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  // firt load try to fetch the player ID

  const retrivePlayerIdLS = () => {
    const pId = localStorage.getItem('player_id');
    if (pId && pId !== '') {
      setPlayerId(pId);
    }
  };

  const fetchCustomsUserAgent = () => {
    const userAgentString = navigator.userAgent;
    if (userAgentString.includes('Convertify')) {
      setIsApp(true);
      if (userAgentString.includes('ConvertifyIOS')) {
        setIsAppIOS(true);
      } else {
        initAndroidEventDispacher();
      }
    } else {
      setIsApp(false);
    }
  };

  const getIsMobileDevice = () => {
    if (/iPad|Android|iPhone|iPod/.test(navigator.userAgent)) setIsMobileDevice(true);
  };

  const fetchAndRegisterDevice = async () => {
    if (playerId && AuthService.isAuthenticated()) {
      // retrive players is asociated from
      let registered = false;
      const deviceInfo = await DevicesProvider.getUserDevices();
      if (deviceInfo.success) {
        const { data: devices } = deviceInfo;
        devices.forEach((device) => {
          if (device === playerId) {
            registered = true;
          }
        });
      }
      // if player_id is not registered, asociate device
      if (!registered) {
        await DevicesProvider.registerDevice(playerId);
      }
    }
  };

  const deregisterDevice = async () => {
    if (playerId && AuthService.isAuthenticated()) {
      await DevicesProvider.deregisterDevice(playerId);
    }
  };

  useEffect(() => {
    // first look for the player ID on the query string
    if (location) {
      const { search } = location;
      if (search) {
        const urlParams = new URLSearchParams(search);
        if (urlParams && urlParams.has('player_id')) {
          if (urlParams.get('player_id') !== 'null') {
            setPlayerId(urlParams.get('player_id'));
            localStorage.setItem('player_id', urlParams.get('player_id'));
          }
        }
      }
    }
    // look in the local storage
    retrivePlayerIdLS();
    // review device type IsAPP and is APP on IOS
    fetchCustomsUserAgent();
    getIsMobileDevice();
  }, []);

  useEffect(() => {
    const updateRegister = async () => {
      await fetchAndRegisterDevice();
    };
    updateRegister();
  }, [playerId]);

  const validateSessionWeb = () => {
    if (ENV !== ENVIRONMENTS.local) {
      setTimeout(() => {
        const actualState = AuthService.isAuthenticated();
        AuthService.authCheckSession().then((response) => {
          if (!response.session) {
            if (actualState) {
              AuthService.unsetAuthData();
              window.location.reload();
            }
          }
        }).catch(() => {
          AuthService.unsetAuthData();
          window.location.reload();
        });
      }, 10000);
    }
  };

  return (
    <WebViewContext.Provider
      value={{
        playerId,
        setPlayerId,
        isApp,
        isAppIOS,
        fetchAndRegisterDevice,
        deregisterDevice,
        isMobileDevice,
      }}
    >
      {children}
    </WebViewContext.Provider>
  );
};

export { WebViewContext, WebViewProvider };
