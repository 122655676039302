/* eslint-disable no-restricted-syntax */
import * as Sentry from '@sentry/browser';
import {
  startOfWeek,
  endOfWeek,
  getDay,
  differenceInHours,
  formatISO,
  addWeeks,
  isBefore,
  isAfter,
  format,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { authAppSync } from '../auth';
import {
  getPosts,
  getBestTimeToPostBySM,
  getMonthlySummaryPosts,
  getScheduledPublications,
} from '../../graphql/queries';
import {
  FACEBOOK,
  ENV,
  ENVIRONMENTS,
  INSTAGRAM,
  TWITTER,
  LINKEDIN,
} from '../../utils/constants/globals';
import FacebookAdsProvider from './FacebookAdsProvider';
import CompetitorsProvider from './CompetitorsProvider';
import postRecommendationsMock from '../../mocks/scheduler/PostRecommedations.mock';
import MonthlySummary from '../../mocks/scheduler/MonthlySummaryPost.mock.json';
import EventsMock from '../../mocks/scheduler/Events.mock.json';
import UserProvider from './UserProvider';
import { LinkedinAdsProvider, PostProvider } from '.';
import { connectedAccountFormat, sleep } from '../../utils';
import { updateBoostingFailed } from '../../graphql/mutations';

export default class SchedulerProvider {
  static formatByWeek(elements, key) {
    const elementsByWeek = [
      [], [], [], [], [], [], [],
    ];
    for (const element of elements) {
      const weekDay = getDay(new Date(element[key]));
      const index = (weekDay || 7) - 1;
      elementsByWeek[index] = [
        ...elementsByWeek[index],
        element,
      ];
    }
    return elementsByWeek;
  }

  static cleanRecomm(recommWeek, posts) {
    const result = [];
    recommWeek.forEach((recommDay, index) => {
      const postByday = posts[index]??[];
      if (recommDay.length) {
        const day = [];
        for (let i = 0; i < recommDay.length; i += 1) {
          const recomm = recommDay[i];
          let hasPost = false;
          for (const post of postByday) {
            if (post.publishTime === recomm.publishTime
              && recomm.socialNetworks.indexOf(post.type) >= 0) {
              hasPost = true;
              break;
            }
          }
          if (!hasPost) {
            // Compare if its the same publishtime as previous recommendation
            if (day.length && recomm.publishTime === day[day.length - 1].publishTime) {
              day[day.length - 1].socialNetworks = [
                ...day[day.length - 1].socialNetworks, ...recomm.socialNetworks,
              ];
              day[day.length - 1].alpha = Math.max(day[day.length - 1].alpha, recomm.alpha);
            } else {
              day.push(recomm);
            }
          }
        }
        result.push(day);
      } else {
        result.push(recommDay);
      }
    });
    return result;
  }

  static async fetchPosts(week) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const date = new Date(week);
      const from = startOfWeek(date, { weekStartsOn: 1 });
      const to = endOfWeek(date, { weekStartsOn: 1 });
      let posts = [];
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: getPosts,
          variables: {
            from,
            to,
            sort: 'DATE_ASC',
          },
          fetchPolicy: 'no-cache',
        });
        posts = response.data.posts;
      } else {
        posts = EventsMock.data.posts;
      }
      posts = posts.map((p) => ({
        ...p,
        isPost: true,
      }));
      const postsByWeek = this.formatByWeek(posts, 'publishTime');
      result = {
        message: 'success',
        success: true,
        data: postsByWeek,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchPostsByDay(day) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const date = new Date(day);
      const from = startOfDay(date);
      const to = endOfDay(date);
      let posts = [];
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: getPosts,
          variables: {
            from,
            to,
            sort: 'DATE_ASC',
          },
          fetchPolicy: 'network-only',
        });
        posts = response.data.posts;
      } else {
        posts = EventsMock.data.posts;
        await sleep(2500);
      }
      posts = posts.map((p) => ({
        ...p,
        isPost: true,
      }));
      result = {
        message: 'success',
        success: true,
        data: posts,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static formatByDay(objects, areRecommendations = true, dateKey = 'publishTime') {
    const days = {};

    for (const obj of objects) {
      const key = formatISO(startOfDay(new Date(obj[dateKey])));
      if (days[key] !== undefined) {
        const day = days[key];
        if (areRecommendations && obj.publishTime === day[day.length - 1].publishTime) {
          day[day.length - 1].socialNetworks = [
            ...day[day.length - 1].socialNetworks, ...obj.socialNetworks,
          ];
          day[day.length - 1].alpha = Math.max(day[day.length - 1].alpha, obj.alpha);
        } else {
          day.push(obj);
        }
      } else {
        days[key] = [obj];
      }
    }

    return days;
  }

  static async fetchRecommendations(week, posts, audiences = null, isMobile = false) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let lastWeekAudiences = [];
      if (audiences?.length) {
        lastWeekAudiences = audiences;
      } else {
        if (ENV !== ENVIRONMENTS.local) {
          const timezone = format(new Date(), 'XXX');
          const response = await UserProvider.fetchConnectedAccounts();
          if (!response.success) return response;
          const connectedAccounts = response.data;
          const accounts = new Set(Object.keys(connectedAccounts));

          const [fbResponse, twResponse, igResponse, liResponse] = await Promise.all([
            accounts.has(FACEBOOK) && authAppSync.client.query({
              query: getBestTimeToPostBySM,
              variables: {
                socialNetwork: FACEBOOK,
                timezone,
              },
            }),
            accounts.has(TWITTER) && authAppSync.client.query({
              query: getBestTimeToPostBySM,
              variables: {
                socialNetwork: TWITTER,
                timezone,
              },
            }),
            accounts.has(INSTAGRAM) && authAppSync.client.query({
              query: getBestTimeToPostBySM,
              variables: {
                socialNetwork: INSTAGRAM,
                timezone,
              },
            }),
            accounts.has(LINKEDIN) && authAppSync.client.query({
              query: getBestTimeToPostBySM,
              variables: {
                socialNetwork: LINKEDIN,
                timezone,
              },
            }),
          ]);
          const fbAudiences = (fbResponse && fbResponse.data)
            ? fbResponse.data.getLastWeekAudienceSize : [];
          const twAudiences = (twResponse && twResponse.data)
            ? twResponse.data.getLastWeekAudienceSize : [];
          const igAudiences = (igResponse && igResponse.data)
            ? igResponse.data.getLastWeekAudienceSize : [];
          const liAudiences = (liResponse && liResponse.data)
            ? liResponse.data.getLastWeekAudienceSize : [];
          lastWeekAudiences = [
            ...fbAudiences.map((audience) => ({
              ...audience,
              socialNetworks: [FACEBOOK],
              isPost: false,
            })),
            ...twAudiences.map((audience) => ({
              ...audience,
              socialNetworks: [TWITTER],
              isPost: false,
            })),
            ...igAudiences.map((audience) => ({
              ...audience,
              socialNetworks: [INSTAGRAM],
              isPost: false,
            })),
            ...liAudiences.map((audience) => ({
              ...audience,
              socialNetworks: [LINKEDIN],
              isPost: false,
            })),
          ];
        } else {
          lastWeekAudiences = postRecommendationsMock;
        }
        const now = new Date();
        for (let i = 0; i < lastWeekAudiences.length; i += 1) {
          const iso = new Date(lastWeekAudiences[i].isoDate);
          const sumWeek = (nextWeek) => {
            if (isBefore(nextWeek, now)) {
              return sumWeek(addWeeks(nextWeek, 1));
            }
            return nextWeek;
          };
          const isoDate = formatISO(sumWeek(iso));
          const isoDateUtc = new Date(isoDate).toISOString();
          lastWeekAudiences[i].isoDate = isoDateUtc;
          lastWeekAudiences[i].publishTime = isoDateUtc;
        }
      }
      const recommendations = [];
      const today = new Date();
      const startDate = startOfWeek(week, { weekStartsOn: 1 });
      const minDate = today > startDate ? today : startDate;
      const end = endOfWeek(week, { weekStartsOn: 1 });
      const max = {
        facebook: 0,
        twitter: 0,
        instagram: 0,
        linkedin: 0,
      };
      for (const lw of lastWeekAudiences) {
        const iso = new Date(lw.isoDate);
        // eslint-disable-next-line no-continue
        if (!isMobile && (iso < minDate || iso > end)) continue;
        if (lw.count > max[lw.socialNetworks[0]]) {
          max[lw.socialNetworks[0]] = lw.count;
        }
        recommendations.push(lw);
      }
      for (const recomm of recommendations) {
        if (!max[recomm.socialNetworks[0]]) {
          recomm.alpha = Math.floor(Math.random() * 5) / 10 + 0.5;
        } else {
          recomm.alpha = (recomm.count / max[recomm.socialNetworks[0]]);
        }
      }
      if (!isMobile) {
        let recommendationsByWeek = this.formatByWeek(
          recommendations.sort((a, b) => (new Date(a.publishTime) - new Date(b.publishTime))),
          'isoDate',
        );
        recommendationsByWeek = this.cleanRecomm(recommendationsByWeek, posts);
        result = {
          message: 'success',
          success: true,
          data: {
            lastWeekAudiences,
            recommendationsByWeek,
          },
        };
      } else {
        // Mobile
        result = {
          message: 'success',
          success: true,
          data: this.formatByDay(
            recommendations.sort((a, b) => (new Date(a.publishTime) - new Date(b.publishTime))),
          ),
        };
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static formatAdCampaigns(campaigns, start, end) {
    const campaignsByWeek = [
      [], [], [], [], [], [], [],
    ];
    for (let i = 0; i < campaigns.length; i += 1) {
      const campaign = campaigns[i];
      const startDate = new Date(campaign.startDateTime);
      const endDate = new Date(campaign.endDateTime);
      const initialDay = startDate < start ? start : startDate;
      const endDay = endDate > end ? end : endDate;
      const weekDay = getDay(new Date(initialDay));
      const index = (weekDay || 7) - 1;
      const totalDuration = Math.ceil(differenceInHours(
        new Date(campaign.endDateTime),
        new Date(campaign.startDateTime),
      ) / 24);
      const weekDuration = Math.ceil(differenceInHours(
        new Date(endDay),
        new Date(initialDay),
      ) / 24);
      campaignsByWeek[index] = [
        ...campaignsByWeek[index],
        {
          ...campaign,
          totalDuration,
          weekDuration,
          rowNumber: i,
        },
      ];
    }

    return {
      count: campaigns.length,
      campaignsByWeek,
    };
  }

  static async fetchAdCampaigns(week) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const start = startOfWeek(week, { weekStartsOn: 1 });
      const end = endOfWeek(week, { weekStartsOn: 1 });
      const [responseFB, responseLI] = await Promise.all([
        FacebookAdsProvider.fetch(start, 'isoweek', true, false, false, false),
        LinkedinAdsProvider.fetch(start, 'isoweek', true, false, false, false),
      ]);
      const campaigns = [...responseFB.data, ...responseLI.data];
      const data = this.formatAdCampaigns(campaigns, start, end);
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchAdCampaignsByMonth(day) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const [responseFB, responseLI] = await Promise.all([
        FacebookAdsProvider.fetch(day, 'month', true),
        LinkedinAdsProvider.fetch(day, 'month', true),
      ]);
      const data = [...responseFB.data, ...responseLI.data];
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchCompetitorsPosts(week, competitorsPosts = null, isMobile = false) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const date = new Date(week);
      const start = startOfWeek(date, { weekStartsOn: 1 });
      const end = endOfWeek(date, { weekStartsOn: 1 });
      let competitors;
      if (competitorsPosts && competitorsPosts.length > 0) {
        competitors = competitorsPosts;
      } else {
        const response = await CompetitorsProvider.fetchRecentActivity();
        if (!response.success) return result;
        competitors = response.data;
      }

      if (!isMobile) {
        const competitorsByWeek = [
          [], [], [], [], [], [], [],
        ];

        for (const competitor of competitors) {
          const publicationDate = new Date(competitor.date);
          // eslint-disable-next-line no-continue
          if (publicationDate < start || publicationDate > end) continue;
          const weekDay = getDay(publicationDate);
          const index = (weekDay || 7) - 1;
          competitorsByWeek[index] = [
            ...competitorsByWeek[index],
            competitor,
          ];
        }
        result = {
          message: 'success',
          success: true,
          data: {
            competitors,
            competitorsByWeek,
          },
        };
      } else {
        result = {
          message: 'success',
          success: true,
          data: this.formatByDay(competitors, false, 'date'),
        };
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchEvents(week, lastWeekAudiences) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const postsResponse = await this.fetchPosts(week);
      let events = [
        [], [], [], [], [], [], [],
      ];
      let audiences = [];
      if (postsResponse.success) {
        events = [...postsResponse.data];
      }
      const recommResponse = await this.fetchRecommendations(week, events, lastWeekAudiences);
      if (recommResponse.success) {
        audiences = recommResponse.data.lastWeekAudiences;
        events = events.map((day, index) => {
          const eventDay = [
            ...day,
            ...recommResponse.data.recommendationsByWeek[index],
          ];
          return eventDay.sort((a, b) => (new Date(a.publishTime) - new Date(b.publishTime)));
        });
      }
      result = {
        message: 'success',
        success: true,
        data: {
          lastWeekAudiences: audiences,
          events,
        },
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchConnectedAccounts() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await UserProvider.fetchConnectedAccounts();
      if (!response.success) return response;
      const data = connectedAccountFormat(response.data);
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static findCampign(adCampaigns, capignsDeleted) {
    if (adCampaigns) {
      const result = [];
      capignsDeleted.forEach((element) => {
        adCampaigns.campaignsByWeek.forEach((day, index) => {
          const tempCapaingIndex = day.findIndex((camp) => parseInt(camp.id, 10) === element);
          if (tempCapaingIndex >= 0) {
            result.push({
              day: index,
              id: tempCapaingIndex,
            });
          }
        });
      });
      return result;
    }
    return null;
  }

  static async deleteEvent({
    type, id, events, day, index, adCampaigns,
  }) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await PostProvider.deletePost(type, id);
      if (!response.success) return response;
      const data = {
        events: JSON.parse(JSON.stringify(events)),
        adCampaigns: JSON.parse(JSON.stringify(adCampaigns)),
      };
      data.events[day].splice(index, 1);
      if (type === FACEBOOK) {
        const campaingsToRemove = this.findCampign(adCampaigns, response.data);
        campaingsToRemove.forEach((camp) => {
          data.adCampaigns.campaignsByWeek[camp.day].splice(camp.id, 1);
        });
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async reschedulePost({
    post, postingOption, scheduleDate, events, day, index, date,
  }) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await PostProvider.reschedulePost(
        post.type, post.id, postingOption, scheduleDate,
      );
      if (!response.success) return response;
      let data = JSON.parse(JSON.stringify(events));
      data[day].splice(index, 1);
      const start = startOfWeek(date, { weekStartsOn: 1 });
      const end = endOfWeek(date, { weekStartsOn: 1 });
      const scheduled = new Date(scheduleDate);
      if (
        isBefore(scheduled, end)
        && isAfter(scheduled, start)
      ) {
        const weekDay = getDay(scheduled);
        const newPost = {
          ...post,
          publishTime: formatISO(scheduleDate),
        };
        const indexDay = (weekDay || 7) - 1;
        data[indexDay] = [
          ...data[indexDay],
          newPost,
        ];
        data = data.map((d) => (
          d.sort((a, b) => (new Date(a.publishTime) - new Date(b.publishTime)))
        ));
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async editPost({
    post, message, events, day, index,
  }) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await PostProvider.editPost(
        post.type, post, message,
      );
      if (!response.success) return response;
      const data = JSON.parse(JSON.stringify(events));
      data[day][index] = {
        ...data[day][index],
        message,
      };
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async getMonthlySummaryPosts(day) {
    const date = new Date(day);
    const timezone = format(new Date(), 'XXX');
    let result = {
      message: '',
      success: false,
      data: [],
    };
    let data;
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: getMonthlySummaryPosts,
          variables: {
            date,
            timezone,
          },
        });
      } else {
        response = MonthlySummary;
      }
      data = response.data;

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }

    return result;
  }

  static async getScheduledPublications() {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    let data = [];
    try {
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: getScheduledPublications,
        });
        data = response.data.getScheduledPublications.data;
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async updateBoostingFailed(boostId, type) {
    const result = {
      message: '',
      success: false,
    };

    const variables = { boostId, type };
    try {
      const response = await authAppSync.client.mutate({
        mutation: updateBoostingFailed,
        variables,
      });
      result.success = response.data.updateBoosting.success;
    } catch (err) {
      result.message = err.message;
      result.success = false;
    }
    return result;
  }
}
