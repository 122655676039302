import gql from 'graphql-tag';

export const fetchCalendlyEvent = gql`
  query fetchCalendlyEvent{
    fetchCalendlyEvent {
        startTime
        joinUri
        evtName
        evtStatus
    }
  }
`;

export default {};
