const postRecommendationsMock = [
  {
    count: 450,
    isoDate: '2020-10-14T16:00:00.000Z',
    publishTime: '2020-10-14T16:00:00.000Z',
    socialNetworks: ['facebook'],
    __typename: 'LastWeekAudienceSize',
  },
  {
    count: 500,
    isoDate: '2020-10-15T16:00:00.000Z',
    publishTime: '2020-10-15T16:00:00.000Z',
    socialNetworks: ['facebook'],
    __typename: 'LastWeekAudienceSize',
  },
  {
    count: 290,
    isoDate: '2020-10-19T03:00:00.000Z',
    publishTime: '2020-10-19T03:00:00.000Z',
    socialNetworks: ['facebook'],
    __typename: 'LastWeekAudienceSize',
  },
  {
    count: 450,
    isoDate: '2020-10-14T17:00:00.000Z',
    publishTime: '2020-10-14T17:00:00.000Z',
    socialNetworks: ['twitter'],
    __typename: 'LastWeekAudienceSize',
  },
  {
    count: 500,
    isoDate: '2020-10-15T03:00:00.000Z',
    publishTime: '2020-10-15T03:00:00.000Z',
    socialNetworks: ['twitter'],
    __typename: 'LastWeekAudienceSize',
  },
  {
    count: 250,
    isoDate: '2020-10-19T03:00:00.000Z',
    publishTime: '2020-10-19T03:00:00.000Z',
    socialNetworks: ['twitter'],
    __typename: 'LastWeekAudienceSize',
  },
  {
    count: 100,
    isoDate: '2020-10-14T17:00:00.000Z',
    publishTime: '2020-10-14T17:00:00.000Z',
    socialNetworks: ['instagram'],
    __typename: 'LastWeekAudienceSize',
  },
  {
    count: 500,
    isoDate: '2020-10-15T23:00:00.000Z',
    publishTime: '2020-10-15T23:00:00.000Z',
    socialNetworks: ['instagram'],
    __typename: 'LastWeekAudienceSize',
  },
  {
    count: 450,
    isoDate: '2020-10-19T03:00:00.000Z',
    publishTime: '2020-10-19T03:00:00.000Z',
    socialNetworks: ['instagram'],
    __typename: 'LastWeekAudienceSize',
  },
  {
    count: 100,
    isoDate: '2020-10-14T17:00:00.000Z',
    publishTime: '2020-10-14T17:00:00.000Z',
    socialNetworks: ['linkedin'],
    __typename: 'LastWeekAudienceSize',
  },
  {
    count: 500,
    isoDate: '2020-10-15T23:00:00.000Z',
    publishTime: '2020-10-15T23:00:00.000Z',
    socialNetworks: ['linkedin'],
    __typename: 'LastWeekAudienceSize',
  },
  {
    count: 450,
    isoDate: '2020-10-19T03:00:00.000Z',
    publishTime: '2020-10-19T03:00:00.000Z',
    socialNetworks: ['linkedin'],
    __typename: 'LastWeekAudienceSize',
  },
];

export default postRecommendationsMock;
