import * as Sentry from '@sentry/browser';
import { authAppSync } from '../auth';
import { getUserQuotas } from '../../graphql/queries';
import Roles from '../../mocks/users/roles.mock.json';
import {
  ENVIRONMENTS,
  ENV,
} from '../../utils/constants/globals';
import { becomeFreeOnce } from '../../graphql/mutations';

export default class RoleProvider {
  static async fetchRole() {
    if (ENV === ENVIRONMENTS.local) {
      return Promise.resolve({
        data: Roles.proRole,
        success: true,
        message: 'success',
      });
    }
    // return Promise.resolve(Roles.proRole);
    /**
     * this comment should be for further implementations from
     * backend
     */
    try {
      const { data } = await authAppSync.client.query({
        query: getUserQuotas(),
        fetchPolicy: 'network-only',
      });
      return {
        data: data.getUserQuotas,
        success: true,
        message: 'success',
      };
    } catch (e) {
      Sentry.captureException(e);
      return {
        data: null,
        success: false,
        message: e.message,
      };
    }
  }

  static async becomeFreeOnce() {
    if (ENV === ENVIRONMENTS.local) {
      return {
        data: true,
        success: true,
        message: 'success',
      };
    }
    try {
      const { data } = await authAppSync.client.mutate({
        mutation: becomeFreeOnce,
      });
      return {
        data: data.becomeFreeOnce,
        success: true,
        message: 'success',
      };
    } catch (e) {
      Sentry.captureException(e);
      return {
        data: null,
        success: false,
        message: e.message,
      };
    }
  }
}
