export const piper = {
  Piper1: require('./piper/piper-1.svg'),
  Piper1NoLine: require('./piper/piper-1-no-line.svg'),
  Piper1NoLineNormal: require('./piper/piper-1-no-line-normal.svg'),
  Piper2: require('./piper/piper-2.svg'),
  Piper2Background: require('./piper/piper-2-background.svg'),
  Piper3: require('./piper/piper-3.svg'),
  PiperAcademy: require('./piper/piper-academy.svg'),
  Calendar: require('./piper/piper-calendar.svg'),
  Camera: require('./piper/piper-camera.svg'),
  Celebrate: require('./piper/piper-celebrate.svg'),
  CelebrateConfetti: require('./piper/piper-celebrate-confetti.svg'),
  Head: require('./piper/piper-head.svg'),
  NudgingPaw1: require('./piper/piper-nudging-paw-1.svg'),
  NudgingPaw2: require('./piper/piper-nudging-paw-2.svg'),
  NudgingPaw3: require('./piper/piper-nudging-paw-3.svg'),
  NudgingPaw2Background: require('./piper/piper-nuding-paw-2-background.svg'),
  PiperScheduler: require('./piper/piper-scheduler.svg'),
  RocketGIF: require('./piper/piper-rocket.gif'),
  PiperCalendarGIF: require('./piper/piper-calendar-background.gif'),
  PiperTypingGIF: require('./piper/piper-typing-background.gif'),
  PiperCelebrateGIF: require('./piper/piper-celebrate.gif'),
  Rocket: require('./piper/piper-rocket.svg'),
  Settings: require('./piper/piper-settings.svg'),
  CameraBackground: require('./piper/piper-camera-background.svg'),
  MobileCameraBackground: require('./piper/s-piper-camera-background.svg'),
  Paw2Background: require('./piper/piper-nudging-paw-2-w-background.svg'),
  HeadBackground: require('./piper/head-background.svg'),
  Fail1: require('./piper/piper-fail-1.svg'),
  Fail2: require('./piper/piper-fail-2.svg'),
  Fail3: require('./piper/piper-fail-3.svg'),
  Fail4: require('./piper/piper-fail-4.svg'),
  PiperMessagesBackground: require('./piper/piper-messages.svg'),
  PiperMessagesBackgroundMobile: require('./piper/PiperMessagesBackgroundMobile.svg'),
  PiperMessagesNoBackground: require('./piper/piper-messages-wo-bg.svg'),
  Piper2BigBackground: require('./piper/piper-2-big-background.svg'),
  PiperRocketBackground: require('./piper/piper-rocket-background.svg'),
  PiperRocketBackgroundTablet: require('./piper/piper-rocket-background-tablet.svg'),
  PiperPublishSuccess: require('./piper/piper-traffic-light-green.svg'),
  PiperPublishFail: require('./piper/piper-traffic-light-red.svg'),
  Piper1BackgroundSecondary: require('./piper/piper-1-background-secondary.svg'),
  Paw: require('./piper/paw.svg'),
  PiperThumb: require('./piper/piper-thumb2.svg'),
  PiperHeadLoginApp: require('./piper/piper-login-app-header.png'),
  PiperPublishInstagram: require('./piper/piper-publish-instagram.svg'),
  PiperProfessor: require('./piper/piper-professor.svg'),
  PiperStudent: require('./piper/piper-student.svg'),
  PiperSad: require('./piper/piper-sad.svg'),
  PiperSadNormal: require('./piper/piper-sad-normal.svg'),
  PiperRun: require('./welcomeModal/piper-run.svg'),
  PiperLogoRoundedWhite: require('./piper/piper-logo-rounded-white.svg'),
  PiperLogo: require('./piper/piper-logo.svg'),
  PiperSitting: require('./piper/piper-sitting.svg'),
  PiperSittingRight: require('./piper/piper-sitting-right.svg'),
  PiperMeet: require('./piper/piper-meet.svg'),
  PiperTool: require('./piper/piper-tool.svg'),
  PiperBackgroundGreen: require('./piper/piper-bg-green.svg'),
  PiperBackgroundBlue: require('./piper/piper-bg-blue.svg'),
  PiperRocketBackgroundPink: require('./piper/piper-rocket-bg-pink.svg'),
  PiperBackgroundYellow: require('./piper/piper-bg-yellow.svg'),
  PiperMicrophone: require('./piper/piper-mic.svg'),
  PiperMetrics: require('./piper/piper-metrics.svg'),
};

export const bubbles = {
  Bubble1: require('./bubbles/bubble-1.svg'),
  Bubble2: require('./bubbles/bubble-2.svg'),
  Bubble3: require('./bubbles/bubble-3.svg'),
  Bubble4: require('./bubbles/bubble-4.svg'),
};

export const dotted = {
  large: require('./divisorLine.svg'),
  small: require('./divisorLine.svg'),
  medium: require('./divisorLine.svg'),
};

export const ColoredDogo = {
  blue: require('./ColoredPiper/blue_dogo.svg'),
  green: require('./ColoredPiper/green_dogo.svg'),
  purple: require('./ColoredPiper/purple_dogo.svg'),
  yellow: require('./ColoredPiper/yellow_dogo.svg'),
  orange: require('./ColoredPiper/orange_dogo.svg'),
};

export const onboardingRedirectModal = {
  createPost: require('./onboardingRedirectModal/create-post.svg'),
  social: require('./onboardingRedirectModal/social.svg'),
  mediaLibrary: require('./onboardingRedirectModal/media-library.svg'),
};

export const tutorialModal = {
  sliderOneEn: require('./tutorialModal/slider_1_en.svg'),
  sliderOneEs: require('./tutorialModal/slider_1_es.svg'),
  sliderOneMdEn: require('./tutorialModal/slider_1_en_md.svg'),
  sliderOneMdEs: require('./tutorialModal/slider_1_es_md.svg'),
  sliderOneSmEn: require('./tutorialModal/slider_1_en_sm.svg'),
  sliderOneSmEs: require('./tutorialModal/slider_1_es_sm.svg'),
  sliderTwoEn: require('./tutorialModal/slider_2_en.svg'),
  sliderTwoEs: require('./tutorialModal/slider_2_es.svg'),
  sliderTwoSmEn: require('./tutorialModal/slider_2_en_sm.svg'),
  sliderTwoSmEs: require('./tutorialModal/slider_2_es_sm.svg'),
  sliderTreeEn: require('./tutorialModal/slider_3_en.svg'),
  sliderTreeEs: require('./tutorialModal/slider_3_es.svg'),
  sliderTreeSmEn: require('./tutorialModal/slider_3_en_sm.svg'),
  sliderTreeSmEs: require('./tutorialModal/slider_3_es_sm.svg'),
  sliderFourEn: require('./tutorialModal/slider_4_en.svg'),
  sliderFourEs: require('./tutorialModal/slider_4_es.svg'),
  sliderFourSmEn: require('./tutorialModal/slider_4_en_sm.svg'),
  sliderFourSmEs: require('./tutorialModal/slider_4_es_sm.svg'),
  sliderFiveEn: require('./tutorialModal/slider_5_en.svg'),
  sliderFiveEs: require('./tutorialModal/slider_5_es.svg'),
  sliderFiveSmEn: require('./tutorialModal/slider_5_en_sm.svg'),
  sliderFiveSmEs: require('./tutorialModal/slider_5_es_sm.svg'),
  piperHelloEs: require('./tutorialModal/piper_hello_es.svg'),
  piperHelloEn: require('./tutorialModal/piper_hello_en.svg'),
};

export const welcomeModal = {
  sliderOneEn: require('./welcomeModal/welcome-slider-1-en-md.png'),
  sliderOneEs: require('./welcomeModal/welcome-slider-1-es-md.png'),
  sliderOneSmEn: require('./welcomeModal/welcome-slider-1-en-sm.png'),
  sliderOneSmEs: require('./welcomeModal/welcome-slider-1-es-sm.png'),
  sliderTwoEn: require('./welcomeModal/welcome-slider-2-en-md.png'),
  sliderTwoEs: require('./welcomeModal/welcome-slider-2-es-md.png'),
  sliderTwoSmEn: require('./welcomeModal/welcome-slider-2-en-sm.png'),
  sliderTwoSmEs: require('./welcomeModal/welcome-slider-2-es-sm.png'),
  sliderThreeEn: require('./welcomeModal/welcome-slider-3-en-md.png'),
  sliderThreeEs: require('./welcomeModal/welcome-slider-3-es-md.png'),
  sliderThreeSmEn: require('./welcomeModal/welcome-slider-3-en-sm.png'),
  sliderThreeSmEs: require('./welcomeModal/welcome-slider-3-es-sm.png'),
};

export const loginApp = {
  buttonApple: require('./backgrounds/login-button-apple.png'),

  headerMobile: require('./backgrounds/login-mobile-header.svg'),
  headerTablet: require('./backgrounds/login-portrait-header.svg'),
  headerLandscape: require('./backgrounds/login-landscape-header.svg'),
  footer: require('./mobileLine.svg'),

  footerMobile: require('./backgrounds/login-mobile-footer.svg'),
  footerTablet: require('./backgrounds/login-portrait-footer.svg'),
  footerLandscape: require('./backgrounds/login-landscape-footer.svg'),
};

export const wavesAcademy = {
  mobile: require('./backgrounds/wave-academy-mobile.svg'),
};

export const backgrounds = {
  piperScheduler: require('./backgrounds/camera-background.svg'),
};

export const landing = {
  heroShapes: require('./landing-hero-shapes.svg'),
  heroShapesDesktop: require('./landing-hero-shapes-desktop.svg'),
  dogFootprints: require('./dog-footprints1.svg'),
  dogFootprints2: require('./dog-footprints2.svg'),
  piperDialogs: {
    dialog1: {
      mobile: require('./piper-dialog1-mobile.svg'),
      desktop: require('./piper-dialog1-desktop.svg'),
      mobileEs: require('./piper-dialog1-mobile-es.svg'),
      desktopEs: require('./piper-dialog1-desktop-es.svg'),
    },
    dialog2: {
      mobile: require('./piper-dialog2-mobile.svg'),
      desktop: require('./piper-dialog2-desktop.svg'),
      mobileEs: require('./piper-dialog2-mobile-es.svg'),
      desktopEs: require('./piper-dialog2-desktop-es.svg'),
    },
    dialog3: {
      mobile: require('./piper-dialog3-mobile.svg'),
      desktop: require('./piper-dialog3-desktop.svg'),
      mobileEs: require('./piper-dialog3-mobile-es.svg'),
      desktopEs: require('./piper-dialog3-desktop-es.svg'),
    },
    dialog4: {
      mobile: require('./piper-dialog4-mobile.svg'),
      desktop: require('./piper-dialog4-desktop.svg'),
      mobileEs: require('./piper-dialog4-mobile-es.svg'),
      desktopEs: require('./piper-dialog4-desktop-es.svg'),
    },
  },
};

export const ToolsHeroShapes = require('./tools/header-shapes.svg');

export const guideLines = {
  guideLine1: require('./tools/guide-line-1.svg'),
  guideLine2: require('./tools/guide-line-2.svg'),
  guideLine3: require('./tools/guide-line-3.svg'),
};

export const toolsDogFootprint = require('./tools/dog-footprint.svg');
