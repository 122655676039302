/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button as BaseButton, CircularProgress } from '@material-ui/core';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import {
  SECONDARY,
  PRIMARY,
  LARGE,
  MEDIUM,
  L_FONT_SIZE,
  M_FONT_SIZE,
  L_LINE_HEIGHT,
  M_LINE_HEIGHT,
  OUTLINED,
  CONTAINED,
} from './constants';
import { colors } from '../../styled/theme';
import useCallGA from '../../hooks/useCallGA';
// TO DO Analytic implementation
// import AnalyticsLink from './AnalyticsLink';

const BrandBtn = styled(BaseButton)`
  && {
    font-style: normal;
    font-weight: 600;
    padding:  ${({ padding }) => (padding || '10px 24px')};
    font-size: ${({ fontSize }) => (fontSize || '16px')};
    line-height: 110%;
    border: 2px solid transparent;
    background-color: ${({ danger }) => (danger ? colors.red : colors.primary)};
    &:hover {
      border: 2px solid transparent;
      background-color: ${({ danger }) => (danger && colors.redDark)};
    }
    ${({ disabled }) => disabled && (
    `background-color: ${colors.gray02};
      color: ${colors.white};`
  )}
    ${({ typebrand }) => (typebrand === SECONDARY) && css`
      background-color: white;
      border-color: ${({ danger }) => (danger ? colors.red : colors.primary)};
      &:hover {
        background-color: ${colors.gray01};
        border: 2px solid ${({ danger }) => (danger ? colors.red : colors.primary)};
      }
      ${({ disabled }) => disabled && (
    `background-color: ${colors.gray02};
          color: ${colors.white};`
  )}
    `}
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    ${({ danger }) => danger && css`
      .MuiCircularProgress-circle{
        stroke: ${colors.red};
      }
    `}
  }
`;

const getPadding = (padding, size) => {
  const defaultPadding = '8px 24px';
  if (padding !== '') return padding;
  if (size === LARGE) return '10px 24px';
  return defaultPadding;
};

const Button = ({
  onClick,
  children,
  type,
  disabled,
  size,
  handlerClick,
  isLoading,
  className,
  danger,
  padding,
  submit,
  to,
  gaData,
  navLink,
  ...props
}) => {
  const fontSize = size === LARGE ? L_FONT_SIZE : M_FONT_SIZE;
  const lineHeight = size === LARGE ? L_LINE_HEIGHT : M_LINE_HEIGHT;
  const paddingApplied = getPadding(padding, size);
  const callGA = useCallGA();
  const handleComposedClick = (e) => {
    if (gaData.action) callGA(gaData, to);
    handlerClick(e);
    onClick(e);
  };
  return (
    <BrandBtn
      onClick={handleComposedClick}
      type={submit ? 'submit' : 'button'}
      variant={type === SECONDARY ? OUTLINED : CONTAINED}
      color="primary"
      disabled={disabled || isLoading}
      typebrand={type}
      fontSize={fontSize}
      lineheight={lineHeight}
      className={className}
      padding={paddingApplied}
      danger={danger}
      {...props}
    >
      {children}
      {isLoading && <StyledCircularProgress danger={danger} size={24} />}
    </BrandBtn>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  handlerClick: PropTypes.func,
  isLoading: PropTypes.bool,
  danger: PropTypes.bool,
  padding: PropTypes.string,
  submit: PropTypes.bool,
  to: PropTypes.string,
  gaData: PropTypes.instanceOf(Object),
  navLink: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  children: PropTypes.node,
  className: '',
  type: PRIMARY,
  disabled: false,
  size: MEDIUM,
  handlerClick: () => {},
  isLoading: false,
  danger: false,
  padding: '',
  submit: false,
  to: '',
  gaData: {},
  navLink: false,
  onClick: () => {},
};

export default Button;
