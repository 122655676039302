import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import AuthService from '../services/auth/AuthService';

export const AnalyticsContext = React.createContext();

export const constants = {
  SET: '_SET_',
};

/**
 *
 * dataLayerObject = { task, taskData} || { ...attributes }
 * the first is for send global actions to tag manager, (has task attribute)
 * the second is for the data arquitechture made by EPA
 */

const AnalyticsProvider = ({ children }) => {
  const { locale } = useIntl();
  const dataLayerPush = (dataLayerObject) => {
    /*
      * Hotfix for the bug present when the language is different from en
      *
      * Validate that the last object in dataLayer is different from the new one
      */
    const lastIndex = window.dataLayer.length - 1;
    const id = AuthService.getAuthID() || '';

    if (
      dataLayerObject.event === 'pv'
        && dataLayerObject.pagePath === window.dataLayer[lastIndex].pagePath
        && dataLayerObject.pathTitle === window.dataLayer[lastIndex].pathTitle
    ) return;

    if (dataLayerObject.task && dataLayerObject.task === constants.SET) {
      window.dataLayer.push('set', dataLayerObject.taskData);
    } else {
      /* I have to use this name for avoid clash with other
      context vars, because this use code injection */
      // eslint-disable-next-line no-unused-vars
      // eslint-disable-next-line camelcase
      const x__dataLayerData__x = {
        locale,
        ...dataLayerObject,
      };
      if (AuthService.isAuthenticated()) {
        x__dataLayerData__x.id = id;
      }
      window.dataLayer.push(x__dataLayerData__x);
    }
  };
  return (
    <AnalyticsContext.Provider
      value={{
        dataLayerPush,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AnalyticsProvider;
