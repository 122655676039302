import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import AnalyticsAnchor from '../../buttons/AnalyticsAnchor';
import { ButtonText } from '../../buttons';
import { colors } from '../../../styled/theme';
import GlobalTheme from '../../../styled/GlobalTheme';

const CustomAnalyticsAnchor = styled(AnalyticsAnchor)`
  ${GlobalTheme.breakpoints.up('md')}{
    margin-right: 16px;
  }
`;

const text = css`
  color: ${colors.text02};
  text-align: left;
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  ${GlobalTheme.breakpoints.only('md')}{
    font-size: 14px;
  }
`;

const CustomButtonText = styled(ButtonText)`
  ${text};
  justify-content: flex-start;
  line-height: ${(props) => props.lineHeight || '24px'};
`;

const Text = styled.div`
  ${text};
  line-height: ${(props) => props.lineHeight};
`;

const SocialMediaIcon = styled.img`
  width: 26px;
  display: inline-block;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 30px;
  }
`;

export default function FooterElement({
  element,
  className,
}) {
  const {
    to,
    gaData,
    title,
    rel,
    icon,
    alt,
    lineHeight,
  } = element;
  return to ? (
    <CustomAnalyticsAnchor
      className={`elementAnchor ${className || ''}`}
      href={to}
      gaData={gaData}
      rel={rel}
      target="_blank"
    >
      { icon && (
        <SocialMediaIcon src={icon} alt={alt} />
      ) }
      {title && (
        <CustomButtonText lineHeight={lineHeight} className="elementText" fontSize="18px" color={colors.text02}>
          {title}
        </CustomButtonText>
      )}
    </CustomAnalyticsAnchor>
  ) : <Text lineHeight={lineHeight} className={`elementText ${className || ''}`}>{title}</Text>;
}

FooterElement.propTypes = {
  element: PropTypes.instanceOf(Object),
  className: '',
};

FooterElement.defaultProps = {
  className: '',
  element: null,
};
