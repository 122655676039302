import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Media from "react-media";
import { HeaderImage } from "../../../assets/images/imageRepository";
import {
  landing,
  piper,
} from "../../../assets/illustrations/illustrationRepository";
import { ButtonPrimary, ButtonSecondary } from "./Buttons";
import routes from "../../../utils/constants/routes";
import mediaQueries from "../../../utils/mediaQueries";
import PromoCodeContext from "../../../contexts/PromoCodeContext";
import sanitizePlans from "../../CreateAccount/sanitizePlans";
import ListOfSocialMedias from "../../../components/ListOfSocialMedias";

export default function Promotional() {
  const history = useHistory();
  const intl = useIntl();
  const { PromoCode } = useContext(PromoCodeContext);
  const [trialDaysM, setTrialDaysM] = useState(0);
  const [trialDays, setTrialDays] = useState(0);
  const [pathSignup, setPathSignup] = useState(routes.PRICING);

  const handleLearnMore = () => history.push(routes.TOOLS);
  const handleStartTrial = () => history.push(routes.CREATE_ACCOUNT);

  useEffect(() => {
    const [monthlyPlan] = sanitizePlans(PromoCode);
    setTrialDaysM(monthlyPlan.trialDuration);
    setTrialDays(monthlyPlan.trialDuration);
  }, [PromoCode, trialDaysM]);

  useEffect(() => {
    const fromSignupPro = window.localStorage.getItem("isLandingPro");
    if (fromSignupPro) {
      setPathSignup(routes.CREATE_ACCOUNT_PRO);
    }
  }, []);

  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <PromotionalWrapper>
          <Hero>
            <Title>
              <FormattedMessage
                id="landing.promotional.title"
                defaultMessage="Social media made easy?"
              />
            </Title>
            <Subtitle>
              <FormattedMessage
                id="landing.promotional.subtitle"
                defaultMessage="Yup. We mean it."
                values={{
                  lineBreak: <br />,
                }}
              />
            </Subtitle>

            <HeroShapesContainer>
              <HeroShapes
                src={
                  matches.xlPlusDownLess
                    ? landing.heroShapes
                    : landing.heroShapesDesktop
                }
                alt="hero shapes"
              />
            </HeroShapesContainer>
            <HeroPiperContainer>
              <HeroPiper src={piper.PiperSitting} alt="piper sitting" />
            </HeroPiperContainer>
            {matches.mdUp && (
              <Description isSpanish={intl.locale === "es"}>
                <DescriptionContainer isSpanish={intl.locale === "es"}>
                  <DescriptionText>
                    <FormattedMessage
                      id="landing.promotional.description"
                      defaultMessage="<bText>Developed for small business owners like you</bText>, our intuitive tools and personalized recommendations save you time, trouble, and tears."
                      values={{
                        bText: (...chunks) => <BoldText>{chunks}</BoldText>,
                      }}
                    />
                  </DescriptionText>
                  <DescriptionText>
                    <FormattedMessage
                      id="landing.promotional.description2"
                      defaultMessa
                      values={{
                        bText: (...chunks) => <BoldText>{chunks}</BoldText>,
                      }}
                      ge="With Piper, you’ll create great posts and manage all your social accounts like a pro. The result?  More time for you."
                    />
                  </DescriptionText>
                  <ListOfSocialMedias />
                </DescriptionContainer>
                <ButtonsContainer>
                  <ButtonContainer>
                    <ButtonPrimary
                      onClick={handleLearnMore}
                      gaData={{
                        action: "Learn more",
                        label: "(button)(hero)",
                      }}
                    >
                      <FormattedMessage
                        id="k.learnMore"
                        defaultMessage="Learn more"
                      />
                    </ButtonPrimary>
                  </ButtonContainer>
                  <ButtonContainer>
                    <ButtonSecondary
                      largeText={intl.locale === "es"}
                      onClick={handleStartTrial}
                      gaData={{
                        action: "Start trial",
                        label: "(button)(hero)",
                      }}
                    >
                      {trialDays > 0 ? (
                        <FormattedMessage
                          id="landing.promotional.button.trial"
                          defaultMessage="Start your free {trialDays}-day trial!"
                          values={{
                            trialDays,
                          }}
                        />
                      ) : (
                        <FormattedMessage
                          id="plan.pro.without.trial"
                          defaultMessage="Create your account"
                        />
                      )}
                    </ButtonSecondary>
                  </ButtonContainer>
                </ButtonsContainer>
              </Description>
            )}
          </Hero>
          {matches.mdDownLess && (
            <Description isSpanish={intl.locale === "es"}>
              <DescriptionContainer isSpanish={intl.locale === "es"}>
                <DescriptionText>
                  <FormattedMessage
                    id="landing.promotional.description1.mobile"
                    defaultMessage="<bText>Smart tools and tailored advice for small business owners –</bText> {lineBreak}Save time, trouble, and tears."
                    values={{
                      bText: (...chunks) => <BoldText>{chunks}</BoldText>,
                      lineBreak: intl.locale === "es" ? "" : <br />,
                    }}
                  />
                </DescriptionText>
                <DescriptionText>
                  <FormattedMessage
                    id="landing.promotional.description2.mobile"
                    defaultMessage="With Piper, you’ll manage all your social accounts like a pro. {lineBreak}The result?  More time for you."
                    values={{
                      bText: (...chunks) => <BoldText>{chunks}</BoldText>,
                      lineBreak: <br />,
                    }}
                  />
                </DescriptionText>
                <ListOfSocialMedias mobile />
              </DescriptionContainer>
              <ButtonsContainer>
                <ButtonContainer>
                  <ButtonPrimary onClick={handleLearnMore}>
                    <FormattedMessage
                      id="k.learnMore"
                      defaultMessage="Learn more"
                    />
                  </ButtonPrimary>
                </ButtonContainer>
                <ButtonContainer>
                  <ButtonSecondary
                    largeText={intl.locale === "es"}
                    onClick={handleStartTrial}
                  >
                    {trialDays > 0 ? (
                      <FormattedMessage
                        id="landing.promotional.button.trial.mobile"
                        defaultMessage="Start your free {trialDays}-day trial!"
                        values={{
                          trialDays,
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        id="plan.pro.without.trial.mobile"
                        defaultMessage="Create your account"
                      />
                    )}
                  </ButtonSecondary>
                </ButtonContainer>
              </ButtonsContainer>
            </Description>
          )}
        </PromotionalWrapper>
      )}
    </Media>
  );
}

const PromotionalWrapper = styled.div``;
const Hero = styled.div`
  background-image: url(${HeaderImage.mobile});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 500px;
  padding-top: 32px;
  padding-left: 24px;
  z-index: 1;
  @media (min-width: 420px) {
    background-position-y: -34px;
  }
  @media (min-width: 480px) {
    background-position-y: -64px;
  }
  @media ${mediaQueries.mdUp} {
    background-image: url(${HeaderImage.desktop});
    background-position-x: -186px;
    background-position-y: -120px;
    height: 676px;
  }
  @media (min-width: 1096px) {
    background-position-x: center;
  }
  @media ${mediaQueries.xlPlusUp} {
    padding-top: 60px;
    padding-left: 98px;
  }
  @media (min-width: 1441px) {
    padding-left: 0;
  }
`;
const HeroShapesContainer = styled.div`
  right: 0;
  top: 28%;
  position: absolute;
  @media ${mediaQueries.mdUp} {
    top: 42%;
  }
  @media ${mediaQueries.xlPlusUp} {
    top: 14%;
    right: -24px;
  }
`;
const HeroShapes = styled.img`
  width: 100%;
`;
const HeroPiperContainer = styled.div`
  bottom: -5px;
  right: -58px;
  position: absolute;
  z-index: 0;
  @media ${mediaQueries.lgPlusUp} {
    right: -42px;
  }
  @media ${mediaQueries.xlPlusUp} {
    bottom: 0;
    right: -68px;
    width: 256px;
    height: 256px;
  }
`;
const HeroPiper = styled.img`
  width: 100%;
`;
const Title = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-size: 32px;
  font-weight: bold;
  line-height: 35px;
  max-width: 201px;
  @media ${mediaQueries.mdUp} {
    font-size: 40px;
    line-height: 54px;
    max-width: 100%;
  }
  @media (min-width: 1441px) {
    max-width: 1424px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 100px;
  }
`;
const Subtitle = styled.p`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  font-size: 19px;
  font-weight: 600;
  line-height: 26px;
  margin: 6px 0 0 0;
  @media ${mediaQueries.mdUp} {
    font-size: 28px;
    line-height: 38px;
  }
  @media (min-width: 1441px) {
    max-width: 1424px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 100px;
  }
`;
const Description = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 30px 34px 40px 34px;
  ${({ isSpanish }) =>
    isSpanish &&
    `
    padding-left: 20px;
    padding-right: 21px;
  `}
  @media ${mediaQueries.mdUp} {
    background: none;
    margin-top: 40px;
    padding: 0;
  }
  @media (min-width: 1441px) {
    max-width: 1424px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 100px;
  }
`;
const DescriptionContainer = styled.div`
  padding-left: 17px;
  padding-right: 14px;
  ${({ isSpanish }) =>
    isSpanish &&
    `
    padding-left: 0;
    padding-right: 0;
  `}
  @media ${mediaQueries.mdUp} {
    max-width: 476px;
    padding-left: 0;
    padding-right: 0;
  }
`;
const DescriptionText = styled.p`
  color: ${({ theme }) => theme.colors.black3E};
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  @media ${mediaQueries.mdUp} {
    color: ${({ theme }) => theme.colors.white};
    font-size: 18px;
    line-height: 25px;
  }
  &:nth-child(2) {
    margin-top: 32px;
  }
`;
const BoldText = styled.span`
  font-weight: bold;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  @media ${mediaQueries.mdUp} {
    margin-top: 60px;
  }
  @media ${mediaQueries.xlPlusUp} {
    margin-top: 22px;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  &:nth-child(2) {
    margin-top: 20px;
    @media ${mediaQueries.xlPlusUp} {
      margin-top: 0;
      margin-left: 26px;
    }
  }
  @media ${mediaQueries.mdUp} {
    justify-content: flex-start;
  }
  @media ${mediaQueries.xlPlusUp} {
    width: max-content;
  }
`;
