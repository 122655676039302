import authRoutes from './authenticated';
import publicRoutes from './public';
import communityRedirects from './community/redirects';

export const redirects = [
  ...communityRedirects,
];

const routes = [
  ...authRoutes,
  ...publicRoutes,
];

export default routes;
