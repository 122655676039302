const es = {
  "dashboard.greeting.afternoon": "¡Buenas tardes {name}!",
  "dashboard.greeting.evening": "¡Buenas noches {name}!",
  "dashboard.greeting.morning": "¡Buenos días {name}!",
  "dashboard.greeting.night": "¡Buenas noches {name}!",
  "piperAdvice.boostManager.health.high":
    "¿Cómo van esas métricas? Recuerda compararlos con los orgánicos que puedes ver en la sección Comunidad.",
  "piperAdvice.boostManager.health.low":
    "Me alegra que estás consciente de la fuerza que tiene la creación de campañas publicitarias en la comunicación de tu negocio.",
  "piperAdvice.boostManager.health.medium":
    "Sigue creando campañas publicitarias de tu contenido, estoy seguro de que sabes la diferencia que hace.",
  "piperAdvice.boostManager.health.zero":
    "Estás perdiendo la oportunidad de potenciar la comunicación con tus clientes. ¡Vamos a crear campañas publicitarias de tu contenido!",
  "piperAdvice.community.comments.unreads.high":
    "¡Es mejor que prestes atención! Tienes muchos comentarios para leer y esa no es una buena idea.",
  "piperAdvice.community.comments.unreads.low":
    "¡Lo estás haciendo bien! Revisa los nuevos comentarios.",
  "piperAdvice.community.comments.unreads.medium":
    "¡Responde a más comentarios! Así tu público se mantiene interesado y aumenta la confianza",
  "piperAdvice.community.comments.unreads.zero":
    "No tienes comentarios para leer. Mantén activa a tu comunidad creando campañas de tus publicaciones.",
  "piperAdvice.community.inbox.unreads.high":
    "Planificar con anticipación te permitirá generar mejor contenido y obtener mejores resultados. ¡Vamos a hacerlo!",
  "piperAdvice.community.inbox.unreads.low":
    "No dejes a tus clientes esperando demasiado. Las respuestas rápidas aumentan la confianza en tu marca.",
  "piperAdvice.community.inbox.unreads.medium":
    "No olvides que tienes un límite de tiempo para responder mensajes, ¡Ahora es un buen momento para hacerlo!",
  "piperAdvice.community.inbox.unreads.zero":
    "No tienes mensajes nuevos. Publica contenido nuevo para que pueda generar nuevos intereses para tus clientes.",
  "piperAdvice.scheduler.inbox.planning.high":
    "¡Excelente! Me gusta que seas ordenado y organizado en la planificación de tu contenido.",
  "piperAdvice.scheduler.inbox.planning.low":
    "Para un impacto máximo, publica al menos una vez al día. He sugerido días / horas óptimos cuando tu público está más activa.",
  "piperAdvice.scheduler.inbox.planning.medium":
    "Estás haciendo un muy buen trabajo planificando tus próximas publicaciones.",
  "piperAdvice.scheduler.inbox.planning.zero":
    "Planificar con anticipación te permitirá generar mejor contenido y obtener mejores resultados. ¡Vamos a hacerlo!",
  "piperAdvice.scheduler.inbox.planning.ai":
    "Aquí están tus publicaciones para una semana. Recomiendo revisar y editarlas si es necesario.",
  "settings.audienceGraphic.high":
    "¿Cómo van esas métricas? Recuerda compararlos con los orgánicos que puedes ver en la sección Comunidad.",
  "settings.audienceGraphic.low":
    "Para llegar a más personas y tener una público más grande, mantén intereses relevantes y expande la edad o la ubicación de la público. Piensa en lo que le gusta a la gente cuando usa tu producto o servicio.",
  "settings.audienceGraphic.none": "",
};

export default es;
