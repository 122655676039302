import gql from 'graphql-tag';

export const updateCompany = gql`
  mutation updateCompany($location: String, $companyName: String!, $logoUrl: String, $industryCategory: [String], $strategy: String) {
    updateCompany( data: {
      location: $location,
      companyName: $companyName,
      logoUrl: $logoUrl,
      industryCategory: $industryCategory,
      strategy: $strategy,
    }){
      id
    }
  }
`;

export const updateAIResponseType = gql`
  mutation updateAIResponseType(
    $type: AutoResponseTypes!
  ) {
    updateAIResponseType(
      type: $type
    ) {
      autoResponseType
    }
  }
`;

export const createOrUpdateFaq = gql`
  mutation createOrUpdateFaqs (
    $id: Int
    $answer: String!
    $question: String!
  ) {
    createOrUpdateFaqs(
      id: $id
      answer: $answer
      question: $question
    ) {
      success
      message
      created {
        id
        answer
        question
        businessId
      }
      updated {
        id
        answer
        question
        businessId
      }
    }
  }
`;

export const destroyFaq = gql`
  mutation destroyFaq($id: Int!) {
    destroyFaq(id: $id) {
        success
        message
        count
    }
  }
`;

export const updateExtraBusinessInfo = gql`
  mutation updateExtraBusinessInfo(
    $products: [String]!
    $openingHours: [OpeningHours]!
    $physicLocation: String
    $email: String
    $phone: String
  ) {
    updateExtraBusinessInfo(
      data: {
        products: $products
        openingHours: $openingHours
        physicLocation: $physicLocation
        email: $email
        phone: $phone
      }
    ) {
      message
      success
    }
  }
`;

export default {};
