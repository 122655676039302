import gql from 'graphql-tag';

export const saveCalendlyEvent = gql`
  mutation saveCalendlyEvent($evtUrl: String!) {
    saveCalendlyEvent(evtUrl: $evtUrl) {
      success
      info
      errorMessage
    }
  }
`;

export default {};
