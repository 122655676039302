import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import GlobalTheme from '../../styled/GlobalTheme';

export const Icon = styled.div`
  width:${(props) => props.size || '24px'};
  height:${(props) => props.size || '24px'};
  background-size:${(props) => props.size || '24px'};
  background-color: ${(props) => props.color || props.theme.colors.black};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  display: inline-block;
  opacity: ${(props) => (props.opacity ? props.opacity : '100%')};
  mask: url(${(props) => props.src}) no-repeat center;
  mask-size:${(props) => props.size || '24px'};
  -webkit-mask: url(${(props) => props.src}) no-repeat center;
  -webkit-mask-size:${(props) => props.size || '24px'};
  ${(props) => props.backgroundColor && `
    &:hover{
      background-color: ${props.backgroundColor};
    }
  `}
  ${GlobalTheme.breakpoints.down('sm')} {
    ${(props) => (props.marginSM ? `margin: ${props.marginSM};` : '')}
  }
`;
export default function ColoredIcon({
  className,
  src,
  color,
  hoverColor,
  size,
  opacity,
  margin,
  marginSM,
  onClick,
}) {
  return (
    <Icon
      className={`${className} coloredIcon`}
      src={src}
      color={color}
      hoverColor={hoverColor}
      size={size}
      opacity={opacity}
      margin={margin}
      marginSM={marginSM}
      onClick={onClick}
    />
  );
}

ColoredIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  src: PropTypes.string,
  hoverColor: PropTypes.string,
  opacity: PropTypes.string,
  margin: PropTypes.string,
  marginSM: PropTypes.string,
  onClick: PropTypes.func,
};

ColoredIcon.defaultProps = {
  className: '',
  color: '',
  size: '',
  src: '',
  hoverColor: '',
  opacity: '100%',
  margin: '0',
  marginSM: '0',
  onClick: () => {},
};
