const es = {
  'metrics.hero.title': 'Métricas',
  'metrics.k.interactions': 'interacciones',

  'metrics.hero.options.interval.week': 'Esta Semana',
  'metrics.hero.options.interval.7.days': 'Últimos 7 días',
  'metrics.hero.options.interval.14.days': 'Últimos 14 días',
  'metrics.hero.options.interval.30.days': 'Últimos 30 días',
  'metrics.hero.options.interval.60.days': 'Últimos 60 días',
  'metrics.hero.infoDisabledRange': 'Algunos rangos estarán disponibles conforme pase el tiempo desde que conectaste tus redes.',

  'metrics.connectAccounts.noAccountsConnected': 'Conecta al menos una red',
  'metrics.connectAccounts.accountsConnected': 'Puedes ver más métricas si conectas más redes',

  'metrics.data.empty': 'Aún no hay información de este periodo.',

  'metrics.socialConnectButton': 'Conectar {socialNetwork}',
  'metrics.piperScore.title': 'Tu Piper Score',
  'metrics.piperScore.advice': 'Lo mejor es tener un puntaje de más de 60. Tú puedes.',
  'metrics.piperScore.score.text': 'Score Recomendado',

  'metrics.socialGrowth.title': 'Cómo has crecido',
  'metrics.socialGrowth.subtitle': 'Así han crecido tus redes respecto al periodo seleccionado.',
  'metrics.socialgrowth.followers.text': 'SEGUIDORES',
  'metrics.socialgrowth.interactions.text': 'INTERACCIONES',
  'metrics.socialgrowth.reach.text': 'ALCANCE',

  'metrics.mostActivePosts.title': 'Tus publicaciones con mayor alcance',
  'metrics.mostActivePosts.subtitle': 'Estas son tus mejores publicaciones, que tuvieron un buen alcance. Puedes convertirlas en un anuncio y llegar a muchas más personas.',

  'metrics.pagePerformance.title': 'Rendimiento de tus publicaciones',
  'metrics.pagePerformance.tab.reach': 'Alcance',
  'metrics.pagePerformance.tab.reactions': 'Reacciones',
  'metrics.pagePerformance.tab.comments': 'Comentarios',
  'metrics.pagePerformance.tab.shares': 'Compartidos',
  'metrics.pagePerformance.info.reach': 'personas',
  'metrics.pagePerformance.info.rections': 'reacciones',
  'metrics.pagePerformance.info.likes': 'likes',
  'metrics.pagePerformance.info.comments': 'comentarios',
  'metrics.pagePerformance.info.shares': 'compartidos',
  'metrics.pagePerformance.info.reachPaidFB': 'Anuncio Facebook',
  'metrics.pagePerformance.info.boost': 'Anuncio en',
  'metrics.postPerformance.title': 'Publicaciones realizadas',
  'metrics.postPerformance.subtitle': 'Aquí podrás ver la cantidad de publicaciones que has hecho en cada una de tus redes conectadas.',
  'metrics.postPerformance.advice': 'Busca los puntos más grandes, analiza por qué tuvieron mayor interacción y replícala.',
  'metrics.postPerformance.graph.tooltip.button': 'Ver publicación',
  'metrics.networkBetterInteractions.title': 'Tu red con mejor interacción',
  'metrics.bestPostMedia.title': 'Tu mejor tipo de publicación',

  'metrics.bestInteraction.title': 'Tu red con mejor interacción',
  'metrics.bestInteraction.text.interactions': 'Interacciones',

  'metrics.bestType.title': 'Tu mejor tipo de publicación',

  'metrics.likes.title': 'Likes',
  'metrics.likes.subtitle': 'Tu red con más likes',
  'metrics.comments.title': 'Comentarios',
  'metrics.comments.subtitle': 'Tu red con más comentarios',

  'metrics.bestPostMedia.image': 'Imagen',
  'metrics.bestPostMedia.video': 'Video',
  'metrics.bestPostMedia.album': 'Galería',

  'metric.tooltip.grown.socialNetwork.title': 'Debido a los lineamientos de las propias redes sociales, toma en cuenta lo siguiente:',
  'metric.tooltip.grown.socialNetwork.instagram': 'Sólo es posible considerar los datos de tu cuenta desde que fue convertida a cuenta de negocio.',
  'metric.tooltip.grown.socialNetwork.linkedin': 'Sólo se mostraran métricas si tu página conectada es de negocio.',
  'metric.tooltip.grown.socialNetwork.tiktok': 'Las Interacciones y Alcance sólo se pueden calcular a partir de las publicaciones hechas en Social Piper.',
};

export default es;
