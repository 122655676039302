import gql from 'graphql-tag';

export const getCities = gql`
  query cities ($name: String!, $language: LANGUAGE) {
    cities (name: $name, language: $language) {
      key
      name
      region
      regionId
      countryCode
      countryName
      type
      countries
    }
  }
`;

export const searchLinkedinLocation = gql`
  query searchLinkedinLocation($wordToSearch: String) {
    searchLinkedinLocation(wordToSearch: $wordToSearch) {
      id
      name
    }
  }
`;
