import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { IconButton } from '../../buttons';
import HoverMenuElement from './HoverMenuElement';
import colors from '../../../styled/colors';

const IconElement = styled.div`
  position: relative;
  cursor: pointer;
`;

export default function NavBarIconButton({
  element,
}) {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <IconElement
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <IconButton
        icon={element.icon}
        alt={element.title}
        target="_blank"
        rel="noreferrer"
        size="24px"
        to={element.to}
        href={element.href}
        hoverColor={colors.grayMediaLibrary}
      />
      <HoverMenuElement
        to={element.to}
        href={element.href}
        title={element.title}
        gaData={element.gaData}
        isIconHovering={isHovering}
      />
    </IconElement>
  );
}

NavBarIconButton.propTypes = {
  element: PropTypes.instanceOf(Object).isRequired,
};
