const en = {
  'verifyEmail.title': 'Verify your email!',
  'verifyEmail.verification.message': 'We have sent an email verification to <e>{email}</e> check your email and insert the code here.',
  'verifyEmail.verification.resend.text': 'If you didn\'t receive this email',
  'verifyEmail.verification.resend.button': 'Resend verification e-mail now',
  'verifyEmail.verification.resend.text.disabled': 'Check your email now!',
  'verifyEmail.support.text1': 'Didn\'t receive this email? Check your spam file. If you still don\'t receive it, <link>resend verification email here.</link> {lineBreak}Remember that this email may take a few minutes to arrive.',
  'verifyEmail.support.text2': 'if your email is wrong or misspelled please contact us at: <link>{emailSupport}</link> or use our live chat for help.',
  'verifyEmail.support.formLabel': 'VERIFICATION CODE:',
};

export default en;
