const es = {
  'proOfferModal.stage.one': 'Selecciona tu plan',
  'proOfferModal.stage.two': 'Detalles del pago',
  'proOfferModal.stage.three': 'Confirmación',
  'proOfferModal.paymentDetails.select': 'Selecciona tu método de pago',
  'proOfferModal.paymentDetails.one': 'Usaremos tu método de pago ya añadido',
  'proOfferModal.paymentDetails.addPayment': 'Añade método de pago',
  'proOfferModal.plan': 'Plan',
  'proOfferModal.paymentMethod': 'Método de pago',
  'proOfferModal.change.plan': 'Cambia de plan',
  'proOfferModal.change.paymentMethod': 'Cambia de método de pago',
  'proOfferModal.success.subtitle': '¡Felicidades! ¡Tu subscripción ha sido exitosa!',
  'proOfferModal.success.text': '¡Estamos felices de tenerte a bordo!',
  'proOfferModal.error.subtitle': 'No fue posible realizar el pago de tu suscripción.',
  'proOfferModal.error.text': 'Ahora puedes intentar alguna de estas opciones:',
  'proOfferModal.error.b1': 'Intentar nuevamente con tu mismo método de pago.',
  'proOfferModal.error.b2': 'Elige otro método de pago.',
  'proOfferModal.error.b3': 'Agregar un nuevo método de pago.',
  'proOfferModal.error.legend': '<bold>Si el problema persiste,</bold> escríbenos en el chat de esta página o contáctanos en <sp>support@socialpiper.com</sp>',
  'proOfferModal.error.loader.title': 'Procesando tu pago',
  'proOfferModal.error.loader.text': 'Esto puede tomar unos minutos, por favor <bold>no cierres la ventana.</bold>',
};

export default es;
