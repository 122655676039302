import constants from './constants/es';
import numbers from './numbers/es';
import warnings from './warnings/es';
import helpers from './helpers/es';
import languages from './languages/es';
import dynamicMessages from './dynamicMessages/es';
import alerts from './alerts/es';
import notifications from './notifications/es';

const es = {
  ...constants,
  ...numbers,
  ...warnings,
  ...helpers,
  ...languages,
  ...dynamicMessages,
  ...alerts,
  ...notifications,
};

export default es;
