import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { YellowCheck, ChevronLeftIcon } from '../../../assets/icons/iconRepository';
import GlobalTheme from '../../../styled/GlobalTheme';
import { lowSpreadShadow, paragraph } from '../../../styled/mixins';
import { colors } from '../../../styled/theme';

const PlanDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 3;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: white;
  ${lowSpreadShadow}
`;

const Header = styled.div`
  ${paragraph}
  font-weight: 700;
  font-size: 22px;
  width: 100%;
  display: flex;
  background: ${({ color }) => color};
  color: white;
  padding-top: 20px;
  ${({ recommended }) => recommended && css`
    position: relative;
    span{
      position: absolute;
      top: -10px;
      right: 32px;
      width: min(170px, 100%);
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      background: ${colors.secondary};
      border-radius: 20px;
      color: ${colors.black};
      ${GlobalTheme.breakpoints.down('sm')} {
        font-size: 12px;
        height: 25px;
        line-height: 25px;
        width: 120px;
      }
    }
  `}
`;

const Title = styled.div`
  text-align: center;
  flex: 0 0 240px;
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
`;

const BasicInfo = styled.div`
  flex: 0 0 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 30px 32px;
  width: 100%;
`;

const Features = styled.ul`
  list-style-image: url(${YellowCheck});
  width: 100%;
  color: ${colors.text02};
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 20px;
  font-size: 12px;
`;

const Price = styled.div`
  ${paragraph}
  margin: 24px 0;
  font-size: 20px;
  font-weight: 600;
  color: ${({ comingSoon }) => (comingSoon ? colors.gray90 : colors.black)};
`;

const ShowDetailsButton = styled.a`
  ${paragraph}
  cursor: pointer;
  font-size: 11px;
  display: flex;
  color: ${colors.grayMediaLibrary};
`;

const Details = styled.div`
  flex: 1;
  padding: 16px 16px 16px 0;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const DetailsDescription = styled.p`
  ${paragraph}
  color: ${colors.text02};
  margin-bottom: 12px;
  font-size: 14px;
  padding-right: 56px;
  width: 100%;
`;

const DetailsList = styled.ul`
  list-style: disc;
  width: 100%;
  color: ${colors.text02};
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 20px;
  font-size: 14px;
  padding-right: 56px;
  margin-bottom: 8px;
`;

const ChevronBox = styled.div`
  width: 13px;
  height: 8.5px;
  margin: 2px 3px 0 3px;
`;

const Chevron = styled.img`
  width: 100%;
  height: auto;
`;

const PlanDetails = ({
  title,
  features,
  price,
  detailsDescription,
  details,
  comingSoon,
  recommended,
  color,
  setShowDetails,
}) => {
  const intl = useIntl();
  const handleDetails = () => {
    setShowDetails('');
  };
  return (
    <PlanDetailsContainer
      comingSoon={comingSoon}
    >
      <Card>
        <Header
          recommended={recommended}
          color={color}
        >
          <Title>
            {title}
          </Title>
          {recommended && (
            <span>
              <FormattedMessage
                id="k.recommended"
                defaultMessage="Recommended"
              />
            </span>
          )}
        </Header>
        <CardContainer>
          <BasicInfo>
            <Features>
              { features.map((feature) => (<li>{ feature }</li>))}
            </Features>
            <Price
              comingSoon={comingSoon}
            >
              {comingSoon ? intl.formatMessage({
                id: 'k.comingSoon',
                defaultMessage: 'Coming soon',
              }) : price}
            </Price>
          </BasicInfo>
          <Details>
            <DetailsDescription>
              {detailsDescription}
            </DetailsDescription>
            <DetailsList>
              { details.map((detail) => (<li>{ detail }</li>))}
            </DetailsList>
            <ShowDetailsButton
              onClick={handleDetails}
              id="details"
            >
              <ChevronBox>
                <Chevron
                  src={ChevronLeftIcon}
                  alt="Chevron"
                />
              </ChevronBox>
              <FormattedMessage
                id="k.details.hide"
                defaultMessage="Hide details"
              />
            </ShowDetailsButton>
          </Details>
        </CardContainer>
      </Card>
    </PlanDetailsContainer>
  );
};

PlanDetails.propTypes = {
  title: PropTypes.string.isRequired,
  features: PropTypes.instanceOf(Array).isRequired,
  price: PropTypes.string.isRequired,
  detailsDescription: PropTypes.string.isRequired,
  details: PropTypes.instanceOf(Array).isRequired,
  comingSoon: PropTypes.bool,
  recommended: PropTypes.bool,
  color: PropTypes.string.isRequired,
  setShowDetails: PropTypes.func.isRequired,
};

PlanDetails.defaultProps = {
  comingSoon: false,
  recommended: false,
};

export default PlanDetails;
