import { format, startOfDay } from 'date-fns';
import { authAppSync } from '../../services/auth';
import { getCompromisedBudget, getAdCampaignsMetrics } from '../../graphql/queries';

export const deleteCachedObject = (object) => {
  const regex = new RegExp(`^\\$?${object}`);

  Object.keys(authAppSync.client.cache.data.data).forEach((key) => (
    key.match(regex) && authAppSync.client.cache.data.delete(key)
  ));
};

export const deleteCachedQuery = (query) => {
  const adCampaignsQueries = Object.keys(authAppSync.client.cache.data.data.ROOT_QUERY).filter(
    (k) => k.match(new RegExp(`^${query}`)),
  );

  for (let index = 0; index < adCampaignsQueries.length; index += 1) {
    const key = adCampaignsQueries[index];
    delete authAppSync.client.cache.data.data.ROOT_QUERY[key];
  }
};

/**
 * Updates the apollo cached compromisedBudget query
 * @param {float} adBudget new ad campaign's budget
 * @param {float} newMonthlyBudget new monthlyBudget budget
 */
export const updateCachedCompromisedBudget = ({ adBudget, newMonthlyBudget }) => {
  try {
    const date = new Date(format(new Date(), 'Y-MM-15'));
    const timezone = format(new Date(), 'XXX');
    const compromisedBudgetQuery = authAppSync.client.readQuery({
      query: getCompromisedBudget(),
      variables: {
        date,
        timezone,
      },
    });

    let {
      totalCompromisedBudget,
      totalRemaining,
      monthlyBudget,
    } = compromisedBudgetQuery.compromisedBudget;

    totalCompromisedBudget += (adBudget || 0);
    totalRemaining += (adBudget || 0);

    monthlyBudget = newMonthlyBudget || monthlyBudget;

    authAppSync.client.writeQuery({
      query: getCompromisedBudget(),
      variables: {
        date,
        timezone,
      },
      data: {
        compromisedBudget: {
          ...compromisedBudgetQuery.compromisedBudget,
          totalCompromisedBudget,
          totalRemaining,
          monthlyBudget,
        },
      },
    });
  } catch (err) {
    // getCompromisedBudget hasn't been called yet
  }
};

/**
 * Updates the apollo cached getAdCampaignsMetrics query
 * @param {int} sumPostedCount quantity of posts added
 * @param {int} sumBoostedCount quantity of boosts added
 */
export const updateCachedAdCampaignsMetrics = ({ sumPostedCount, sumBoostedCount }) => {
  try {
    const date = startOfDay(new Date());
    const timezone = format(new Date(), 'XXX');
    const getAdCampaignsMetricsQuery = authAppSync.client.readQuery({
      query: getAdCampaignsMetrics,
      variables: {
        date,
        timezone,
      },
    });

    let {
      postedCount,
      boostedCount,
    } = getAdCampaignsMetricsQuery.getAdCampaignsMetrics;

    postedCount += (sumPostedCount || 0);
    boostedCount += (sumBoostedCount || 0);

    authAppSync.client.writeQuery({
      query: getAdCampaignsMetrics,
      variables: {
        date,
        timezone,
      },
      data: {
        compromisedBudget: {
          ...getAdCampaignsMetricsQuery.getAdCampaignsMetrics,
          postedCount,
          boostedCount,
        },
      },
    });
  } catch (err) {
    // getAdCampaignsMetrics hasn't been called yet
  }
};
