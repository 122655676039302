import * as Sentry from '@sentry/browser';
import {
  ENV,
  ENVIRONMENTS,
} from '../../utils/constants/globals';
import {
  updateLinkedInPage,
  fetchLinkedInPageShares,
} from '../../graphql/mutations';
import { authAppSync } from '../auth';

export default class LinkedinProvider {
  static async updateSelectedPage(isPersonalPage, linkedinId, name, imageUrl) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      if (ENV !== ENVIRONMENTS.local) {
        const variables = isPersonalPage
          ? { isPage: false }
          : {
            isPage: true,
            linkedinId,
            name,
            imageUrl,
          };
        await authAppSync.client.mutate({
          mutation: updateLinkedInPage,
          variables,
        });
        if (!isPersonalPage) {
          await authAppSync.client.mutate({
            mutation: fetchLinkedInPageShares,
          });
        }
      }
      result.success = true;
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
