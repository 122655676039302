import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';
import styled, { css } from 'styled-components';
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  StarIcon,
} from '../../../assets/icons/iconRepository';
import GlobalTheme from '../../../styled/GlobalTheme';
import { lowSpreadShadow, paragraph } from '../../../styled/mixins';
import { colors } from '../../../styled/theme';
import mediaQueries from '../../../utils/mediaQueries';
import useGetCurrencySymbol from '../../../hooks/useGetCurrencySymbol';
import { PLAN_LITE, PLAN_PRO, PLAN_BUSINESS } from '../../../utils/constants/globals';

const PlanOptionContainer = styled.div`
  margin-right: 16px;
  flex: 1 1 0;
  cursor: ${({ comingSoon }) => (comingSoon ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${({ selected }) => (selected ? '2px solid #00BF62;' : '0')};
  border-radius: ${({ selected }) => (selected ? '20px;' : '0')};
  padding: 12px 5px;
  ${GlobalTheme.breakpoints.down('sm')} {
    flex: 1;
    width: 100%;
    margin: 0 0 16px;
  }
  &:last-child {
    margin: 0;
  }
  @media (max-width: 320px)   {
    width: 85%;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: white;
  border-radius: 6px;
  ${lowSpreadShadow}
  opacity: ${({ selected }) => (selected ? '1' : '0.4')};
  ${({ recommended, theme, isBusinness }) => recommended && !isBusinness && css`
    border: 8px solid ${theme.colors.secondary};
    border-right: 0;
    border-left: 0;
    box-shadow: none;
  `};
`;

const Header = styled.div`
  ${paragraph}
  width: 100%;
  background: ${({ color }) => color};
  color: white;
  border-radius: 6px 6px 0 0;
  height: 140px;
  padding: 12px 4px 0;
  ${GlobalTheme.breakpoints.down('sm')} {
    text-align: left;
    padding-bottom: 8px;
    height: auto;
  }
  ${({ recommended }) => recommended && css`
    position: relative;
    border-radius: 0;
    span{
      position: absolute;
      top: -18px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: min(170px, 100%);
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      background: ${colors.secondary};
      border-radius: 20px;
      color: ${colors.black};
      &::before,
      &::after {
        content: url(${StarIcon});
        display: inline-block;
        transform: scale(0.8);
      }
      &::before {
        margin-right: 4px;
      }
      &::after {
        margin-left: 4px;
      }
      ${GlobalTheme.breakpoints.down('sm')} {
        left: 0;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
        width: 120px;
      }
    }
  `}
`;

const CardTitle = styled.h3`
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  line-height: 26px;
`;

const CardSubTitle = styled.p`
  font-size: 12px;
  text-align: center;
  margin-bottom: 0;
  line-height: 16px;
  ${GlobalTheme.breakpoints.down('xs')} {
    color: ${({ color }) => color};
    text-align: left;
    width: 172px;
    margin-left: 16px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8px 19px;
  width: 100%;
  ${GlobalTheme.breakpoints.down('xs')} {
    padding-bottom: 50px;
  }
`;

const Features = styled.ul`
  width: 100%;
  color: ${colors.text02};
  font-size: 11px;
  padding: 0;
  margin: 0;
  height: 125px;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.grayE9};
  padding: 3px 0;
  &:nth-last-child(1) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayE9};
  }
  &::before {
    content: '';
    background: ${({ theme }) => theme.colors.chartGray};
    border-radius: 50%;
    width: 5px;
    height: 5px;
    margin-top: 5px;
    margin-right: 5px;
  }
  ${GlobalTheme.breakpoints.down('xs')} {
    &::before {
      width: 5px;
      height: 5px;
      margin-right: 5px;
    }
  }
`;

const MoreText = styled.span`
  color: ${({ color }) => color};
  font-size: 12px;
  margin: 12px 0;
`;

const Price = styled.div`
  ${paragraph}
  font-weight: 600;
  width: 100%;
  text-align: center;
  margin: ${({ isBasic }) => (isBasic ? '13px' : '8px')} 0;
  ${GlobalTheme.breakpoints.down('sm')} {
    display: flex;
    align-items: center;
  }
`;

const PriceContent = styled.div`
  line-height: 1;
`;
const PriceText = styled.span`
  color: ${({ color }) => color};
  font-size: 30px;
  ${({ isComingSoon, theme }) => (isComingSoon ? `color: ${theme.colors.grayE9};` : '')}
  &::before {
    content: '${({ symbol }) => symbol}';
  }
  &::after {
    content: '${({ cur }) => cur}';
    font-size: 12px;
    margin-left: 8px;
  }
  ${GlobalTheme.breakpoints.down('xs')} {
    font-size: 40px;
    &::before,
    &::after {
      font-size: 20px;
    }
  }
`;
const PriceDescription = styled.div`
  color: ${({ theme }) => theme.colors.gray02};
  font-size: 10px;
  text-align: center;
`;

const ShowDetailsButton = styled.a`
  ${paragraph}
  cursor: pointer;
  font-size: 11px;
  color: ${({ color }) => color};
  display: flex;
  margin-top: 8px;
  text-decoration: underline;
  ${GlobalTheme.breakpoints.down('xs')} {
    display: none;
  }
`;

const SelectThisPlan = styled.div`
  padding-left: 26px;
  position: relative;
  color: ${({ isBusinness }) => (isBusinness ? colors.gray90 : colors.black)};
  pointer-events: ${({ isBusinness }) => (isBusinness ? 'none' : 'all')};
  cursor: ${({ isBusinness }) => (isBusinness ? 'not-allowed' : 'pointer')};
  margin-top: ${({ isPro }) => (isPro ? '16px' : '24px')};
  font-size: 16px;
  font-weight: 600;
  ${GlobalTheme.breakpoints.down('sm')} {
    margin: 8px 0 0;
  }
  &:before {
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    left: 0;
    top: 0;
    bottom: 0;
    margin: 1px 0;
    border: ${({ isBusinness }) => (isBusinness ? '0px' : '1px')} solid ${({ comingSoon }) => (comingSoon ? colors.gray90 : colors.primary)};
    border-radius: 100%;
  }
  ${({ selected }) => selected && css`
    &:after {
      content: '';
      position: absolute;
      height: 8px;
      width: 8px;
      left: 5px;
      top: 0;
      bottom: 0;
      margin: 6px 0;
      background: ${colors.primary};
      border-radius: 100%;
    }
  `}
`;

const Details = styled.div`
  padding: 16px 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const DetailsDescription = styled.p`
  ${paragraph}
  color: ${colors.text02};
  margin-bottom: 12px;
  font-size: 14px;
  padding-right: 56px;
  width: 100%;
`;

const DetailsList = styled.ul`
  list-style: disc;
  width: 100%;
  color: ${colors.text02};
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 20px;
  font-size: 14px;
`;

const ActualPlan = styled.span`
  font-size: 12px;
  color: ${colors.green};
`;

const PlanSelection = styled.div`
  ${GlobalTheme.breakpoints.down('xs')} {
    position: relative;
    top: ${({ isModal }) => (isModal ? '-57px' : '-67px')};
    line-height: 1;

  }
`;

const TrialDaysHolder = styled.div`
  color: ${colors.lightBlue};
  margin-top: 6px;
  font-size: 20px;
`;

const PlanOption = ({
  title,
  description,
  features,
  price,
  isMonthly,
  planId,
  comingSoon,
  recommended,
  details,
  detailsDescription,
  selected,
  onClick,
  color,
  setShowDetails,
  hasSelector,
  isModal,
  currencyCode,
  trialDays,
}) => {
  const [showHiddenDetails, setShowHiddenDetails] = useState(false);
  const currencySymbol = useGetCurrencySymbol(currencyCode);
  const handleDetails = () => {
    if (window.innerWidth < GlobalTheme.breakpoints.values.sm) {
      setShowHiddenDetails(!showHiddenDetails);
    } else {
      setShowDetails(planId);
    }
  };
  const handleClick = (e) => {
    const { id } = e.target;
    if (id !== 'details') {
      onClick();
    }
  };

  return (
    <Media queries={mediaQueries}>
      {(matches) => {
        // eslint-disable-next-line no-unused-vars
        let chevron = '';
        if (matches.smDown) {
          chevron = showHiddenDetails ? ChevronUpIcon : ChevronDownIcon;
        } else {
          chevron = showHiddenDetails ? ChevronLeftIcon : ChevronRightIcon;
        }
        return (
          <PlanOptionContainer
            onClick={handleClick}
            comingSoon={comingSoon}
            selected={selected}
          >
            <Card
              recommended={recommended}
              selected={(planId === PLAN_PRO || planId === PLAN_BUSINESS)}
              isBusinness={(planId === PLAN_BUSINESS)}
            >
              <Header
                recommended={recommended}
                color={color}
              >
                <CardTitle>
                  {title}
                </CardTitle>
                {!matches.smDown ? (
                  <CardSubTitle>
                    {description}
                  </CardSubTitle>
                ) : null}
                {recommended && planId !== PLAN_BUSINESS && (
                  <span>
                    <FormattedMessage
                      id="k.popular"
                      defaultMessage="Popular"
                    />
                  </span>
                )}
              </Header>
              <CardContainer
                selected={selected}
                recommended={recommended}
              >
                <Price isBasic={(planId === PLAN_LITE)}>
                  <PriceContent>
                    <PriceText
                      color={color}
                      isComingSoon={comingSoon}
                      cur={currencyCode}
                      symbol={currencySymbol}
                    >
                      {price}
                    </PriceText>
                    <PriceDescription>
                      {price === 0 && (
                        null
                      )}
                      {(price > 0 && !comingSoon) && (
                        <FormattedMessage
                          id={isMonthly
                            ? 'k.price.billed.month'
                            : 'k.price.billed.annually'}
                          defaultMessage={isMonthly
                            ? 'Billed monthly'
                            : 'Billed annually'}
                          values={{
                            price,
                          }}
                        />
                      )}
                      {(comingSoon) && (
                        <FormattedMessage
                          id="k.comingSoon"
                          defaultMessage="Coming soon"
                        />
                      )}
                      {(isModal && (trialDays > 0)) && (
                        <TrialDaysHolder isModal={isModal}>
                          <FormattedMessage
                            id="planOption.selectThisPlanTrial"
                            defaultMessage="Free {trialDays} days trial"
                            values={{ trialDays }}
                          />
                        </TrialDaysHolder>
                      )}
                    </PriceDescription>
                  </PriceContent>
                  {matches.smDown && (
                    <CardSubTitle color={color}>
                      {description}
                    </CardSubTitle>
                  )}
                </Price>
                <Features>
                  {features.map((feature) => (<FeatureItem>{feature}</FeatureItem>))}
                </Features>
                {(planId === PLAN_PRO && matches.smDown) && (
                  <MoreText color={color}>
                    <FormattedMessage
                      id="k.andmore"
                      defaultMessage="and more"
                    />
                  </MoreText>
                )}
                <ShowDetailsButton
                  onClick={handleDetails}
                  id="details"
                  showHiddenDetails={showHiddenDetails}
                  color={color}
                >
                  <FormattedMessage
                    id="k.moreFeatures"
                    defaultMessage="See more features"
                  />
                </ShowDetailsButton>
                {showHiddenDetails && (
                  <Details>
                    <DetailsDescription>
                      {detailsDescription}
                    </DetailsDescription>
                    <DetailsList>
                      {details.map((detail) => (<li>{detail}</li>))}
                    </DetailsList>
                  </Details>
                )}
              </CardContainer>
            </Card>
            {(hasSelector) && (
              <>
                <PlanSelection
                  isModal={isModal}
                >
                  <SelectThisPlan
                    selected={selected}
                    comingSoon={comingSoon}
                    isPro={(planId === PLAN_PRO)}
                    isBusinness={(planId === PLAN_BUSINESS)}
                  >
                    <FormattedMessage
                      // eslint-disable-next-line no-nested-ternary
                      id={planId === PLAN_BUSINESS
                        ? 'k.comingSoon'
                        : (selected) ? 'planOption.selectedPlan' : 'planOption.selectThisPlan'}
                      // eslint-disable-next-line no-nested-ternary
                      defaultMessage={planId === PLAN_BUSINESS
                        ? 'k.comingSoon'
                        : (selected) ? 'planOption.selectedPlan' : 'planOption.selectThisPlan'}
                    />
                    {(trialDays > 0) && (
                      <TrialDaysHolder>
                        <FormattedMessage
                          id="planOption.selectThisPlanTrial"
                          defaultMessage="Free {trialDays} days trial"
                          values={{ trialDays }}
                        />
                      </TrialDaysHolder>
                    )}
                  </SelectThisPlan>
                  {isModal && (
                    <ActualPlan>
                      <FormattedMessage
                        id="planOption.actualPlan"
                        defaultMessage="You are already on this plan"
                      />
                    </ActualPlan>
                  )}
                </PlanSelection>
              </>
            )}
          </PlanOptionContainer>
        );
      }}
    </Media>
  );
};

PlanOption.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  features: PropTypes.instanceOf(Array).isRequired,
  price: PropTypes.string.isRequired,
  isMonthly: PropTypes.bool.isRequired,
  planId: PropTypes.string.isRequired,
  comingSoon: PropTypes.bool,
  recommended: PropTypes.bool,
  details: PropTypes.instanceOf(Array).isRequired,
  detailsDescription: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  setShowDetails: PropTypes.func.isRequired,
  hasSelector: PropTypes.bool,
  isModal: PropTypes.bool,
  currencyCode: PropTypes.string,
  trialDays: PropTypes.number,
};

PlanOption.defaultProps = {
  comingSoon: false,
  recommended: false,
  selected: false,
  hasSelector: false,
  isModal: false,
  currencyCode: 'USD',
  trialDays: false,
};

export default PlanOption;
