/* eslint-disable react/jsx-props-no-spreading */
import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import errorsDictionary from '../i18n/translations/backend/errors/en';
import GlobalTheme from '../styled/GlobalTheme';
import { DEFAULT_DURATION, SEVERITY, VARIANT } from '../utils/constants/alerts';

export const AlertsContext = React.createContext(null);

const StyledSnackbar = styled(Snackbar)`
  && {
    top: 0;
    width: 100%;
    border-radius: 0;
    ${GlobalTheme.breakpoints.down('xs')} {
      left: 0;
      right: 0;
    }
  }
`;

const StyledAlert = styled(Alert)`
  && {
    width: 100%;
    border-radius: 0;
    .MuiAlert-message {
      width: 100%;
      text-align: center;
      font-size: 20px;

      ${GlobalTheme.breakpoints.down('sm')} {
        padding-top: 30px;
      }
    }
  }
`;

const Redirect = styled.a`
  color: #5d310b;
  text-decoration: underline;
`;

const AlertsProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    open: false,
    content: null,
    severity: SEVERITY.SUCCESS,
    variant: VARIANT.FILLED,
    duration: DEFAULT_DURATION,
    hasClose: true,
    title: null,
    action: null,
    icon: false,
    redirectLabel: '',
    redirectURL: '',
  });

  const showAlert = (
    content,
    severity = SEVERITY.SUCCESS,
    duration = DEFAULT_DURATION,
    hasClose = true,
    variant = VARIANT.FILLED,
    title = null,
    action = null,
    icon = false,
    redirectLabel = '',
    redirectURL = '',
  ) => {
    setAlert({
      open: true,
      content,
      severity,
      variant,
      duration,
      hasClose,
      title,
      action,
      icon,
      redirectLabel,
      redirectURL,
    });
  };

  const history = useHistory();

  const hideAlert = () => {
    setAlert((a) => ({
      ...a,
      open: false,
    }));
  };

  const showError = (content) => {
    showAlert(content, SEVERITY.ERROR);
  };

  const showBackendError = (error) => {
    const id = error.replace(/GraphQL error:?\s?/, '');
    showAlert(
      <FormattedMessage id={id} defaultMessage={errorsDictionary[id]} />,
      SEVERITY.ERROR,
    );
  };

  const handleClose = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  return (
    <AlertsContext.Provider
      value={{
        showAlert,
        showError,
        showBackendError,
        hideAlert,
      }}
    >
      <StyledSnackbar
        open={alert.open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={alert.duration}
        onClose={alert.hasClose ? handleClose : () => {}}
      >
        <StyledAlert
          severity={alert.severity}
          variant={alert.variant}
          icon={alert.icon}
          action={
            alert.action
            || (alert.hasClose && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            ))
          }
        >
          {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
          {alert.content}
          {alert.redirectLabel && (
            <Redirect href={alert.redirectURL}>{alert.redirectLabel}</Redirect>
          )}
        </StyledAlert>
      </StyledSnackbar>
      {children}
    </AlertsContext.Provider>
  );
};

AlertsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AlertsProvider;
