const en = {
  'metrics.hero.title': 'Metrics',
  'metrics.k.interactions': 'interactions',

  'metrics.hero.options.interval.week': 'This Week',
  'metrics.hero.options.interval.7.days': 'Last 7 days',
  'metrics.hero.options.interval.14.days': 'Last 14 days',
  'metrics.hero.options.interval.30.days': 'Last 30 days',
  'metrics.hero.options.interval.60.days': 'Last 60 days',
  'metrics.hero.infoDisabledRange': 'Some selections will become available over time since your socials were connected.',

  'metrics.connectAccounts.noAccountsConnected': 'Conecta al menos una red',
  'metrics.connectAccounts.accountsConnected': 'You can see more metrics if you connect more social networks',

  'metrics.data.empty': 'There is no information on this period yet.',

  'metrics.socialConnectButton': 'Connect {socialNetwork}',
  'metrics.piperScore.title': 'Your Piper Score',
  'metrics.piperScore.advice': 'The best is to have a score of more than 60. You can do it!',
  'metrics.piperScore.score.text': 'Score Recommended',

  'metrics.socialGrowth.title': 'How you\'ve grown.',
  'metrics.socialGrowth.subtitle': 'This is how your socials have grown compared to the selected period.',
  'metrics.socialgrowth.followers.text': 'FOLLOWERS',
  'metrics.socialgrowth.interactions.text': 'INTERACTIONS',
  'metrics.socialgrowth.reach.text': 'REACH',

  'metrics.mostActivePosts.title': 'Your post with the greatest reach.',
  'metrics.mostActivePosts.subtitle': 'These are your best posts which had a good reach. You can boost them and try to reach many more people.',

  'metrics.pagePerformance.title': 'Your posts performance',
  'metrics.pagePerformance.tab.reach': 'Reach',
  'metrics.pagePerformance.tab.reactions': 'Reactions',
  'metrics.pagePerformance.tab.comments': 'Comments',
  'metrics.pagePerformance.tab.shares': 'Shares',
  'metrics.pagePerformance.info.reach': 'people',
  'metrics.pagePerformance.info.rections': 'reactions',
  'metrics.pagePerformance.info.likes': 'likes',
  'metrics.pagePerformance.info.comments': 'comments',
  'metrics.pagePerformance.info.shares': 'shares',
  'metrics.pagePerformance.info.reachPaidFB': 'Ad on Facebook.',
  'metrics.pagePerformance.info.boost': 'Boost',
  'metrics.postPerformance.title': 'Past posts.',
  'metrics.postPerformance.subtitle': 'Here you can see the number of past posts on each of your connected socials.',
  'metrics.postPerformance.advice': 'Check out the biggest circles in the chart; the biggest the better.',
  'metrics.postPerformance.graph.tooltip.button': 'See post',
  'metrics.networkBetterInteractions.title': 'Your social network with the best interaction.',
  'metrics.bestPostMedia.title': 'Your best post type.',

  'metrics.bestInteraction.title': 'Your social network with the best interaction.',
  'metrics.bestInteraction.text.interactions': 'Interactions',

  'metrics.bestType.title': 'Your best post type',

  'metrics.likes.title': 'Likes',
  'metrics.likes.subtitle': 'Your social with the most likes',
  'metrics.comments.title': 'Comments',
  'metrics.comments.subtitle': 'Your social with the most comments.',

  'metrics.bestPostMedia.image': 'Image',
  'metrics.bestPostMedia.video': 'Video',
  'metrics.bestPostMedia.album': 'Gallery',

  'metric.tooltip.grown.socialNetwork.title': 'Due to the guidelines of the social networks themselves, take into account the following:',
  'metric.tooltip.grown.socialNetwork.instagram': 'It is only possible to consider the data of your account since it was converted to a business account.',
  'metric.tooltip.grown.socialNetwork.linkedin': 'Metrics will only be displayed if your connected page is a business page.',
  'metric.tooltip.grown.socialNetwork.tiktok': 'Interactions and Reach can only be calculated from posts made on Social Piper.',
};

export default en;
