import * as Sentry from '@sentry/browser';
import { authAppSync } from '../auth';
import { comments as query } from '../../graphql/queries';
import {
  replyFacebookComment,
  deleteFacebookComment,
  replyInstagramComment,
  deleteInstagramComment,
  replyTweet,
  deleteTweetReply,
  markAsRead,
  likeFBComment,
  likeTComment,
  replyLinkedinComment,
  deleteLinkedinReply,
  likeLinkedinComment,
  markAsReadBySocialNetwork,
} from '../../graphql/mutations';
import { commentsMock, replyCommentsMock, deleteFacebookCommentMock } from '../../mocks/comments';
import { ENVIRONMENTS, ENV } from '../../utils/constants/globals';
import { SOCIAL_MEDIA } from '../../views/Community/PostComments/constants';
import { ProfilePlaceholder } from '../../assets/icons/iconRepository';

export default class CommentProvider {
  static async fetchBy(socialMediaContentId, pagination, orderBy = null) {
    let result;
    let data;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        result = await authAppSync.client.query({
          query,
          fetchPolicy: 'network-only',
          variables: {
            socialMediaContentId,
            pagination,
            orderBy,
          },
        });
        data = result;
      } else {
        data = commentsMock;
      }
      result = data.data.comments;
      result = result.map((msg) => ({
        ...msg,
        avatar: msg.avatar || ProfilePlaceholder,
      }));
      result = {
        message: 'success',
        success: true,
        data: result,
      };
    } catch (err) {
      Sentry.captureException(err);
      result = {
        message: err.message,
        success: false,
        data: null,
      };
    }
    return result;
  }

  static async replyBy(socialNetwork, input) {
    let mutation;
    let mutationName;
    let result;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        switch (socialNetwork) {
          case SOCIAL_MEDIA.facebook:
            mutation = replyFacebookComment;
            mutationName = 'replyFacebookComment';
            break;
          case SOCIAL_MEDIA.instagram:
            mutation = replyInstagramComment;
            mutationName = 'replyInstagramComment';
            break;
          case SOCIAL_MEDIA.twitter:
            mutation = replyTweet;
            mutationName = 'replyTweet';
            break;
          case SOCIAL_MEDIA.linkedin:
            mutation = replyLinkedinComment;
            mutationName = 'replyLinkedinComment';
            break;
          default:
            break;
        }
        result = await authAppSync.client.mutate({
          mutation,
          variables: {
            input,
          },
        });
      } else {
        mutationName = 'replyFacebookComment';
        result = replyCommentsMock;
      }
      result = result.data[mutationName];
      result = {
        message: 'success',
        success: true,
        data: result,
      };
    } catch (err) {
      Sentry.captureException(err);
      result = {
        message: err.message,
        success: false,
        data: null,
      };
    }
    return result;
  }

  static async deleteBy(socialNetwork, commentId) {
    let mutation;
    let mutationName;
    let result;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        switch (socialNetwork) {
          case SOCIAL_MEDIA.facebook:
            mutation = deleteFacebookComment;
            mutationName = 'deleteFacebookComment';
            break;
          case SOCIAL_MEDIA.instagram:
            mutation = deleteInstagramComment;
            mutationName = 'deleteInstagramComment';
            break;
          case SOCIAL_MEDIA.twitter:
            mutation = deleteTweetReply;
            mutationName = 'deleteTweetReply';
            break;
          case SOCIAL_MEDIA.linkedin:
            mutation = deleteLinkedinReply;
            mutationName = 'deleteLinkedinReply';
            break;
          default:
            break;
        }
        result = await authAppSync.client.mutate({
          mutation,
          variables: {
            commentId,
          },
        });
      } else {
        mutationName = 'deleteFacebookComment';
        result = deleteFacebookCommentMock;
      }
      result = result.data[mutationName];
      result = {
        message: 'success',
        success: true,
        data: result,
      };
    } catch (err) {
      Sentry.captureException(err);
      result = {
        message: err.message,
        success: false,
        data: null,
      };
    }
    return result;
  }

  static async markAsRead(id) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        data = await authAppSync.client.mutate({
          mutation: markAsRead,
          fetchPolicy: 'no-cache',
          variables: {
            ids: id,
            type: 'comment',
          },
        });
      } else {
        data = { data: { markAsRead: { success: true } } };
      }
      result = {
        message: 'success',
        success: true,
        data: data.data.markAsRead.success,
        ids: id,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async markAsReadBySocialNetwork(socialNetwork) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        data = await authAppSync.client.mutate({
          mutation: markAsReadBySocialNetwork,
          fetchPolicy: 'no-cache',
          variables: {
            socialNetwork,
          },
        });
      } else {
        data = { data: { markAsReadAll: { success: true } } };
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async likeComment(id, socialNetwork) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let data;
      let likeQuery;
      switch (socialNetwork) {
        case SOCIAL_MEDIA.facebook:
          likeQuery = likeFBComment;
          break;
        case SOCIAL_MEDIA.twitter:
          likeQuery = likeTComment;
          break;
        case SOCIAL_MEDIA.linkedin:
          likeQuery = likeLinkedinComment;
          break;
        default:
          break;
      }
      if (ENV !== ENVIRONMENTS.local) {
        if (socialNetwork === SOCIAL_MEDIA.instagram) {
          result.message = 'Invalid Social Network';
          return result;
        }
        data = await authAppSync.client.mutate({
          mutation: likeQuery,
          fetchPolicy: 'no-cache',
          variables: {
            commentId: id,
          },
        });
      } else {
        data = { data: { likeFBComment: true } };
      }
      result = {
        message: 'success',
        success: true,
        data: data.data.likeFBComment,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
