import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import AppSync from './AppSyncConfig';

export default class AuthAppSync {
  init(token) {
    this.client = new AWSAppSyncClient({
      url: AppSync.graphqlEndpoint,
      region: AppSync.region,
      auth: {
        type: AUTH_TYPE.OPENID_CONNECT,
        jwtToken: () => token,
      },
      disableOffline: true,
    });
  }
}
