/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AnalyticsLink from './AnalyticsLink';
import { icon as iconMixin } from '../../styled/mixins';
import AnalyticsAnchor from './AnalyticsAnchor';

const CustomLink = styled(AnalyticsLink)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomAnchor = styled(AnalyticsAnchor)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.div`
  ${iconMixin}
`;

const Button = styled.button`
  text-rendering: auto;
  color: ${({ theme }) => theme.colors.black};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  background-color: transparent;
  font-family: ${({ theme }) => theme.font.font1};
  padding: ${({ theme }) => theme.space};
  border: none;
  border-radius: 2px;
  outline: none;
  &:hover ${Icon} {
    background: ${({ theme, hoverColor }) => hoverColor || theme.colors.gray02};
  }
`;

const renderIconButton = ({
  to,
  href,
  gaData,
  navLink,
  hoverColor,
  size,
  icon,
  color,
  onClick,
  target,
  rel,
  className,
  disabled,
  ...props
}) => {
  if (to) {
    return (
      <CustomLink onClick={onClick} to={to} gaData={gaData} navLink={navLink}>
        <Button disabled={disabled} gaData={gaData} className={className} type="button" hoverColor={hoverColor} {...props}>
          <Icon size={size} icon={icon} color={color} />
        </Button>
      </CustomLink>
    );
  }

  if (href) {
    return (
      <CustomAnchor target={target} rel={rel} onClick={onClick} href={href} gaData={gaData} navLink={navLink}>
        <Button disabled={disabled} gaData={gaData} className={className} type="button" hoverColor={hoverColor} {...props}>
          <Icon size={size} icon={icon} color={color} />
        </Button>
      </CustomAnchor>
    );
  }

  return (
    <Button gaData={gaData} onClick={onClick} disabled={disabled} className={className} type="button" hoverColor={hoverColor} {...props}>
      <Icon size={size} icon={icon} color={color} />
    </Button>
  );
};

const IconButton = (props) => {
  return renderIconButton(props);
};

IconButton.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  gaData: PropTypes.instanceOf(Object),
  navLink: PropTypes.bool,
  hoverColor: PropTypes.string,
  disabled: PropTypes.disabled,
};

IconButton.defaultProps = {
  icon: '',
  color: '',
  to: false,
  className: '',
  size: '',
  onClick: () => {},
  gaData: {},
  navLink: false,
  hoverColor: null,
  disabled: false,
};
export default IconButton;
