/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import mediaQueries from '../../../utils/mediaQueries';

const Container = styled.div``;
const TestimonialWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${({ theme }) => theme.colors.white};
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  align-items: center;
  color:${({ theme }) => theme.colors.grayMediaLibrary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  touch-action: none;
`;

const Quote = styled.p`
  color: ${({ theme }) => theme.colors.blue02};
  position: relative;
  font-size: 19px;
  line-height: 26px;
  width: 100%;
  font-weight: normal;
  text-align: left;
  box-sizing: border-box;
  margin: 0;
  transition-property: transform,opacity;
  transition-duration: .3s;
  transition-delay: 0;
  transition-timing-function: ease-in-out;
  &::before,
  &::after {
    content: '"';
  }
  @media ${mediaQueries.lgPlusUp} {
    font-size: 18px;
    line-height: 25px;
  }
`;

const Author = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  padding:0;
  text-align: left;
  transition-property: transform,opacity;
  transition-duration: .3s;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  margin-top: 18px;
`;
const AvatarWrapper = styled.div`
  height: 70px;
  width: 70px;
  @media ${mediaQueries.lgPlusUp} {
    height: 82px;
    width: 82px;
  }
`;
const Avatar = styled.img`
  width: 100%;
`;
const AuthorInfo = styled.div`
  margin-left: 16px;
  @media ${mediaQueries.lgPlusUp} {
    display: flex;
  }
`;
const AuthorName = styled.p`
  font-size: 16px;
  font-weight: normal;
  line-height: 40px;
  margin: 0;
`;
const AuthorCompany = styled.p`
  font-size: 16px;
  font-weight: normal;
  line-height: 25px;
  text-transform: uppercase;
  position: relative;
  top: -8px;
  margin: 0;
  @media ${mediaQueries.lgPlusUp} {
    line-height: 40px;
    top: 0;
    margin-left: 4px;
  }
`;
export default function Testimonial({ testimonial }) {
  return (
    <Container rightQuote>
      <TestimonialWrapper>
        <TextContainer>
          <Quote>
            <FormattedMessage
              id={testimonial.quoteId}
              defaultMessage={testimonial.quote}
            />
          </Quote>
          <Author>
            <AvatarWrapper>
              <Avatar src={testimonial.avatar} />
            </AvatarWrapper>
            <AuthorInfo>
              <AuthorName>{testimonial.author},</AuthorName>
              <AuthorCompany>{testimonial.company}</AuthorCompany>
            </AuthorInfo>
          </Author>
        </TextContainer>
      </TestimonialWrapper>
    </Container>
  );
}

Testimonial.propTypes = {
  testimonial: PropTypes.shape({
    author: PropTypes.string,
    authorId: PropTypes.string,
    company: PropTypes.string,
    color: PropTypes.string,
    textColor: PropTypes.string,
    avatar: PropTypes.string,
    quote: PropTypes.string,
    quoteId: PropTypes.string,
    purpose: PropTypes.string,
    purposeId: PropTypes.string,
  }),
};

Testimonial.defaultProps = {
  testimonial: {
    author: '',
    authorId: '',
    color: '',
    company: '',
    textColor: '',
    avatar: '',
    quote: '',
    quoteId: '',
    purpose: '',
    purposeId: '',
  },
};
