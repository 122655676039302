import React, { useContext, useEffect, useState } from 'react';
import RoleContext from '../contexts/RolesContext';

const useCanAccess = (roles = []) => {
  const { role } = useContext(RoleContext);
  const [canAccess, setCanAccess] = useState(false);
  useEffect(() => {
    setCanAccess((roles.indexOf(role)) !== -1);
  }, [role]);
  return canAccess;
};

export default useCanAccess;
