import gql from 'graphql-tag';

export const updateLinkedinAdCampaign = gql`
  mutation updateLinkedinAdCampaign($boostingUpdateSettings: BoostingUpdateSettings!) {
    updateLinkedinAdCampaign( boostingUpdateSettings: $boostingUpdateSettings){
      id
      startDateTime
      endDateTime
    }
  }
`;

export const updateLinkedinAdCampaignStatus = gql`
  mutation updateLinkedinAdCampaignStatus($newAdCampaignStatus: NewAdCampaignStatus!) {
    updateLinkedinAdCampaignStatus( newAdCampaignStatus: $newAdCampaignStatus){
      id
      campaignStatus
      effectiveStatus
    }
  }
`;

export const createLinkedinAdCampaign = gql`
  mutation createLinkedinAdCampaign($boostingExistingPost: BoostingExistingPost!) {
    createLinkedinAdCampaign(boostingExistingPost: $boostingExistingPost){
      id
      isExpired
      startDateTime
      endDateTime
    }
  }
`;
