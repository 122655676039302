// eslint-disable-next-line
import ROUTES from '../../../utils/constants/routes';
export const POSTFLOW_ROUTES = {
  select: '/postflow',
  create: '/postflow/create',
  schedule: '/postflow/schedule',
  editor: '/postflow/editor',
  defaultCancelRoute: ROUTES.DASHBOARD,
  endRoute: ROUTES.SCHEDULER,
};
export const POSTFLOW_ROUTES_FOR_ROUTER = {
  select: '',
  create: '/create',
  schedule: '/schedule',
  editor: '/editor',
};

export const CAPTION_MAX_LENGTH = {
  twitter: 280,
  facebook: 1000,
};

export const EDITOR_MAX_UPLOAD_SIZE = 3;// max upload size for Editor, on MB
export const MINI_MEDIA_LIBRARY_TABS = [
  {
    title: 'Piper Recommends',
    id: 'templates.piper.title',
    defaultMessage: 'Piper Recommends',
    isSocialpiperRec: true,
    isTemplates: false,
  },
  {
    title: 'Let Piper create an image for you',
    id: 'postflow.minilibrary.tab4',
    defaultMessage: 'Let Piper create an image for you',
    isSocialpiperRec: false,
    isTemplates: false,
  },
  {
    title: 'Templates',
    id: 'postflow.minilibrary.tab1',
    defaultMessage: 'Templates',
    isSocialpiperRec: false,
    isTemplates: true,
  },
  {
    title: 'My creations',
    id: 'postflow.minilibrary.tab2',
    defaultMessage: 'My creations',
    isSocialpiperRec: false,
    isTemplates: false,
  },
  {
    title: 'Photos',
    id: 'postflow.minilibrary.tab3',
    defaultMessage: 'Photos',
    isSocialpiperRec: false,
    isTemplates: false,
  },
];

export const ANGELES_TAB = {
  title: 'Grupo Angeles',
  id: 'postflow.minilibrary.tab5',
  defaultMessage: 'Hospital Angeles',
  isSocialpiperRec: false,
  isTemplates: false,
};

export const TEMPLATES_SOURCE = 'templates';
