const es = {
  "scheduler.activity.label": "Actividad de tu público",
  "scheduler.activity.less": "Menos actividad",
  "scheduler.activity.more": "Más actividad",
  "scheduler.advancedDrawer.content.competitors":
    "Publicaciones de competidores",
  "scheduler.advancedDrawer.content.past": "Publicaciones pasadas",
  "scheduler.advancedDrawer.content.scheduled": "Publicaciones programadas",
  "scheduler.advancedDrawer.content.title": "Mostrar contenido",
  "scheduler.advancedDrawer.profiles.title": "Mostrar perfiles",
  "scheduler.advancedDrawer.calendarView.title": "Vista del calendario",
  "scheduler.advancedDrawer.title": "Configuración avanzada",
  "scheduler.advice":
    "Para maximizar el impacto, pública por lo menos una vez al día{linebreak}Te he sugerido los días y horarios más optimos de acuerdo a cuando tu público está más activo.",
  "scheduler.bestTimes.filter.label": "Mostrar mejores horarios para publicar",
  "scheduler.view.filter.label": "Ver por",
  "scheduler.competitors.heading": "Publicaciones de competidores",
  "scheduler.deleteModal.confirm": "Si, eliminar esta publicación",
  "scheduler.deleteModal.description":
    "Estás por eliminar tu publicación.{lineBreak}Esta acción eliminará tambien la publicación de tus redes sociales.{lineBreak}¿estas seguro de que quieres eliminar esta publicación?",
  "scheduler.deleteModal.title": "Eliminar publicación",
  "scheduler.showDeleteModal.button": "Eliminar publicación",
  "scheduler.editPost": "Edita tu publicación",
  "scheduler.editPost.label": "Título / Texto de la publicación",
  "scheduler.eventCard.boost": "Crear campaña",
  "scheduler.eventCard.scheduled": "Programado {hour}",
  "scheduler.eventCard.video.error.text": "“Hubo un error en tu video.”",
  "scheduler.eventCard.image.error.text": "“Hubo un error en tu imagen.”",
  "scheduler.eventCard.album.error.text": "“Hubo un error en tu album.”",
  "scheduler.eventCard.video.processing": "Preparando tu publicación",
  "scheduler.hero.createPost": "+ Crear nueva publicación",
  "scheduler.hero.piper":
    '"Mi mejor recomendación para que publiques está marcada en verde obscuro. Busca estos espacios dentro del calendario."',
  "scheduler.hero.piper.ipad":
    '"El mejor momento para publicar está marcado en verde oscuro. Búscalo"',
  "scheduler.hero.piper.mobile":
    '"Mi mejor recomendación para que publiques está marcada en verde obscuro. Busca estos espacios en cada día."',
  "scheduler.piperRecommendation.title.afternoon": "Publicación vespertina",
  "scheduler.piperRecommendation.title.evening": "Publicación nocturna",
  "scheduler.piperRecommendation.title.morning": "Publicación matutina",
  "scheduler.piperRecommendation.title.night": "Publicación nocturna",
  "scheduler.piperRecommendation.title.monthView.afternoon": "Vespertina",
  "scheduler.piperRecommendation.title.monthView.evening": "Nocturna",
  "scheduler.piperRecommendation.title.monthView.morning": "Matutina",
  "scheduler.piperRecommendation.title.monthView.night": "Nocturna",
  "scheduler.piperRecommendation.button.create.post": "Crear publicación",
  "scheduler.noleftposts.text":
    "Ya no podrás agendar más publicaciones hasta el {date}. Vuélvete Pro y agenda sin límites.",
  "scheduler.reschedule.modal.title": "Reprogramar",
  "scheduler.boosts.heading": "Campañas publicitarias",
  "scheduler.mobile.postNotFound":
    "No tienes publicaciones en este día. ¡Crea una!",
  "scheduler.mobile.postNotFound.dayBefore":
    "No tienes publicaciones en este día.",
  "scheduler.mobile.postNotFound.button": "Crea una publicación para este día",
  "scheduler.mobile.competitors.empty":
    "Tus competidores no han publicado hoy.",
  "scheduler.mobile.recommendations.notFound":
    "No tienes recomendaciones en este día",
  "scheduler.mobile.tabPosts": "Publicaciones",
  "scheduler.mobile.tabRecommendation": "Recomendaciones",
  "scheduler.mobile.tabCompetitors": "Competidores",
  "scheduler.mobile.post.header": "TU PUBLICACIÓN",
  "scheduler.mobile.postedIn": "Publicado en:",
  "scheduler.mobile.boost": "Crear campaña",
  "scheduler.mobile.processing.post.text": "Preparando tu publicación",
  "scheduler.mobile.failed.post.text":
    "“Hubo un error programando tu video. Descártalo o vuelve a intentarlo”",
  "scheduler.mobile.dropdown.edit": "Editar",
  "scheduler.mobile.dropdown.reschedule": "Reprogramar",
  "scheduler.mobile.scheduled": "Programado",
  "scheduler.mobile.button.dismiss": "Eliminar",
  "scheduler.mobile.button.retry": "Publicar ahora",
  "scheduler.mobile.boost.fail.description":
    "“The Boost linked to this post failed to upload. Please dismiss this notification and create a new boost”",
  "scheduler.mobile.button.dismiss.boost": "Eliminar campaña",
  "scheduler.cardDefault.title": "Conecta las redes de tu negocio",
  "scheduler.cardDefault.message":
    "Así puedo mostrarte los mejores horarios para publicar.",
  "scheduler.cardDefault.cancelPosts": "Cancelar",
  "scheduler.modals.isPublishing.title": "Estoy programando tus publicaciones",
  "scheduler.modals.isPublishing.subtitle": "Esto podría tomar hasta varios minutos. {br} Por favor, ten paciencia.",
  "scheduler.modals.isRegerating.title": "Estoy regenerando tu calendario de contenido.",
  "scheduler.modals.isRegerating.subtitle": "Esto podría tomar unos minutos. {br} Por favor, ten paciencia.",
  "scheduler.cardDefault.multiplePostStore":
    "Aceptar las publicaciones seleccionadas",
  "scheduler.cardDefault.selectAll": "Seleccionar todos",
  "scheduler.cardDefault.regeneratePosts.day":
    "Generar nueva propuesta para todo el dia",
  "scheduler.cardDefault.regeneratePosts.week":
    "Generar nueva propuesta para toda la semana",
  "scheduler.cardDefault.regeneratePosts.month":
    "Generar nueva propuesta para todo el mes",
  "scheduler.competitors.message":
    "Dime quienes son tus competidores o alguien a quien te gustarúa observar para tener inspiración. Aquí te mostraré sus últimas publicaciones.",
  "scheduler.postDetail.title": "Detalles de la publicación",
  "scheduler.postDetail.posted.text": "Publicado en:",
  "scheduler.postDetail.scheduled.text": "Programado:",
  "scheduler.postDetail.rescheduled.button": "Reprogramar",
  "scheduler.postDetail.engagement.text": "Interés generado:",
  "scheduler.postDetail.boosting.text": "Resultados de campaña:",
  "scheduler.postDetail.boosting.result.reach": "alcance",
  "scheduler.postDetail.boosting.result.reactions": "reaccciones",
  "scheduler.postDetail.boosting.result.spent": "USD gastado",
  "scheduler.postDetail.caption.label": "Texto",
  "scheduler.postDetail.caption.edit.button": "Editar texto",
  "scheduler.postDetail.caption.regenerate.button": "Generar nuevamente",
  "scheduler.postDetail.boost.button": "Crear campaña",
  "scheduler.postDetail.deletepost.button": "Eliminar publicación",
  "scheduler.postDetail.reviewed.button": "Ok. Revisado",
  "scheduler.postDetail.declinereviewed.button": "Remover verificado",
  "scheduler.postDetail.dismiss.button": "Eliminar",
  "scheduler.postDetail.retry.button": "Reintentar y publicar ahora",
  "scheduler.postDetail.processing.text":
    '"Hubo un error preparando tu video para {socialNetwork}. Puedes intentar publicarlo de nuevo o eliminarlo.”',
  "scheduler.boost.card.duration.monthView":
    "Boosted post. {daysCount, plural, one {# día} other {# días}}.",
  "scheduler.postDetail.tiktok.delete.message":
    "Si deseas borrar este video, hazlo desde la aplicación de TikTok.",
  "scheduler.boostCard.processing.text": "Preparando tu campaña",
  "scheduler.boost.error.modal.title": "No fue posible crear tu campaña.",
  "scheduler.boost.error.modal.description1":
    "Vuelve a crearla desde Calendario.",
  "scheduler.boost.error.modal.description2":
    "Por favor, revisa la publicación asociada a esta campaña.",
  "scheduler.post.detail.update.success": "Tu publicación ha sido reenviada.",
  "scheduler.post.detail.update.fail":
    "Tu publicación no se pudo actualizar, por favor inténtalo de nuevo.",
  "scheduler.post.detail.deleted.success": "La publicación ha sido eliminada.",
  "scheduler.post.detail.boost.deleted.success":
    "La campaña ha sido eliminada.",
  "scheduler.post.detail.boost.deleted.fail":
    "Tu campaña no se pudo actualizar, por favor inténtalo de nuevo.",
};

export default es;
