/**
 * this file includes the Auth0 and Appsync initialization
 */
import Auth from './Auth';
import Lock from './Lock';
import LockApple from './LockApple';
import AuthAppSync from '../appsync/AuthAppSync';
import PublicAppSync from '../appsync/PublicAppSync';

export const AuthInstance = new Auth();
export const LockInstance = new Lock();
export const LockInstanceApple = new LockApple();
const publicAppSync = new PublicAppSync();
const authAppSync = new AuthAppSync();

/**
 * @class AuthFactory
 * This class is responsible for make the
 * objects for connect with Auth0 service,
 * it's called ONLY in the index.js for avoid
 * constructing objects every call in Appsync
 */
/**
 * sidenote: if you want to use the lock for sign up /
 * login with facebook or another social provider, you should
 * to re-instance the lock instance with the most recent value
 */
export default class AuthFactory {
  static init() {
    AuthInstance.init();
    LockInstance.init();
    LockInstanceApple.init();
    publicAppSync.init();
  }

  static initAuthAppSync(token) {
    authAppSync.init(token);
  }
}

export {
  publicAppSync,
  authAppSync,
};
