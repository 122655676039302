import * as Sentry from '@sentry/browser';
import { TagsMock } from '../../mocks/lookups';
import { authAppSync } from '../auth';
import { getTags } from '../../graphql/queries';
import { ENVIRONMENTS, ENV, LANGUAGES_SUFIX } from '../../utils/constants/globals';

export default class TagsProvider {
  static async search(searchValue, language = 'en') {
    let result;
    const columnName = `name${LANGUAGES_SUFIX[language]}`;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        result = await authAppSync.client.query({
          query: getTags(columnName),
          variables: {
            where: `{ "${columnName}": { "$like": "%${searchValue}%" } }`,
          },
        });
        const { data } = result;

        result = data.tags.map((tag) => ({
          id: tag.id,
          value: tag[columnName],
          label: tag[columnName],
        }));
      } else {
        result = TagsMock;
      }
      result = {
        message: 'success',
        success: true,
        data: result,
      };
    } catch (err) {
      Sentry.captureException(err);
      result = {
        message: err.message,
        success: false,
        data: null,
      };
    }
    return result;
  }
}
