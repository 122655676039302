import gql from 'graphql-tag';

export const createBusinessLead = gql`
  mutation createBusinessLead($input: BusinessLead!) {
    createBusinessLead(input: $input) {
      success
      info
      errorMessage
    }
  }
`;

export default {};
