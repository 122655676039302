import * as Sentry from '@sentry/browser';
import decode from 'unescape';
import axios from 'axios';
import { authAppSync } from '../auth';
import {
  getCompany,
  getCompanyCategories,
  searchBusinessCategories,
  getUploadUrl,
  fetchFaqs,
  getExtraBusinessInfo,
} from '../../graphql/queries';
import { decodeHTML } from '../../helpers/string';
import {
  ENVIRONMENTS,
  ENV,
  LANGUAGES_SUFIX,
} from '../../utils/constants/globals';
import {
  createOrUpdateFaq,
  destroyFaq,
  updateAIResponseType,
  updateCompany,
  updateExtraBusinessInfo,
} from '../../graphql/mutations';
import businessInfoMock from '../../mocks/business/businessInfo.mock.json';
import { capitalize } from '../../utils';

export default class BusinessProvider {
  static async fetch(attr = '') {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: attr ? getCompany(attr) : getCompany(),
          fetchPolicy: 'no-cache',
        });
      } else {
        response = businessInfoMock;
      }
      result = {
        message: 'success',
        success: true,
        data: response.data.company,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchCategories(language = 'en') {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: getCompanyCategories,
          fetchPolicy: 'network-only',
        });
      } else {
        response = { data: [] };
      }

      const data = response.data.companyCategories.map((c) => ({
        id: c.id,
        value: c[`category${LANGUAGES_SUFIX[language]}`],
        label: c[`category${LANGUAGES_SUFIX[language]}`],
      }));

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchBusinessInfo(language = 'en') {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const [responseBusiness, responseCategories] = await Promise.all([
        this.fetch(),
        this.fetchCategories(language),
      ]);

      result = {
        message: 'success',
        success: true,
        data: [
          responseBusiness.data,
          responseCategories.data.map((c) => ({
            ...c,
            value: decodeHTML(c.value),
            label: decodeHTML(c.label),
          })),
        ],
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async searchCategories(searchValue, intl) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const { locale } = intl;
      const column = `category${locale === 'en' ? '' : capitalize(locale)}`;
      const response = await authAppSync.client.query({
        query: searchBusinessCategories,
        variables: {
          where: `{ "${column}": { "$like": "%${searchValue}%" } }`,
        },
      });

      const data = response.data.categories.map((c) => ({
        id: c.id,
        value: c.id,
        label: c[column],
      }));

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async uploadLogo(businessInfo) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.query({
        query: getUploadUrl,
        variables: {
          fileName: businessInfo.logoName,
          fileType: businessInfo.logoType,
          folder: process.env.REACT_APP_S3_LOGOS_PATH,
        },
      });

      const { uploadUrl } = response.data.getUploadUrl;
      const decodedUrl = decode(uploadUrl);

      const options = {
        headers: {
          'Content-Type': businessInfo.logoType,
          'Content-Encoding': 'base64',
        },
      };
      const decodedData = Buffer.from(
        businessInfo.logo.replace(/^data:image\/\w+;base64,/, ''),
        'base64',
      );
      await axios.put(decodedUrl, decodedData, options);

      const rawLogoUrl = new URL(decodedUrl);
      const data = `${rawLogoUrl.origin}${rawLogoUrl.pathname}`;
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async update(businessInfo, logoUrl) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      if (ENV !== ENVIRONMENTS.local) {
        await authAppSync.client.mutate({
          mutation: updateCompany,
          variables: {
            companyName: businessInfo.name || '',
            location: JSON.stringify(businessInfo.location),
            logoUrl,
            industryCategory: businessInfo.category.map((item) => item.id),
          },
        });
      }

      result = {
        message: 'success',
        success: true,
        data: [],
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async updateAIResponseType(type) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      if (ENV !== ENVIRONMENTS.local) {
        await authAppSync.client.mutate({
          mutation: updateAIResponseType,
          variables: {
            type,
          },
        });
      }

      result = {
        message: 'success',
        success: true,
        data: [],
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchFaqs() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.query({
        query: fetchFaqs,
      });

      result = {
        message: 'success',
        success: true,
        data: response.data.getFaqsByCompany,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async createOrUpdateFaq(faq) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: createOrUpdateFaq,
        variables: faq,
      });

      result = {
        message: 'success',
        success: true,
        data: response.data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async deleteFaq(faqId) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: destroyFaq,
        variables: { id: faqId },
      });

      result = {
        message: 'success',
        success: true,
        data: response.data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async getExtraBusinessInfo() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.query({
        query: getExtraBusinessInfo,
      });

      result = {
        message: 'success',
        success: true,
        data: response.data.getExtraBusinessInfo,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async updateExtraBusinessInfo(newData) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: updateExtraBusinessInfo,
        variables: newData,
      });

      result = {
        message: 'success',
        success: true,
        data: response.data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
