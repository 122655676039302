// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { socials4ai } from "../graphql/queries/openAI.query";
import { AudienceProvider, UserProvider } from "../services/entities";
import BusinessProvider from "../services/entities/BusinessProvider";
import moment from "moment";

export const MissingConfig = Object.freeze({
  None: -1,
  NoAppsConnected: 0,
  NoCompanyInfo: 1,
});

export const GenerationType = Object.freeze({
  Generate: "GENERATE",
  Regenerate: "REGENERATE",
});

export const isLengthZero = (iterable) => !iterable || iterable.length === 0;

export const parseGender = (genderId) => {
  switch (genderId) {
    case 3:
      return "All";
    case 2:
      return "Males";
    case 1:
      return "Females";
    default:
      return "";
  }
};

export const appsConnectedParser = (obj) =>
  obj
    ? Object.entries(obj)
        .map(([key, value]) => {
          if (key === "__typename") return "";
          if (value) return key.charAt(0).toUpperCase() + key.slice(1);
          return "";
        })
        .filter((val) => val !== "")
    : null;