import gql from 'graphql-tag';

const connectedAccounts = `
  connectedAccounts {
    facebook {
      pageName
      avatar
      toSetup
      requireAttention
    }
    twitter {
      pageName
      avatar
      requireAttention
    }
    instagram {
      pageName
      avatar
      toSetup
      requireAttention
    }
    linkedin {
      pageName
      avatar
      isOrganization
      requireAttention
    }
    tiktok {
      pageName
      avatar
      toSetup
      requireAttention
    }
  }
`;

export const getUserInformation = (
  attr = `
    email
    firstName
    lastName
    currency
    facebookPageName
    hasCurrencySelected
    notifications {
      id
      name
    }
  `,
) => gql`
  query {
    me {
      id,
      ${attr}
    }
  }
`;

export const getConnectedAccounts = gql`
  query {
    ${connectedAccounts}
  }
`;

export const getUserSettings = (
  attr = `
    language
    country
    onboardingFinished
    nextOnboardingStep
    requiredCard
    currencyCode
    countryCode
    emailVerified
    statusTutorial
    onboardingDateStatus
    isOptIn
  `,
) => gql`
  query {
    getSettings {
      ${attr}
    }
  }
`;

export const verifyEmail = (
  attr = `
  isVerified,
  errors {
    title,
    message
  }
  `,
) => gql`
  query {
    verifyEmail {
      ${attr}
    }
  }
`;

export const getUserByEmail = gql`
  query getUserByEmail($email: String){
    getUserByEmail(email: $email){
      id,
      email,
      authId,
    }
  }
`;

export const existsAuthId = gql`
  query existsUser{
    existsUser
  }
`;

export const getListNotifications = gql`
  query getListNotification {
    getListNotification {
      data {
        type
        dataPending
      }
    }
  }
`;
export const currencyLookUp = gql`
  query currencyLookUp {
    currencyLookUp {
        id
        code
        name
        nameEs
        usdExchangeRate
    }
  }
`;

export const getUserCurrencies = gql`
query userCurrencies {
  userCurrencies {
    fbAdAccount {
      currencyCode
      usdExchangeRate
    }
    liAdAccount {
      currencyCode
      usdExchangeRate
    }
    settings {
      currencyCode
      usdExchangeRate
    }
    plan {
      currencyCode
      usdExchangeRate
    }
  }
}
`;
