import gql from 'graphql-tag';

export const getInAppTouch = gql`
  query getInAppTouch {
    getInAppTouch {
        id
        displayLimit
        displayCount
        lifetimeValue
        messageIdAttached
    }
  }
`;

export default getInAppTouch;
