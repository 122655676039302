import gql from 'graphql-tag';

export const fetchLinkedInData = gql`
  mutation fetchLinkedInData {
    fetchLinkedInData{
      professional {
        linkedinId
        name
        avatar
        alreadyTaken
      }
      pages {
        linkedinId
        name
        avatar
        alreadyTaken
      }
    }
  }
`;

export const updateLinkedInPage = gql`
  mutation updateLinkedInPage($isPage: Boolean!, $linkedinId: String, $name: String, $imageUrl: String){
    updateLinkedInPage(isPage: $isPage, linkedinId: $linkedinId, name: $name, imageUrl: $imageUrl)
  }
`;

export const fetchLinkedInPageShares = gql`
  mutation fetchLinkedInPageShares {
    fetchLinkedInPageShares
  }
`;

export const replyLinkedinComment = gql`
  mutation replyLinkedinComment ($input: CommentInput!) {
    replyLinkedinComment (input: $input) {
      id
      from
      commentText
      createdTime
      replyComments {
        id
        from
        commentText
        createdTime
        read
        userImage
      }
      read
      userImage
    }
  }
`;

export const deleteLinkedinReply = gql`
  mutation deleteLinkedinReply ($commentId: Int!) {
    deleteLinkedinReply(commentId: $commentId)
  }
`;

export const deleteLinkedinPost = gql`
  mutation deleteLinkedinPost ($contentId: Int!) {
    deleteLinkedinPost(contentId: $contentId){
      success
      message
      data
    }
  }
`;

export const editLinkedinPost = gql`
  mutation editLinkedinPost ($contentId: Int!, $message: String, $scheduleTime: AWSDateTime, $imageUrl: String) {
    editLinkedinPost(contentId: $contentId, message: $message,  scheduleTime: $scheduleTime, imageUrl: $imageUrl)
  }
`;

export const rescheduleLinkedinPost = gql`
  mutation rescheduleLinkedinPost ($contentId: Int!, $scheduleTime: AWSDateTime, $postNow: Boolean) {
    rescheduleLinkedinPost(contentId: $contentId, scheduleTime: $scheduleTime,  postNow: $postNow)
  }
`;
export const createLinkedinPost = gql`
  mutation createLinkedinPost ($data: LinkedinShareInput!) {
    createLinkedinPost(data: $data)
  }
`;

export const likeLinkedinComment = gql`
  mutation likeLinkedinComment ($commentId: ID!) {
    likeLinkedinComment(commentId: $commentId)
  }
`;

export default {};
