import constants from './constants/en';
import numbers from './numbers/en';
import warnings from './warnings/en';
import helpers from './helpers/en';
import languages from './languages/en';
import dynamicMessages from './dynamicMessages/en';
import alerts from './alerts/en';
import notifications from './notifications/en';

const en = {
  ...constants,
  ...numbers,
  ...warnings,
  ...helpers,
  ...languages,
  ...dynamicMessages,
  ...alerts,
  ...notifications,
};

export default en;
