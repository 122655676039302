import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Dialog as MuiDialog } from '@material-ui/core';
import GlobalTheme from '../../../styled/GlobalTheme';
import { CloseOutlineIcon } from '../../../assets/icons/iconRepository';
import individualFeatures from '../constants/individualFeatures';
import roles from '../../../utils/constants/roles';

const Wrapper = styled(MuiDialog)`
  && {
    .MuiPaper-root{
      padding: 16px 24px 32px 24px;
    }
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Title = styled.h3`
  font-size: 28px;
  font-weight: bold;
  line-height: 38px;
`;
const IconContainer = styled.div`
  cursor: pointer;
  width: 30px;
`;
const Icon = styled.img`
  width: 100%;
`;
const Content = styled.div`
  margin-top: 32px;
`;
const List = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 30px;
`;
const Feature = styled.div``;
const FeatureHeader = styled.div`
  background: ${({ theme }) => theme.colors.grayF2};
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 14px;
`;
const FeatureTitle = styled.h4`
  font-size: 19px;
  font-weight: 600;
  line-height: 26px;
  margin: 0;
`;
const FeatureList = styled.ul`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 26px;
  padding-left: 30px;
  margin: 0;
  margin-top: 18px;
  max-width: 289px;
`;
const FeatureItem = styled.li`
  font-size: 16px;
  line-height: 26px;
`;
const UnlimitedText = styled.span`
  font-style: italic;
`;

const FeaturesPlanModal = ({ open, setOpen, selectedRole }) => {
  const [features, setFeatures] = useState(individualFeatures);

  return (
    <Wrapper
      fullScreen={window.innerWidth < GlobalTheme.breakpoints.values.md}
      open={open}
    >
      <Header>
        <Title>
          {selectedRole === roles.PRO && (
            <FormattedMessage
              id="newPricing.modal.features.title.pro"
              defaultMessage="Individual Plan {lineBreak}features"
              values={{
                lineBreak: <br />,
              }}
            />
          )}
          {selectedRole === roles.BUSINESS_MAIN && (
            <FormattedMessage
              id="newPricing.modal.features.title.business"
              defaultMessage="Team Plan {lineBreak}features"
              values={{
                lineBreak: <br />,
              }}
            />
          )}
        </Title>
        <IconContainer onClick={() => setOpen(false)}>
          <Icon src={CloseOutlineIcon} alt="Close icon" />
        </IconContainer>
      </Header>
      <Content>
        <List>
          {features.map(
            (feature) => ((selectedRole === roles.PRO && feature.onlyBiz) ? (<></>) : (
              <Feature key={feature.id}>
                <FeatureHeader>
                  <FeatureTitle>{feature.title}</FeatureTitle>
                </FeatureHeader>
                <FeatureList>
                  {feature.features.map((featureItem) => (
                    <FeatureItem key={featureItem.id}>
                      {featureItem.description}
                      {featureItem.unlimited && (
                        <UnlimitedText>
                          <FormattedMessage
                            id="newPricing.modal.features.unlimited.text"
                            defaultMessage=" - unlimited"
                          />
                        </UnlimitedText>
                      )}
                    </FeatureItem>
                  ))}
                </FeatureList>
              </Feature>
            ))
          )}
        </List>
      </Content>
    </Wrapper>
  );
};

FeaturesPlanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedRole: PropTypes.string.isRequired,
};

export default FeaturesPlanModal;
