const adCampaignsMock = [
  {
    id: '41',
    facebookAdId: '6186708877969',
    lifeTimeBudget: 176523,
    startDateTime: '2021-04-05T22:15:00.000Z',
    endDateTime: '2021-09-22T22:15:00.000Z',
    type: 'piperRecommendationBudget',
    campaignStatus: 'running',
    isExpired: false,
    imageUrl: 'https://www.imagen.com.mx/assets/img/IMAGEN_Tv.jpg',
    postMessage: 'Discover our new deals now!',
    statusFromFB: 'ACTIVE',
    effectiveStatus: 'active',
    goal: null,
    instagramPlacement: -1,
    attachmentType: 'album',
    insights: {
      likes: 1200,
      comments: 341,
      shares: null,
      reach: '3000',
      spend: '500',
      reactionsFromFB: 230,
      commentsFromFB: 123,
      sharesFromFB: 77,
      __typename: 'Insights',
    },
    __typename: 'Boosting',
  },
  {
    id: '40',
    facebookAdId: '6186708877969',
    lifeTimeBudget: 176523,
    startDateTime: '2021-09-11T22:15:00.000Z',
    endDateTime: '2021-09-22T22:15:00.000Z',
    type: 'piperRecommendationBudget',
    campaignStatus: 'running',
    isExpired: false,
    imageUrl: 'https://www.imagen.com.mx/assets/img/IMAGEN_Tv.jpg',
    postMessage: 'Discover our new deals now!',
    statusFromFB: 'ACTIVE',
    effectiveStatus: 'active',
    goal: null,
    instagramPlacement: -1,
    attachmentType: 'album',
    insights: {
      likes: null,
      comments: 0,
      shares: null,
      reach: '0',
      spend: '0',
      reactionsFromFB: 0,
      commentsFromFB: 0,
      sharesFromFB: 0,
      __typename: 'Insights',
    },
    __typename: 'Boosting',
  },
  {
    id: '30',
    facebookAdId: '6186708877969',
    lifeTimeBudget: 176523,
    startDateTime: '2021-09-08T22:15:00.000Z',
    endDateTime: '2021-09-09T22:15:00.000Z',
    type: 'piperRecommendationBudget',
    campaignStatus: 'running',
    isExpired: true,
    imageUrl: 'https://www.imagen.com.mx/assets/img/IMAGEN_Tv.jpg',
    postMessage: 'Hey there come join us!',
    statusFromFB: 'ACTIVE',
    effectiveStatus: 'active',
    goal: null,
    instagramPlacement: 1,
    insights: {
      likes: null,
      comments: 0,
      shares: null,
      reach: '0',
      spend: '0',
      reactionsFromFB: 0,
      commentsFromFB: 0,
      sharesFromFB: 0,
      __typename: 'Insights',
    },
    __typename: 'Boosting',
  },
  {
    id: '20',
    facebookAdId: '6186708877969',
    lifeTimeBudget: 176523,
    startDateTime: '2021-09-07T22:15:00.000Z',
    endDateTime: '2021-09-12T22:15:00.000Z',
    type: 'piperRecommendationBudget',
    campaignStatus: 'running',
    isExpired: false,
    imageUrl: 'https://www.imagen.com.mx/assets/img/IMAGEN_Tv.jpg',
    postMessage: 'Let me introduce you to the new asset',
    statusFromFB: 'ACTIVE',
    effectiveStatus: 'Review',
    goal: null,
    instagramPlacement: 1,
    insights: {
      likes: null,
      comments: 0,
      shares: null,
      reach: '0',
      spend: '0',
      reactionsFromFB: 0,
      commentsFromFB: 0,
      sharesFromFB: 0,
      __typename: 'Insights',
    },
    __typename: 'Boosting',
  },
  {
    id: '21',
    facebookAdId: '6186708877969',
    lifeTimeBudget: 176523,
    startDateTime: '2021-09-07T22:15:00.000Z',
    endDateTime: '2021-09-12T22:15:00.000Z',
    type: 'piperRecommendationBudget',
    campaignStatus: 'deleted',
    isExpired: false,
    imageUrl: 'https://www.imagen.com.mx/assets/img/IMAGEN_Tv.jpg',
    postMessage: 'Let me introduce you to the new asset',
    statusFromFB: 'ACTIVE',
    effectiveStatus: 'Deleted',
    goal: null,
    instagramPlacement: 1,
    insights: {
      likes: null,
      comments: 0,
      shares: null,
      reach: '0',
      spend: '0',
      reactionsFromFB: 0,
      commentsFromFB: 0,
      sharesFromFB: 0,
      __typename: 'Insights',
    },
    __typename: 'Boosting',
  },
  {
    id: '10',
    facebookAdId: '6186708877969',
    lifeTimeBudget: 176523,
    startDateTime: '2021-09-08T22:15:00.000Z',
    endDateTime: '2021-09-21T22:15:00.000Z',
    type: 'piperRecommendationBudget',
    campaignStatus: 'paused',
    isExpired: false,
    imageUrl: '',
    postMessage: 'Check our updated release!',
    statusFromFB: 'PAUSED',
    effectiveStatus: 'paused',
    goal: null,
    instagramPlacement: -1,
    insights: {
      likes: null,
      comments: 0,
      shares: null,
      reach: '0',
      spend: '0',
      reactionsFromFB: 0,
      commentsFromFB: 0,
      sharesFromFB: 0,
      __typename: 'Insights',
    },
    __typename: 'Boosting',
  },
];

export default adCampaignsMock;
