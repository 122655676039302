import React from 'react';

export const contextObject = {
  publishQuota: 0,
  setPublishQuota: () => {},
  limitPublishQuota: 10,
  setLimitPublishQuota: () => {},
  competitorsQuota: 0,
  setCompetitorsQuota: () => {},
  limitCompetitorsQuota: 5,
  setLimitCompetitorsQuota: () => {},
  boostQuota: 0,
  setBoostQuota: () => {},
  setLimitBoostQuota: () => {},
  limitBoostQuota: 0,
};

const QuotasContext = React.createContext(contextObject);

export default QuotasContext;
