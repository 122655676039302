import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import LayoutComponent from '../components/globals/Layout';
import useDidUpdateEffect from '../hooks/useDidUpdateEffect';
import BusinessProvider from '../services/entities/BusinessProvider';
import { UserProvider } from '../services/entities';
import { authAppSync } from '../services/auth';
import { useFetchUser } from './UserContext';

export const LayoutContext = React.createContext();

/**
 * Component for connection from view to provider
 */
export const Layout = ({
  Header,
  Footer,
  children,
  title,
  dataLayer,
  accessOnTrialEnds,
  scrollToTop,
  disableHeader,
  overFlowVisible,
}) => {
  const {
    setState,
  } = useContext(LayoutContext);

  useEffect(() => {
    setState({
      Header,
      Footer,
      title,
      dataLayer,
      accessOnTrialEnds,
      scrollToTop,
      disableHeader,
      overFlowVisible,
    });
  }, []);

  useDidUpdateEffect(() => {
    setState({
      Header,
      Footer,
      title,
      dataLayer,
      accessOnTrialEnds,
      scrollToTop,
      disableHeader,
      overFlowVisible,
    });
  }, [title, Header, Footer]);

  return (
    <>
      {children}
    </>
  );
};

Layout.propTypes = {
  Footer: PropTypes.node,
  Header: PropTypes.node,
  accessOnTrialEnds: PropTypes.bool,
  children: PropTypes.node.isRequired,
  scrollToTop: PropTypes.bool,
  title: PropTypes.string,
  dataLayer: PropTypes.instanceOf(Object),
  disableHeader: PropTypes.bool,
  overFlowVisible: PropTypes.bool,
};

Layout.defaultProps = {
  Header: undefined,
  Footer: undefined,
  title: '',
  accessOnTrialEnds: false,
  scrollToTop: true,
  dataLayer: {},
  disableHeader: false,
  overFlowVisible: false,
};

const LayoutProvider = ({ children }) => {
  const [user, setUser] = useFetchUser();

  const [state, setState] = useState({
    Header: null,
    Footer: null,
    title: '',
    dataLayer: {},
    accessOnTrialEnds: false,
    scrollToTop: true,
    overFlowVisible: false,
  });
  const [showBecomeProModal, setShowBecomeProModal] = useState(false);
  const [showNoLeftPostsModal, setShowNoLeftPostsModal] = useState(false);
  const [showModalSubAppsModal, setShowModalSubAppsModal] = useState(false);
  const [businessImage, setBusinessImage] = useState(null);

  const fetchlogoUrl = async () => {
    const response = await BusinessProvider.fetch('logoUrl');
    if (response.success) {
      setBusinessImage(response.data.logoUrl);
    }
  };

  const updateUserStatus = async () => {
    let userF = {};
    const response = await UserProvider.fetch(`
      id
      email
      firstName
      lastName
      hasTrial
      remainingTrialDays
      currency
      hasCurrencySelected
      stillActive
      analyticsDays
      notifications {
        id
        name
      }
      subscriptionStatus
      haveCagetories
      companyName
      connectedAccounts {
          facebook {
            pageName
          }
          instagram{
            pageName
          }
          twitter{
            pageName
          }
          linkedin{
            pageName
          }
        }
        isOptIn
        analyticsDays
    `, false);
    if (response.success && response.data) {
      const { data } = response;
      userF = {
        ...user,
        ...data,
      };
      setUser(userF);
    }
    return userF;
  };

  useEffect(() => {
    if (authAppSync.client) {
      fetchlogoUrl();
      updateUserStatus();
    }
  }, [authAppSync.client]);
  return (
    <LayoutContext.Provider
      value={{
        setState,
        fetchlogoUrl,
        setBusinessImage,
        businessImage,
        user,
        setUser,
        updateUserStatus,
        showBecomeProModal,
        setShowBecomeProModal,
        setShowNoLeftPostsModal,
        showModalSubAppsModal,
        setShowModalSubAppsModal,
      }}
    >
      <LayoutComponent
        Header={state.Header}
        Footer={state.Footer}
        title={state.title}
        dataLayer={state.dataLayer}
        accessOnTrialEnds={state.accessOnTrialEnds}
        scrollToTop={state.scrollToTop}
        businessImage={businessImage}
        endOfTrial={!user.stillActive}
        showBecomeProModal={showBecomeProModal}
        setShowBecomeProModal={setShowBecomeProModal}
        showNoLeftPostsModal={showNoLeftPostsModal}
        setShowNoLeftPostsModal={setShowNoLeftPostsModal}
        disableHeader={state.disableHeader}
        overFlowVisible={state.overFlowVisible}
      >
        {children}
      </LayoutComponent>
    </LayoutContext.Provider>
  );
};

LayoutProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutProvider;
