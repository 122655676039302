import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GlobalTheme from '../../styled/GlobalTheme';

const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

const PiperImage = styled.img`
  width:${(props) => props.width || '100%'};
  max-height:100%;
  height: auto;
  transform:${(props) => props.flipPiper && 'scaleX(-1)'};
  object-fit: contain;
  ${GlobalTheme.breakpoints.down('sm')}{
    min-width: 50px;
  }
  ${(props) => props.hide && `
      ${GlobalTheme.breakpoints.up(props.hide)}{
        display:block;
      }
      ${GlobalTheme.breakpoints.down(props.hide)}{
        display:none;
      }
  `}
  ${(props) => props.show && `
      ${GlobalTheme.breakpoints.down(props.show)}{
        display:block;
      }
      ${GlobalTheme.breakpoints.up(props.up)}{
        display:none;
      }
  `}
`;

const Piper = ({
  image,
  title,
  className,
  hide,
  show,
  flipPiper,
  width,
  maxWidth,
}) => {
  let up = false;
  if (show) {
    breakpoints.find((breakpoint, index) => {
      if (breakpoint === show) { up = breakpoints[index + 1]; }
      return {};
    });
  }
  return (
    <>
      <PiperImage
        className={`${className} piper`}
        src={image}
        alt={title}
        hide={hide}
        show={show}
        up={up}
        flipPiper={flipPiper}
        width={width}
      />
    </>
  );
};

Piper.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  hide: PropTypes.string,
  show: PropTypes.string,
  flipPiper: PropTypes.bool,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
};

Piper.defaultProps = {
  className: '',
  image: null,
  title: null,
  hide: null,
  show: null,
  flipPiper: false,
  width: '100%',
  maxWidth: '100%',
};

export default Piper;
