import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Media from 'react-media';
import { useIntl } from 'react-intl';
import Promotional from './components/Promotional';
import ToolsSection from './sections/ToolsSections';
import MeetPiper from './components/MeetPiper';
import PiperSteps from './components/PiperSteps';
import { Layout } from '../../contexts/LayoutContext';
import { WebViewContext } from '../../contexts/WebViewContext';
import Testimonials from './sections/Testimonials';
import PricingSection from '../Pricing/components/PricingSection';
import ROUTES from '../../utils/constants/routes';
import { UserSettingsContext } from '../../contexts/UserSettingsContext';
import AwardSection from './components/AwardSection';
import WhereToPostSection from './components/WhereToPostSection';
import PiperSection from './sections/PiperSection';
import mediaQueries from '../../utils/mediaQueries';

export default function Landing() {
  const intl = useIntl();
  const history = useHistory();
  const { pathname } = useLocation();
  const { isApp } = useContext(WebViewContext);
  const { setLanguage } = useContext(UserSettingsContext);
  if (pathname === '/en/' || pathname === '/en') {
    setLanguage('en');
  }
  if (pathname === '/es/' || pathname === '/es') {
    setLanguage('es');
  }

  useEffect(() => {
    if (isApp) {
      history.push(ROUTES.LOGIN_APP);
    }
  }, [isApp]);
  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <Layout
          accessOnTrialEnds
          title={intl.formatMessage({
            id: 'k.seo.title',
            defaultMessage: 'Piper',
          }, {
            titlePage: '',
          })}
        >
          <Promotional />
          <ToolsSection />
          <PiperSection />
          {matches.mdUp && (
            <WhereToPostSection />
          )}
          <PricingSection landing />
          {matches.mdDownLess && (
            <AwardSection />
          )}
          <Testimonials />
          {matches.mdDownLess && (
            <WhereToPostSection />
          )}
        </Layout>
      )}
    </Media>
  );
}

export {
  Promotional,
  MeetPiper,
  Testimonials,
  PiperSteps,
};
