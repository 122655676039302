/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { css } from 'styled-components';
import { Button as MuiButton, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { colors } from '../../styled/theme';

const StyledButton = styled(MuiButton)`
  && {
    ${(props) => `
      font-size: ${props.fontSize || '18px'};
      font-weight: ${props.fontWeight || '600'};
      margin: ${props.margin || '0'};
      color: ${props.color || colors.primary};
      text-align: ${props.textAlign || 'left'};
    `}
    line-height: 120%;
    border: 0;
    position: relative;
    padding: 0;
    font-family: ${({ theme }) => theme.font.font1};
    text-decoration: none;
    cursor: pointer;
    &:hover {
      background: transparent;
      border: 0;
    }
    ${(props) => props.disabled && `
      color: ${colors.gray90};
      pointer-events: none;
    `}
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    ${({ danger }) => danger && css`
      .MuiCircularProgress-circle{
        stroke: ${colors.red};
      }
    `}
  }
`;

const ButtonLink = ({
  children,
  disabled,
  isLoading,
  className,
  danger,
  to,
  gaData,
  onClick,
  navLink,
  ...props
}) => (
  // <AnalyticsLink onClick={onClick} to={to} gaData={gaData} navLink={navLink}>
  <StyledButton
    color="primary"
    disabled={disabled || isLoading}
    className={className}
    danger={danger}
    onClick={onClick}
    {...props}
  >
    {children}
    {isLoading && <StyledCircularProgress danger={danger} size={20} />}
  </StyledButton>
  // </AnalyticsLink>
);

ButtonLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  gaData: PropTypes.instanceOf(Object),
  isLoading: PropTypes.bool,
  navLink: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

ButtonLink.defaultProps = {
  className: '',
  disabled: false,
  isLoading: false,
  danger: false,
  gaData: {},
  navLink: false,
  onClick: () => {},
  to: '',

};

export default ButtonLink;
