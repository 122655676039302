import gql from 'graphql-tag';

export const updateAdCampaigns = gql`
  mutation updateAdCampaigns($boostingUpdateSettings: BoostingUpdateSettings!) {
    updateAdCampaigns( boostingUpdateSettings: $boostingUpdateSettings){
      id
      startDateTime
      endDateTime
    }
  }
`;

export const updateAdCampaignStatus = gql`
  mutation updateAdCampaignStatus($newAdCampaignStatus: NewAdCampaignStatus!) {
    updateAdCampaignStatus( newAdCampaignStatus: $newAdCampaignStatus){
      id
      campaignStatus
      effectiveStatus
    }
  }
`;

export const createAdCampaign = gql`
  mutation createAdCampaign($boostingExistingPost: BoostingExistingPost!) {
    createAdCampaign(boostingExistingPost: $boostingExistingPost){
      id
      isExpired
      startDateTime
      endDateTime
    }
  }
`;

/**
 * @deprecated
 */
export const deleteBoostedPost = gql`
  mutation deleteBoostedPost($adId: ID!) {
    deleteBoostedPost(adId: $adId)
  }
`;
