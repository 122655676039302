import gql from 'graphql-tag';

export const getUserQuotas = (attr = `
  role
  endDate
  permissions{
    useEditor
    useProTemplates
    useTemplates
  }
  quotas{
    canPublish{
        limit
        usage
    }
    canBoost{
        limit
        usage
    }
    canAddCompetitorFB{
        limit
        usage
    }
    canAddCompetitorIG{
        limit
        usage
    }
  }
`) => gql`
  query {
    getUserQuotas {
      ${attr}
    }
  }
`;

export default {};
