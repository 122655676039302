export const decodeHTML = (string) => (
  string.replace(/&(#(?:x[0-9a-f]+|\d+)|[a-z]+);?/gi, ($0, $1) => {
    const map = {
      gt: '>',
      amp: '&',
    };
    if ($1[0] === '#') {
      return String.fromCharCode($1[1].toLowerCase() === 'x' ? parseInt($1.substr(2), 16) : parseInt($1.substr(1), 10));
    }
    return map[$1] || $0;
  })
);

export default {};
