import * as Sentry from '@sentry/browser';
import { myCreationsMock } from '../../mocks/templates';
import { authAppSync } from '../auth';
import { myCreations, getEditorState, getEditorSticker } from '../../graphql/queries';
import { deleteUserImage, saveEditorSticker } from '../../graphql/mutations';
import { ENVIRONMENTS, ENV } from '../../utils/constants/globals';

export default class MyCreationsProvider {
  static async fetchByTag(limit, offset, tagName, language) {
    let result;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        result = await authAppSync.client.query({
          query: myCreations,
          fetchPolicy: 'network-only',
          variables: {
            tagName,
            pagination: {
              limit,
              offset,
            },
            language,
          },
        });
        const { data } = result;
        result = data.getOwnContent;
      } else {
        result = myCreationsMock;
      }
      result = {
        message: 'success',
        success: true,
        data: result,
      };
    } catch (err) {
      Sentry.captureException(err);
      result = {
        message: err.message,
        success: false,
        data: null,
      };
    }
    return result;
  }

  static async delete(imageId) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      if (ENV !== ENVIRONMENTS.local) {
        await authAppSync.client.mutate({
          mutation: deleteUserImage,
          variables: {
            imageId,
          },
        });
      }
      result = {
        message: 'success',
        success: true,
        data: [],
      };
    } catch (err) {
      Sentry.captureException(err);
    }
    return result;
  }

  static async getEditorState(imageId) {
    let result;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        result = await authAppSync.client.query({
          query: getEditorState,
          fetchPolicy: 'no-cache',
          variables: {
            imageId,
          },
        });
        const { data } = result;
        result = data.getEditorState;
      } else {
        // result = myCreationsMock;
      }
      result = {
        message: 'success',
        success: true,
        data: result,
      };
    } catch (err) {
      Sentry.captureException(err);
      result = {
        message: err.message,
        success: false,
        data: null,
      };
    }
    return result;
  }

  static async saveEditorStickers(name, folder) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      if (ENV !== ENVIRONMENTS.local) {
        await authAppSync.client.mutate({
          mutation: saveEditorSticker,
          variables: {
            name,
            folder,
          },
        });
      }
      result = {
        message: 'success',
        success: true,
        data: [],
      };
    } catch (err) {
      Sentry.captureException(err);
    }
    return result;
  }

  static async getEditorSticker() {
    let result;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        result = await authAppSync.client.query({
          query: getEditorSticker,
          fetchPolicy: 'no-cache',
        });
        const { data } = result;
        result = data.getEditorSticker.data;
      }
      result = {
        message: 'success',
        success: true,
        data: result,
      };
    } catch (err) {
      Sentry.captureException(err);
      result = {
        message: err.message,
        success: false,
        data: null,
      };
    }
    return result;
  }
}
