import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import GlobalEnglish from '../../../i18n/translations/global/en';

const ReduceFormatted = ({ languageKey }) => (
  <FormattedMessage
    id={languageKey}
    defaultMessage={GlobalEnglish[languageKey]}
  />
);

ReduceFormatted.propTypes = {
  languageKey: PropTypes.string.isRequired,
};

export default ReduceFormatted;
