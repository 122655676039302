import { useRef, useState, useEffect } from 'react';

export default function useHeight(requirement = []) {
  const containerRef = useRef(null);
  const [height, setHeight] = useState(0);
  const getHeight = () => {
    if (containerRef.current) setHeight(containerRef.current.offsetHeight);
  };
  useEffect(() => {
    getHeight();
    const resizeEvent = window.addEventListener('resize', () => {
      getHeight();
    });
    return (() => {
      window.removeEventListener('resize', resizeEvent);
    });
  }, [containerRef, ...requirement]);

  return [containerRef, height];
}
