const en = {
  'newPricing.title': 'Pricing',
  'newPricing.subtitle': 'Select a plan that works for you',
  'pricing.join.subtitle':
    'The best platform to <h>manage your business social networks,</h> in one place easily.',
  'newPricing.litePlan.title': 'Lite Plan',
  'newPricing.plan.description.lite': 'For freelancers or social media beginners',
  'newPricing.plan.free.text': 'Free always',
  'newPricing.plan.lite.content1': 'Schedule <bold>10 posts</bold> per month.',
  'newPricing.plan.lite.content2': 'Only <bold>10 templates</bold> recommendations ready to use.',
  'newPricing.plan.lite.content3': 'Connect up to 1 account per social network.',
  'newPricing.plan.lite.ctaButton': 'Start now',
  'newPricing.proPlan.title': 'Individual Plan',
  'newPricing.plan.description.pro': 'for freelancers / solopreneurs & social media account managers',
  'newPricing.plan.pro.content1': 'Schedule unlimited posts',
  'newPricing.plan.pro.content2': 'Unlimited ready-to-use {lineBreak}templates and designs',
  'newPricing.plan.pro.content3': '1 user per plan',
  'newPricing.plan.pro.content4': 'Unlimited creation of images and texts with AI for your business social media.',
  'newPricing.plan.pro.content5': 'Metrics Dashboard.',
  'newPricing.plan.pro.ctaButton': 'Start a {daysTrial}-day free trial',
  'newPricing.businessPlan.title': 'Team Plan',
  'newPricing.full.managment.title': 'Full Social Media Management',
  'newPricing.plan.description.business': 'Let us be your Community Manager!',
  'newPricing.plan.business.info': 'Includes all the benefits of the Individual Plan plus a Professional Community Manager to take you along:',
  'newPricing.plan.business.content1': 'Work plan amd your content grid.',
  'newPricing.plan.business.content2': 'Design up to 16 monthly content items and their publication on your connected social media through Social Piper.',
  'newPricing.plan.business.content3': 'Creation of advertising campaigns based on your budget to boost your posts.',
  'newPricing.plan.business.content4': 'Review of results.',
  'newPricing.plan.business.content5': 'Answer to comments from your community in your social media connected to Social Piper.',
  'newPricing.plan.business.content6': 'Includes the service for all social media for which Social Piper has support at the time of signing up for the service.',
  'newPricing.plan.comingSoon.text': 'Coming Soon!',
  'newPricing.card.seeDetails': 'See plan details',
  'newPricing.videoTour.description': 'Discover all you can do in Social Piper',
  'newPricing.plan.apply.promo': 'Promo code {code} applied',
  'plan.pro.without.trial': 'Create your account',
  'newPricing.cta.button': 'Compare plans',
  'newPricing.modal.features.title.pro': 'Individual Plan {lineBreak}features',
  'newPricing.modal.features.title.business': 'Full management service features',
  'newPricing.modal.features.unlimited.text': ' - unlimited',
  'newPricing.price.monthly.text': '/mo.',
  'newPricing.price.annually.text': '/yr.',
  'newPricing.form.name': 'Name',
  'newPricing.form.email': 'E-mail',
  'newPricing.form.phone': 'Telephone number {aterisk}',
  'newPricing.form.title': 'Please leave us the following information to contact you as soon as possible:',
  'newPricing.form.successMessage': 'Thank you! We’ll get in touch soon.',
};

export default en;
