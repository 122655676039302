const en = {
  'login.button': 'Log in',
  'login.createAccount.text': "Don't have an account yet?",
  'login.facebook.button': 'Log in with Facebook',
  'login.forgotPassword': 'Forgot Password?',
  'login.header': 'Welcome Back!',
  'login.rememberMe.text': 'Remember me',
  'login.resetPassword': 'Reset your password',
  'login.resetPassword.description': "Please write the email you registered in Social Piper. You'll receive a link to create a new password.",
  'login.resetPassword.helpSupport': 'If you encounter any problem, please contact us at  <a>support@socialpiper.com</a> or use our live chat.',
  'login.resetPassword.succes.alert': 'The reset password email was sent.',
  'login.signup.link': 'Sign up',
  'login.apple.button': 'Log in with Apple',
  'signin.apple.button': 'Sign in with Apple',
  'signup.apple.button': 'Sign up with Apple',
  'login.signup.link.app': 'DON’T HAVE AN ACCOUNT?',
  'login.expirderModal.tip': 'Wow! You’ve been away for a while! Please, login again.',
};
export default en;
