import gql from 'graphql-tag';

export const recentFacebookActivity = gql`
  query comps {
    facebookRecentCompetitorsPosts {
      name
      caption
      dateTime
      mediaUrl
      mediaType
      interactions
      network
      profilePic
      permalinkUrl
      sharedLink {
        url
      }
    }
  }
`;

export const recentInstagramActivity = gql`
  query comps {
    instagramRecentCompetitorsPosts {
      name
      caption
      dateTime
      mediaUrl
      mediaType
      interactions
      network
      profilePic
      permalinkUrl
    }
  }
`;
