import { useState, useEffect } from 'react';

const useGetCurrencySymbol = (currencyCode) => {
  const [symbol, setSymbol] = useState('');
  const getCurrencySymbol = (locale, currency) => (0).toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).replace(/[\dA-Za-z]/g, '').trim();
  useEffect(() => {
    try {
      const sym = getCurrencySymbol('EN', currencyCode);
      setSymbol(sym);
    } catch (err) {
      setSymbol('');
    }
  }, [currencyCode]);
  return symbol;
};

export default useGetCurrencySymbol;
