const reachStimateMock = (linkedIn) => (
  ((linkedIn)
    ? {
      data: {
        reachEstimateLinkedin: {
          targetSize: 100000,
          __typename: 'ReachEstimate',
        },
      },
    } : {
      data: {
        reachEstimate: {
          targetSize: 1900000,
          __typename: 'ReachEstimate',
        },
      },
    })
);

export default reachStimateMock;
