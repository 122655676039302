import gql from 'graphql-tag';

const getDevices = gql`
  query getDevices{
    getUserDevices{
      playerId
      isLogged
    }
}
`;

export default getDevices;
