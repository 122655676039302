const es = {
  "postflow.create.AddimageTittle": "Agregar imagenes",
  "postflow.create.alertMissingSocial": "Selecciona al menos una red social",
  "postflow.create.caption": "Agregar texto",
  "postflow.create.label.notInspired": "¿Sin inspiración?",
  "postflow.create.label.usePiper": "Usa a Piper",
  "postflow.create.CaptionsPlaceHolder": "Tu texto va aqui...",
  "postflow.create.facebookCheck": "Publicar en Facebook",
  "postflow.create.instagramCheck": "Publicar en Instagram",
  "postflow.create.missingCaptionEmpty": "El texto no puede quedar vacío",
  "postflow.create.missing.image": "La imagen no puede quedar vacía",
  "postflow.create.caption.overflow":
    "Para publicar en Facebook y/o Instagram, debes reducir la cantidad de caracteres a un máximo de 1,000.",
  "postflow.create.caption.overflow.twitter":
    "Para publicar en Twitter, debes reducir la cantidad de caracteres a un máximo de 280.",
  "postflow.create.PreviewComment": "Comentar",
  "postflow.create.PreviewJustNow": "Ahora...",
  "postflow.create.PreviewLike": "Me gusta",
  "postflow.create.PreviewPlaceholder": "Tu texto va aqui...",
  "postflow.create.PreviewShare": "Compartir",
  "postflow.create.tittle": "Crear publicación",
  "postflow.create.twitterCheck": "Publicar en Twitter",
  "postflow.create.where2Post": "Selecciona dónde publicar",
  "postflow.create.tooltip.imageIndicator.title":
    "Agrega múltiples imágenes a tu plublicación.",
  "postflow.create.tooltip.imageIndicator.text1":
    "Puedes agregar más de una imagen a tu plublicación. Esta guía visual te permite ver cuántas imágenes puedes cargar según la red:",
  "postflow.create.tooltip.imageIndicator.limit.facebook":
    "Para <HighlightNetwork>Facebook</HighlightNetwork>, puedes cargar hasta <bold>9</bold> imágenes.",
  "postflow.create.tooltip.imageIndicator.limit.instagram":
    "En <HighlightNetwork>instagram</HighlightNetwork>, puedes usar <bold>1</bold> imágen.",
  "postflow.create.tooltip.imageIndicator.limit.twitter":
    "Para <HighlightNetwork>Twitter</HighlightNetwork> puedes cargar hasta <bold>4</bold> imágenes.",
  "postflow.create.tooltip.imageIndicator.limit.linkedin":
    "En <HighlightNetwork>Linkedin</HighlightNetwork>, puedes usar <bold>9</bold> imágenes.",
  "postflow.create.tooltip.imageIndicator.text2":
    "<HighlightText>El límite de imágenes en tu publicación dependerá de las redes que tengas seleccionadas.</HighlightText> Por ejemplo, si seleccionas Instagram y Twitter, sólo podrás publicar 1 imagen para las dos redes.",
  "postflow.editor.accept": "Guardar",
  "postflow.preview.title": "Vista previa",
  "postflow.editor.acceptPost": "Guardar y publicar",
  "postflow.editor.alertSaveFail":
    "Tu imagen no pudo ser guardada. Por favor, intenta nuevamente o manda un correo a support@socialpiper.com",
  "postflow.editor.cancel": "Cancelar",
  "postflow.editor.continueButton": "Continuar",
  "postflow.editor.noChangesWarning": "Sin cambios para guardar",
  "postflow.editor.sizeUploadFail": "El tamaño de archivo máximo es 3 MB",
  "postflow.editor.saveSuccess": "¡La imagen ha sido guardada!",
  "postflow.editor.startSaveStatus": "Guardando plantilla",
  "postflow.editor.tittle": "Edita tu imagen",
  "postflow.editor.proMessage":
    "Esta es una función del plan Pro. Edita la imagen, sus textos, agrega nuevas imágenes, cambia la posición de tu logo y más",
  "postflow.editorModal.cancelButton": "Cancelar",
  "postflow.editorModal.confirmButton": "Confirmar",
  "postflow.editorModal.helperLenght": "El título no puede quedar vacío",
  "postflow.editorModal.saveTittle": "Guarda tu creación",
  "postflow.editorModal.titleForm": "Título",
  "postflow.editorModal.titlePlaceHolder": "Nombre de la plantilla",
  "postflow.editorModal.titleTags": "Etiquetas",
  "postflow.minilibrary.compacTitle": "Centro Creativo",
  "postflow.minilibrary.pipertip":
    "Lo sentimos. No hay plantillas que coincidan con tu búsqueda. Prueba utilizando palabras o conceptos similares.",
  "postflow.minilibrary.placeholderMyCreations": "Buscar etiqueta",
  "postflow.minilibrary.placeholderOtherImages": "Buscar ...",
  "postflow.minilibrary.typeCaptionPlaceholder":
    "Escribe algo sobre la imagen...",
  "postflow.minilibrary.buttonGenerateImage": "Generar",
  "postflow.minilibrary.placeholderTemplates": "Buscar",
  "postflow.minilibrary.tab1": "Plantillas",
  "postflow.minilibrary.tab2": "Tus creaciones",
  "postflow.minilibrary.tab3": "Fotografías",
  "postflow.minilibrary.tab4": "Usa a Piper",
  "postflow.minilibrary.tab5": "Hospital Angeles",
  "postflow.minilibrary.title": "Centro Creativo",
  "postflow.navbar.cancel": "Cancelar",
  "postflow.navbar.step1": "SELECCIONA IMÁGENES",
  "postflow.navbar.mobileStep1": "SELECCIONA",
  "postflow.navbar.step2": "CREAR PUBLICACIÓN",
  "postflow.navbar.mobileStep2": "CREA",
  "postflow.navbar.step3": "PROGRAMAR PUBLICACIÓN",
  "postflow.navbar.mobileStep3": "PROGRAMA",
  "postflow.navbar.title": "Nueva Publicación",
  "postflow.preview.edit": "Editar Imagen",
  "postflow.preview.regenerate": "Generar nuevamente",
  "postflow.preview.previewContainerText":
    "Las redes sociales cambian constantemente. Tu publicación puede parecer ligeramente diferente cuando se publique.",
  "postflow.schedule.backButton": "Atras",
  "postflow.create.image.limit":
    "{socialNetwork} solo permite hasta {limit} imágenes. Deselecciona algunas para crear tu post.",
  "postflow.schedule.basicRoleQuota":
    "Te quedan {posts} publicaciones. Utilízalas antes del {date}. Recuerda que cada publicación cuenta para el límite. ¿Quieres publicar ilimitadamente?",
  "postflow.schedule.basicRoleQuota.noLeft":
    "Has alcanzado tu límite de publicaciones programadas este mes. ¿Quieres programar más, tener más contenido y funciones?",
  "postflow.schedule.finishButton": "Publicar",
  "postflow.schedule.withBoost.finishButton": "Publicar y promocionar",
  "postflow.schedule.isMostActive": "Aquí es cuando tu público esta más activo",
  "postflow.schedule.analyzing.audience": "“Estoy analizando tu audiencia”",
  "postflow.schedule.msgModalPublished": "Publicado",
  "postflow.schedule.msgModalScheduled": "Programado",
  "postflow.schedule.subtittleError":
    "Por favor, revisa que tus redes sociales se encuentran conectadas y vuelve a intentarlo",
  "postflow.schedule.time2post": "Selecciona un horario para publicar",
  "postflow.schedule.titleBoost": "Crear campaña",
  "postflow.schedule.titleFacebook": "Facebook",
  "postflow.schedule.titleInstagram": "Instagram",
  "postflow.schedule.titleModalFail": "¡Tu publicación esta lista!",
  "postflow.schedule.titleModalFailAll": "No fue posible crear tu publicación",
  "postflow.schedule.titleModalSuccess":
    "¡Todas tus publicaciones estan listas!",
  "postflow.schedule.titleModalSuccess.whith.stories": "¡Estamos casi listos!",
  "postflow.schedule.titleWaitingModal":
    "Ya estoy preparando las publicaciones en tus redes.",
  "postflow.schedule.titleLazyingDoggo":
    "Estoy generando tu calendario de contenido.",
  "postflow.schedule.subtitleLazyingDoggo":
    "Esto podría tomar hasta varios minutos. {br} Por favor, ten paciencia.",
  "postflow.schedule.subTitleWaitingModal": "¡Tenme paciencia!",
  "postflow.schedule.subTitleModalFail": "Pero hay un problema.",
  "postflow.schedule.warning":
    "Ups! No pudimos programar una de tus publicaciones. Parece que algo salió mal con tu cuenta de <facebook>Facebook</facebook><instagram>Instagram</instagram><twitter>Twitter</twitter><linkedin>LinkedIn</linkedin>. Inténtalo de nuevo. Si el problema continúa, contáctanos en <WarningEmail>support@socialpiper.com</WarningEmail>",
  "postflow.schedule.titleTwitter": "Twitter",
  "postflow.schedule.titleLinkedin": "Linkedin",
  "postflow.schedule.tittle": "Programar publicación",
  "postflow.schedule.wordNow": "Ahora",
  "postflow.schedule.alertMissingRequirement":
    "Para poder continuar, necesitas <link>agregar un metodo de pago a tu cuenta de Social Piper.</link>",
  "postflow.schedule.setAudience":
    "Háblame de tu audiencia y te daré una buena reomendación para tu campaña.",
  "postflow.ScheduleOptions.custom": "Personalizada",
  "postflow.ScheduleOptions.immediately": "Publicar ahora",
  "postflow.ScheduleOptions.piperRecommendation": "Piper te recomienda",
  "postFlow.selectImage.dragDropText":
    "Haz clic aquí para subir una imagen o arrástrala desde de tu dispositivo",
  "postFlow.selectImage.dragDropText.mobile":
    "Toca aquí para seleccionar una imagen desde tu dispositivo",
  "postflow.selectimage.pipermessage":
    "Selecciona una plantilla, imagen de nuestro Centro Creativo, o sube una imagen o video propio.",
  "postflow.selectimage.tittle":
    "Selecciona desde Centro Creativo <s>{o}</s> sube tu contenido visual",
  "postflow.selectimage.mobileTittle": "Selecciona qué publicar",
  "postflow.selectimage.cancelButton": "Cancelar agregar nueva imagen",
  "settings.create.advice":
    "Conecta todos los perfiles de redes sociales de tu negocio para comenzar a publicar",
  "postflow.editor.WELCOME_TITLE":
    "¿Cómo deseas iniciar para crear tu plantilla?",
  "postflow.editor.WELCOME_LOADING_IMAGE": "Cargando...",
  "postflow.editor.WELCOME_EMPTY_TEXT": "Comienza con un lienzo en blanco.",
  "postflow.editor.WELCOME_UPLOAD_TEXT": "Deseo cargar mi propia imagen.",
  "postflow.editor.WELCOME_UPLOAD_HELP":
    "Puedes arrastrar aquí un archivo JPG o PNG o hacer clic aquí para seleccionarlo desde tu dispostivo.",
  "postflow.editor.WELCOME_UPLOAD_FAIL":
    "Sólo puedes arrastrar un archivo a la vez.",
  "postflow.editor.HELP_EDIT_1":
    "Para editar el objeto, haz doble clic sobre él.",
  "postflow.editor.HELP_TEXT_INSERT":
    "Haz clic en el lugar del lienzo donde desees colocar el nuevo texto.",
  "postflow.editor.HELP_TEXT_TYPING":
    "Comienza a escribir, y si deseas finalizar sólo haz clic en otro lugar.",
  "postflow.editor.HELP_OBJECT_DRAW":
    "Haz clic en el lienzo para comenzar a dibujar.",
  "postflow.editor.HELP_IMAGE_DRAW":
    "Haz clic en el lienzo para colocar la imagen.",
  "postflow.editor.HELP_POLYGON_CLOSE":
    "Si no deseas cerrar el polígono, presiona Enter.",
  "postflow.editor.PANEL_FONT_STYLE": "Fuente",
  "postflow.editor.PANEL_FONT_TEXT": "Texto",
  "postflow.editor.PANEL_POSITION": "Posición",
  "postflow.editor.PANEL_ROTATION": "Rotación",
  "postflow.editor.PANEL_SIZE": "Tamaño",
  "postflow.editor.PANEL_FILL": "Relleno",
  "postflow.editor.PANEL_STROKE": "Contorno",
  "postflow.editor.PANEL_WIDTH": "Ancho",
  "postflow.editor.PANEL_HEIGHT": "Alto",
  "postflow.editor.PANEL_BORDER_RADIUS": "Radio del borde",
  "postflow.editor.PANEL_OPACITY": "Opacidad",
  "postflow.editor.PANEL_IMAGE": "Imagen",
  "postflow.editor.PANEL_IMAGE_DROP": "Haz clic o arrastra y suelta aquí",
  "postflow.editor.PANEL_ARRANGE_STB": "Enviar hacia atrás",
  "postflow.editor.PANEL_ARRANGE_STF": "Enviar hacia adelante",
  "postflow.editor.WELCOME_UPLOAD_SIZE_FAIL": "El tamaño de archivo maximo es",
  "postflow.boost.unactive.title": "Podría no bastar con sólo publicar.",
  "postflow.boost.unactive.text":
    "Recuerda que los algoritmos de redes como Facebook, Instagram y otras le dan más visibilidad y prioridad a aquellas publicaciones acompañadas de una campaña.{br}<bb>¡Además por tiempo limitado con 0% de comisión!</bb>",
  "postflow.boost.multiImage.cta":
    "Pronto podrás crear crear campañas publicitarias con más de 1 imagen.",
  "postflow.boost.unactive.cta": "Muéstrame cómo",
  "postflow.boost.unactive.again.title":
    "La próxima vez ¡Prueba crear una campaña!",
  "postflow.boost.unactive.again.text":
    "Incluso con el mínimo de inversión, puedes llegar a muchas personas.",
  "postflow.boost.unactive.again.cta": "¡Muéstrame de nuevo!",
  "postflow.selectimage.pipermessage.mobil":
    "Selecciona una imagen o foto desde tu dispositivo",
  "postflow.instagram.connect.modal.tip":
    "Para conectar tu cuenta de negocio de Instagram, <TipHighlight>primero conecta tu página de negocio de Facebook.</TipHighlight>",
  "postflow.instagram.connect.modal.help":
    "¿No tienes una pagina de negocio de Facebook?",
  "postflow.instagram.connect.modal.help.link": "Crea una ahora",
  "postflow.instagram.connect.modal.tip.facebookConnected":
    "Sólo puedes conectar 1 cuenta de negocio de Instagram. <TipHighlight>¿No sabes si tu cuenta es de negocio? <TipLink>Revisa estos pasos.</TipLink></TipHighlight>",
  "postflow.instagram.connect.button": "Conectar Instagram",
  "postflow.create.file.selected.not.permited":
    "El archivo multimedia no está soportado",
  "postflow.create.file.not.selected":
    "El archivo multimedia no se ha seleccionado",
  "postflow.create.file.social.duration.max":
    "El video excede la duración permitida por {socialNetwork}. Tu video no debe durar más de 1 min.",
  "postflow.create.file.twitter.duration.max":
    "El video excede la duración permitida por Twitter. Tu video no debe durar más de 2 min. 15 seg.",
  "postflow.create.file.size.max":
    "En Instagram sólo puedes publicar 1 foto o video a la vez. En el caso del video no debe pesar más de 100 MB.",
  "postflow.create.file.permited.type":
    "El formato de tu video no es compatible. Sólo se aceptan video en formato MOV, MP4 o M4V.",
  "postflow.create.file.dimension.limit":
    "Instagram no permite las dimensiones del video, intenta cargar un video con otras dimensiones.",
  "postflow.schedule.progress.message.upload": "Subiendo vídeo",
  "postflow.schedule.progress.message.posting": "Publicando tu video",
  "postflow.schedule.progress.message.post.facebook": "Publicando a Facebook",
  "postflow.schedule.progress.message.post.instagram": "Publicando a Instagram",
  "postflow.schedule.progress.message.schedule.facebook":
    "Programando a Facebook",
  "postflow.schedule.progress.message.schedule.instagram":
    "Programando a Instagram",
  "postflow.schedule.progress.message.schedule.storiesig":
    "Programando a Instagram Stories",
  "postFlow.selectImage.dragDropText.video":
    "¡Ahora también puedes subir videos!",
  "postflow.create.file.selected.not.dimension":
    "Por favor verifica el formato de tu vídeo",
  "postflow.create.modal.instagram.wanrning.title":
    "El formato de tu video es vertical y excede el tamaño permitido por Instagram. Cuando se publique, será recortado automáticamente para ajustarse de forma apropiada.",
  "postflow.create.modal.instagram.wanrning.title.mobile":
    "El formato de tu video es vertical y excede el tamaño permitido por Instagram. Cuando se publique, será recortado automáticamente para ajustarse {lineBreak}de forma apropiada.",
  "postflow.create.modal.instagram.wanrning.subtitle":
    "Puedes continuar o volver a intentarlo con otro video.",
  "postflow.create.modal.instagram.wanrning.subtitle.mobile":
    "Puedes continuar o volver a {lineBreak}intentarlo con otro video.",
  "postflow.create.upload.other.video": "Subir otro video",
  "postflow.preview.video.warning":
    "En esta vista previa el video aún no se ha recortado para ajustarlo a Instagram y se ajustará cuando se publique. Asegúrate de que todo el contenido importante del video esté en el centro.",
  "postFlow.schedule.progress.information":
    "Esto puede demorar un poco, según la duración de tu video.",
  "postFlow.dragDrop.validation.mutimedia":
    "Sólo puedes publicar 1 video o imágenes.",
  "postflow.create.modal.instagram.install.title":
    "Asegúrese de tener la aplicación de Instagram instalada en su dispositivo.",
  "postflow.create.modal.instagram.install.subtitle": "Si no lo tiene, ",
  "postflow.create.modal.instagram.install.subtitle.link": "instalar aquí.",
  "postflow.schedule.publish.stories":
    "¡Ahora, vamos a publicar tu historia de Instagram!",
  "postflow.create.placeholder.storiesIG": "No se requiere texto",
  "postflow.schedule.storie.message.default":
    "Vamos a publicar tu historia en Instagram",
  "postflow.editor.modal.piper.text":
    "No has cargado tu logo aún.{lineBreak}¡Hazlo ahora! Así podrás visualizar todas las plantillas con tu logo incluido.{lineBreak}",
  "postflow.editor.modal.piper.text.small":
    "Puedes cambiarlo después en Configuración.",
  "postflow.create.modal.tiktok.wanrning.title.publishNow":
    'En breve, ingresa a la app móvil de TikTok y ve a la sección "Bandeja de entrada", ahí encontrarás una notificación para finalizar el proceso de publicación. Esto es debido a una limitante actual de TikTok.',
  "postflow.create.modal.tiktok.wanrning.title.schedule":
    'Debido a una limitante de TikTok, una vez que llegue la hora y fecha para publicar tu video, recibirás un correo electrónico (y una notificación en tu app móvil de Social Piper) para que ingreses a la sección "Bandeja de entrada" en la aplicación móvil de TikTok y así permitas finalizar la publicación.',
  "postflow.create.modal.tiktok.checkbox": "No volver a mostrar este mensaje.",
  "postflow.schedule.publish.tiktok": "Publica tu TikTok ahora",
  "postflow.schedule.progress.message.post.tiktok": "Publicando a TikTok",
  "postflow.schedule.progress.message.schedule.tiktok": "Programando a TikTok",
  "postflow.scheduleModal.okbutton": "OK",
  "postflow.create.video.is.upload":
    "Espera un momento, tu video se está cargando",
  "postflow.schedule.title.video":
    "Continua haciendo otras actividades mientras termina el proceso de publicación.",
  "postflow.schedule.subtitle.video":
    "Consulta el progreso en la sección Calendario. Al concluir te enviaremos un correo electrónico.",
  "postflow.schedule.file.upload.fail":
    "El archivo multimedia no se cargo de manera correcta, intenta nuevamente",
  "postflow.schedule.piper.post.video.success":
    "“A partir de aquí{linebreak}¡Yo me encargo!”",
  "postflow.create.modals.caption.title": "Tu texto hablará de:",
  "postflow.create.modals.caption.generate": "Generar texto",
  "postflow.create.modals.caption.cancel": "Cancelar",
  "postflow.create.modals.confirmCaption.title": "Esta es una idea:",
  "postflow.create.modals.confirmCaption.cancel": "Cancelar",
  "postflow.create.modals.confirmCaption.goBack": "Volver a empezar",
  "postflow.create.modals.confirmCaption.regenerate": "Generar otra idea",
  "postflow.create.modals.confirmCaption.confirm": "Usar este texto",
  "postflow.create.modals.loading.create": "Estoy generando tu publicación.",
  "postflow.create.modals.loading.subtitlecreate":
    "Esto podría tomar minutos. {br} Por favor, ten paciencia.",
  "postflow.create.modals.loading.add": "Estoy generando tu nueva imagen",
  "postflow.alerts.missingConfigs.label":
    "Debe completar su perfil comercial. ",
  "postflow.alerts.missingConfigs.redirect": "Ir a ajustes",
};

export default es;
