import { createGlobalStyle } from 'styled-components';
import { h1, small, heading } from './mixins';
import GlobalTheme from './GlobalTheme';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  body{
    overflow-x: hidden;
    overscroll-behavior: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    background: ${({ theme }) => theme.colors.screenBackground};
    color: ${(props) => props.theme.colors.dark};
    font-family: ${(props) => props.theme.font.font1};
    font-style: normal;
    font-weight: normal;
    min-width:100vw;
    font-size: 18px;
    width: 100vw;
    height: 100vh;
    display: flex;
    ${GlobalTheme.breakpoints.down('md')}{
      font-size: 18px;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  /** we should check this */
  img {
    object-fit: cover;
  }

  h1,h2,h3 {
    ${heading}
  }

  ${GlobalTheme.breakpoints.down('md')}{
    h1,h2,h3 {
      line-height: 120%;
      font-weight: 600;
    }
  }

  h1 {
    ${h1};
  }

  small {
    ${small};
  }

  a {
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
  }

  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  .clamp-lines__button {
    color: ${(props) => props.theme.colors.primary};
    font-family: ${(props) => props.theme.font.font1};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    border-radius: none;
    cursor: pointer;
    &:focus{
      outline: none;
    }
    &:hover {
      color: ${(props) => props.theme.colors.primaryDark};
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 -10px 0 -6px;
    }
    40% {
      box-shadow: 0 -10px 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 -10px 0 -6px;
    }
    40% {
      box-shadow: 0 -10px 0 0;
    }
  }

  @-webkit-keyframes vibrating {
    0% {
      transform: translateY(-20px);
    }
    50% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(-20px);
    }
  }

  @keyframes vibrating {
    0% {
      transform: translateY(-20px);
    }
    50% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(-20px);
    }
  }

  @-webkit-keyframes circleChartProgress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  @keyframes circleChartProgress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  @keyframes pawAnimation {
    0%{
      opacity: 1;
    }
    50%{
      opacity: 0;
    }
    100%{
      opacity: 0;
    }
  }

  .circleChartProgressClass {
    animation-fill-mode: both;
    animation: circleChartProgress 1s ease-out forwards;
    -webkit-animation: circleChartProgress 1s ease-out forwards;
  }

  @keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }
`;

export default GlobalStyles;
