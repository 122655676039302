const en = {
  'trialEnds.modal.hasPayment': 'Activate your Social Piper plan! Change your payment method*',
  'trialEnds.modal.notPayment': 'Enter a payment method to activate your plan with Social Piper',
  'trialEnds.modal.title': 'Your trial period has ended',
  'trialEnds.modal.subtitle1': 'Don’t miss your access to all these benefits:',
  'trialEnds.modal.subtitle2': 'Do not miss the best moments to publish on your networks this week:',
  'trialEnds.modal.subtitle3': 'Don’t miss the opportunity to reach up to',
  'onlyPro.modal.message': 'Oh no! This app is only available for users with Pro and Business Plan.',
  'onlyPro.create.modal.message': 'Oh no! You don\'t have an account yet! Create a new one in just a few of minutes at socialpiper.com',
  'update.session.modal.message': 'In order to validate your email, you\'ll need to login again.',
  'trialEnds.modal.benefits.benefit1': '<s>Best day and time</s> to post',
  'trialEnds.modal.benefits.benefit2': '<s>Thousands of templates</s> ready to use or edit',
  'trialEnds.modal.benefits.benefit3': 'Smart guide to <s>investing in advertising</s>',
  'trialEnds.modal.benefits.benefit4': '<s>Reply to messages</s> from all your accounts in one place',
  'trialEnds.modal.audience.people': 'people',
  'trialEnds.modal.audience.text': 'creating a boost with the help of Piper.',
  'trialEnds.modal.hasPayment.text': '* The payment method you entered is invalid or you do not have a payment method available. Subscribe and change or add another payment method.',
  'modal.android.disabled': 'To continue using Social Piper, please open your web browser and go to www.socialpiper.com',
};

export default en;
