import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../styled/theme';
import Pipers from '../Pipers/Pipers';
import { Loading } from '../../styled/GlobalComponents';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${colors.rocketPiperBackground};
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  left:0;
  overflow: hidden;
  z-index: 999;
`;

const CustomPiper = styled(Pipers.RocketGIF)`
  width: 270px;
  height: auto;
  -webkit-animation: vibrating 1.8s infinite ease-in-out;
  animation: vibrating 1.8s infinite ease-in-out;
`;

const CustomLoading = styled(Loading)`
  margin: 24px 0;
`;

const RocketLoader = ({
  children,
}) => (
  <Container>
    <CustomPiper />
    <CustomLoading />
    {children}
  </Container>
);

RocketLoader.propTypes = {
  children: PropTypes.node,
};

RocketLoader.defaultProps = {
  children: null,
};

export default RocketLoader;
