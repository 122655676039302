/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import React from 'react';
import useCallGA from '../../hooks/useCallGA';

/**
 * A react-router Link or NavLink with Google Analytics events injected.
 * @summary This component should be used whenever we want to navigate with a button.
 * It injects an Google Analytics event before redirecting
 *
 * @param {string} to [optional] - location to redirect. Can handle a redirect with injected data.
 * @param {Object} ga [optional] - Google analytics optional additional information.
 * @param {function} onClick [optional] - Additional callback to call on Click, you can handle an
 * to add styles when the url destination is selected
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */
export default function AnalyticsAnchor({
  href,
  gaData = {},
  onClick,
  children,
  className,
  ...props
}) {
  const callGA = useCallGA();

  const handleClick = () => {
    if (gaData.action) callGA(gaData, href);
    onClick();
  };

  return (
    <a
      className={className}
      onClick={handleClick}
      href={href}
      {...props}
    >
      {children}
    </a>
  );
}

AnalyticsAnchor.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  gaData: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  href: PropTypes.string,
};

AnalyticsAnchor.defaultProps = {
  children: null,
  className: '',
  gaData: {},
  onClick: () => {},
  href: '',
};
