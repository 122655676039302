const es = {
  'boostManager.ad.title': 'TU CAMPAÑA',
  'boostManager.ad.status.Deleted': 'Eliminado',
  'boostManager.ad.status.deleted': 'Eliminado',
  'boostManager.ad.status.Finished': 'Terminados',
  'boostManager.ad.status.finished': 'Terminados',
  'boostManager.ad.status.Paused': 'Pausado',
  'boostManager.ad.status.paused': 'Pausado',
  'boostManager.ad.status.Rejected': 'Rechazado',
  'boostManager.ad.status.rejected': 'Rechazado',
  'boostManager.ad.status.Review': 'En revisión',
  'boostManager.ad.status.review': 'En revisión',
  'boostManager.ad.status.Pending': 'Pendiente',
  'boostManager.ad.status.pending': 'Pendiente',
  'boostManager.ad.status.Running': 'Corriendo',
  'boostManager.ad.status.running': 'Corriendo',
  'boostManager.ad.status.Scheduled': 'Programada',
  'boostManager.ad.status.scheduled': 'Programada',
  'boostManager.ad.status.in_process': 'Configurando',
  'boostManager.ad.status.error': 'Error',
  'boostManager.ad.status.active': 'Activa',
  'boostManager.ad.status.failed': 'Fallido',
  'boostManager.advice.sufficientBudget': '“Todavía tienes presupuesto suficiente para promocionar tus publicaciones y llegar a 4,000 nuevos clientes este mes..”',
  'boostManager.budget.commited.defineBudget': 'Define tu presupuesto mensual',
  'boostManager.budget.committed.title': 'Estado del presupuesto comprometido',
  'boostManager.budget.define': 'Define tu presupuesto',
  'boostManager.budget.edit': 'Edita tu presupuesto',
  'boostManager.budget.editCta': 'Edita tu presupuesto',
  'boostManager.budget.monthly.resets': 'Se reinicia el {date}',
  'boostManager.budget.monthly.title': 'Presupuesto mensual',
  'boostManager.budget.overTime.spenUsed.facebook': 'Facebook',
  'boostManager.budget.overTime.spenUsed.linkedin': 'LinkedIn',
  'boostManager.budget.overTime.subtitle': 'Cuánto has invertido en los últimos 3 meses.',
  'boostManager.budget.overTime.title': 'Historial de tu presupuesto',
  'boostManager.budget.title': 'Tu presupuesto mensual de campañas',
  'boostManager.delete.message': 'De verdad quieres detener tu campaña publicitaria? {lineBreak}{lineBreak} Esto parará y cerrará la campaña. Si crees que puedes volver a usarla, mejor selecciona pausar.',
  'boostManager.delete.title': 'Borrar tu campaña',
  'boostManager.delete.cta': 'Sí, terminar mi campaña',
  'boostManager.edit.title': 'Edita la duración de tu campaña',
  'boostManager.edit.cta': 'Guarda la duración de mi campaña',
  'boostManager.hero.adjustBudget': 'Ajusta tu presupuesto mensual',
  'boostManager.hero.message': '"Te ayudaré a obtener el mayor valor con tu presupuesto"',
  'boostManager.hero.reach': 'Público alcanzado en {month}: <s>{reach}</s>',
  'boostManager.noBoosts': 'Una vez que inicies una promoción, la información sobre cuándo comenzó, alcance, reacciones, comentarios, compartidos, gasto hasta la fecha y estado se mostrará aquí.',
  'boostManager.pause.message': '¿Realmente quieres pausar tu campaña?',
  'boostManager.pause.title': 'Pausa tu campaña',
  'boostManager.pause.cta': 'Sí, pausa mi campaña',
  'boostManager.postsTable.active': 'Campañas activas',
  'boostManager.postsTable.deleted': 'Campañas borradas',
  'boostManager.postsTable.lastMonth': 'El mes pasado',
  'boostManager.postsTable.paused': 'Campañas pausadas',
  'boostManager.postsTable.thisMonth': 'Este mes',
  'boostManager.postsTable.title': 'Campañas publicitarias',
  'boostManager.run.message': '¿Realmente quieres reanudar esta campaña?',
  'boostManager.run.title': 'Corre tu campaña',
  'boostManager.run.cta': 'Sí, reanuda mi campaña',
  'boostManager.runningUntil': '<b>Activa</b> hasta el {date}',
  'boostManager.spendToData': 'Consumido',
  'boostManager.editDuration': 'Editar duración',
  'boostManager.budget.tooltip.text': 'Has alcanzado tu presupuesto mensual. ¡Edítalo!',
};

export default es;
