/* eslint-disable max-len */
/* eslint-disable no-return-assign */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Media from 'react-media';
import { useIntl } from 'react-intl';
import AuthService from '../../../services/auth/AuthService';
import { ButtonLink as Link } from '../../buttons';
import mediaQueries from '../../../utils/mediaQueries';
import DesktopLoggedFooter from './DesktopLoggedFooter';
import MobileLoggedFooter from './MobileLoggedFooter';
import FooterWithoutAuth from './FooterWithoutAuth';
import { container } from '../../../styled/mixins';
import GlobalTheme from '../../../styled/GlobalTheme';
import { WebViewContext } from '../../../contexts/WebViewContext';
// eslint-disable-next-line import/no-named-as-default
import PublicRoutes from '../../../utils/constants/routes';
import { santanderBadge } from '../../../assets/images/imageRepository';

const FooterContainer = styled.div`
  background: ${(props) => props.theme.colors.secondary};
  position: relative;
`;

export const FooterWrapper = styled.div`
  display: flex;
  ${container}
  padding-top: 16px;
  padding-bottom: 11px;
  ${GlobalTheme.breakpoints.up('xl')} {
    max-width: 1440px;
    padding-right: 40px;
    padding-left: 40px;
  }
  ${({ isApp }) => isApp && `
    ${GlobalTheme.breakpoints.down('sm')}{
      padding: 4px 8px;
    }
  `}
`;

export const ButtonLink = styled(Link)`
  && {
    ${({ loged }) => (loged ? '' : 'display:block;')}
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: ${(props) => props.theme.colors.text02};
    &:hover {
      color: ${(props) => props.theme.colors.text02};
    }
  }
`;

const BadgeContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  @media ${mediaQueries.lgPlusUp} {
    width: 230px;
  }
  @media ${mediaQueries.xlPlusUp} {
    width: 327px;
  }
`;
const Badge = styled.img`
  width: 100%;
`;

const getFooterComponent = (matches, logged, location, isApp) => {
  if (location.pathname === PublicRoutes.CREATE_ACCOUNT && isApp) return <MobileLoggedFooter />;
  if (location.pathname === PublicRoutes.CREATE_ACCOUNT_IOS && isApp) return <MobileLoggedFooter />;
  if (matches.xlUp && logged) return <DesktopLoggedFooter />;
  if (!matches.xlUp && logged) return <MobileLoggedFooter />;
  return <FooterWithoutAuth />;
};

const Footer = () => {
  const location = useLocation();
  const intl = useIntl();
  const [logged, setLogged] = useState(true);
  const { isApp, isAppIOS } = useContext(WebViewContext);
  useEffect(() => {
    setLogged(AuthService.isAuthenticated());
  }, [location]);
  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <FooterContainer>
          <FooterWrapper isApp={isApp || isAppIOS}>
            {getFooterComponent(matches, logged, location, isApp)}
          </FooterWrapper>
          {matches.xlPlusUp && !logged && (
            <BadgeContainer>
              <Badge
                src={
                  intl.locale === 'es'
                    ? santanderBadge.desktopEs
                    : santanderBadge.desktop
                }
              />
            </BadgeContainer>
          )}
        </FooterContainer>
      )}
    </Media>
  );
};

export default Footer;
