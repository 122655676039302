import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import InView from 'react-intersection-observer';
import Media from 'react-media';
import GlobalTheme, { breakpointValues } from '../../../styled/GlobalTheme';
import { LandingText } from './Landing.styles';
import mediaQueries from '../../../utils/mediaQueries';

const Step = styled.div`
  max-width: 100vw;
  margin-left: 0;
  ${({ section }) => {
    switch (section) {
      case 'meetPiper':
        return css`
          margin-top: 0;
          @media ${mediaQueries.lgPlusUp} {
            margin-left: 36px;
          }
          @media ${mediaQueries.xlPlusUp} {
            margin-left: 0;
          }
        `;
      case 'scheduling':
        return css`
          margin-top: 38px;
          @media ${mediaQueries.mdUp} {
            margin-left: 14px;
            margin-top: 42px;
          }
          @media ${mediaQueries.lgPlusUp} {
            margin-left: 48px;
            margin-top: 46px;
          }
          @media ${mediaQueries.xlPlusUp} {
            margin-left: 24px;
            margin-top: 60px;
          }
        `;
      case 'post':
        return css`
          margin-top: 50px;
          @media ${mediaQueries.mdUp} {
            margin-top: 45px;
          }
          @media ${mediaQueries.lgPlusUp} {
            margin-left: 24px;
            margin-top: 37px;
          }
          @media ${mediaQueries.xlPlusUp} {
            margin-left: 0;
            margin-top: 52px;
          }
        `;
      case 'budget':
        return css`
          margin-top: 52px;
          @media ${mediaQueries.mdUp} {
            margin-left: 37px;
            margin-top: 45px;
          }
          @media ${mediaQueries.lgPlusUp} {
            margin-left: 76px;
          }
          @media ${mediaQueries.xlPlusUp} {
            margin-left: 67px;
            margin-top: 80px;
          }
        `;
      default:
        break;
    }
    return css``;
  }}
`;

const FeatureDogoContainer = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  max-width: 1035px;
  grid-column-gap: ${(props) => props.theme.space * 4}px;
  width: 100%;
  grid-template-columns:${(props) => (props.piperRight ? '430px 510px' : '510px 430px')};
  ${({ section, piperRight }) => section === 'meetPiper' && `
    grid-template-columns: ${piperRight ? '656px minmax(auto, 481px)' : 'minmax(auto, 481px) 656px'};
    max-width: 1256px;
    @media (max-width: 1024px) {
      grid-template-columns: 470px minmax(auto, 352px);
      max-width: 900px;
    }
    @media ${mediaQueries.mdDown} {
      grid-template-columns: 361px minmax(auto, 259px);
      max-width: 666px;
    }
  `}
  ${({ section, piperRight }) => section === 'scheduling' && `
    grid-template-columns: ${piperRight ? '425px 530px' : '530px 425px'};
    max-width: 1140px;
    @media (max-width: 1024px) {
      grid-template-columns: 367px 294px;
      max-width: 803px;
    }
    @media ${mediaQueries.mdDown} {
      grid-template-columns: 280px minmax(auto, 216px);
      max-width: 598px;
    }
  `}
  ${({ section, piperRight }) => section === 'post' && `
    grid-template-columns: ${piperRight ? '660px minmax(auto, 530px)' : 'minmax(auto, 530px) 660px'};
    max-width: 1240px;
    @media (max-width: 1024px) {
      grid-template-columns: 472px minmax(auto, 344px);
      max-width: 877px;
    }
    @media ${mediaQueries.mdDown} {
      grid-template-columns: 348px minmax(auto, 253px);
      max-width: 648px;
    }
  `}
  ${({ section }) => section === 'budget' && `
    grid-template-columns: 434px 652px;
    max-width: 1177px;
    @media (min-width: 1024px) and (max-width: 1280px) {
      grid-template-columns: 298px 501px;
      max-width: 893px;
    }
    @media ${mediaQueries.mdDown} {
      grid-template-columns: 244px minmax(auto, 350px);
      max-width: 663px;
    }
  `}
  grid-template-areas:"${(props) => (props.piperRight ? 'feature info' : 'info feature')}";
  ${GlobalTheme.breakpoints.down('sm')} {
    max-width: 400px;
    margin: 0 auto;
    grid-template-columns: 100%;
    grid-template-rows: fit-content;
    grid-template-areas: "feature" "info";
    grid-gap: ${(props) => props.theme.space * 2}px;
    justify-items: center;
  }
`;

const ImageContainer = styled.div`
  @media ${mediaQueries.smDown} {
    width: fit-content;
  }
`;

const Feature = styled.img`
  width: 100%;
  margin: auto;
  grid-area: feature;
  ${GlobalTheme.breakpoints.down('md')} {
    margin: 0;
  }
`;
const FeatureContainer = styled.div`
  ${GlobalTheme.breakpoints.down('sm')} {
  ${({ section }) => {
    let cssSection;
    switch (section) {
      case 'scheduling':
        cssSection = `
          width: 157px;
          height: 177px;
        `;
        break;
      case 'post':
        cssSection = `
          width: 281px;
          height: 187px;
      `;
        break;
      case 'budget':
        cssSection = `
          width: 289px;
          height: 139px;
      `;
        break;
      default:
        cssSection = '';
        break;
    }
    return cssSection;
  }}
  }
`;

const slideIn = (piperRight, inView) => css`
  transition-property: transform, opacity;
  transition-duration: .3s;
  transition-delay: 0;
  transition-timing-function: ease-in-out;
  ${!inView && `
    opacity: 0;
    transform: ${piperRight ? 'translateX(100%)' : 'translateX(-100%)'};
  `}
`;

const Info = styled.div`
  width: 100%;
  grid-area: info;
  ${(props) => slideIn(props.piperRight, props.inView)};
`;

const Title = styled.h3`
  font-size: 19px;
  margin-bottom: 4px;
  font-weight: 700;
  color: ${({ color }) => color};
  @media (max-width: 1240px) {
    font-size: 14px;
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    font-size: 10px;
    margin-bottom: 0;
  }
`;

const SubTitle = styled.p`
  font-size: 35px;
  line-height: 44px;
  margin: 0;
  margin-bottom: 3px;
  @media (max-width: 1240px) {
    font-size: 24px;
    line-height: 33px;
  }
  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 25px;
  }
`;

const CustomLandingText = styled(LandingText)`
  font-size: 20px;
  line-height: 32px;
  @media (max-width: 1240px) {
    font-size: 14px;
    line-height: 22px;
  }
  @media ${mediaQueries.mdDown} {
    font-size: 12px;
    line-height: 20px;
  }
`;

export default function PiperStep({ content }) {
  const piperRight = content.piperPosition === 'right';
  return (
    <Media queries={{
      sm: `(max-width: ${breakpointValues.md - 1}px)`,
      md: `(max-width: ${breakpointValues.lgPlus - 1}px)`,
      lg: `(max-width: ${breakpointValues.xlPlus - 1}px)`,
    }}
    >
      {(matches) => {
        let image = content.feature.desktop;
        if (matches.md) image = content.feature.tablet;
        if (matches.lg) image = content.feature.tabletPro;
        if (matches.sm) image = content.feature.mobile;
        return (
          <Step key={content.key} section={content.key}>
            <InView threshold={0.3}>
              {({ inView, ref }) => (
                <FeatureDogoContainer
                  piperRight={piperRight}
                  ref={ref}
                  section={content.key}
                >
                  <ImageContainer>
                    <FeatureContainer section={content.key}>
                      <Feature piperRight={piperRight} src={image} alt="feature" />
                    </FeatureContainer>
                  </ImageContainer>
                  <Info piperRight={piperRight} inView={inView}>
                    <Title color={content.color}>{content.title}</Title>
                    <SubTitle>{content.subtitle}</SubTitle>
                    <CustomLandingText>{content.textContent}</CustomLandingText>
                  </Info>
                </FeatureDogoContainer>
              )}
            </InView>
          </Step>
        );
      }}
    </Media>
  );
}

PiperStep.propTypes = {
  content: PropTypes.shape({
    feature: PropTypes.string,
    hideLine: PropTypes.bool,
    key: PropTypes.string,
    piperPosition: PropTypes.string,
    src: PropTypes.string,
    style: PropTypes.any,
    textContent: PropTypes.any,
    title: PropTypes.any,
    subtitle: PropTypes.any,
    color: PropTypes.string,
  }),
};

PiperStep.defaultProps = {
  content: {
    feature: null,
    hideLine: null,
    key: null,
    piperPosition: '',
    src: null,
    style: null,
    textContent: null,
    title: null,
    subtitle: null,
    color: '',
  },
};
