import { useRef, useState, useEffect } from 'react';

export default function useWidth(requirement = []) {
  const containerRef = useRef(null);
  const [width, setWidth] = useState(0);
  const getWidth = () => {
    if (containerRef.current) setWidth(containerRef.current.offsetWidth);
  };
  useEffect(() => {
    getWidth();
    const resizeEvent = window.addEventListener('resize', () => {
      getWidth();
    });
    return (() => {
      window.removeEventListener('resize', resizeEvent);
    });
  }, [containerRef, ...requirement]);
  return [containerRef, width];
}
