export const DoggoLogo = require('./doggo.svg');
export const DoggoBorderWhiteLogo = require('./doggo-border-white.svg');
export const LettersLogo = require('./logotype.svg');
export const FullLogoWithSlogan = require('./logotype-slogan.svg');
export const FullLogo = require('./doggoText.svg');
export const LoginLogo = require('./login-logo.png');
export const AcademyLogoLetters = require('./logo-academy.png');
export const AcademyLogoVertical = require('./logo-academy-vertical.png');
export const PiperHolder = require('./piperHolder.jpg');
export const SantanderChallenge = require('./santander-challenge-logo.svg');
