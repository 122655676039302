export const SEVERITY = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

export const VARIANT = {
  FILLED: 'filled',
  OUTLINED: 'outlined',
  STANDARD: 'standard',
};

export const DEFAULT_DURATION = 8000;

export const DURATIONS = {
  SHORT: 3000,
  DEFAULT: 8000,
  LONG: 16000,
};
