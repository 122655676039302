const en = {
  'tutorialModal.skip': 'Skip',
  'tutorialModal.done': 'Done',
  'tutorialModal.slide1.title': 'Now you’re set!',
  'tutorialModal.slide1.text': 'Check your Piper Score and see how your business is doing in your Dashboard.',
  'tutorialModal.slide2.text': 'Check the Creative Center and choose from my recommendations or from our hundreds of ready-to-use templates',
  'tutorialModal.slide3.text': 'Get my Good time to post recommendations to publish your content, schedule it and organize your content and Ads',
  'tutorialModal.slide4.text': 'Check at a glance how are your Social Ads performing and manage your advertising Budget',
  'tutorialModal.slide5.text': 'Interact with your social media users in a single place by connecting your social profiles',
  'tutorialModal.piperTip': "Hi! I'm Piper",
  'tutorialModal.createapp.slide1.title': 'Welcome',
  'tutorialModal.createapp.slide1.text': 'Use the Piper Score and see how your business is doing in your Dashboard.',
  'tutorialModal.createapp.slide2.text': 'Check the Creative Center and choose from my recommendations or from our hundreds of ready-to-use templates.',
  'tutorialModal.createapp.slide3.text': 'Get my Good time to post recommendations to publish your content, schedule it and organize your content and Ads.',
  'tutorialModal.createapp.slide4.text': 'Easily create Social Ads, check their performance and manage your advertising budget.',
  'tutorialModal.createapp.slide5.text': 'Interact with your social media users in a single place by connecting your social profiles.',
};

export default en;
