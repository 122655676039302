import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { flexBox } from '../../../../../styled/mixins';
import CardRadio from './CardRadio';

const CardsRadioGroup = styled.div`
  ${flexBox}
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

const CardsSelector = ({
  paymentMethods,
  handleClickCard,
  handleDeleteCard,
}) => (
  <CardsRadioGroup>
    {paymentMethods.map((card) => (
      <CardRadio
        key={`Card_${card.id}`}
        card={card}
        handleClickCard={handleClickCard}
        handleDeleteCard={handleDeleteCard}
        hasMoreOnePayment={paymentMethods.length > 1}
      />
    ))}
  </CardsRadioGroup>
);

CardsSelector.propTypes = {
  paymentMethods: PropTypes.instanceOf(Array).isRequired,
  handleClickCard: PropTypes.func.isRequired,
  handleDeleteCard: PropTypes.func.isRequired,
};

export default CardsSelector;
