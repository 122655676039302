import React, { useState, useContext, useEffect, useRef } from "react";
import Axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import { addDays } from "date-fns";
import { useIntl } from "react-intl";
import { SocialContext } from "./SocialContext";
import { PiperRecommendationsProvider } from "../services/entities";
import { sortSocialNetworks } from "../utils";
import AuthService from "../services/auth/AuthService";
import { STORIES_IG, TIKTOK } from "../utils/constants/globals";
import { WebViewContext } from "./WebViewContext";
import SchedulerProvider from "../services/entities/SchedulerProvider";
import PostFlowProvider from "../services/entities/PostFlowProvider";

export const PostFlowContext = React.createContext({});

const PostFlowContextClass = ({ children }) => {
  console.warn({ children });
  const intl = useIntl();
  const { socialNetworks } = useContext(SocialContext);
  const { isApp, isAppIOS } = useContext(WebViewContext);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [postCaption, setPostCaption] = useState("");
  const [where2Post, setWhere2Post] = useState({});
  const [publishNow, setPublishNow] = useState({});
  const [time2Post, setTime2Post] = useState({});
  const [showQuickPost, setShowQuickPost] = useState(false);
  const [canAddLogo, setCanAddLogo] = useState(true);
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [postIsBeingCreatedAutomatically, setPostIsBeingCreatedAutomatically] =
    useState(false);

  const [defaultMessageStorie] = useState(
    intl.formatMessage({
      id: "postflow.schedule.storie.message.default",
      defaultMessage: "Let's post your scheduled Instagram story",
    })
  );
  const linkAppInstallRef = useRef(false);

  const [showIGAppInstallModal, setShowIGAppInstallModal] = useState(false);
  const [basicNetwork, setBasicNetwork] = useState(true);
  const [publishStories, setPublishStories] = useState(false);
  const [sendNowStories, setSendNowStories] = useState(false);
  const [urlMediaIG, setUrlMediaIG] = useState("");
  const [responseUploadVideo, setResponseUploadVideo] = useState({});
  const [haveVideo, setHaveVideo] = useState(false);
  const [mediaIsGeneratedByPiper, setMediaIsGeneratedByPiper] = useState(false);
  const [captionIsGeneratedByPiper, setCaptionIsGeneratedByPiper] = useState(false);

  const overrideSelectedMedia = (media) => {
    if (!media) {
      setSelectedMedia([]);
    } else {
      setSelectedMedia(media);
    }
  };

  const updateWhere2Post = (w2Post) => {
    const updateW2P = {
      ...where2Post,
      ...w2Post,
    };
    setWhere2Post(updateW2P);
    const haveNetworkBasic =
      updateW2P.twitter ||
      updateW2P.facebook ||
      updateW2P.instagram ||
      updateW2P.linkedin ||
      updateW2P.tiktok;
    setBasicNetwork(haveNetworkBasic);
  };

  const updateTime2Post = (t2Post) => {
    const [sn] = Object.keys(t2Post);
    setTime2Post({
      ...time2Post,
      ...t2Post,
    });
    if (t2Post[sn]) {
      const npnObj = {
        [sn]: false,
      };
      setPublishNow({
        ...publishNow,
        ...npnObj,
      });
    }
  };

  const updatePublishNow = (pn) => {
    const [sn] = Object.keys(pn);
    setPublishNow({
      ...publishNow,
      ...pn,
    });
    if (pn[sn]) {
      const n2tp = {
        [sn]: new Date(),
      };
      const st2p = {
        ...time2Post,
        ...n2tp,
      };
      setTime2Post(st2p);
    }
  };

  const getRecommendations = (
    socialNetwork,
    posts,
    recommendation,
    newTime2Post
  ) => {
    let hasPost = false;
    const postsByNetwork = posts.filter((post) => post.type === socialNetwork);
    if (postsByNetwork?.length) {
      postsByNetwork.forEach((post) => {
        const postDate = new Date(post.publishTime).getTime();
        const recommendationDate = new Date(recommendation).getTime();
        if (postDate === recommendationDate && socialNetwork === post.type) {
          hasPost = true;
        }
      });
    } else {
      hasPost = false;
    }
    if (!hasPost) {
      // eslint-disable-next-line no-param-reassign
      newTime2Post[socialNetwork] = recommendation;
    } else {
      // eslint-disable-next-line no-param-reassign
      newTime2Post[socialNetwork] = addDays(recommendation, 1);
    }
  };

  const initPostFlowObj = async (cb, overrideSN) => {
    overrideSelectedMedia([]);
    setPostCaption("");
    if (AuthService.isAuthenticated()) {
      const tempSN = overrideSN || socialNetworks;
      const snKeys = Object.keys(tempSN).sort(sortSocialNetworks);
      const w2P = {};
      const timeObjs = [];
      snKeys.forEach((sn) => {
        const objSn = tempSN[sn];
        if (objSn && objSn.pageName && !objSn.toSetup) {
          w2P[sn] = true;
          publishNow[sn] = false;
          timeObjs.push(
            PiperRecommendationsProvider.fetchBestTimeToPostbySN(sn)
          );
        } else {
          timeObjs.push(true);
        }
      });
      const recommendTimes = await Promise.all(timeObjs);
      const newTime2Post = {};
      await Promise.all(
        snKeys.map(async (sn, idx) => {
          const objSn = tempSN[sn];
          if (objSn && objSn.pageName && !objSn.toSetup) {
            if (recommendTimes[idx]) {
              const timeResponse = recommendTimes[idx];
              if (timeResponse?.success) {
                const responsePosts = await SchedulerProvider.fetchPostsByDay(
                  timeResponse.data
                );
                getRecommendations(
                  sn,
                  responsePosts.data,
                  timeResponse.data,
                  newTime2Post
                );
                if (sn === TIKTOK) {
                  const tiktokDate = new Date();
                  tiktokDate.setHours(tiktokDate.getHours() + 1);
                  newTime2Post.tiktok = tiktokDate;
                }
              } else {
                newTime2Post[sn] = new Date();
              }
            }
          }
        })
      );
      if (isApp) {
        w2P.storiesIG = false;
        const storiesDate = new Date();
        storiesDate.setHours(storiesDate.getHours() + 1);
        newTime2Post.storiesIG = storiesDate;
        publishNow.storiesIG = false;
        newTime2Post.tiktok = storiesDate;
      }

      setWhere2Post(w2P);
      setPublishNow(publishNow);
      setTime2Post(newTime2Post);
      if (cb) {
        cb();
      }
    }
  };

  const openQuickPostFlow = async (cb, oSN) => {
    setShowQuickPost(true);
    await initPostFlowObj(cb, oSN);
  };

  const closeQuickPostFlow = () => {
    setShowQuickPost(false);
  };

  const getPublishObj = () => {
    const selectedKeys = Object.keys(where2Post).filter((sn) => where2Post[sn]);
    const existStories = where2Post.storiesIG;
    if (!selectedKeys.length) {
      throw new Error("postflow.create.alertMissingSocial");
    }
    if (!postCaption && !existStories) {
      throw new Error("postflow.create.missingCaptionEmpty");
    }
    if (!selectedMedia) {
      throw new Error("postflow.create.missing.image");
    }
    const sessionId = moment().unix();
    const publishObjs = selectedKeys.map((sn) => {
      const pnObj = publishNow[sn];
      const postObj = {
        socialNetwork: sn,
        selectedMedia,
        caption: postCaption,
        sessionId,
      };
      if (sn === STORIES_IG) {
        setPublishStories(true);
        setSendNowStories(pnObj);
        postObj.caption = defaultMessageStorie;
      }
      if (pnObj) {
        postObj.publishNow = pnObj;
      } else {
        postObj.publishNow = pnObj;
        postObj.scheduleTime = time2Post[sn];
      }
      return postObj;
    });
    return publishObjs;
  };

  const validateInstagramInstall = () => {
    if (isApp && !isAppIOS) {
      try {
        const isAppInstall = JSON.parse(
          window.Android.validityInstagramInstall()
        );
        if (isAppInstall) {
          updateWhere2Post({ storiesIG: !where2Post.storiesIG });
        }
        if (!isAppInstall) {
          setShowIGAppInstallModal(true);
        }
        // eslint-disable-next-line no-empty
      } catch {}
    }
    if (isAppIOS) {
      if (linkAppInstallRef?.current) {
        linkAppInstallRef.current.click();
      }
    }
  };

  const ourRequest = Axios.CancelToken.source();

  const uploadVideoServer = async (arrayImages) => {
    const listVideo = arrayImages.filter((image) => image.isVideo);
    if (listVideo.length && !haveVideo) {
      setUploadingVideo(true);
      setHaveVideo(true);
      const responseVideoUpload = await PostFlowProvider.uploadVideo(
        listVideo,
        false,
        ourRequest
      );
      setResponseUploadVideo(responseVideoUpload);
      return responseVideoUpload;
    }
    if (haveVideo) return responseUploadVideo;
    return { success: false };
  };

  useEffect(() => {
    window.setVerifyAppIOS = (flag) => {
      const saveWhere2Post = JSON.parse(localStorage.getItem("where2Post"));
      const lastState = saveWhere2Post.storiesIG;
      saveWhere2Post.storiesIG = !lastState;
      if (flag === 1) {
        updateWhere2Post(saveWhere2Post);
      }
      if (flag === 0) {
        setShowIGAppInstallModal(true);
      }
    };
  }, []);

  useEffect(() => {
    if (isAppIOS) {
      localStorage.setItem("where2Post", JSON.stringify(where2Post));
    }
  }, [where2Post]);

  return (
    <PostFlowContext.Provider
      value={{
        where2Post,
        publishNow,
        time2Post,
        showQuickPost,
        postCaption,
        setPostCaption,
        setShowQuickPost,
        selectedMedia,
        overrideSelectedMedia,
        updateWhere2Post,
        updateTime2Post,
        updatePublishNow,
        getPublishObj,
        openQuickPostFlow,
        closeQuickPostFlow,
        canAddLogo,
        setCanAddLogo,
        showIGAppInstallModal,
        setShowIGAppInstallModal,
        validateInstagramInstall,
        linkAppInstallRef,
        basicNetwork,
        urlMediaIG,
        setUrlMediaIG,
        publishStories,
        sendNowStories,
        initPostFlowObj,
        uploadingVideo,
        setUploadingVideo,
        uploadVideoServer,
        ourRequest,
        responseUploadVideo,
        setResponseUploadVideo,
        setHaveVideo,
        mediaIsGeneratedByPiper,
        setMediaIsGeneratedByPiper,
        captionIsGeneratedByPiper,
        setCaptionIsGeneratedByPiper,
        postIsBeingCreatedAutomatically,
        setPostIsBeingCreatedAutomatically,
      }}
    >
      {children}
    </PostFlowContext.Provider>
  );
};

PostFlowContextClass.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PostFlowContextClass;
