const en = {
  'audienceInterestsSelect.helper': 'Start typing tags you think your audience will be into',
  'audienceInterestsSelect.label': 'Keywords related to your business',
  'audienceInterestsSelect.placeholder': 'E.g. Roast Beef, Mac & cheese',
  'audienceInterestsSelect.noOptionsMessage': 'We couldn’t find what you’re looking for. Try a more general or similar category.',
  'audienceLocationsSelect.helper': 'Start typing the name of the state, city or country and select from the list',
  'audienceLocationsSelect.label': 'Where are your clients?',
  'audienceLocationsSelect.alert.added.countryGroup': 'Do you want to replace your cities and/or countries with {region}?',
  'audienceLocationsSelect.alert.replace.countryGroup': 'Do you want to replace your regions with {location}?',
  'audienceLocationsSelect.alert.added.country': '{country} contains at least one location you already selected. Do you want to replace them?',
  'audienceLocationsSelect.alert.added.region': '{region} contains or is contained in at least one location you already selected. Do you want to replace them?',
  'audienceLocationsSelect.alert.added.city': '{city} is contained inside at least one location you already selected. Do you want to replace them?',
  'businessCategoriesSelect.label': 'Type of business',
  'businessCategoriesSelect.placeholder': 'Write and select an option',
  'businessLocationSelect.label': 'Your city',
  'competitors.search.facebook.disabled.placeholder': 'First connect your Facebook Business page.',
  'competitors.search.facebook.placeholder': 'Type the name of their Facebook Business page (i.e. "Coca Cola Official")',
  'competitors.search.instagram.disabled.placeholder': 'Please connect your Instagram business account.',
  'competitors.search.instagram.placeholder': 'Type the username of their Instagram account (i.e. "cocacola")',
  'competitors.search.helpText': 'Max 1 competitor.',
  'connectCompetitors.title': 'Connect your competitors',
  'connectCompetitors.title.upsell': 'Add as many compettiors as you want!',
  'competitors.piperTip.text': 'Add competitors to watch their activity and get inspiration for your posts.',
  'competitors.socialprofile.title': '{socialNetwork} Competitors',
  'competitors.socialprofile.subtitle': 'Start typing the exact name of the competitor, including special characters ( like -_+ @ ) and select from the options displayed. <s>Only Business pages will be displayed.</s>',
  'competitors.socialprofile.tip.facebook': '<h>Tip:</h> To find out if a page is valid, check to see if it has a "like" button.',
  'competitors.socialprofile.tip.instagram': '<h>Tip:</h> To find out if a profile is business, check that the profile on IG has a contact button.',
  'competitors.socialprofile.search.button': 'Add competitor',
  'competitors.socialprofile.search.helptext': 'For example type “CocaCola_Official”',
  'settings.billing.billingInformation.advice': 'This is used to pay your plan fees and Social Piper Boost fees.',
  'settings.billing.billingInformation.advice.app': 'This is only used to pay Boost fees on Social Piper; the subscription is made through the payment method associated with your account in the App Store.',
  'settings.billing.billingInformation.card.expirationAbbr': 'Exp',
  'settings.billing.billingInformation.newPayment': 'Add new payment method',
  'settings.billing.billingInformation.title': 'Your Billing Information',
  'settings.billing.billingInformation.tooltip.text': 'Click to change this card as your default method payment',
  'settings.billing.disableAccount.modal.confirm': 'Yes, disable account',
  'settings.billing.disableAccount.modal.piperTip': "If you delete your account all your information will be erased and you won't be able to access it again.",
  'settings.billing.disableAccount.modal.title': 'You are about to delete your account!',
  'settings.billing.disableAccount.question': "Do you want to permanently delete your account? We're sure we will miss you.",
  'settings.billing.disableAccount.title': 'Disable your account',
  'settings.billing.paymentHistory.chargeTypes.all': 'All charge types',
  'settings.billing.paymentHistory.chargeTypes.boost': 'Boosts fee',
  'settings.billing.paymentHistory.chargeTypes.subscription': 'Plan fee',
  'settings.billing.paymentHistory.dateFilter.currentMonth': 'This month',
  'settings.billing.paymentHistory.payment.concept.boostFees': '{prevMonth} Boost fees',
  'settings.billing.paymentHistory.payment.concept.monthlyPlan': 'Monthly Plan',
  'settings.billing.paymentHistory.payment.concept.apple.1': 'Monthly Plan',
  'settings.billing.paymentHistory.payment.concept.apple.2': 'Yearly Plan',
  'settings.billing.paymentHistory.payment.concept.android.1': 'Monthly Plan',
  'settings.billing.paymentHistory.payment.concept.android.2': 'Yearly Plan',
  'settings.billing.paymentHistory.title': 'Payment History',
  'settings.billing.payments.empty': 'Once you start with the One Plan Subscription and create a Boost, here you will see your payment history.',
  'settings.billing.planInformation.activateAccount': 'Activate your subscription',
  'settings.billing.planInformation.reActivateAccount': 'Reactivate your subscription',
  'settings.billing.planInformation.addPayment': 'To activate your subscription, add a payment method',
  'settings.billing.planInformation.autoPay': 'Auto-pay, next payment {date}',
  'settings.billing.planInformation.cancelSubscription': 'Cancel subscription',
  'settings.billing.planInformation.description': 'Keep enjoying your Free Trial! To continue using Social Piper after the free trial ends, you will need to subscribe for US$99/month to activate your subscription.',
  'settings.billing.planInformation.monthAbbr': 'mo',
  'settings.billing.planInformation.yearAbbr': 'yr',
  'settings.billing.planInformation.plan.manage': 'Manage plan',
  'settings.billing.planInformation.planDescription': 'You are currently on a {time} plan.',
  'settings.billing.planInformation.subscribed.planDescription': 'Subscribed to {time} plan.',
  'settings.billing.planInformation.promoCode.title': 'Promotional Code:',
  'settings.billing.planInformation.daysLeft': 'Free trial, {daysCount, plural, one {# day left} other {# days left}}',
  'settings.billing.planInformation.subscribed': 'You are now subscribed to Social Piper',
  'settings.billing.planInformation.canceled': 'You have canceled your subscription to Social Piper.',
  'settings.billing.planInformation.title': 'Your Plan Information',
  'settings.billing.planInformation.plansButton': 'Check out Social Piper plans',
  'settings.billing.planInformation.trialDescription': 'You are currently enjoying your free trial.',
  'settings.billing.planInformation.canceledSubscription': 'Canceled subscription',
  'settings.billing.planInformation.canceled.daysLeft': 'Your account will be active for: {daysCount, plural, one {# day} other {# days}}',
  'settings.billing.planInformation.trialName': 'Free Trial Plan',
  'settings.billing.planInformation.basicPlan.title': 'Lite Plan',
  'settings.billing.planInformation.litePlan.title': 'Lite Plan',
  'settings.billing.planInformation.proPlan.title': 'Pro Plan',
  'settings.billing.planInformation.businessPlan.title': 'Business Plan',
  'settings.billing.planInformation.basicPlan.description': 'Your Lite Plan features will be renewed on: {date}',
  'settings.billing.planInformation.basicPlan.upgrade': 'No more limitations! Access and edit +1000 exclusive templates, publish as much as you want, unlock the potential to reach more people  and more!',
  'settings.billing.planInformation.professional.title': 'Professional Plan',
  'settings.billing.planInformation.professional.downgrade.note': 'At the end of your period, if you don\'t activate your subscription, your account will be suspended.',
  'settings.business.advice': 'This information helps me deliver recommendations for your business like what to post, when to post, and how much money is needed in advertising to reach more of your audience.',
  'settings.business.businessInformation.advice': 'This content will help me provide insights and tips specifically tailored to your business',
  'settings.business.businessInformation.name': 'Business name',
  'settings.business.businessInformation.prefill': 'Pre-fill this data from your Facebook Business profile',
  'settings.business.businessInformation.sizeLogo': 'Photos should be less than 2MB',
  'settings.business.businessInformation.title': 'Business Information',
  'settings.business.businessExtraInformation.title': 'Business extra information',
  'settings.business.businessExtraInformation.advice': 'Filling out these fields will provide Piper with an enhanced ability to respond to your DMs or post comments.',
  'settings.business.businessInformation.updateLogo': '<color>Update</color> your business logo',
  'settings.business.primaryTarget.advice': 'For the best result, focus your efforts on the people that are most likely to be your customers. As much of your business is',
  'settings.business.primaryTarget.prefill': 'Pre-fill the Audience Interest from your Facebook Ad Account',
  'settings.business.primaryTarget.recommended': 'Recommended',
  'settings.business.primaryTarget.title': 'Your primary target',
  'settings.business.primaryTarget.tooBroad': 'Too broad',
  'settings.business.primaryTarget.tooSpecific': 'Too specific',
  'settings.personal.advice': 'Keep your social profiles up to date so we can help you make the most of every post!',
  'settings.personal.personalInformation.email': 'E-mail',
  'settings.personal.personalInformation.firstName': 'First Name',
  'settings.personal.personalInformation.lastName': 'Last Name',
  'settings.personal.personalInformation.title': 'Personal Information',
  'settings.personal.preferences.language': 'Website language',
  'settings.personal.preferences.title': 'Preferences',
  'settings.personal.updatePassword.confirm': 'Confirm new password',
  'settings.personal.updatePassword.newPassword': 'New password',
  'settings.personal.updatePassword.title': 'Update password',
  'settings.primaryTarget.audience.size.advice.tooBroad': 'In order to reach the adequate people, be a little more specific on the location, age and interest. With an audience too broad, your posts and boost might deliver to people not really interested.',
  'settings.primaryTarget.audience.size.advice.tooSpecific': "In order to reach more people and have a bigger audience, add more interests, expand the audience's age or location. Think about what people like when using your product or service.",
  'settings.primaryTarget.audience.size.chart.reach': 'Estimated reach is {size} people',
  'settings.primaryTarget.audience.size.chart.title.facebook': 'Potential Audience Size in Facebook',
  'settings.primaryTarget.audience.size.chart.title.linkedin': 'Potential Audience Size in LinkedIn',
  'settings.social.advice.competitors': 'Tell me your competitors or a company you would like to watch for inspiration and I will show you a feed of their latest posts.',
  'settings.social.advice.connect': 'Connect up to create content, see your competitors, boost your posts, and track their performance.',
  'settings.tab.billingAndPlan': 'Billing & Plan',
  'settings.tab.businessAndAudience': 'Business & Audience',
  'settings.tab.personalAndPreferences': 'Personal & Preferences',
  'settings.tab.socialProfile': 'Social Profile',
  'settings.tab.competitors': 'Competitors',
  'settings.billing.planInformation.cancelSubscription.app.text': 'To cancel your subscription, you must do it from the App Store. ',
  'settings.billing.planInformation.cancelSubscription.app.link': 'Follow these steps to do it.',
  'settings.business.card.block.title': 'Complete your business profile!',
  'settings.business.card.block.message': 'Add the name, location, and category of your business.{br}Upload your logo so I can apply it to the templates for you.',
  'settings.business.card.block.button': 'Complete your profile',
  'settings.business.card.block.audience.title': 'Do you want to reach more people?',
  'settings.business.card.block.audience.message': 'Fill in the information about your customers to set up successful ads for your business.',
  'settings.business.card.block.audience.button': 'Fill in information',
  'settings.personal.personalInformation.holder': 'Here you will receive important information about your account and notifications',
  'settings.personal.card.block.title': 'Let\'s keep in touch!',
  'settings.personal.card.block.message': 'Add your name and a contact email to receive important information and notifications.',
  'settings.personal.card.block.button': 'Add',
  'settings.business.card.block.interest': 'Add a category of your business.',
  'settings.business.card.block.company': 'Add the name of your business.',
  'settings.business.card.block.logo': 'Upload your logo so I can apply it to the templates for you.',
  'settings.business.card.block.location': 'Add the location of your business.',
  'settings.personal.title.social.conection': 'Logged with',
  'settings.billing.paymentHistory.android.notification.1': 'RECOVERED',
  'settings.billing.paymentHistory.android.notification.2': 'RENEWED',
  'settings.billing.paymentHistory.android.notification.3': 'CANCELED',
  'settings.billing.paymentHistory.android.notification.4': 'PURCHASED',
  'settings.billing.paymentHistory.android.notification.5': 'ON HOLD',
  'settings.billing.paymentHistory.android.notification.6': 'IN GRACE PERIOD',
  'settings.billing.paymentHistory.android.notification.7': 'RESTARTED',
  'settings.billing.paymentHistory.android.notification.8': 'PRICE CHANGE CONFIRMED',
  'settings.billing.paymentHistory.android.notification.9': 'DEFERRED',
  'settings.billing.paymentHistory.android.notification.10': 'PAUSED',
  'settings.billing.paymentHistory.android.notification.11': 'PAUSE CHEDULE CHANGED',
  'settings.billing.paymentHistory.android.notification.12': 'REVOKED',
  'settings.billing.paymentHistory.android.notification.13': 'EXPIRED',
};

export default en;
