import gql from 'graphql-tag';

export const getInterests = gql`
  query interests ($query: String!, $language: LANGUAGE) {
    interests (query: $query, language: $language) {
      id
      name
    }
  }
`;

export const searchLinkedinInterests = gql`
  query searchLinkedinInterests($wordToSearch: String) {
    searchLinkedinInterests(wordToSearch: $wordToSearch) {
      id
      name
    }
  }
`;
export const fetchLinkedInSuggestions = gql`
  query fetchLinkedInSuggestions($interests:[ClientInterest]){
    fetchLinkedInSuggestions(interests:$interests){
        id
        name
    }
  }
`;
