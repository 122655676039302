const es = {
  'onboarding.aboutbiz.action1': 'Pre-rellena esta información utilizando los datos de tu perfil de negocio de Facebook',
  'onboarding.aboutbiz.goToDashboard': 'Explora el dashboard si prefieres',
  'onboarding.aboutbiz.heading': 'Cuéntanos sobre tu negocio',
  'onboarding.aboutbiz.heading.variant': 'Cuéntanos de ti y tu negocio',
  'onboarding.aboutAudience.heading': 'Cuéntanos sobre tus clientes',
  'onboarding.aboutbiz.inputUserFirstName': 'Tu Nombre',
  'onboarding.aboutbiz.inputUserLastName': 'Tu Apellido',
  'onboarding.aboutbiz.inputName': 'Nombre de tu negocio',
  'onboarding.aboutbiz.inputPlaceholder': 'Ej. Cafetería Amores',
  'onboarding.aboutbiz.text': 'Déjanos saber un poco más sobre lo que haces. Esta información nos ayudará a entregarte recomendaciones para tu negocio, por ejemplo qué publicar, cuando publicar y cuánto dinero se necesita en publicidad para que puedas alcanzar a un público más grande.',
  'onboarding.aboutbiz.uploadLogo': 'Sube el logo de tu empresa',
  'onboarding.createAccount.BoostingPowerInsights': '<strong>Piper</strong> te ayuda a llegar a un mayor público, tomando el control de tus gastos y con la ayuda de recomendaciones basadas en inteligencia artificial.',
  'onboarding.aboutbiz.logoAdvice': 'El logotipo de tu compañía es opcional pero muy recomendable, ya que se utiliza en las plantillas que tenemos para ti; recuerda que lo podrás cargar más tarde en la sección Configuración.',
  'onboarding.billing.heading': 'Comienza tu prueba gratuita de {trialDuration} días',
  'onboarding.billing.pro.enjoyFeatures': 'Y disfruta de acceso ilimitado a todas nuestras funciones.',
  'onboarding.billing.trialDuration': 'Prueba social Piper gratis por 30 días',
  'onboarding.billing.pro.subscribing': 'Te estás suscribiendo al <b>Plan Pro.</b>',
  'onboarding.billing.basic.subscribe': '¿Quieres probar primero el Plan Lite?',
  'onboarding.billing.selectPlan': 'Selecciona tu plan de pago',
  'onboarding.billing.addPayment': 'Agrega un método de pago',
  'onboarding.billing.addPayment.email': 'Email de tu negocio',
  'onboarding.billing.continueButton': 'Comenzar prueba',
  'onboarding.billing.continueButton.without.trial': 'Subscribirse',
  'onboarding.billing.pro.note': 'Puedes cancelar en cualquier momento sin comisiones. Los beneficios de su plan actual se podrán utilizar hasta el final de su último mes facturado.',
  'onboarding.billing.charging.note': 'Puedes cancelar en cualquier momento sin compromiso ni cargos extra y los beneficios de tu plan actual seguirán disponibles hasta el término de tu período de prueba.',
  'onboarding.billing.charging.validateFoundsNote': 'Te cobraremos $ 1 USD para verificar tu método de pago. Una vez verificado, será reembolsado.',
  'onboarding.tooltip.business.name.title': 'Nombre de tu negocio',
  'onboarding.tooltip.business.name.description': 'Dinos el nombre con el que tus clientes conocen tu negocio.{linebreak}{linebreak}Posteriormente podrás cambiar fácilmente esta información en la sección Configuración.{linebreak}{linebreak}Por ejemplo:',
  'onboarding.tooltip.business.city.title': 'Tu ciudad',
  'onboarding.tooltip.business.city.description': '¿En qué ciudad se encuentra tu negocio? Incluso puedes indicar un estado, país o región.{linebreak}{linebreak}Posteriormente podrás cambiar fácilmente esta información en la sección Configuración.{linebreak}{linebreak}Por ejemplo:',
  'onboarding.tooltip.business.category.title': 'Categoría de tu negocio',
  'onboarding.tooltip.business.category.description': 'Aquí cuéntanos a qué te dedicas en tu negocio; lo podrás describir a través de elegir una o más categorías de la lista, sólo comienza a escribir y te ayudaremos a encontrarlas rápidamente.{linebreak}{linebreak}Posteriormente podrás cambiar fácilmente esta información en la sección Configuración.{linebreak}{linebreak}Por ejemplo:',
  'onboarding.tooltip.audience.locations.title': 'Ubicación de tu público',
  'onboarding.tooltip.audience.locations.description': 'Puedes elegir una o más ubicaciones, además de una ciudad, puedes indicar un estado, país o región. Te recomendamos que sólo elijas las principales o donde tiene mayor influencia tu negocio, ya que esta información será usada al momento de crear campañas publicitarias en Facebook.{linebreak}{linebreak}Posteriormente podrás cambiar fácilmente esta información en la sección Configuración.{linebreak}{linebreak}Por ejemplo:',
  'onboarding.tooltip.audience.interests.title': 'Intereses de tu público',
  'onboarding.tooltip.audience.interests.description': 'Elige uno o más intereses que describen el mercado al que te dirijes. Te recomendamos que elijas sólo aquellos que sean los más relevantes, ya que esta información será usada al momento de crear campañas publicitarias en Facebook, y para que Piper te de recomendaciones más acertadas sobre las plantillas gráficas que puedan interesarte para publicar.{linebreak}{linebreak}Posteriormente podrás cambiar fácilmente esta información en la sección Configuración.{linebreak}{linebreak}Por ejemplo:',
  'onboarding.tooltip.audience.gender.title': 'Género',
  'onboarding.tooltip.audience.gender.description': 'Es muy recomendable que elijas el género que conforma la mayoría de tu público al que diriges tu negocio, ya que esta información será usada al momento de crear campañas publicitarias en Facebook.{linebreak}{linebreak}Posteriormente podrás cambiar fácilmente esta información en la sección Configuración.{linebreak}{linebreak}Por ejemplo:',
  'onboarding.tooltip.audience.age.title': 'Edad',
  'onboarding.tooltip.audience.age.description': 'Aquí podrás elegir el rango de edades que conforma la mayoría de tu público al que diriges tu negocio. Te recomendamos que elijas un rango dentro del que se ubica la mayoría de tu público, ya que esta información será usada al momento de crear campañas publicitarias en Facebook y así podrían ser más eficiente.{linebreak}{linebreak}Posteriormente podrás cambiar fácilmente esta información en la sección Configuración.{linebreak}{linebreak}Por ejemplo:',
  'onboarding.tooltip.audience.potential.title': 'Tu público potencial',
  'onboarding.billing.apple.continueButton': 'Pagar y continuar',
  'onboarding.mesaage.apple.transaction.fail': 'Lo sentimos, tu transacción no se pudo completar, valida la información de tu tarjeta en el App Store',
  'onboarding.billing.trial.freeTrial': 'Prueba Gratis',
  'onboarding.billing.trial.reminder': 'Recordatorio de 7 días',
  'onboarding.billing.trial.list1': 'Te recordaremos <blue>7 días antes</blue> de que termine tu periodo de prueba.',
  'onboarding.billing.trial.list2': 'Se te enviará un recordatorio vía email el <blue>{reminder}.</blue>',
  'onboarding.billing.trial.list3': '<blue>Ahora no pagas nada.</blue> Sólo agrega un método de pago para después.',
  'onboarding.billing.trial.today': 'Hoy',
  'onboarding.tooltip.pro.cvc.title': 'CVC',
  'onboarding.tooltip.pro.cvc.description': 'CVC son los tres números de su código de seguridad en el reverso de su tarjeta.',
  'k.save.category': 'Guardar y mostrar plantillas',
  'alert.error.category': 'Selecciona al menos uno tipo de negocio',
  'onboarding.billing.only.credit.card.text': 'Solo tarjetas de crédito',
  'onboarding.billing.select.plan.title': '1. Selecciona cada cuando quieres pagar',
  'onboarding.billing.pay.title': '2. Ingresa los detalles de pago',
  'onboarding.billing.pay.stripe.text': 'Pago seguro con',
  'planOption.selected.text.info': 'Facturado {period} por {currency} {price}',
  'planOption.selected.period.monthly': 'mensualmente',
  'planOption.selected.period.yearly': 'anualmente',
  'onboarding.billing.trial.info.title': '¿Cómo funciona tu prueba gratuita de {days} días?',
  'onboarding.billing.trial.info.date.start': '<blue>Inicia tu prueba</blue>{linebreak}{date}',
  'onboarding.billing.trial.info.date.end': '<blue>Primer cobro</blue>{linebreak}{date}',
  'onboarding.billing.trial.info.trial.duration': 'Prueba {days} días gratis',
  'onboarding.billing.trial.info.trial.before': 'Te recordaremos 7 días antes',
  'onboarding.billing.trial.info.list.one': 'Te recordaremos <bold>7 días antes</bold> de que termine tu periodo de prueba.',
  'onboarding.billing.trial.info.list.two': '<blue>Ahora no pagas nada.</blue> Sólo agrega un método de pago.',
  'onboarding.billing.faq.link.more': 'Más preguntas',
  'onboarding.billing.faq.question.1': '¿Puedo cancelar mi cuenta en cualquier momento?',
  'onboarding.billing.faq.answer.1': 'Si, puedes cancelar tu cuenta en cualquier momento, solo ve a Configuración, a Plan y Facturación, da click en el botón Borrar cuenta, y tu cuenta será cancelada. Si decides volver a usar Social Piper, podemos reactivar tu cuenta.',
  'onboarding.billing.faq.question.2': '¿Puedo pagar trimestralmente?',
  'onboarding.billing.faq.answer.2': 'Por el momento solo tenemos dos opciones para su suscripción recurrente: mensual y anual.',
  'onboarding.billing.faq.question.3': '¿Qué sucede si hay un problema con mi tarjeta de crédito?',
  'onboarding.billing.faq.answer.3': 'Cada mes intentaremos cobrarte la suscripción. En caso de que surja un problema, nuestro sistema volverá a intentar realizar el cargo en tu tarjeta de crédito. Si el problema persiste, nos comunicaremos contigo para informarte que hay un problema con ella, para que puedas consultar con tu banco o actualizar la información de la tarjeta. En caso de que no haya respuesta, podrás utilizar la plataforma durante 7 días más y, después de esto, tu cuenta será bloqueada y se te pedirá que actualices tu información de pago cada vez que intentes ingresar a Social Piper.',
  'onboarding.schedule.date.title1': '¡Ya solo falta un paso!',
  'onboarding.schedule.date.title2': '¡Todo Listo!',
  'onboarding.schedule.date.title1v2': 'Agenda tu asesoría personalizada',
  'onboarding.schedule.date.title2v2': '¡Todo listo!',
  'onboarding.schedule.date.calendly1': 'Un Piper Expert te ayudará a configurar y usar todo el potencial de tu cuenta.',
  'onboarding.schedule.date.calendly2': 'Con esto te garantizaremos que tengas la mejor experiencia en Social Piper, y que resuelvas cualquier duda sobre cómo utilziar esta increíble plataforma. Tan solo selecciona una fecha y hora, después da clic en confirmar para agendar la videollamada con nuestro Piper Expert.',
  'onboarding.schedule.date.info0': '{userName}: tu asesoria personalizada con un Piper Expert quedó programada para:',
  'onboarding.schedule.date.info1': 'Recibirás los detalles y liga de zoom de esta videollamada a tu correo.',
  'onboarding.schedule.date.info2': 'Mientras tanto, puedes seguir explorando Social Piper revisando las recomendaciones de Piper y echarle un vistazo a los +3,000 templates disponibles para ti.',
  'onboarding.schedule.date.info3': 'Si quieres modificar o cancelar tu asesoria personalizada, puedes hacerlo desde el correo que recibiste de confirmación de la reunión.',
  'onboarding.schedule.support.text1': 'Si deseas programar la llamada para otro momento o si tienes alguna duda, por favor escríbenos a <link>{emailSupport}</link> o a nuestro chat en vivo.',
  'onboarding.support.text1': 'Si deseas programar la videollamada para otro momento o si tienes alguna duda, por favor, escíbenos a <link>{emailSupport}</link> o a nuestro chat en vivo.',
  'onboarding.schedule.button.goToSocialpiper': 'Ir a Social Piper',
  // linked in onboarding
  'onBoarding.audience.modal': 'Define a tus clientes',
  'onboarding.tooltip.audience.potential.description': 'De acuerdo a los datos que has introducido, verás que este indicador cambiará. Siéntete libre de cambiar la información que has puesto hasta que la aguja se encuentre en la zona "Recomendada".{linebreak}{linebreak}Esto es importante, ya que así podrás crear campañas publicitarias en {socialNetwork} más eficientes.',
  'onBoarding.facebook.audience.title': 'Tus clientes en Facebook',
  'onboarding.tooltip.audience.facebook.clients': 'En Facebook tus clientes potenciales comparten sus intereses generales. ¿Cuáles se relacionan con tu negocio?',
  'onBoarding.linkedin.audience.title': 'Tus clientes en LinkedIn',
  'onboarding.tooltip.audience.linkedin.clients': 'En Linkedin puedes alcanzar clientes potenciales con base en rasgos profesionales como: empleo, industria e intereses profesionales.',
  'audience.interests.li.warning': 'Tu audiencia es muy pequeña para crear una campaña. Para ampliarla, agrega más intereses.',
  'audience.interests.li.suggestions': 'Puedes usar estas sugerencias:',
  'audience.interests.li.info': 'Tu audiencia está lista para crear una campaña.',
  'audience.interests.button.giveme': 'Dame mas sugerencias',
  'alert.warning.onboarding.billingSection.apps.purchase.invalid': 'No se pudo completar el proceso de pago. Vuelve a intentarlo o ponte en contacto con support@socialpiper.com',
};

export default es;
