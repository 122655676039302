import React, { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Media from 'react-media';
import { mobileLoggedFooterButtons } from './footerDictionaries';
import FooterElement from './FooterElement';
import FullSizeLogo from '../Logos/FullSizeLogo';
import GlobalTheme, { breakpointValues } from '../../../styled/GlobalTheme';
import mediaQueries from '../../../utils/mediaQueries';
import { WebViewContext } from '../../../contexts/WebViewContext';

const FooterWrapper = styled.nav`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  ${GlobalTheme.breakpoints.down('sm')}{
    flex-direction: column;
    align-items: center;
    display:block;
  }
  ${({ isApp }) => isApp && `
  ${GlobalTheme.breakpoints.down('sm')}{
    flex-direction: column;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;

  }
    ${GlobalTheme.breakpoints.up('md')}{
      flex-direction: row;
      justify-content: space-between;
      padding: 0 24px;
    }
  `}
`;

const LinksContainer = styled.div`
  flex: 1;
  margin-left: 40px;
  display: flex;
  flex-wrap: wrap;
  ${GlobalTheme.breakpoints.down('sm')}{
    margin-left: 0;
  }
`;

const InfoText = styled.p`
  flex: 0 0 100%;
  color: ${(props) => props.theme.colors.text02};
  text-align: center;
  font-size: 10px;
  margin-top: 16px;
  ${GlobalTheme.breakpoints.down('sm')}{
    order: 1;
  }
  ${({ isApp }) => isApp && `
    ${GlobalTheme.breakpoints.down('sm')}{
      margin: 12px 0 0;
    }
  `}
`;

const Links = styled.div`
  flex: 0 0 100%;
  display: flex;
  ${GlobalTheme.breakpoints.down('sm')}{
    margin-top: 20px;
    order: 2;
    flex-direction: column;
    align-items: center;
  }
  ${({ isApp }) => isApp && `
    @media (max-device-width: 1023px)  and (orientation: landscape) {
      display: none;
    }
  `}
`;

const CustomFooterElement = styled(FooterElement)`
  && {
    text-align: center;
    & .elementText {
      text-align: center;
    }
    ${GlobalTheme.breakpoints.down('sm')}{
      margin-bottom: 12px;
    }
  }
`;

export default function MobileLoggedFooter() {
  const intl = useIntl();
  const { isApp, isAppIOS } = useContext(WebViewContext);
  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <FooterWrapper isApp={(isApp || isAppIOS)}>
          <FullSizeLogo height="auto" />
          <LinksContainer>
            {((isApp || isAppIOS) && matches.mdDownLess) ? (
              null
            ) : (
              <Links isApp={(isApp || isAppIOS)}>
                { mobileLoggedFooterButtons.map((element) => (
                  <CustomFooterElement element={typeof element === 'function' ? element(intl.locale) : element} />
                ))}
              </Links>
            )}
          </LinksContainer>
          <InfoText isApp={(isApp || isAppIOS)}>
            <FormattedMessage
              id={isApp ? 'k.copyrightApp' : 'k.copyright'}
              defaultMessage="© Copyright {year} {lineBreak}All rights reserved"
              values={{
                year: new Date().getFullYear(),
                lineBreak: (window.innerWidth <= breakpointValues.sm) ? <br /> : '',
              }}
            />
          </InfoText>
        </FooterWrapper>
      )}
    </Media>
  );
}
