import * as Sentry from '@sentry/browser';
import { publicAppSync } from '../auth';
import { getPromos } from '../../graphql/queries/promos.query';
import {
  ENVIRONMENTS,
  ENV,
} from '../../utils/constants/globals';
import promosMock from '../../mocks/plans/getPromos.mock.json';
import { getPlansIOS } from '../../graphql/queries';

/**
 * shape of the promocode
 * {
 *   plans: [],
 *   isValid: false,
 * }
 */

export default class PromoCodeProvider {
  static async fetchPromo(code = '', currencyCode = 'USD', countryCode = 'US') {
    if (ENV !== ENVIRONMENTS.local) {
      try {
        const { data } = await publicAppSync.client.query({
          query: getPromos(),
          variables: { codePlan: code, currencyCode, countryCode },
          fetchPolicy: 'network-only',
        });
        return {
          data: data.getPromos,
          success: true,
          message: 'success',
        };
      } catch (e) {
        Sentry.captureException(e);
        return {
          data: null,
          success: false,
          message: e.message,
        };
      }
    }
    return {
      data: promosMock.data.getPromos,
      message: 'success',
      success: true,
    };
  }

  static async getPlansIOS(currencyCode = 'USD', countryCode = 'US') {
    if (ENV !== ENVIRONMENTS.local) {
      try {
        const { data } = await publicAppSync.client.query({
          query: getPlansIOS(),
          variables: { currencyCode, countryCode },
        });
        return {
          data: data.getPlansIOS,
          success: true,
          message: 'success',
        };
      } catch (e) {
        Sentry.captureException(e);
        return {
          data: null,
          success: false,
          message: e.message,
        };
      }
    }
    return {
      data: promosMock.data.getPromos,
      message: 'success',
      success: true,
    };
  }
}
