import gql from 'graphql-tag';

export const setAdAccount = gql`
  mutation setAdAccount($userId: ID!, $selectedAdAccount: AdAccountInput!) {
    setAdAccount (userId: $userId, selectedAdAccount:$selectedAdAccount ) {
      id
      facebookId
      accountId
      currency
      accountStatus
      name
      businessName
      fundingSourceId
      isSelected
      haveBoost
    }
  }
`;

export const unlinkFacebookAccount = gql`
  mutation unlinkFacebookAccount {
    unlinkFacebookAccount {
      success
      errorMessage
    }
  }
`;

export const unlinkLinkedinAccount = gql`
  mutation unlinkLinkedinAccount {
    unlinkLinkedinAccount {
      success
    }
  }
`;

export const unlinkTwitterAccount = gql`
  mutation unlinkTwitterAccount {
    unlinkTwitterAccount
  }
`;

export const unlinkInstagramAccount = gql`
  mutation unlinkInstagramAccount {
    unlinkInstagramAccount {
      errorMessage
      success
    }
  }
`;
export const unlinkTiktokAccount = gql`
  mutation unlinkTiktokAccount {
    unlinkTiktokAccount {
      success
    }
  }
`;

export const socialLoginResultMirror = gql`
  mutation socialLoginResultMirror ($authId: String!, $network: SOCIAL_NETWORK!, $status: Boolean, $reason: String) {
    socialLoginResultMirror(authId: $authId, network: $network, status: $status, reason: $reason){
      authId
      network
      status
      reason
    }
  }
`;

export const createLinkedinAdAccount = gql`
  mutation createLinkedinAdAccount($accountName: String!, $currency:String!){
    createLinkedinAdAccount(accountName: $accountName, currency:$currency)
  }
`;

export const updateLinkedinAdAccount = gql`
  mutation updateLinkedinAdAccount($accountId:String!, $accountName: String!, $currency:String!){
    updateLinkedinAdAccount(accountId: $accountId, accountName: $accountName, currency:$currency)
  }
`;

export const updateBoostingFailed = gql`
  mutation updateBoosting($boostId: Int, $type: String){
    updateBoosting(boostId: $boostId, type: $type) {
      success
    }
  }
`;
