import gql from 'graphql-tag';

export const getTags = (attr = `
  name
`) => gql`
query getTags ($sort:[[String]], $where: String){
  tags(sort: $sort, where: $where) {
    id
    ${attr}
  }
}
`;

export default {};
