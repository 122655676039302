const errorsDictionary = {
  'backend.error.currency.usd': 'USD',
  'backend.error.no.platform': 'Cannot login with this platform',
  'backend.error.login.failed': 'Login Failed',
  'backend.error.no.token': 'No token provided',
  'backend.error.no.auth.header': 'No auth header provided',
  'backend.error.unauthorized': 'Unauthorized',
  'backend.error.internal.error': 'Internal Error',
  'backend.error.admin.auth': 'Invalid admin authentication',
  'backend.error.missing.email': 'Missing email',
  'backend.error.generic.error.type': 'InternalError',
  'backend.error.no.credentials': 'No credentials provided',
  'backend.error.unknown.field': 'Unknown Field',
  'backend.error.entity.not.found': 'Entity not found',
  'backend.error.entity.creation.failed': 'Entity creation failed',
  'backend.error.entity.update.failed': 'Entity update failed',
  'backend.error.missing.parameters': 'Missing parameters',
  'backend.error.email.failed': 'There was an error sending the email through SES',
  'backend.error.invalid.parameters': 'Invalid parameters',
  'backend.error.invalid.scheduled.parameters': 'Invalid parameters. ScheduleTime can only be used when postNow is false or not provided.',
  'backend.error.image.does.not.exist': 'The image does not exist',
  'backend.error.image.update.failure': 'There was an error saving the image',
  'backend.error.reschedule.invalid.parameters': 'Invalid parameters. ScheduleTime can only be used when postNow is false or not provided.',
  'backend.error.user.already.following.competitor': 'User already following competitor',
  'backend.error.user.has.no.facebook.page.linked': 'User has no Facebook Page linked',
  'backend.error.user.has.no.instagram.page.linked': 'User has no Instagram Page linked',
  'backend.error.boost.cannot.be.created': "Your boost couldn't be created. Please try again or contact support@socialpiper.com.",
  'backend.error.facebook.not.policy.accepted': "Whoops! We couldn't create your boost on <bold>Facebook</bold>. It seems that you have not agreed the non-discrimination policy on Meta (Facebook).<TextLink>Do it here</TextLink> and try again to create your boost from the Scheduler.{linebreak}If the problem persist please contact us at <email>support@socialpiper.com</email>",
  'backend.error.facebook.not.policy.accepted.with.add.error': "Whoops! We couldn't create your boost on <bold>Facebook</bold>. It seems that you have not agreed the non-discrimination policy on Meta (Facebook).<TextLink>Do it here</TextLink> and try again to create your boost from the Scheduler.{linebreak}In addition to his, we couldn’t process at least one other of your posts, please try again.{linebreak}If the problem persist please contact us at <email>support@socialpiper.com</email>",
  'backend.error.user.has.no.twitter.account.linked': 'User has no Twitter account linked',
  'backend.error.user.has.no.company': 'User has no company',
  'backend.error.error.in.bulk.insert': 'Error in Bulk insert',
  'backend.error.comment.does.not.exist': 'The parent comment does not exist',
  'backend.error.comment.creation.failed': 'The comment creation failed',
  'backend.error.comment.deletion.failed': 'The comment deletion failed',
  'backend.error.error.saving.new.targetClient': 'Error Saving new targetClient',
  'backend.error.error.updating.user': 'Error updating user',
  'backend.error.oauth.failed': 'Twitter login error. Verify callback url and application credentials.',
  'backend.error.invalid.email': 'Invalid email',
  'backend.error.user.deleted': 'User deleted',
  'backend.error.cannot.create.empty.post': 'No message nor image url is present. Cannot create empty post.',
  'backend.error.page.has.no.posts': 'Linked page has no posts to fetch',
  'backend.error.facebook.post.delete.failure': 'Failed to delete post on Facebook',
  'backend.error.facebook.post.reschedule.failure': 'Failed to reschedule post on Facebook',
  'backend.error.post.creation.failure': 'There was an error creating your post',
  'backend.error.facebook.request.failure': 'Failed request to Facebook API',
  'backend.error.facebook.page.not.found': 'Facebook page not found',
  'backend.error.twitter.init.failed': 'Twitter init process failed',
  'backend.error.post.invalid.schedule': 'Schedule must be a valid date at least 10 minutes from now',
  'backend.error.twitter.invalid.text.length': 'Tweet text length must be between 1 and 280 characters',
  'backend.error.twitter.status.update.failure': 'Error while calling tweet status update',
  'backend.error.twitter.status.delete.failure': 'Error while calling tweet status delete',
  'backend.error.twitter.status.reschedule.failure': 'Error while calling tweet status reschedule',
  'backend.error.twitter.user.does.not.exist': 'The Twitter user linked to your account does not exist',
  'backend.error.social.networks.init.failed': 'Social Networks Init Failed',
  'backend.error.message.creation.failed': 'Message Creation failed',
  'backend.error.message.creation.failed.more.24hrs': 'Facebook Businesses pages only have up to 24 hours to respond to a user.',
  'backend.error.inbox.does.not.exist': 'The facebook conversation does not exist',
  'backend.error.targetClient.configuration.not.complete': 'Target Client configuration for this company is not complete',
  'backend.error.no.currency.found': 'No currency found for user',
  'backend.error.no.facebook.adAccount': 'No Facebook adAccount found for user',
  'backend.error.invalid.facebook.ad.account.status': 'Facebook ad account has an invalid status',
  'backend.error.invalid.facebook.ad.account.funding': 'Facebook ad account has an invalid funding source',
  'backend.error.registered.facebook.adAccount': 'The user already have a registered Facebook Ad Account',
  'backend.error.invalid.facebook.adAccount.currency': 'The Facebook ad account has a different currency from the one previously selected',
  'backend.error.ad.deletion.failure': 'There was an error deleting the boosted post',
  'backend.error.ad.spent.retrieve.failure': 'There was an error getting the money spent from the ad',
  'backend.error.company.has.no.targetClient': 'Company has no Target Client',
  'backend.error.user.has.only.one.card.left': 'You must have at least one card registered on your account. Please add one more card and try again deleting this one.',
  'backend.error.unknown.webhook': 'Unknown web hook format',
  'backend.error.no.action': 'No action',
  'backend.error.no.effect': 'Notification did not cause modifications',
  'backend.error.webhook.unhandled': 'Unhandled web hook message for a field',
  'backend.error.webhook.error': 'Error processing web hook notification',
  'backend.error.webhook.error.page.not.exists': 'Page does not exist. Cannot create post',
  'backend.error.webhook.parent.comment': 'Could not find parent comment for this reply',
  'backend.error.webhook.facebook.conversation.not.found': 'Conversation not found in facebook',
  'backend.error.webhook.postId': 'Cannot get post id from share webhook payload',
  'backend.error.edition.failure': 'There was an error on the update',
  'backend.error.attachment.creation.failure': 'There was an error creating the attachment',
  'backend.error.account.is.already.connected': 'The account already exists',
  'backend.error.account.disconnection.failure': 'The account could not be disconnected',
  'backend.error.stripe.client.not.found': 'The stripe client for the user was not found',
  'backend.error.subscription.failed': 'The plan subscription failed',
  'backend.error.subscription.deletion.failed': 'The plan subscription deletion failed',
  'backend.error.subscription.not.found': 'The subscription was not found on the DB',
  'backend.error.payments.not.found': 'The payments were not found on the DB',
  'backend.error.password.reset.failure': 'The password reset email failed to be sent',
  'backend.error.weekly.metrics.error': 'There was an error retrieving weekly metrics',
  'backend.error.your.account.not.is.in.usd.currency': 'To create an ad campaign, you need a Facebook ad account based in US Dollars. To create it, just follow these simple steps: https://www.youtube.com/watch?v=APEnHEHDjh0',
  'backend.error.no.updated.facebook.ad.account': 'There was an error to update the Facebook ad account',
  'backend.error.no.created.time.recommendation': 'There was an error to save the time recommendation',
  'backend.error.no.created.historic.template': 'There was an error to save the historic template',
  'backend.error.facebook.interest.error': 'There was an error to fetch facebook interests',
  'backend.error.update.authId.by.user': 'There was an error to update the auth id by user',
  'backend.error.no.data': 'No data to retrieve',
  'permission.quota.exceeded': 'To add more competitors you must upgrade to Pro plan.',
  'backend.error.linkedin.init.failed': 'LinkedIn init process failed',
  'backend.error.linkedin.user.not.found': 'LinkedIn user not found',
  'backend.error.linkedin.page.not.found': 'LinkedIn page not found',
  'backend.error.linkedin.request.failure': 'Failed request to LinkedIn API',
  'backend.error.user.has.linkedin.page': 'There\'s already a LinkedIn page connected',
  'backend.error.linkedin.post.update.failure': 'There was an error to update your post',
  'backend.error.linkedin.post.reschedule.failure': 'There was an error to reschedule your post',
  'backend.error.linkedin.post.delete.failure': 'There was an error to delete your post',
  'backend.error.comment.liked.failed': 'There was an error to like the comment',
  'backend.error.linkedin.page.already.link': 'Your LinkedIn account is already link with a Social Piper user',
  'backend.error.linkedin.user.already.link': 'Your LinkedIn page is already link with a Social Piper user',
  'backend.error.linkedin.adCampaign.update.failure': 'There was an error to update your Campaign',
  'backend.error.linkedin.adCampaign.update.failure.toSoon': 'The start time should be after post\'s schedule time',
  'backend.error.invalid.phone': 'Invalid phone number',
  // Stipe errors
  'stripe.error.invalid_number': 'The card number is invalid. Check the card details or use a different card.',
  'stripe.error.email_invalid': 'The email is invalid. Check your email or use a different email.',
  'stripe.error.invalid_expiry_month': 'The card’s expiration month is invalid',
  'stripe.error.invalid_expiry_year': 'The card’s expiration year is invalid',
  'stripe.error.invalid_cvc': 'The card’s security code is invalid',
  'stripe.error.incorrect_number': 'The card number is incorrect',
  'stripe.error.incomplete_number': 'The card number is incomplete',
  'stripe.error.incomplete_cvc': 'The card’s security code is incomplete',
  'stripe.error.incomplete_expiry': 'The card’s expiration date is incomplete',
  'stripe.error.expired_card': 'The card has expired',
  'stripe.error.incorrect_cvc': 'The card’s security code is incorrect',
  'stripe.error.incorrect_zip': 'The card’s zip code failed validation',
  'stripe.error.invalid_expiry_year_past': 'The card’s expiration year is in the past',
  'stripe.error.card_declined': 'The card was declined',
  'stripe.error.missing': 'There is no card on a customer that is being charged',
  'backend.stripe.addpayment': 'The card was declined,please use a different card.',
  'stripe.error.only.credit': 'Your card is not a credit card. Try again with a credit card.',
  // Mailing errorsDictionary
  'email.change.duplicated': 'The email is already taken.',
  'email.verification.error.sendingLimitReach': 'You reached the limit of emails sent. Wait 10 minutes and try again',
  'email.verification.error.sendingError': 'We could not send your verification code',
  'email.verification.error.already.verified': 'Your mail is already verified. Let\'s continue to Social Piper!',
  'email.verification.error.not.exists': 'We have not sent you a code yet.',
  'email.verification.error.expired': 'Your code has expired, please request a new one',
  'email.verification.error.failed.too.much': 'You failed many times, please request a new code',
  'email.verification.error.missmatch': 'The code is incorrect, please verify your email',
};

module.exports = errorsDictionary;
