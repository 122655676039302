import styled from 'styled-components';
import GlobalTheme from '../../../styled/GlobalTheme';

export const LandingTitle = styled.h2`
  text-align: center;
  margin-bottom: ${(props) => props.theme.space * 6}px;
  text-align: center;
  font-size: 38px;
  line-height: 1;

  ${GlobalTheme.breakpoints.down('lg')} {
    font-size: 30px;
  }
  ${GlobalTheme.breakpoints.down('xs')} {
    font-size: 26px;
  }
`;

export const LandingText = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
`;

export const LandingSubTitle = styled.h3`
  font-size: 32px;
  line-height: 100%;
  ${GlobalTheme.breakpoints.down('lg')} {
    font-size: 30px;
  }
`;
