const en = {
  "dashboard.greeting.afternoon": "Good afternoon {name}!",
  "dashboard.greeting.evening": "Good evening {name}!",
  "dashboard.greeting.morning": "Good morning {name}!",
  "dashboard.greeting.night": "Good night {name}!",
  "piperAdvice.boostManager.health.high":
    "How are those metrics doing? Remember to compare them with the organic ones that you can see in the Community section.",
  "piperAdvice.boostManager.health.low":
    "I'm glad that you are aware of the strength that creating a boost has in the communication of your business.",
  "piperAdvice.boostManager.health.medium":
    "Keep boosting your content, I'm sure you know the difference it makes.",
  "piperAdvice.boostManager.health.zero":
    "You are missing the opportunity to empower the communication with your customers. Let's boost your content!",
  "piperAdvice.community.comments.unreads.high":
    "You better pay attention! You have a lot of comments to read and that's not a good idea. They’re waiting for your reply",
  "piperAdvice.community.comments.unreads.low":
    "You're doing it right! Check out the new comments.",
  "piperAdvice.community.comments.unreads.medium":
    "Reply to more comments! So your audience sticks around and trust you.",
  "piperAdvice.community.comments.unreads.zero":
    "You have no comments to read. Keep your community active by boosting your posts.",
  "piperAdvice.community.inbox.unreads.high":
    "Planning ahead will allow you to generate better content and get better results. Let's do it!",
  "piperAdvice.community.inbox.unreads.low":
    "Don’t leave your customers waiting too long. Quick responses increase brand trust.",
  "piperAdvice.community.inbox.unreads.medium":
    "Don't forget that you have a time limit to answer messages, so now is a good time to do it!",
  "piperAdvice.community.inbox.unreads.zero":
    "You have no new messages. Post some new content so you could generate new interests for your clients.",
  "piperAdvice.scheduler.inbox.planning.high":
    "Great! I like that you are tidy and organized by having a good planning of your content.",
  "piperAdvice.scheduler.inbox.planning.low":
    "For maximum impact, post at least once a day. {lineBreak}I've suggested optimal days/time when your audience is most active.",
  "piperAdvice.scheduler.inbox.planning.medium":
    "You are doing a very good job planning your next posts!",
  "piperAdvice.scheduler.inbox.planning.zero":
    "Planning ahead will allow you to generate better content and get better results. Let's do it!",
  "piperAdvice.scheduler.inbox.planning.ai":
    "Here are your post for a week. I recommend reviewing and editing them if necessary.",
  "settings.audienceGraphic.high":
    "How are those metrics doing? Remember to compare them with the organic ones that you can see in the Community section.",
  "settings.audienceGraphic.low":
    "In order to reach more people and have a bigger audience, keep relevant interests, and expand the audience's age or location. Think about what people like when using your product or service.",
  "settings.audienceGraphic.none": "",
};

export default en;
