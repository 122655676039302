// TODO: Fix and improve this cleaning fiunction
const numberClean = (value) => {
  if (!Number.isInteger(value)) {
    return value.toFixed(2);
  }
  return value;
};

const sanitizePlans = (PromoCode) => {
  try {
    const [monthlyPlan] = PromoCode.plans
      .filter((plan) => (plan.isMonthly))
      .map((plan) => ({
        ...plan,
        monthlyPrice: numberClean(plan.monthlyPrice),
        realPrice: numberClean(plan.realPrice),
      }));
    const [yearlyPlan] = PromoCode.plans
      .filter((plan) => (!plan.isMonthly))
      .map((plan) => ({
        ...plan,
        monthlyPrice: numberClean(plan.monthlyPrice),
        realPrice: numberClean(plan.realPrice),
      }));
    return [monthlyPlan, yearlyPlan];
  } catch (error) {
    throw new Error("There's something wrong with the promo codes info retrieved");
  }
};

export default sanitizePlans;
