const en = {
  'addPayment.advice': 'Your information will be safe, so you don’t have to worry',
  'addPayment.button': 'Add Payment',
  'addPayment.cardDetails.addressLine': 'Address line {number}',
  'addPayment.cardDetails.advice': 'CVC are the three numbers of your security code in the back of your card',
  'addPayment.cardDetails.cardHolderName': 'Cardholder name',
  'addPayment.cardDetails.cardNumber': 'Credit card number',
  'addPayment.cardDetails.cardNumberShort': 'Card number',
  'addPayment.cardDetails.city': 'City',
  'addPayment.cardDetails.country': 'Country',
  'addPayment.cardDetails.expirationDateShort': 'Exp',
  'addPayment.cardDetails.expirationDate': 'Expiration day',
  'addPayment.cardDetails.state': 'State',
  'addPayment.cardDetails.zipCode': 'Zip code',
  'addPayment.contactDetails.title': 'Billing address',
  'addPayment.title': 'Add Payment Method',
  'settings.modal.only.credit.text': 'Only credit cards',
  'settings.modal.only.credit.text.sm': 'Only {lineBreak}credit cards',
};

export default en;
