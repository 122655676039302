const targetClients = {
  normal: {
    ageUpperBound: 55,
    ageLowerBound: 25,
    location: '[{"id":"2673660","label":"Mexico City, Distrito Federal, Mexico","type":"city","regionId":"2513","countryCode":"MX"}]',
    genders: ['{"id":1,"value":"Female"}'],
    interests: [
      {
        facebookId: '6003337847248',
        name: 'Hard Rock Cafe',
        __typename: 'AudienceInterest',
      },
    ],
    linkedinLocation: '[{"id": "urn:li:geo:103323778","name":"Mexico"}]',
    linkedinInterests: '[{"id": "urn:li:interest:1538426","name":"Marketing"}]',
    __typename: 'TargetClient',
  },
  fbEmpty: {
    ageUpperBound: 55,
    ageLowerBound: 18,
    location: '',
    genders: ['{"id":1,"value":"Female"}'],
    interests: [
      {
        facebookId: '6003337847248',
        name: 'Hard Rock Cafe',
        __typename: 'AudienceInterest',
      },
    ],
    linkedinLocation: '[{"id": "urn:li:geo:103323778","name":"Mexico"}]',
    linkedinInterests: '[{"id": "urn:li:interest:1538426","name":"Marketing"}]',
    __typename: 'TargetClient',
  },
  liEmpty: {
    ageUpperBound: 55,
    ageLowerBound: 18,
    location: '[{"id":"2673660","label":"Ciudad de México, Distrito Federal, México","type":"city","regionId":"2513","countryCode":"MX"}]',
    genders: ['{"id":1,"value":"Female"}'],
    interests: [
      {
        facebookId: '6003337847248',
        name: 'Hard Rock Cafe',
        __typename: 'AudienceInterest',
      },
    ],
    linkedinLocation: '',
    linkedinInterests: '',
    __typename: 'TargetClient',
  },
  socialEmpty: {
    ageUpperBound: 55,
    ageLowerBound: 18,
    location: '',
    genders: ['{"id":1,"value":"Female"}'],
    interests: [
      {
        facebookId: '6003337847248',
        name: 'Hard Rock Cafe',
        __typename: 'AudienceInterest',
      },
    ],
    linkedinLocation: '',
    linkedinInterests: '',
    __typename: 'TargetClient',
  },
  allEmpty: {
    ageUpperBound: null,
    ageLowerBound: null,
    location: '',
    genders: [],
    interests: [
      {
        facebookId: '6003337847248',
        name: 'Hard Rock Cafe',
        __typename: 'AudienceInterest',
      },
    ],
    linkedinLocation: '',
    linkedinInterests: '',
    __typename: 'TargetClient',
  },
};

export default targetClients;
