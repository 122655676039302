import gql from 'graphql-tag';

export const searchInstagramCompetitor = (attr = `
  name
  picture
  username
  isVerified
`) => gql`
  query searchInstagramCompetitor($username: String!){
    instagramCompetitorSearch(username: $username){
      id
      ${attr}
    }
  }
`;

export const fetchSavedCompetitors = (attr = `
  name
  picture
  instagramId
  username
`) => gql`
  query competitors{
    competitors{
      id
      ${attr}
    }
  }
`;

export const instagramPosts = gql`
  query instagramPosts($pagination: Pagination, $orderBy: POSTS_ORDER_BY) {
    instagramPosts(pagination: $pagination, orderBy: $orderBy) {
      id
      message
      publishTime
      likes
      commentsNumber
      image
      attachmentType
      unreadCommentsCount
    }
  }
`;

export const validatePostVideoInstagram = gql`
  query validatePostVideoInstagram($id: String) {
    validatePostVideoInstagram(id: $id) {
        isValid
        code
    }
  }
`;
