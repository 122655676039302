/* eslint-disable react/jsx-props-no-spreading */

import PropTypes from 'prop-types';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import useCallGA from '../../hooks/useCallGA';

/**
 * A react-router Link or NavLink with Google Analytics events injected.
 * @summary This component should be used whenever we want to navigate with a button.
 * It injects an Google Analytics event before redirecting
 *
 * @param {string} to [optional] - location to redirect. Can handle a redirect with injected data.
 * @param {Object} ga [optional] - Google analytics optional additional information.
 * @param {function} onClick [optional] - Additional callback to call on Click, you can handle an
 * @param {bool} navLink [optional] - If true creates a NavLink component which can be used
 * to add styles when the url destination is selected
 * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
 */
export default function AnalyticsLink({
  to,
  gaData = {},
  onClick,
  children,
  className,
  navLink,
  exact,
  isActive,
  ...props
}) {
  const callGA = useCallGA();
  const handleClick = (e) => {
    if (gaData.action) callGA(gaData, to);
    onClick(e);
  };

  return (
    <>
      {navLink ? (
        <NavLink
          className={className}
          onClick={handleClick}
          to={to}
          exact={exact}
          isActive={isActive}
          {...props}
        >
          {children}
        </NavLink>
      ) : (
        <Link
          className={className}
          onClick={handleClick}
          to={to}
          exact={exact}
          {...props}
        >
          {children}
        </Link>
      )}
    </>
  );
}

AnalyticsLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  exact: PropTypes.bool,
  gaData: PropTypes.instanceOf(Object),
  isActive: PropTypes.bool,
  navLink: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

AnalyticsLink.defaultProps = {
  children: null,
  className: '',
  gaData: {},
  onClick: () => {},
  to: '',
  navLink: false,
  exact: false,
  isActive: false,
};
