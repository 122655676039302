import * as Sentry from '@sentry/browser';
import { authAppSync } from '../auth';
import {
  getPaymentMethods,
  getPaymentHistory,
  getPaymentHistoryApple,
  getPaymentHistoryAndroid,
} from '../../graphql/queries';
import {
  setDefaultPaymentMethod,
  deletePaymentMethod,
  addCreditCard, validateFounds,
} from '../../graphql/mutations';
import { ENV, ENVIRONMENTS } from '../../utils/constants/globals';
import newPaymentMethodsMock from '../../mocks/settings/newPaymentMethods.mock.json';

export default class BillingProvider {
  static async fetchPaymentMethods(attr = null) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    if (ENV === ENVIRONMENTS.local) {
      result.data = newPaymentMethodsMock.data.getPaymentMethods;
      result.success = true;
      return result;
    }
    try {
      const response = await authAppSync.client.query({
        query: attr ? getPaymentMethods(attr) : getPaymentMethods(),
        fetchPolicy: 'network-only',
      });
      // Sort by isDefaultPayment
      const data = response.data.getPaymentMethods.sort((a) => (a.isDefaultPayment ? -1 : 1));
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async updateDefaultMethod(token, paymentMethods) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      await authAppSync.client.mutate({
        mutation: setDefaultPaymentMethod,
        variables: {
          token,
        },
      });
      // Update the default default
      const payments = paymentMethods.map((p) => ({
        ...p,
        isDefaultPayment: p.id === token,
      }));
      // Sort by isDefaultPayment
      const data = payments.sort((a) => (a.isDefaultPayment ? -1 : 1));
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async deletePaymentMethod(token, paymentMethods) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: deletePaymentMethod,
        variables: {
          token,
        },
      });

      const { info } = response.data.deletePaymentMethod;
      // Remove the deleted
      let payments = paymentMethods.filter((p) => (p.id !== token));
      payments = payments.map((p) => ({
        ...p,
        isDefaultPayment: p.id === info,
      }));
      // Sort by isDefaultPayment
      const data = payments.sort((a) => (a.isDefaultPayment ? -1 : 1));
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async addPaymentMethod(stripe, contactInfo, planId = null) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const { token, error } = await stripe.createToken({
        name: contactInfo.cardholderName,
        address_line1: contactInfo.billingAddressLineOne,
        address_city: contactInfo.city,
        address_state: contactInfo.state,
        address_zip: '',
        address_country: contactInfo.country,
      });

      if (!error) {
        const response = await authAppSync.client.mutate({
          mutation: addCreditCard,
          variables: {
            input: {
              token: token.id,
              name: contactInfo.cardholderName,
              addressLine1: contactInfo.billingAddressLineOne,
              addressCity: contactInfo.city,
              addressState: contactInfo.state,
              addressZip: '',
              addressCountry: contactInfo.country,
              email: contactInfo.email,
              ...(planId && { planId }),
            },
          },
        });

        if (response.data.addCreditCard && response.data.addCreditCard.success) {
          return planId ? {
            success: true,
            message: 'success',
            data: {},
          } : this.fetchPaymentMethods();
        }
        result.message = 'stripe.error.processing_error';
        Sentry.captureMessage('stripe.error.processing_error');
      } else {
        result.message = `stripe.error.${error.code}`;
        Sentry.captureException(error);
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchPaymentHistory(attr = null) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.query({
        query: attr ? getPaymentHistory(attr) : getPaymentHistory(),
        fetchPolicy: 'no-cache',
      });
      result = {
        message: 'success',
        success: true,
        data: response.data.paymentHistory,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchPaymentHistoryApple() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.query({
        query: getPaymentHistoryApple(),
        fetchPolicy: 'no-cache',
      });
      result = {
        message: 'success',
        success: true,
        data: response.data.paymentHistoryApple,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchPaymentHistoryAndroid() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.query({
        query: getPaymentHistoryAndroid(),
        fetchPolicy: 'no-cache',
      });
      result = {
        message: 'success',
        success: true,
        data: response.data.paymentHistoryAndroid,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async validatefounds(trialDuration = 0) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: validateFounds,
        fetchPolicy: 'no-cache',
        variables: {
          trialDuration,
        },
      });
      if (response) {
        const { data } = response;
        const { success, errorMessage, info } = data.validateFounds;
        result = {
          message: info || errorMessage,
          success,
        };
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
