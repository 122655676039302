import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import TinySlider from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';
import { ChevronRightBlueIcon, ChevronLeftBlueIcon } from '../../assets/icons/iconRepository';
import GlobalTheme from '../../styled/GlobalTheme';
import mediaQueries from '../../utils/mediaQueries';

const defaultSettings = {
  lazyload: true,
  nav: true,
  mouseDrag: true,
  mode: 'carousel',
  axis: 'horizontal',
  autoplayDirection: 'forward',
  loop: true,
  autoplay: true,
  controls: false, // remove built-in nav buttons
  autoplayHoverPause: true,
  touch: true,
  arrowKeys: false,
};

const CarouselWrapper = styled.div`
  width: 100%;
  /* display: flex; */
  position: relative;
  [data-action='stop'],
  [data-action='start'] {
    display: none;
  }
  .tns-outer {
    position: relative;
  }
  .tns-nav {
    --gap: 13px;
    align-items: center;
    bottom: ${({ bottomPagination }) => bottomPagination || '-26px'};
    display: flex;
    justify-content: center;
    order: 2;
    position: relative;
    position: absolute;
    width: 100%;
    z-index: 2;
    margin-right: calc(-1 * var(--gap));
    width: calc(100% + var(--gap));
    & > * {
      margin-right: var(--gap);
    }

    button {
      background: ${({ theme }) => theme.colors.white};
      border: none;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      padding: 0;
      opacity: 0.4;
      &:focus {
        outline: none;
      }
      &.tns-nav-active {
        background: ${({ theme }) => theme.colors.white};
        opacity: 1;
      }
      ${GlobalTheme.breakpoints.up('md')} {
        height: 15px;
        width: 15px;
        --gap: 36px;
      }
    }
    @media ${mediaQueries.mdUp} {
      bottom: ${({ bottomPagination }) => bottomPagination || '-40px'};
    }
  }
  .tns-horizontal.tns-subpixel > .tns-item {
    display: inline-grid;
  }
`;

const button = () => css`
  padding: 0;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
  background-position: center;
  background-repeat: no-repeat;
  user-select: none;
  outline: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  z-index: 2;
  font-size: 40px;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  color: #666;
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67),
    color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  display: block;
  position: absolute;
  top: 39%;
  bottom: calc(50% - 20px);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const NextButton = styled.button`
  ${button};
  display: none;
  background-image: url(${ChevronRightBlueIcon});
  margin-right: -22px;
  right: 30px;
  left: auto;
  background-size: 32px;
  ${GlobalTheme.breakpoints.up('md')} {
    right: 50%;
    margin-right: -379px;
  }
`;

const PrevButton = styled.button`
  ${button};
  display: none;
  background-image: url(${ChevronLeftBlueIcon});
  left: 8px;
  right: auto;
  ${GlobalTheme.breakpoints.up('md')} {
    left: 50%;
    margin-left: -379px;
  }
`;

export default function TinySliderCarousel({ children, settings, bottomPagination }) {
  const [slider, setSlider] = useState({});
  // the slider object is the tiny-slider object, review options in:
  // https://github.com/ganlanyuan/tiny-slider
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    // This is a hack!!! Should found a better way to do It
    // This timeout send to the end of the event loop the draw of the slider
    setTimeout(() => {
      setLoaded(true);
      if (slider.play)slider.play();
    }, 0);
  }, []);
  return (
    <CarouselWrapper bottomPagination={bottomPagination}>
      {loaded && (
      <TinySlider
        settings={{
          ...defaultSettings,
          ...settings,
        }}
        ref={(ts) => {
          if (ts)setSlider(ts.slider);
        }}
      >
        {children}
      </TinySlider>
      )}
      <PrevButton type="button" onClick={() => { slider.goTo('prev'); }} />
      <NextButton type="button" onClick={() => { slider.goTo('next'); }} />
    </CarouselWrapper>
  );
}

TinySliderCarousel.propTypes = {
  children: PropTypes.node,
  settings: PropTypes.instanceOf(Object),
  bottomPagination: PropTypes.string,
};

TinySliderCarousel.defaultProps = {
  children: <></>,
  settings: {},
  bottomPagination: '',
};
