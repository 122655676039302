import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AuthService from '../services/auth/AuthService';
import {
  EVENT_TYPE,
  LOGIN_METHOD,
} from '../utils/analytics/constants';
import {
  getTypeOfAcc,
} from '../utils/analytics';

export const GA4Context = React.createContext();

/**
 *
 * dataLayerObject = { task, taskData} || { ...attributes }
 * the first is for send global actions to tag manager, (has task attribute)
 * the second is for the data arquitechture made by EPA
 */

const GA4Provider = ({ children }) => {
  const [definedUserId, setDefinedUserId] = useState(false);
  const pushDatalayer = (dataLayerEvent) => {
    const dLayer = window.dataLayer || [];
    dLayer.push(dataLayerEvent);
  };

  const setLoginInfo = (silent = false) => {
    const id = AuthService.getAuthID() || '';
    const typeAcc = getTypeOfAcc(id);
    if (!definedUserId && id) {
      const dataLayerEvent = {
        userId: `${id}`,
        event: EVENT_TYPE.login,
        loginMethod: `${LOGIN_METHOD[typeAcc]}${(silent ? '-silent' : '')}`,
      };
      pushDatalayer(dataLayerEvent);
      setDefinedUserId(true);
    }
  };
  const setEmailVerified = (success) => {
    const dataLayerEvent = {
      event: EVENT_TYPE.emailVerified,
      verifiedEmail: success,
    };
    pushDatalayer(dataLayerEvent);
  };

  const setSignUpInfo = (signupInfo) => {
    const {
      promocode,
      countryCode,
      mobile: phoneNumber,
      isOptIn,
    } = signupInfo;
    const id = AuthService.getAuthID() || '';
    const typeAcc = getTypeOfAcc(id);
    if (!definedUserId && id) {
      const pnFilled = phoneNumber && true;
      const dataLayerEvent = {
        event: EVENT_TYPE.sigup,
        signUpMethod: `${LOGIN_METHOD[typeAcc]}`,
        ...(promocode && { promocode }),
        pnFilled,
        countryCode,
        isOptIn,
      };
      pushDatalayer(dataLayerEvent);
    }
  };

  const setSubscriptionInfo = (subSinfo) => {
    const {
      promocode,
      periodicity,
      trialDuration,
    } = subSinfo;
    const dataLayerEvent = {
      event: EVENT_TYPE.subsCreated,
      ...(promocode && { promocode }),
      periodicity,
      trialDuration,
    };
    pushDatalayer(dataLayerEvent);
  };
  const setAccountAdquired = () => {
    const dataLayerEvent = {
      event: EVENT_TYPE.accountAdquired,
    };
    pushDatalayer(dataLayerEvent);
  };

  return (
    <GA4Context.Provider
      value={{
        setLoginInfo,
        setSignUpInfo,
        setEmailVerified,
        setSubscriptionInfo,
        setAccountAdquired,
      }}
    >
      {children}
    </GA4Context.Provider>
  );
};

GA4Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GA4Provider;
