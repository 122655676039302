import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GlobalTheme from '../../styled/GlobalTheme';
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '../../assets/icons/iconRepository';

const AccordionSty = styled.div`
  margin-top: 1em;
  border: 1px solid ${GlobalTheme.colors.grayE9};
  border-radius: 6px;
`;
const AccTitleSty = styled.div`
  background-color: ${GlobalTheme.colors.grayf43};
  font-size: 12px;
  line-height: 3em;
  padding: 0 1em;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${GlobalTheme.breakpoints.up('md')}{
    font-size: 16px;
  }
  ${GlobalTheme.breakpoints.up('lg')}{
  }
`;
const AccordionDetailsSty = styled.div`
  height: ${({ expanded }) => (
    expanded ? 'auto' : '0'
  )};
  display: ${({ expanded }) => (
    expanded ? 'block ' : 'none'
  )};
`;
const HolderTitle = styled.div``;

const Chevron = styled.div`
  background: url(${({ expanded }) => (
    expanded ? ChevronUpIcon : ChevronDownIcon
  )}) no-repeat;
  background-position: center;
  padding: 0;
  width: 15px;
  height: 15px;
  ${GlobalTheme.breakpoints.up('md')}{
    width: 36px;
    height: 36px;
  }
  ${GlobalTheme.breakpoints.up('lg')}{
  }
`;
const AccordionPricing = ({ title, children, hasExpanded }) => {
  const [expanded, setExpanded] = useState(hasExpanded);
  const togleExpanded = () => {
    // TODO a nice a beatiful toggle animation
    setExpanded(!expanded);
  };
  return (
    <AccordionSty>
      <AccTitleSty
        onClick={() => togleExpanded()}
      >
        <HolderTitle>{title}</HolderTitle>
        <Chevron
          expanded={expanded}
        />
      </AccTitleSty>
      <AccordionDetailsSty
        expanded={expanded}
      >
        {children}
      </AccordionDetailsSty>
    </AccordionSty>
  );
};

AccordionPricing.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  hasExpanded: PropTypes.bool,
};

AccordionPricing.defaultProps = {
  title: 'Title',
  hasExpanded: false,
};

export default AccordionPricing;
