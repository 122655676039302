import React, { useContext, useState } from 'react';
import {
  injectStripe,
} from 'react-stripe-elements';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Media from 'react-media';
import PiperAdvice from '../../PiperAdvice';
import { paragraph } from '../../../../styled/mixins';
import { Button } from '../../../buttons';
import GlobalTheme from '../../../../styled/GlobalTheme';
import { BillingProvider } from '../../../../services/entities';
import useCallGA from '../../../../hooks/useCallGA';
import { AlertsContext } from '../../../../contexts/AlertsContext';
import {
  stripeIcon,
  visaIcon,
  masterCardIcon,
  americanExpressIcon,
  discoverIcon,
} from '../../../../assets/icons/iconRepository';
import StripeCardFormElements from '../../../AddPaymentModal/StripeCardFormElements';
// eslint-disable-next-line import/no-cycle
import { LayoutContext } from '../../../../contexts/LayoutContext';
import mediaQueries from '../../../../utils/mediaQueries';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 20px 0;
`;

const PiperAdviceStyled = styled(PiperAdvice)`
  margin: ${({ margin }) => (margin || '0')};
  font-size: 14px;
  margin-bottom: 25px;
`;

const StripeContainer = styled.div`
  ${GlobalTheme.breakpoints.up('sm')} {
    padding-bottom: 10px;
  }
`;

const ButtonContainer = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
${GlobalTheme.breakpoints.down('sm')}{
  justify-content: center;
  padding: 0 0 20px 0;
}
`;

const ButtonStyled = styled(Button)`
  && {
    margin: 0 12px;
  }
`;

const ContainerImageCard = styled.div`
  display: flex;
  width: 65%;
  margin: auto;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 100%;
    margin: 0;
  };
`;

const ContainerImageCardInner = styled.div`
  display: flex;
  margin-left: auto;
  background-color: #E9E9E9;
  border-radius: 5px;
`;

const CardsImgStr = styled.img`
  width: 75px;
  height: 50px;
  margin: 0;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 40px;
    height: 25px;
  };
`;

const CardsImg = styled.img`
  width: 45px;
  height: 30px;
  margin: 10px 15px;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 25px;
    height: 15px;
    margin: 10px 4px;
  };
`;

const ContainerTextCard = styled.div`
  font-size: 11px;
  width: 80px;
  text-align: center;
  padding-top: 5px;
  font-weight: bold;
  margin-top: 5px;
  padding-left: 10px;
  ${GlobalTheme.breakpoints.down('sm')}{
    padding-top: 0;
    font-size: 8px;
    padding-left: 0;
  }
`;

const RedNote = styled.div`
  ${paragraph}
  background-color: ${(props) => props.theme.colors.orange};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  padding: 1em .5em;
  margin-top: 16px;
  margin-bottom: 40px;
  font-size: 14px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  };
`;

const StripeForm = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 75%;
  margin: auto;
  ${GlobalTheme.breakpoints.down('sm')}{
    width: 100%;
    margin: 0;
  };
`;

const AddPaymentMethod = ({
  handleNext, handleBack, setPaymentMethods, stripe,
}) => {
  const intl = useIntl();
  const callGA = useCallGA();
  const { showBackendError } = useContext(AlertsContext);
  const [cardInfo] = useState({
    cardholderName: '',
    country: '',
    billingAddressLineOne: '',
    billingAddressLineTwo: '',
    city: '',
    state: '',
    zipcode: '',
    email: '',
  });
  const [loading, setLoading] = useState(false);
  const { user } = useContext(LayoutContext);

  const addCard = async () => {
    setLoading(true);
    cardInfo.cardholderName = `${user.firstName} ${user.lastName}`;
    cardInfo.email = user.email;
    const response = await BillingProvider.addPaymentMethod(stripe, cardInfo);
    if (response.success) {
      const validatefounds = await BillingProvider.validatefounds();
      if (validatefounds.success) {
        setPaymentMethods(response.data);
        callGA({ action: 'Add payment method', label: '(button)' });
        handleNext();
      } else {
        showBackendError(validatefounds.message);
      }
    } else {
      showBackendError(response.message);
    }
    setLoading(false);
  };

  return (
    <Container>
      <StripeContainer>
        <PiperAdviceStyled>
          <FormattedMessage
            id="addPayment.advice"
            defaultMessage="Your information will be safe, so you don’t have to worry"
          />
        </PiperAdviceStyled>

        <ContainerImageCard>
          <CardsImgStr src={stripeIcon} alt="cards" width={{ width: '75px', height: '45px' }} />
          <ContainerImageCardInner>
            <Media queries={mediaQueries}>
              {(matches) => (
                <ContainerTextCard>
                  <FormattedMessage
                    id={matches.smDown ? 'settings.modal.only.credit.text.sm' : 'settings.modal.only.credit.text'}
                    defaultMessage="Only Credit Cards"
                    values={{
                      lineBreak: <br />,
                    }}
                  />
                </ContainerTextCard>
              )}
            </Media>

            <CardsImg src={visaIcon} alt="cards" />
            <CardsImg src={masterCardIcon} alt="cards" />
            <CardsImg src={americanExpressIcon} alt="cards" />
            <CardsImg src={discoverIcon} alt="cards" />
          </ContainerImageCardInner>
        </ContainerImageCard>

        <StripeForm>
          <StripeCardFormElements
            intl={intl}
          />
        </StripeForm>
      </StripeContainer>
      <RedNote>
        <FormattedMessage
          id="onboarding.billing.charging.validateFoundsNote"
          defaultMessage="To verify your method payment, you’ll be charged $1 USD, which will be reimbursed once the payment proceeds."
        />
      </RedNote>
      <ButtonContainer>
        <ButtonStyled type="secondary" onClick={handleBack}>
          <FormattedMessage
            id="k.back"
            defaultMessage="Back"
          />
        </ButtonStyled>
        <Button
          onClick={addCard}
          isLoading={loading}
        >
          <FormattedMessage
            id="k.continue"
            defaultMessage="Continue"
          />
        </Button>
      </ButtonContainer>
    </Container>
  );
};

AddPaymentMethod.propTypes = {
  handleBack: propTypes.func.isRequired,
  handleNext: propTypes.func.isRequired,
  setPaymentMethods: propTypes.func.isRequired,
  stripe: propTypes.instanceOf(Object).isRequired,
};

export default injectStripe(AddPaymentMethod);
