import gql from 'graphql-tag';

export const createTargetClient = gql`
  mutation createTargetClient($ageLowerBound: String!, $ageUpperBound: String!, $location: String, $genders: [String!], $interests: String!) {
    createTargetClient( data: {
      ageLowerBound: $ageLowerBound,
      ageUpperBound: $ageUpperBound,
      location: $location,
      genders: $genders,
      interests: $interests,
    }){
      id
    }
  }
`;

export const updateTargetClients = gql`
  mutation updateTargetClients($ageLowerBound: String, $ageUpperBound: String, $location: String, $genders: [String!], $interests: String, $linkedinLocation: String, $linkedinInterests: String, $socialNetwork: SOCIAL_NETWORK) {
    updateTargetClients( data: {
      ageLowerBound: $ageLowerBound,
      ageUpperBound: $ageUpperBound,
      location: $location,
      linkedinLocation: $linkedinLocation,
      genders: $genders,
      interests: $interests,
      linkedinInterests: $linkedinInterests,

    },
    socialNetwork: $socialNetwork,
    ){
      id
    } 
  }
`;
