const snOrder = {
  facebook: 0,
  instagram: 1,
  twitter: 2,
  linkedin: 3,
  tiktok: 4,
  reachPaidFB: 5,
  reachPaidLI: 6,
};

const sortSocialNetworks = (a, b) => (snOrder[a] - snOrder[b]);
export default sortSocialNetworks;
