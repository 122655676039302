const en = {
  'comparePlans.ceil1': 'Connect Facebook, Instagram, Twitter & LinkedIn',
  'comparePlans.oneAccountPN': '1 Account per network',
  'comparePlans.multiAccount': 'Multi-account',
  'comparePlans.ceil2': 'Users per account',
  'comparePlans.multiuser': 'Multiuser',
  'comparePlans.1user': '1 user per account',
  'comparePlans.ceil3': 'Schedule/publish your content daily, weekly, or monthly',
  'comparePlans.ceil2.1': 'Metrics Dashboard',
  'comparePlans.unlimited': 'Unlimited',
  'comparePlans.ceil4': 'Ready to use/edit social creative templates',
  'comparePlans.ceil4.1': 'Creation of images and texts with AI for your business social media',
  'comparePlans.piperRecom': 'Only Piper Recomendations',
  'comparePlans.ceil5': 'Watch your competitor’s social media activity',
  'comparePlans.onePerNetwork': '1 for each network',
  'comparePlans.ceil6': 'Your Business social media posts calendar management',
  'comparePlans.ceil7': 'Recommendations for your best date and time to publish',
  'comparePlans.ceil8': 'Reply to your clients comments/direct messages from one place',
  'comparePlans.ceil9': 'Create awesome content for your business with our graphic editor',
  'comparePlans.ceil10': 'Access the educational content in our Social Piper Academy',
  'comparePlans.ceil11': 'Customer Success Webinars',
  'comparePlans.ceil12': 'Manage your business’ Social Media Ads',
  'comparePlans.ceil13': 'Recomendations for boosting to maximize your campaigns',
  'comparePlans.ceil14': 'Social Ads reports in real time',
  'comparePlans.ceil15': 'Manage Social Piper from the mobile app',
  'comparePlans.ceil16': 'Instagram direct Scheduling',
  'comparePlans.ceil17': 'Approval workflow to get everyone on board before publishing/advertising',
  'comparePlans.ceil17.1': 'Approval workflow to get everyone on board before publishing',
  'comparePlans.ceil17.2': 'Approval workflow to get everyone on board before advertising',
  'comparePlans.ceil18': 'Technical Support 24/7',
  'comparePlans.ceil19.1': 'Work plan and your content',
  'comparePlans.ceil19.2': 'Design up to 16 monthly content items and their publication on your connected social media through Social Piper',
  'comparePlans.ceil19.3': 'Creation of advertising campaigns based on your budget to boost your posts.',
  'comparePlans.ceil19.4': 'Review of results.',
  'comparePlans.ceil19.5': 'Answer to comments from your community in your social media connected to Social Piper.',
  'comparePlans.helpCenter': 'Help Center, Chat and Email',
  'comparePlans.ceil20': 'Payment Methods',
  'comparePlans.payments': 'Visa, Mastercard, AMEX, Discovery',
  'comparePlans.compare': 'Individual Plan Features',
  'comparePlans.pro': 'Individual Plan',
  'comparePlans.basic': 'Full management service',
  'comparePlans.lite': 'Lite Plan',
  'comparePlans.AccTittle1': 'Create Content',
  'comparePlans.AccTittle2': 'Schedule',
  'comparePlans.AccTittle3': 'Social Advertising',
  'comparePlans.AccTittle4': 'Extra Perks',
  'comparePlans.AccTittle5': 'Support',
  'comparePlans.AccTittle6': 'Online community manager',
  'comparePlans.support.feature1': 'Technical Support 24/7 - Help Center, Chat and Email',
};

export default en;
