import gql from 'graphql-tag';

export const getCompromisedBudget = (attr = `
  totalCompromisedBudget
  totalFbSpend
  totalLiSpend
  totalRemaining
  monthlyBudget
  currencyCode
  usdExchangeRate
`) => gql`
  query getCompromisedBudget ($date: AWSDateTime, $timezone: String) {
    compromisedBudget (date: $date, timezone: $timezone) {
      ${attr}
    }
  }
`;

export const getBudgetOverTime = gql`
  query budgetOverTimeResportV2 ($timezone: String) {
    budgetOverTimeReportV2 (timezone: $timezone) {
      months
      spent {
        facebook
        linkedin
      }
    }
  }
`;
