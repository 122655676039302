const en = {
  'subscriptionModal.covid.regularMonthly': 'Regular monthly subscription cost is {price}',
  'subscriptionModal.covid.regularYearly': 'Regular yearly subscription cost is {price}/year',
  'subscriptionModal.monthly.price': 'US {price} / Month',
  'subscriptionModal.regularCost': 'Regular cost US',
  'subscriptionModal.subscribe': 'Subscribe',
  'subscriptionModal.subtitle': 'Select your desired billing period',
  'subscriptionModal.title': 'Subscribe to Social Piper',
  'subscriptionModal.yearly.price': 'US {price}',
};

export default en;
