import gql from "graphql-tag";

export const getCompany = (
  attr = `
  companyName
  logoUrl
  location
`
) => gql`
  query company {
    company {
      id
      ${attr}
    }
  }
`;

export const getCompanyCategories = gql`
  query companyCategories {
    companyCategories {
      id
      category
      categoryEs
    }
  }
`;

export const searchBusinessCategories = gql`
  query categories($where: String) {
    categories(where: $where) {
      id
      category
      categoryEs
    }
  }
`;

export const fetchFaqs = gql`
  query getFaqsByCompany {
    getFaqsByCompany {
      id
      answer
      question
    }
  }
`;

export const getExtraBusinessInfo = gql`
  query q {
    getExtraBusinessInfo {
      products
      openingHours {
        from {
          day
          hour
        }
        to {
          day
          hour
        }
      }
      physicLocation
      email
      phone
    }
  }
`;
