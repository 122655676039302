import * as Sentry from '@sentry/browser';
import { authAppSync } from '../auth';
import {
  getDevices,
} from '../../graphql/queries';
import {
  registerPlayerId,
  deregisterPlayerId,
} from '../../graphql/mutations';

export default class DevicesProvider {
  static async getUserDevices() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.query({
        query: getDevices,
      });
      const { data } = response;
      if (!response.errors) {
        result = {
          data: data.getUserDevices,
          success: true,
        };
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
      result.success = false;
    }
    return result;
  }

  static async registerDevice(playerId) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: registerPlayerId,
        variables: {
          playerId,
        },
      });
      result.success = true;
      result.data = response.data;
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async deregisterDevice(playerId) {
    const result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: deregisterPlayerId,
        variables: {
          playerId,
        },
      });
      result.success = true;
      result.data = response.data;
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
