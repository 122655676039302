const en = {
  'promo.covid.title': 'Covid Relief Offer:',
  'promo.covid.description': 'We are reducing our subscription price.<sup>1</sup>',
  'promo.covid.pricing.monthly': '<no>$99</no>',
  'pricing.covid.price': '<title>Monthly Subscription</title>:{lineBreak}<info><no>$99</no> <red>USD $69/Month</red> <small><up>2</up></small></info><title>{lineBreak}Yearly Subscription:{lineBreak}</title> <info><no>$990</no> <red>USD $690</red> <greenIndicator>2 MONTHS FREE</greenIndicator></info>{lineBreak}',
  'promo.covid.modal.title': 'COVID Relief Special Offer',
  'promo.covid.modal.content': '<p>We want to help the small businesses who’ve been impacted by this crisis get back to business. Because of this we are reducing our subscription.<sup>1</sup></p>',
  'pricing.covid.modal.legal': '*Social Piper subscription fees will NOT be charged during the free trial. You can cancel at any time. Advertising fees / commissions may apply. See Terms of Service.<ol><li>Social Piper regular monthly subscription cost is $99/month.</li><li>Social Piper regular yearly subscription cost is $990/year.</li></ol>',
  'pricing.promocode.modal.legal': '*Social Piper subscription fees will NOT be charged during the free trial. At the end of your trial period, you will be charged automatically. You can cancel at any time. Advertising fees / commissions may apply. See Terms of Service.<ol><li>Social Piper regular monthly subscription cost is $99/month.</li><li>Social Piper regular yearly subscription cost is $990/year.</li></ol>',
  'pricing.promocode.modal.legalReduced': '*Advertising fees / commissions may apply. See Terms of Service.<ol><li>You can activate your account after you finish your trial period (or earlier if you desire).</li><li>Social Piper regular monthly subscription cost is $99/month.</li><li>Social Piper regular yearly subscription cost is $990/year.</li></ol>',
};

export default en;
