import * as Sentry from '@sentry/browser';
import { authAppSync } from '../auth';
import {
  searchFacebookPages,
  facebookCompetitorPages,
  recentFacebookActivity,
  recentInstagramActivity,
} from '../../graphql/queries';
import { searchInstagramCompetitor, fetchSavedCompetitors } from '../../graphql/queries/instagram.query';
import {
  createFacebookCompetitorPage,
  deleteFacebookCompetitorPage,
  addCompetitorAccount,
  removeCompetitorAccount,
} from '../../graphql/mutations';
import {
  FACEBOOK,
  ENV,
  ENVIRONMENTS,
} from '../../utils/constants/globals';
import competitorsRecentActivityMock from '../../mocks/dashboard/competitorsRecentActivity.mock';
import { POST_TYPE } from '../../utils/constants/posts';
import savedFBCompetitors from '../../mocks/settings/savedFBCompetitors.mock.json';
import savedIGCompetitors from '../../mocks/settings/savedIGCompetitors.mock.json';
import cleanURL from '../../views/Settings/tabs/Competitors/utils/cleanURL';

export default class CompetitorsProvider {
  static async search(searchValue, type) {
    if (type === FACEBOOK) {
      const response = await this.fetchFacebookPages(searchValue);
      if (!response.success) return response;
      const data = response.data.map((c) => ({
        ...c,
        value: c.id,
        label: c.name,
      }));
      return {
        success: true,
        message: 'success',
        data,
      };
    }
    const response = await this.fetchInstagramPages(searchValue);
    if (!response.success) return response;
    const data = response.data.map((c) => ({
      ...c,
      value: c.id,
      label: c.username,
    }));
    return {
      success: true,
      message: 'success',
      data,
    };
  }

  static async fetchFacebookPages(searchValue) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.query({
        query: searchFacebookPages,
        variables: { searchValue },
        fetchPolicy: 'network-only',
      });
      const data = response ? response.data.facebookPageSearch : [];

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchInstagramPages(searchValue) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.query({
        query: searchInstagramCompetitor(),
        variables: { username: searchValue },
        fetchPolicy: 'network-only',
      });
      const data = response ? response.data.instagramCompetitorSearch : [];

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetch() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let responses;
      if (ENV !== ENVIRONMENTS.local) {
        const promises = [];
        const fbResponse = authAppSync.client.query({
          query: facebookCompetitorPages,
          fetchPolicy: 'network-only',
        });
        promises.push(fbResponse);
        const igResponse = authAppSync.client.query({
          query: fetchSavedCompetitors(),
          fetchPolicy: 'network-only',
        });
        promises.push(igResponse);
        responses = await Promise.all(promises);
      } else {
        responses = [
          savedFBCompetitors,
          savedIGCompetitors,
        ];
      }

      const data = responses.map((response, index) => {
        if (!index) {
          return response.data.facebookCompetitorPages.map((c) => ({
            socialId: c.facebookId,
            value: c.id,
            label: c.name,
            picture: c.picture,
          }));
        }
        return response.data.competitors.map((c) => ({
          socialId: c.instagramId,
          value: c.id,
          label: c.username,
          picture: c.picture,
        }));
      });

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async addFacebookCompetitor(competitor, competitors) {
    const { id, name, link } = competitor;
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: createFacebookCompetitorPage,
        variables: {
          facebookId: id,
          name,
          link,
        },
      });
      const added = response.data.createFacebookCompetitorPage.facebookCompetitorPage;

      const data = competitors.filter((c) => c.socialId);
      data.push({
        socialId: added.facebookId,
        value: added.id,
        label: added.name,
        picture: added.picture,
      });

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async addInstagramCompetitor(competitor, competitors) {
    const {
      id,
      name,
      picture,
      username,
    } = competitor;
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: addCompetitorAccount,
        variables: {
          id,
          name,
          profileImage: cleanURL(picture),
          username,
        },
      });

      const added = response.data.addCompetitorAccount;
      const data = competitors.filter((c) => c.socialId);
      data.push({
        socialId: added.instagramId,
        value: added.id,
        label: added.name,
        picture: added.picture,
      });

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async deleteFacebookCompetitor(id, competitors) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const response = await authAppSync.client.mutate({
        mutation: deleteFacebookCompetitorPage,
        variables: {
          id,
        },
      });

      const deleted = response.data.deleteFacebookCompetitorPage.facebookCompetitorPage;
      const data = competitors.filter((competitor) => competitor.value !== deleted.id);
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async deleteInstagramCompetitor(id, competitors) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      await authAppSync.client.mutate({
        mutation: removeCompetitorAccount,
        variables: {
          id,
        },
      });

      const data = competitors.filter((competitor) => competitor.value !== id);

      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchRecentActivity() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let responseFb;
      let responseIg;
      if (ENV !== ENVIRONMENTS.local) {
        [responseFb, responseIg] = await Promise.all([
          authAppSync.client.query({
            query: recentFacebookActivity,
            fetchPolicy: 'network-only',
          }),
          authAppSync.client.query({
            query: recentInstagramActivity,
            fetchPolicy: 'network-only',
          }),
        ]);
      } else {
        // [responseFb = {}, responseIg = {}] = {};
        [responseFb, responseIg] = competitorsRecentActivityMock;
      }
      let data = responseFb.data.facebookRecentCompetitorsPosts.map((fbCompActivity, i) => {
        const isGIF = fbCompActivity.mediaType.toLowerCase() === 'animated_image_video';
        return {
          id: i,
          pageName: fbCompActivity.name,
          src: fbCompActivity.mediaUrl,
          socialNetwork: fbCompActivity.network,
          date: fbCompActivity.dateTime,
          caption: `${fbCompActivity.caption || ''}${
            fbCompActivity.mediaType.toLowerCase() === POST_TYPE.SHARE
              ? ` <a href="${fbCompActivity.sharedLink.url}" target="_blank" rel="noopener noreferrer">${fbCompActivity.sharedLink.url}</a>`
              : ''
          }`,
          interactions: fbCompActivity.interactions,
          pageImage: fbCompActivity.profilePic,
          isGIF,
          isVideo: !isGIF && fbCompActivity.mediaType.toLowerCase().includes(POST_TYPE.VIDEO),
          permalink: fbCompActivity.permalinkUrl,
          mediaType: fbCompActivity.mediaType,
        };
      });
      data.push(...responseIg.data.instagramRecentCompetitorsPosts.map((igCompActivity, i) => ({
        id: i + data.length,
        key: i + data.length,
        pageName: igCompActivity.name,
        src: igCompActivity.mediaUrl,
        socialNetwork: igCompActivity.network,
        date: igCompActivity.dateTime,
        caption: igCompActivity.caption,
        interactions: igCompActivity.interactions,
        pageImage: igCompActivity.profilePic,
        isVideo: igCompActivity.mediaType.toLowerCase() === POST_TYPE.VIDEO,
        permalink: igCompActivity.permalinkUrl,
        mediaType: igCompActivity.mediaType,
      })));

      data = data.sort((a, b) => new Date(b.date) - new Date(a.date));
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
