import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Media from 'react-media';
import mediaQueries from '../../../utils/mediaQueries';

const Container = styled.div`
  @media ${mediaQueries.xlPlusUp} {
    display: grid;
    width: fit-content;
    ${({ isLastTool }) => isLastTool && `
      justify-self: center;
      grid-column: 1 / 4;
      width: max-content;
    `}
  }
`;
const ImageContainer = styled.div`
  justify-self: center;
  width: 312px;
  touch-action: none;
  @media ${mediaQueries.xlPlusUp} {
    ${({ isLastTool }) => isLastTool && `
      width: 678px;
      margin-right: 92px;
    `}
  }
`;
const Image = styled.img`
  width: 100%;
`;
const Description = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  line-height: 22px;
  margin-top: 34px;
  text-align: center;
  touch-action: none;
  @media ${mediaQueries.xlPlusUp} {
    margin-top: 28px;
    ${({ isLastTool }) => isLastTool && `
      margin-top: 0;
      position: relative;
      top: -42px;
    `}
  }
`;
const Title = styled.p`
  font-weight: bold;
  margin: 0;
  @media ${mediaQueries.xlPlusUp} {
    font-size: 28px;
    line-height: 38px;
  }
`;
const Subtitle = styled.p`
  margin: 0;
  ${({ isLastTool }) => isLastTool && `
    margin: 0 auto;
    max-width: 300px;
    @media ${mediaQueries.xlPlusUp} {
      max-width: 100%;
    }
  `}
  @media ${mediaQueries.xlPlusUp} {
    font-size: 18px;
    line-height: 25px;
  }
`;

const Tool = ({ tool }) => (
  <Media queries={mediaQueries}>
    {(matches) => (
      <Container isLastTool={tool.id === 'tool4'}>
        <ImageContainer isLastTool={tool.id === 'tool4'}>
          <Image src={(tool.image.mobile && matches.xlPlusDownLess)
            ? tool.image.mobile
            : tool.image.desktop}
          />
        </ImageContainer>
        <Description isLastTool={tool.id === 'tool4'}>
          <Title>
            {tool.title}
          </Title>
          <Subtitle isLastTool={tool.id === 'tool4'}>
            {tool.description}
          </Subtitle>
        </Description>
      </Container>
    )}
  </Media>
);

Tool.propTypes = {
  tool: PropTypes.shape({
    id: PropTypes.string,
    image: PropTypes.shape({
      mobile: PropTypes.string,
      desktop: PropTypes.string,
    }),
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

Tool.defaultProps = {
  tool: {
    id: '',
    image: {
      mobile: '',
      desktop: '',
    },
    title: '',
    description: '',
  },
};

export default Tool;
