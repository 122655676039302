const es = {
  'planOption.basic.details.comments': 'Contestar comentarios en tus redes sociales.',
  'planOption.basic.details.competitors': 'Monitorear a tu competencia (Podrás agregar 1 competidor en Facebook y 1 en Instagram).',
  'planOption.basic.details.connect': 'Conectar tu cuenta de negocio de Facebook, Instagram y Twitter (1 por cada red social).',
  'planOption.basic.details.description': 'Tu cuenta Básica te permite:',
  'planOption.basic.details.editPosts': 'Administrar y editar tus publicaciones en la vista de calendario.',
  'planOption.basic.details.messages': 'Acceder a tus mensajes de Facebook Messenger.',
  'planOption.basic.details.recommended': 'Utilizar contenido gráfico sugerido por Piper o sube tu propio contenido.',
  'planOption.basic.details.whenToPost': 'Saber cuándo y dónde publicar gracias a Pipe.',
  'planOption.basic.features.comments': 'Responde a comentarios.',
  'planOption.basic.features.connect': 'Conecta FB, IG, y TW.',
  'planOption.basic.features.posts': '10 publicaciones al mes.',
  'planOption.basic.features.templates': 'Usa las plantillas recomendadas.',
  'planOption.business.details.approval': 'Revisar y aprobar las publicaciones y campañas.',
  'planOption.business.details.connect': 'Conectar más de un perfil de cada red social.',
  'planOption.business.details.description': 'Incluye todo lo del Plan Básico y Pro, además podrás:',
  'planOption.business.details.multiuser': 'Contar con acceso multiusuarios para tu equipo.',
  'planOption.business.features.approval': 'Revisar y aprobar las publicaciones y campañas.',
  'planOption.business.features.connect': 'Conecta más de 1 perfil en FB, IG, y TW.',
  'planOption.business.features.multiuser': 'Acceso multiusuario.',
  'planOption.pro.details.ads': 'Crear campañas publicitarias en Facebook.',
  'planOption.pro.details.budget': 'Mantener control de tu presupuesto para campañas.',
  'planOption.pro.details.competitors': 'Agregar todos los competidores que desees.',
  'planOption.pro.details.description': 'Además de lo que incluye la cuenta Básica, también podrás:',
  'planOption.pro.details.editor': 'Utilizar el editor gráfico en línea.',
  'planOption.pro.details.publish': 'Publicar sin límites.',
  'planOption.pro.details.templates': 'Acceder a miles de plantillas.',
  'planOption.pro.features.boost': 'Convierte tus publicaciones en anuncios.',
  'planOption.pro.features.templates': 'Usa y edita miles de plantillas.',
  'planOption.selectThisPlan': 'Selecciona este plan',
  'planOption.selectedPlan': 'Plan selecionado',
  'planOption.selectThisPlanTrial': '{trialDays} días gratis',
  'planOption.actualPlan': 'Ya estás en este plan',
  'planOption.price.monthly': '{cur} {price} al mes',
  'planOption.price.yearly': '{cur} {price} al año',
  'planOption.description.app': 'Aproveche todas las funciones y el poder de Social Piper y haga que su negocio prospere en las redes sociales.',
  'planOption.name.PRO': 'Pro plan',
  'planOption.name.FULL': 'Gestion Total',
};

export default es;
