import { subDays, addMonths } from 'date-fns';

const start = subDays(new Date(), 10);
const end = addMonths(start, 1);

const getSubscriptionMock = {
  data: {
    /* Subscribed in trial */
    getSubscriptionInfoTrial: {
      userId: 'ID',
      subcriptionId: 12,
      planName: 'Pro Offer plan',
      planStatus: 'trialing',
      planStartDate: end, // end of trial
      planPrice: 9.99,
      isMonthly: true,
      isScheduled: true,
      planEndDate: end,
      promoCode: '',
      countryCode: 'MX',
      currencyCode: 'US',
    },
    /* Active subscription */
    getSubscriptionInfoActive: {
      userId: 'ID',
      subcriptionId: 13,
      planName: 'CICC Monthly',
      planStatus: 'active',
      planStartDate: start,
      planPrice: 99,
      isMonthly: true,
      isScheduled: false,
      planEndDate: end,
      promoCode: 'CICC',
      countryCode: 'MX',
      currencyCode: 'US',
    },
    /* Canceled subscription */
    getSubscriptionInfoCanceled: {
      userId: 'ID',
      subcriptionId: 14,
      planName: 'CICC Monthly',
      planStatus: 'past_due',
      planStartDate: start,
      planPrice: 99.99000000000001,
      isMonthly: false,
      isScheduled: false,
      planEndDate: end,
      promoCode: 'CICC',
      countryCode: 'MX',
      currencyCode: 'US',
    },
    /* Yet to subscribe */
    getSubscriptionInfoNo: {
      userId: 'ID',
      subcriptionId: null,
      planName: 'Trial',
      planStatus: 'trialingOptIn',
      planStartDate: end, // end of trial
      planPrice: 0,
      isMonthly: true,
      isScheduled: false,
      planEndDate: end,
      countryCode: 'MX',
      currencyCode: 'US',
    },
    getSubscriptionBasic: {
      userId: 'ID',
      subcriptionId: null,
      planName: 'Basic',
      planStatus: 'trial',
      planStartDate: end, // end of trial
      planPrice: 0,
      isMonthly: true,
      isScheduled: false,
      planEndDate: end,
      countryCode: 'MX',
      currencyCode: 'US',
    },
    getSubscriptionIOS: {
      userId: 1119,
      subcriptionId: null,
      planName: 'SocialPiper Pro Plan IOs',
      planStatus: '',
      planStartDate: '2022-04-27T22:40:31.000Z',
      planPrice: 24,
      isMonthly: true,
      isScheduled: false,
      planEndDate: '2022-04-25T16:44:32.000Z',
      onTrial: false,
      promoCode: null,
      currencyCode: 'MXN',
      countryCode: 'MX',
      applyIOS: true,
      applyAndroid: false,
    },
    getSubscriptionAndroid: {
      userId: 1119,
      subcriptionId: null,
      planName: 'SocialPiper Pro Plan Android',
      planStatus: '',
      planStartDate: '2022-04-27T22:40:31.000Z',
      planPrice: 24,
      isMonthly: true,
      isScheduled: false,
      planEndDate: '2022-04-25T16:44:32.000Z',
      onTrial: false,
      promoCode: null,
      currencyCode: 'MXN',
      countryCode: 'MX',
      applyIOS: false,
      applyAndroid: true,
    },
  },
};

export default getSubscriptionMock;
