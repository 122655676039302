import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

const CardLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.4);
  z-index: 2;
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
`;

const CardLoader = ({
  loading,
  className,
}) => loading && (
  <CardLoaderContainer
    className={className}
  >
    <StyledCircularProgress size={24} />
  </CardLoaderContainer>
);

CardLoader.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

CardLoader.defaultProps = {
  className: '',
};

export default CardLoader;
