import React from 'react';

const contextObject = {
  role: '',
  setRole: () => {},
  endDate: new Date(),
  setEndDate: () => {},
};

const RoleContext = React.createContext(contextObject);

export default RoleContext;
