import React from 'react';
import mock from '../mocks/plans/getPromos.mock.json';

export const contextObject = {
  code: '',
  isValid: false,
  plans: [],
  promoCodeId: undefined,
};
contextObject.plans = mock.data.getPromos.plans;

const PromoCodeContext = React.createContext(contextObject);

export default PromoCodeContext;
