import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { HelpBlueIcon, CloseOutlineIcon } from '../../assets/icons/iconRepository';
import { paragraph, spreadShadow1 } from '../../styled/mixins';
import { colors } from '../../styled/theme';
import GlobalTheme from '../../styled/GlobalTheme';
import useOutsideClick from '../../hooks/useOutsideClick';

const TooltipContainer = styled.div`
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  z-index: ${({ showTooltip }) => (showTooltip && '2')};
  display: ${({ display }) => (display || 'inline')};
`;

const TooltipImg = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -30px;
  margin: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 'auto')} 0 auto;
  ${({ tooltipBreakpoint }) => css`
    ${GlobalTheme.breakpoints.down(tooltipBreakpoint)}{
      ${({ tooltipWidth }) => !tooltipWidth && `
        right: 0;
        margin-top: 4px;
      `}
      ${({ customizable }) => customizable && `
        right: 0;
      `}
    }
  `}
`;

const Tooltip = styled.div`
  position: absolute;
  left: calc(100% + 40px);
  top: 50%;
  transform: translateY(-47%);
  padding: 16px;
  background: white;
  width: ${({ tooltipWidth }) => (tooltipWidth || '330px')};
  margin: auto 0;
  z-index: 3;
  ${spreadShadow1}
  ${({ onLeft }) => onLeft && `
    left: auto;
    right: 0;
  `}
  ${GlobalTheme.breakpoints.down('lg')}{
    left: auto;
    right: 0;
  };
  ${GlobalTheme.breakpoints.down('sm')}{
    transform: none;
    width: ${({ tooltipWidth }) => (tooltipWidth || 'calc(100% + 24px)')};
    top: 0;
    right: ${(props) => (props.tooltipWidth ? (props.customizable) ? '-10px' : '-50px' : '-12px')};
  }
`;

const TooltipTitle = styled.h5`
  ${paragraph}
  font-size: 18px;
  color: ${colors.lightBlue};
  margin-bottom: 16px;
`;

const TooltipDescription = styled.p`
  ${paragraph}
  font-size: 12px;
  margin-top: ${({ title }) => (title === '' ? '0px' : '20px')};
`;

const TooltipImage = styled.img`
  width: 100%;
  height: auto;
  margin-top: 16px;
`;

const CloseButton = styled.button`
  display: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  background-image: url('${CloseOutlineIcon}');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  ${GlobalTheme.breakpoints.down('md')}{
    display: block;
  }
`;

const FormTooltip = ({
  title,
  description,
  image,
  inputRef,
  children,
  className,
  tooltipWidth,
  onLeft,
  hide,
  display,
  tooltipBreakpoint,
  customizable,
  fullWidth,
  customIcon,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [marginTop, setMarginTop] = useState('auto');
  const tooltipRef = useRef();

  useEffect(() => {
    setMarginTop(inputRef.current && (
      inputRef.current.offsetTop + (inputRef.current.offsetHeight - 20) / 2
    ));
  }, [inputRef.current]);

  useOutsideClick(() => {
    setShowTooltip(false);
  }, tooltipRef);
  return (
    <TooltipContainer
      showTooltip={showTooltip}
      className={className}
      display={display}
      fullWidth={fullWidth}
    >
      {children}
      {!hide && (
        <TooltipImg
          src={customIcon || HelpBlueIcon}
          alt="help"
          onClick={() => setShowTooltip(true)}
          onMouseEnter={() => {
            if (window.innerWidth > 1279) {
              setShowTooltip(true);
            }
          }}
          onMouseLeave={() => {
            if (window.innerWidth > 1279) {
              setShowTooltip(false);
            }
          }}
          marginTop={marginTop}
          tooltipWidth={tooltipWidth}
          tooltipBreakpoint={tooltipBreakpoint}
          customizable={customizable}
        />
      )}
      {showTooltip && (
        <Tooltip
          tooltipWidth={tooltipWidth}
          ref={tooltipRef}
          onLeft={onLeft}
          customizable={customizable}
        >
          <CloseButton
            onClick={() => setShowTooltip(false)}
          />
          {title && (
            <TooltipTitle>
              {title}
            </TooltipTitle>
          )}
          <TooltipDescription title={title}>
            {description}
          </TooltipDescription>
          {image && (
            <TooltipImage
              src={image}
              alt="tooltip image"
            />
          )}
        </Tooltip>
      )}
    </TooltipContainer>
  );
};

FormTooltip.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.shape({
    current: PropTypes.object,
  }), PropTypes.func]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  tooltipWidth: PropTypes.string,
  onLeft: PropTypes.bool,
  hide: PropTypes.bool,
  display: PropTypes.string,
  tooltipBreakpoint: PropTypes.string,
  customizable: PropTypes.bool,
  fullWidth: PropTypes.bool,
  customIcon: PropTypes.bool,
};

FormTooltip.defaultProps = {
  title: '',
  image: '',
  className: '',
  tooltipWidth: '',
  inputRef: {
    current: null,
  },
  onLeft: false,
  hide: false,
  display: '',
  tooltipBreakpoint: 'sm',
  customizable: false,
  fullWidth: true,
  customIcon: false,
};

export default FormTooltip;
