const es = {
  'promo.covid.title': 'Oferta de apoyo por el COVID:',
  'promo.covid.description': 'Estamos reduciendo nuestro precio de suscripción.<sup>1</sup>',
  'promo.covid.pricing.monthly': '<no>$99</no>',
  'pricing.covid.price': '<title>Precio mensual</title>: {lineBreak}<info><no>$99</no> <red>USD $69/mes</red><small> <up>2</up></small></info>{lineBreak}<title>Precio anual:{lineBreak}</title> <info><no>$990</no> <red>USD $690</red> <greenIndicator>2 MESES GRATIS</greenIndicator></info>{lineBreak}',
  'promo.covid.modal.title': 'Oferta de apoyo por el COVID',
  'promo.covid.modal.content': '<p>Deseamos ayudar a reactivar el negocio de las pequeñas empresas que han sido afectadas por la crisis. Es por esto que estamos reduciendo nuestro precio de suscripción.<sup>1</sup></p>',
  'pricing.covid.modal.legal': '*Las comisiones por suscripción a Social Piper no serán cobradas durante el período de prueba. Puedes cancelar en cualquier momento. Las comisiones por concepto de publicidad podrían aplicarse. Ver Términos de Servicio.<ol><li>El costo regular de suscripción mensual a Social Piper es 99 US$/mes.</li><li>El costo regular de suscripción anual de Social Piper es 990 US$/año.</li></ol>',
  'pricing.promocode.modal.legal': '*Las comisiones por suscripción a Social Piper no serán cobradas durante el período de prueba. Al finalizar tu periódo de prueba, se hará el cargo de manera automática. Puedes cancelar en cualquier momento. Las comisiones por concepto de publicidad podrían aplicarse. Ver Términos de Servicio.<ol><li>El costo regular de suscripción mensual a Social Piper es 99 US$/mes.</li><li>El costo regular de suscripción anual de Social Piper es 990 US$/año.</li></ol>',
  'pricing.promocode.modal.legalReduced': '*Las comisiones por concepto de publicidad podrían aplicarse. Ver Términos de Servicio.<ol><li>Puedes activar la suscripción de tu cuenta después de finalizar tu período de prueba (o incluso antes, si así lo deseas).</li><li>El costo regular de suscripción mensual a Social Piper es 99 US$/mes.</li><li>El costo regular de suscripción anual de Social Piper es 990 US$/año.</li></ol>',
};

export default es;
