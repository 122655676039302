export const EVENT_TYPE = {
  login: 'login',
  sigup: 'sign_up',
  emailVerified: 'email_verified',
  subsCreated: 'subs_created',
  accountAdquired: 'account_adquired',
};

export const LOGIN_METHOD = {
  email: 'email',
  facebook: 'facebook',
  apple: 'apple',
};
