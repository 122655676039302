import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Dialog as MuiDialog } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FormController from '../../../components/globals/FormController';
import GlobalTheme from '../../../styled/GlobalTheme';
import mediaQueries from '../../../utils/mediaQueries';
import useGetFormData from '../hooks/useGetFormData';
import Button from '../../../components/buttons/Button';
import { CloseOutlineIcon } from '../../../assets/icons/iconRepository';

const Wrapper = styled(MuiDialog)`
  && {
    .MuiPaper-root {
      width: 100%;
      padding: 12px 20px 20px 20px;
      height: fit-content;
      ${({ isSendSuccess }) => isSendSuccess && `
        height: 180px;
        padding-bottom: 30px;
      `}
      @media ${mediaQueries.mdUp} {
        width: 400px;
        ${({ isSendSuccess }) => isSendSuccess && `
          padding-bottom: 30px;
        `}
      }
    }
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Title = styled.h3`
  font-size: 20px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const CloseIconContainer = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  position: relative;
  right: -12px;
  cursor: pointer;
`;
const CloseIcon = styled.img`
  width: 100%;
`;
const Form = styled.form``;
const FormPhoneInput = styled.div`
  margin-top: 20px;
`;
const LabelInput = styled.div`
  margin-bottom: 0.5em;
  font-size: 16px;
  font-weight: 600;
  ${({ isNotValid }) => isNotValid && `
    color: red;
  `}
`;
const CustomPhoneInput = styled(PhoneInput)`
  && {
    .form-control {
      width: 100%;
      height: 40px;
    }
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;
const SuccessMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const SuccessMessage = styled.h3`
  text-align: center;
`;

const ContactFormModal = ({ open, setOpen }) => {
  const {
    handleChange,
    formData,
    setFormData,
    isEmpty,
    setIsEmpty,
    sendData,
    isSendSuccess,
    setIsSendSuccess,
    hasButtonDisabled,
    isLoading,
  } = useGetFormData();

  const handleSend = async () => {
    await sendData();
  };

  const handleClose = () => {
    setOpen(false);
    setIsSendSuccess(false);
    setFormData({
      name: '',
      email: '',
      phone: '',
    });
    setIsEmpty({
      name: false,
      email: false,
      phone: true,
    });
  };

  return (
    <Wrapper
      fullScreen={window.innerWidth < GlobalTheme.breakpoints.values.md}
      open={open}
      isSendSuccess={isSendSuccess}
    >
      <Header>
        <CloseIconContainer onClick={handleClose}>
          <CloseIcon src={CloseOutlineIcon} />
        </CloseIconContainer>
      </Header>
      {isSendSuccess ? (
        <SuccessMessageContainer>
          <SuccessMessage>
            <FormattedMessage
              id="newPricing.form.successMessage"
              defaultMessage="Thank you! We’ll get in touch soon."
            />
          </SuccessMessage>
        </SuccessMessageContainer>
      ) : (
        <>
          <Title>
            <FormattedMessage
              id="newPricing.form.title"
              defaultMessage="Please leave us the following information to contact you as soon as possible:"
            />
          </Title>
          <Form>
            <FormController
              label={(
                <FormattedMessage
                  id="newPricing.form.name"
                  defaultMessage="Name"
                />
              )}
              value={formData.name}
              placeholder="Liam"
              onChange={handleChange}
              id="name"
              showRequiredError={isEmpty.name}
            />
            <FormController
              label={(
                <FormattedMessage
                  id="newPricing.form.email"
                  defaultMessage="E-mail"
                />
              )}
              value={formData.email}
              placeholder="smith@mail.com"
              onChange={handleChange}
              id="email"
              showRequiredError={isEmpty.email}
            />
            <FormPhoneInput>
              <LabelInput isNotValid={isEmpty.phone}>
                <FormattedMessage
                  id="newPricing.form.phone"
                  defaultMessage="Telephone number {aterisk}"
                  values={{
                    aterisk: isEmpty.phone ? '*' : '',
                  }}
                />
              </LabelInput>
              <CustomPhoneInput
                country={'mx'}
                id="phone"
                value={formData.phone}
                onChange={(value, country, e) => handleChange(e, country)}
                placeholder=""
                isValid={!isEmpty.phone}
                inputProps={{
                  required: true,
                  id: 'phone',
                }}
              />
            </FormPhoneInput>
            <ButtonContainer>
              <Button
                handlerClick={handleSend}
                disabled={hasButtonDisabled}
                isLoading={isLoading}
              >
                <FormattedMessage
                  id="k.send"
                  defaultMessage="Send"
                />
              </Button>
            </ButtonContainer>
          </Form>
        </>
      )}
    </Wrapper>
  );
};

ContactFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ContactFormModal;
