const errorsDictionary = {
  'backend.error.currency.usd': 'USD',
  'backend.error.no.platform': 'No se puede iniciar sesión con esta plataforma',
  'backend.error.login.failed': 'Error al iniciar sesión',
  'backend.error.no.token': 'No se ha proporcionado ningún token',
  'backend.error.no.auth.header': 'No se proporcionó un encabezado de autenticación',
  'backend.error.unauthorized': 'No autorizado',
  'backend.error.internal.error': 'Error interno',
  'backend.error.admin.auth': 'Autenticación de administrador no válida',
  'backend.error.missing.email': 'Falta el correo electrónico',
  'backend.error.generic.error.type': 'InternalError',
  'backend.error.no.credentials': 'No se proporcionaron credenciales',
  'backend.error.unknown.field': 'Campo desconocido',
  'backend.error.entity.not.found': 'Entidad no encontrada',
  'backend.error.entity.creation.failed': 'Falló la creación de la entidad',
  'backend.error.entity.update.failed': 'Error en la actualización de la entidad',
  'backend.error.missing.parameters': 'Parámetros faltantes',
  'backend.error.email.failed': 'Hubo un error al enviar el correo electrónico a través de SES',
  'backend.error.invalid.parameters': 'Parámetros no válidos',
  'backend.error.invalid.scheduled.parameters': 'Parámetros no válidos. ScheduleTime solo se puede usar cuando postNow es falso o no se proporciona. ',
  'backend.error.image.does.not.exist': 'La imagen no existe',
  'backend.error.image.update.failure': 'Hubo un error al guardar la imagen',
  'backend.error.reschedule.invalid.parameters': 'Parámetros no válidos. ScheduleTime solo se puede usar cuando postNow es falso o no se proporciona. ',
  'backend.error.user.already.following.competitor': 'Ya sigues a este competidor',
  'backend.error.user.has.no.facebook.page.linked': 'No tienes una página de Facebook vinculada',
  'backend.error.user.has.no.instagram.page.linked': 'No tienes una página de Instagram vinculada',
  'backend.error.boost.cannot.be.created': 'Tu campaña no pudo ser creada. Por favor, intenta nuevamente o manda un correo a support@socialpiper.com',
  'backend.error.facebook.not.policy.accepted': '¡Ups! No pudimos programar tu campaña en <bold>Facebook</bold>. Parece que no aceptaste la politica de no discriminación de Meta (Facebook). <TextLink>Hazlo aquí</TextLink> y vuelve a crear tu campaña. Si el problema continúa, contáctanos en <email>support@socialpiper.com</email>',
  'backend.error.facebook.not.policy.accepted.with.add.error': '¡Ups! No pudimos programar tu campaña en <bold>Facebook</bold>. Parece que no aceptaste la politica de no discriminación de Meta (Facebook). <TextLink>Hazlo aquí</TextLink> y vuelve a crear tu campaña.{linebreak}Además de esto, no pudimos programar al menos otra de tus publicaciones, por favor inténtalo de nuevo.{linebreak}Si el problema continúa, contáctanos en <email>support@socialpiper.com</email>',
  'backend.error.user.has.no.twitter.account.linked': 'No tienes ninguna cuenta de Twitter vinculada',
  'backend.error.user.has.no.company': 'No tienes nombre de tu empresa',
  'backend.error.error.in.bulk.insert': 'Error en inserción masiva',
  'backend.error.comment.does.not.exist': 'El comentario principal no existe',
  'backend.error.comment.creation.failed': 'Tu comentario no pudo ser enviado. Por favor, intenta nuevamente o manda un correo a support@socialpiper.com',
  'backend.error.comment.deletion.failed': 'Error al eliminar el comentario',
  'backend.error.error.saving.new.targetClient': 'Error al guardar el nuevo targetClient',
  'backend.error.error.updating.user': 'Error al actualizar el usuario',
  'backend.error.oauth.failed': 'Error de inicio de sesión de Twitter. Verifica la URL de devolución de llamada y las credenciales de la aplicación. ',
  'backend.error.invalid.email': 'Correo electrónico no válido',
  'backend.error.user.deleted': 'Usuario eliminado',
  'backend.error.cannot.create.empty.post': 'No hay ningún mensaje ni URL de imagen. No se puede crear una publicación vacía. ',
  'backend.error.page.has.no.posts': 'La página vinculada no tiene publicaciones para buscar',
  'backend.error.facebook.post.delete.failure': 'No se pudo eliminar la publicación en Facebook',
  'backend.error.facebook.post.reschedule.failure': 'No se pudo reprogramar la publicación en Facebook',
  'backend.error.post.creation.failure': 'Hubo un error al crear tu publicación',
  'backend.error.facebook.request.failure': 'Solicitud fallida a la API de Facebook',
  'backend.error.facebook.page.not.found': 'Página de Facebook no encontrada',
  'backend.error.twitter.init.failed': 'Error en el proceso de inicio de Twitter',
  'backend.error.post.invalid.schedule': 'El horario debe ser una fecha válida al menos en 10 minutos a partir de ahora',
  'backend.error.twitter.invalid.text.length': 'La longitud del texto del tweet debe tener entre 1 y 280 caracteres',
  'backend.error.twitter.status.update.failure': 'Error al llamar a la actualización de estado del tweet',
  'backend.error.twitter.status.delete.failure': 'Error al llamar a eliminar el estado del tweet',
  'backend.error.twitter.status.reschedule.failure': 'Error al llamar a la reprogramación del estado del tweet',
  'backend.error.twitter.user.does.not.exist': 'El usuario de Twitter vinculado a tu cuenta no existe',
  'backend.error.social.networks.init.failed': 'Falló la inicialización de redes sociales',
  'backend.error.message.creation.failed': 'Error al crear el mensaje',
  'backend.error.message.creation.failed.more.24hrs': 'Las páginas de Facebook Businesses solo tienen hasta 24 horas para responder a un usuario.',
  'backend.error.inbox.does.not.exist': 'La conversación de Facebook no existe',
  'backend.error.targetClient.configuration.not.complete': 'La configuración del cliente de destino para esta empresa no está completa',
  'backend.error.no.currency.found': 'No se encontró moneda para el usuario',
  'backend.error.no.facebook.adAccount': 'No se encontró ninguna cuenta de anuncios de Facebook para el usuario',
  'backend.error.invalid.facebook.ad.account.status': 'La cuenta de anuncios de Facebook tiene un estado no válido',
  'backend.error.invalid.facebook.ad.account.funding': 'La cuenta publicitaria de Facebook tiene una fuente de financiación no válida',
  'backend.error.registered.facebook.adAccount': 'El usuario ya tiene una cuenta de anuncios de Facebook registrada',
  'backend.error.invalid.facebook.adAccount.currency': 'La cuenta publicitaria de Facebook tiene una moneda diferente a la que se seleccionó anteriormente',
  'backend.error.ad.deletion.failure': 'Hubo un error al eliminar la publicación mejorada',
  'backend.error.ad.spent.retrieve.failure': 'Hubo un error al obtener el dinero gastado en el anuncio',
  'backend.error.company.has.no.targetClient': 'La empresa no tiene un cliente objetivo',
  'backend.error.user.has.only.one.card.left': 'Debe tener al menos una tarjeta registrada en su cuenta. Agregue una tarjeta más y vuelva a intentar eliminar esta. ',
  'backend.error.unknown.webhook': 'Formato de enlace web desconocido',
  'backend.error.no.action': 'Sin acción',
  'backend.error.no.effect': 'La notificación no provocó modificaciones',
  'backend.error.webhook.unhandled': 'Mensaje de enlace web no manejado para un campo',
  'backend.error.webhook.error': 'Error al procesar la notificación de web hook',
  'backend.error.webhook.error.page.not.exists': 'La página no existe. No se puede crear la publicación ',
  'backend.error.webhook.parent.comment': 'No se pudo encontrar el comentario principal para esta respuesta',
  'backend.error.webhook.facebook.conversation.not.found': 'Conversación no encontrada en facebook',
  'backend.error.webhook.postId': 'No se puede obtener el ID de publicación desde la carga útil del webhook compartido',
  'backend.error.edition.failure': 'Hubo un error en la actualización',
  'backend.error.attachment.creation.failure': 'Hubo un error al crear el archivo adjunto',
  'backend.error.account.is.already.connected': 'La cuenta ya existe',
  'backend.error.account.disconnection.failure': 'No se pudo desconectar la cuenta',
  'backend.error.stripe.client.not.found': 'No se encontró el cliente de stripe para el usuario',
  'backend.error.subscription.failed': 'Falló la suscripción del plan',
  'backend.error.subscription.deletion.failed': 'Falló la eliminación de la suscripción del plan',
  'backend.error.subscription.not.found': 'No se encontró la suscripción en la base de datos',
  'backend.error.payments.not.found': 'Los pagos no se encontraron en la base de datos',
  'backend.error.password.reset.failure': 'No se pudo enviar el correo electrónico de restablecimiento de contraseña',
  'backend.error.weekly.metrics.error': 'Hubo un error al recuperar las métricas semanales',
  'backend.error.your.account.not.is.in.usd.currency': 'Para crear una campaña publicitaria, necesita una cuenta publicitaria de Facebook basada en dólares estadounidenses. Para crearlo, simplemente siga estos sencillos pasos: https://www.youtube.com/watch?v=APEnHEHDjh0',
  'backend.error.no.updated.facebook.ad.account': 'Hubo un error al actualizar la cuenta de anuncios de Facebook',
  'backend.error.no.created.time.recommendation': 'Hubo un error al guardar la recomendación de tiempo',
  'backend.error.no.created.historic.template': 'Hubo un error al guardar la plantilla histórica',
  'backend.error.facebook.interest.error': 'Hubo un error al recuperar los intereses de Facebook',
  'backend.error.update.authId.by.user': 'Hubo un error al actualizar el ID de autenticación por usuario',
  'backend.error.no.data': 'No hay datos para recuperar',
  'permission.quota.exceeded': 'Para agregar más competidores debes actualizar al plan Pro.',
  'backend.error.linkedin.init.failed': 'Error en el proceso de inicio de LinkedIn',
  'backend.error.linkedin.user.not.found': 'Usuario de LinkedIn no encontrado',
  'backend.error.linkedin.page.not.found': 'Página de LinkedIn no encontrada',
  'backend.error.linkedin.request.failure': 'Solicitud fallida a la API de LinkedIn',
  'backend.error.user.has.linkedin.page': 'Ya hay una página de LinkedIn conectada',
  'backend.error.linkedin.post.update.failure': 'Hubo un error al actualizar tu publicación',
  'backend.error.linkedin.post.reschedule.failure': 'Hubo un error al reagendar tu publicación',
  'backend.error.comment.liked.failed': 'Hubo un al darle me gusta al comenatrio',
  'backend.error.linkedin.post.delete.failure': 'Hubo un error al eliminar tu publicación',
  'backend.error.linkedin.page.already.link': 'Tu cuenta de LinkedIn ya está vinculado a otro usuario de Social Piper',
  'backend.error.linkedin.user.already.link': 'Tu página de LinkedIn ya está vinculada con otro usuario de Social Piper',
  'backend.error.linkedin.adCampaign.update.failure': 'Hubo un error al actualizar tu campaña',
  'backend.error.linkedin.adCampaign.update.failure.toSoon': 'La fecha de inicio debe ser despues de la fecha programada de publicación',
  'backend.error.invalid.phone': 'El número de teléfono no es válido',
  // Stripe
  'stripe.error.invalid_number': 'El número de tarjeta no es válido. Verifica los datos de la tarjeta o usa una diferente.',
  'stripe.error.invalid_expiry_month': 'El mes de expiración de la tarjeta es inválido.',
  'stripe.error.invalid_expiry_year': 'El año de expiración de la tarjeta es inválido.',
  'stripe.error.invalid_cvc': 'El código de seguridad de la tarjeta es inválido.',
  'stripe.error.incorrect_number': 'El número de la tarjeta es incorrecto.',
  'stripe.error.incomplete_number': 'El número de la tarjeta está incompleto.',
  'stripe.error.incomplete_cvc': 'El código de seguridad de la tarjeta está incompleto.',
  'stripe.error.incomplete_expiry': 'El fecha de expiración de la tarjeta está incompleta.',
  'stripe.error.expired_card': 'La tarjeta ya expiró.',
  'stripe.error.email_invalid': 'Tu email es invalido. Revisa tu email o agrega uno diferente',
  'stripe.error.incorrect_cvc': 'El código de seguridad de la tarjeta es incorrecto.',
  'stripe.error.incorrect_zip': 'El código postal de la tarjeta es incorrecto.',
  'stripe.error.invalid_expiry_year_past': 'La tarjeta ha expirado de acuerdo al año indicado.',
  'stripe.error.card_declined': 'La tarjeta fue declinada.',
  'stripe.error.missing': 'No hay tarjeta en el cliente que está siendo cargado.',
  'stripe.error.processing_error': 'Por favor verifica que los datos de tu tarjeta sea correctos e intenta nuevamente.',
  'backend.stripe.addpayment': 'La tarjeta fue declinada. por favor usa una diferente.',
  'stripe.error.only.credit': 'Tu tarjeta no es de crédito. Intenta nuevamente con una tarjeta de crédito.',
  // Mailing errorsDictionary
  'email.change.duplicated': 'El correo ya esta asociado a otra cuenta.',
  'email.verification.error.sendingLimitReach': 'Alcanzaste el limite de correos que se pueden enviar. Espera 10 minutos e intenta nuevamente.',
  'email.verification.error.sendingError': 'No pudimos enviar el código de verificación.',
  'email.verification.error.already.verified': 'Tu correo ya está verificado ¡Continuemos con Social Piper!',
  'email.verification.error.not.exists': 'No tienes ningún código de verificación. Por favor solicita uno',
  'email.verification.error.expired': 'Tu código ha expirado. Por favor solicita un código nuevo.',
  'email.verification.error.failed.too.much': 'Has hecho muchos intentos incorrectos, por favor solicita un código nuevo.',
  'email.verification.error.missmatch': 'El código es incorrecto, por favor verifica en tu correo.',
};

module.exports = errorsDictionary;
