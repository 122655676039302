import { TRIAL_DURATION } from '../../../../utils/constants/settings';

const es = {
  'landing.banner.covidBanner': 'Oferta de apoyo por COVID. ',
  'landing.meetPiper.piperTip': 'Hola Ana, el próximo lunes a las 11am es el mejor momento en el que tus clientes generalmente interactúan con tus publicaciones en Facebook',
  'landing.meetPiper.title': 'Conoce a Piper, tu guía virtual quién te dará las mejores recomendaciones para que <r>tu negocio</r> brille en redes sociales.',
  'landing.meetPiper.boost': '“Si INVIERTES $23,{linebreak}podrás llegar hasta{linebreak}9,450 clientes{linebreak}potenciales',
  'landing.meetPiper.templates': '“Éstas son las mejores PLANTILLAS{linebreak}que seleccioné para tu negocio”',
  'landing.meetPiper.schedule': '“PROGRAMA tu publicación{linebreak}para el próximo lunes 8:00p.m.{linebreak}y llega a más clientes”',
  'landing.meetPiper.audience': '“El martes a las 7:00p.m.{linebreak}es cuando tus CLIENTES{linebreak}están más activos”',
  'landing.meetPiper.tutorial': '“Ve nuestro nuevo TUTORIAL{linebreak}sobre cómo gestionar{linebreak}comentarios en tus redes sociales”',
  'landing.piperSteps.startSection': '<strong>Social Piper es la </strong> plataforma de creación y marketing todo en uno para hacer crecer tu negocio en las redes sociales.',
  'landing.piperSteps.step1.content': 'Te presentamos a “Piper”, tu nueva mascota de Inteligencia Artificial que aprende contigo y te recomienda lo que mejor funciona en social media para tu negocio.',
  'landing.piperSteps.step1.title': '¡Somos tu guía!',
  'landing.piperSteps.step1.subtitle': '<strong>Te ayudamos</strong> en todo el proceso de las redes sociales',
  'landing.piperSteps.step2.content': 'Publica tus imágenes en los momentos en los que tus clientes usan más sus redes sociales.',
  'landing.piperSteps.step2.title': 'Calendariza',
  'landing.piperSteps.step2.subtitle': 'Conoce el <strong>mejor día y horario para publicar.</strong>',
  'landing.piperSteps.step3.content': 'Tenemos más de 3000 diseños listos para tu negocio, incluso puedes editarlos a tu gusto.',
  'landing.piperSteps.step3.title': 'Publica',
  'landing.piperSteps.step3.subtitle': '<strong>Miles de diseños</strong> para publicar o editar',
  'landing.piperSteps.step4.content': 'Piper te recomienda la mejor cantidad o trabaja con tu propio presupuesto y sabrás a cuántas personas puedes llegar',
  'landing.piperSteps.step4.title': 'Invierte',
  'landing.piperSteps.step4.subtitle': '¿No sabes <strong>cúanto invertir en publicidad?</strong>',
  'landing.piperSteps.superTitle': 'Social Piper es la solución perfecta de marketing en redes sociales todo en uno, fácil de usar, gracias a la inteligencia artificial.',
  'landing.promotional.sectionTitle': 'Simplificando el social media para tu negocio.',
  'landing.testimonial.author.1': '<p>Heather Batzel</p><strong>Batzel Law</strong>',
  'landing.testimonial.author.2': '<p>Regina Trillo</p><strong>Neminative</strong>',
  'landing.testimonial.author.3': '<p>Marco A. González</p><strong>FR Pro & Asociados</strong>',
  'landing.testimonial.author.4': '<p>Denise Agnic Iribarren</p><strong>REZO Propiedades</strong>',
  'landing.testimonial.author.5': "<p>Liza Cohen</p><p>Nature's Nosh</p>",
  'landing.testimonial.quote.1': ' SocialPiper hace que el marketing se sienta mucho más accesible y que los resultados sean alcanzables. Para mí, ha sido una excelente primera parada para desarrollar mi presencia en línea.',
  'landing.testimonial.quote.2': 'Social Piper proporciona un espacio donde puedo recopilar datos, crear y programar publicaciones, comparar resultados, crear y financiar campañas y crear una estrategia de redes sociales basada en datos medibles.',
  'landing.testimonial.quote.3': 'Social Piper me ha facilitado la vida, ser creativo y constante en las redes sociales. En resumen, es orden, creatividad y autogestión para sus redes sociales.',
  'landing.testimonial.quote.4': '¡Social Piper hizo más simple mi vida! El marketing a través de su plataforma es muy amigable. ¡Ha sido una experiencia excelente y lo recomendaría con los ojos cerrados!',
  'landing.testimonial.quote.5': 'Como un emprendedor en solitario, estás haciendo malabares con todas las tareas diarias de administrar una marca de alimentos. SocialPiper me ha permitido gestionar de manera fácil y conveniente toda mi gestión de redes sociales.',
  'landing.testimonial.purpose.1': 'en firmas de Abogados',
  'landing.testimonial.purpose.2': 'en negocios de Alimentos',
  'landing.testimonial.purpose.3': 'en Compañias de Seguros',
  'landing.testimonial.purpose.4': 'en Bienes Raíces',
  'landing.testimonial.purpose.5': 'en negocios de Snacks',
  'landing.title': 'Simplificando el social media para tu negocio',
  'landing.promotional.title': 'AI + Redes Sociales',
  'landing.promotional.subtitle': '¡La forma más inteligente y fácil {lineBreak}de administrar tus redes!',
  'landing.promotional.description': 'Social Piper ha incorporado la IA para crear publicaciones, programarlas y administrar todas las cuentas de redes sociales de tu negocio como un verdadero profesional.',
  'landing.promotional.description2': 'Si tienes un negocio y quieres tener éxito en las redes sociales, Social Piper te ayuda a ahorrar tiempo, dinero y esfuerzo, para que puedas concentrarte en lo que mejor haces: <bText>hacer crecer tu negocio.</bText>',
  'landing.promotional.description1.mobile': 'Social Piper ha incorporado la IA para crear publicaciones, programarlas y administrar todas las cuentas de redes sociales de tu negocio como un verdadero profesional.',
  'landing.promotional.description2.mobile': 'Si tienes un negocio y quieres tener éxito en las redes sociales, Social Piper te ayuda a ahorrar tiempo, dinero y esfuerzo, para que puedas concentrarte en lo que mejor haces: <bText>hacer crecer tu negocio.</bText>',
  'landing.promotional.button.trial.mobile': '¡Inicia tu prueba gratis de {trialDays} días!',
  'landing.promotional.button.trial': '¡Inicia ahora tu prueba gratis de {trialDays} días!',
  'landing.promotional.button2.trial.mobile': '¡Inicia tu prueba gratis de {trialDays} días!',
  'landing.promotional.button2.trial': 'Comienza tu prueba gratis de {trialDays} días',
  'landing.promotional.buttonInfo': 'Prueba Social Piper sin riesgos, <strong>puedes cancelar cuando quieras.</strong>',
  'landing.promotional.buttonInfo.without.trial': '<strong>Puedes cancelar cuando quieras.</strong>',
  'landing.promotional.button.show.video': 'Ve cómo funciona',
  'landing.pricing.title': 'El mejor plan para tu negocio',
  'landing.pricing.title.mobile': 'Seleciona el mejor plan para tu negocio',
  'landing.pricing.description': `Tenemos los mejores planes del mercado, para los que solo tienen un negocio (Pro) y para los que tienen un equipo de marketing o más de un negocio (Business). ¡Pruébalo por ${TRIAL_DURATION} días!`,
  'landing.pricing.description.mobile': `Tenemos los mejores planes del mercado, para los que solo tienen un negocio (Pro) y para los que tienen un equipo de marketing o más de un negocio (Business). ¡Pruébalo por ${TRIAL_DURATION} días!`,
  'landing.pricing.owner.title': 'Pro',
  'landing.pricing.owner.attr.templates': 'Acceso a +1000 plantillas',
  'landing.pricing.owner.attr.designTool': 'Herramienta de diseño de imágenes',
  'landing.pricing.owner.attr.schedule': 'Calendario de programación',
  'landing.pricing.owner.attr.messages': 'Gestor de mensajes y comentarios',
  'landing.pricing.owner.attr.boostTool': 'Herramienta para lanzamiento de campañas',
  'landing.pricing.owner.attr.budgetTool': 'Herramienta para manejo de presupuestos',
  'landing.pricing.owner.attr.competitors': 'Monitor de actividad de tu competencia',
  'landing.pricing.owner.attr.user': '1 usuario',
  'landing.pricing.owner.attr.connection': 'Conexión a una sola cuenta de Facebook, Instagram y Twitter',
  'landing.pricing.owner.price.offer': 'Oferta especial COVID',
  'landing.pricing.owner.yearly.freeMonths': '2 MESES GRATIS',
  'landing.pricing.owner.normalPrice': 'Antes USD <c></c>',
  'landing.pricing.smallTeams.title': 'Business',
  'landing.pricing.smallTeams.further': 'Además:',
  'landing.pricing.smallTeams.attr.allOwner': 'Todo el Plan Pro',
  'landing.pricing.smallTeams.attr.multiuser': 'Acceso Multiusuario',
  'landing.pricing.smallTeams.attr.multipleConnections': 'Conecta más de un perfil de Facebook, Instagram y Twitter',
  'landing.pricing.smallTeams.attr.approval': 'Función de aprobación y revisión de contenido antes de ser publicado',
  'landing.academy.banner.isHere': '¡ya está disponible!',
  'landing.academy.banner.title': 'Conviértete en un experto con ayuda de los expertos',
  'landing.academy.banner.description': 'Social Piper\'s Academy ayuda a tu negocio a alcanzar su máximo potencial a través de herramientas y guías de aprendizaje online, para garantizar el dominio de tu negocio mediante capacitaciones en marketing digital.',
  'landing.academy.banner.button': '¡Vamos a aprender!',
  'landing.app.banner.title': '¡Gestiona las redes sociales de tu negocio desde cualquier lugar!',
  'landing.app.banner.paragraph1': 'Con la app de Social Piper puedes manejar las de tu negocio en cualquier lugar desde la palma de tu mano.',
  'landing.enjoy.superTitle': 'Disfruta la experiencia de usar la mejor herramienta de social media',
  'landing.enjoy.Title': '¡Verás que Menos es Más!',
  'landing.enjoy.paragraph1': 'Social Piper es la plataforma que te ofrece herramientas inmejorables y un proceso simple y automatizado para que tu negocio comience a destacar en las redes sociales.',
  'landing.enjoy.paragraph2': 'Haz click en el video para ver todo lo que Social Piper puede hacer por tu negocio.',
  'landing.whereToPost.title': 'Pública en segundos en:',
  'landing.whereToPost.linkinstagram': 'Ver más',
  'landing.social.instagram.description': '+  Historias {lineBreak}de IG',
  'landing.tooltip.instagram.title': 'La mejor herramienta para publicar en tu página de negocio de Instagram, donde puedes:',
  'landing.tooltip.instagramStories.title': 'Con la aplicación de Social Piper puedes:',
  'landing.tooltip.instagram.feature1': 'Carga fotos y videos',
  'landing.tooltip.instagram.feature2': 'Publica y programa directamente en tu perfil.',
  'landing.tooltip.instagram.feature3': 'Publica o programa en los mejores momentos según las recomendaciones de Piper',
  'landing.tooltip.instagram.feature4': '+3,000 available templates and images ready to use.',
  'landing.tooltip.instagram.feature5': 'Edita y agrega fácilmente tu logotipo a tus imágenes.',
  'landing.tooltip.instagramStories.feature1': 'Sube fotos o videos para tus Historias.',
  'landing.tooltip.instagramStories.feature2': 'Publica inmediatamente',
  'landing.tooltip.instagramStories.feature3': 'Y lo mejor: solo con Social Piper PUEDES <s>programar una Historia de Instagram!</s>',
  'landing.tools.title': 'Tú tienes grandes sueños para tu negocio. {lineBreak}Nosotros, la Inteligencia para alcanzarlos.',
  'landing.tools.subtitle': 'Fácil de usar, todo desde un mismo lugar. Piper te mostrará cómo lograrlo.',
  'landing.tools.title.mobile': 'Tú tienes grandes sueños. Nosotros las herramientas.',
  'landing.tools.subtitle.mobile': 'Fácil de usar, en un mismo sitio. Piper te mostrará cómo.',
  'landing.tools.tool1.title': 'Publicaciones perfectas.',
  'landing.tools.tool1.description': 'Usa tus propios textos e imágenes o deja que “Piper”, nuestra IA las cree para ti.',
  'landing.tools.tool2.title': 'Planeación fácil e inteligente',
  'landing.tools.tool2.description': 'Publica estratégicamente en todas tus redes usando el calendario mas “inteligente” del mercado.',
  'landing.tools.tool2.description.mobile': 'Calendario inteligente para publicar {lineBreak}en todas tus redes.',
  'landing.tools.tool3.title': 'Interacción sin esfuerzo',
  'landing.tools.tool3.description': 'Sin dar vueltas y vueltas. Contesta, o deja que “Piper” conteste todos los comentarios desde un sólo lugar.',
  'landing.tools.tool4.title': '¡Despega al éxito!',
  'landing.tools.tool4.description': 'Piper te ayuda a administrar tu inversión en anuncios para maximizar su alcance.',
  'landing.piper.title': 'Di hola a “Piper”',
  'landing.piper.subtitle': '¡El amigo más inteligente y confiable para las redes sociales de tu negocio!',
  'landing.piper.description1': 'Si eres freelance o formas parte de un equipo en una PyME, Piper está de tu lado.',
  'landing.piper.description2': 'Piper te da ideas, revela tendencias y convierte los datos de la industria en posibles acciones. Piper está en constante aprendizaje –  de ti, de tus seguidores y de expertos en redes sociales. Te da asesorías y métricas para elevar tu marca.',
  'landing.awardSection.title': 'GANADOR 2021',
  'landing.awardSection.subtitle': 'LA MEJOR PLATAFORMA DE REDES SOCIALES DEL MUNDO PARA PEQUEÑAS EMPRESAS',
};

export default es;
