import auth0 from 'auth0-js';

/**
 * Attention please: don't use directly this object
 * or the related vars in localStorage with Auth,
 * always through AuthService :pray:
 */
export default class Auth {
  init() {
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      responseType: process.env.REACT_APP_AUTH0_RESPONSE_TYPE,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
    });
  }
}
