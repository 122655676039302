import * as Sentry from '@sentry/browser';
import pickBy from 'lodash.pickby';
import { publicAppSync, authAppSync } from '../auth';
import {
  createUser,
  updateUser,
  updateUserPassword,
  deleteOwnUser,
  updateAuthUser,
  resetPassword as resetPasswordMutation,
  updateAuthIdByUser as updateAuthIdByUserMutation,
  updateUserSettings,
  updateUserCurrency,
} from '../../graphql/mutations/index';
import {
  getUserInformation,
  getUserByEmail,
  getUserSettings,
  getConnectedAccounts,
  existsAuthId,
  verifyEmail,
  getListNotifications,
  getUserCurrencies,
  currencyLookUp,
} from '../../graphql/queries';
import {
  meMock,
  resetPasswordMock,
  settingsMock,
  userCurrencyMock,
  currencyLookUpMock,
} from '../../mocks/users';
import { ENVIRONMENTS, ENV } from '../../utils/constants/globals';
import responseFactory from '../../utils/responseFactory';
import connectedAccountsMock from '../../mocks/users/connectedAccounts.mock.json';

export default class UserProvider {
  static async create(user) {
    let result;
    try {
      const {
        firstName,
        lastName,
        email,
        wantsEmails,
        language,
        promocode,
        wantsFree,
        currencyCode,
        countryCode,
        isIOS,
        isAndroid,
        mobile,
        isOptIn,
      } = user;
      const userData = {
        firstName,
        lastName,
        businessName: '',
        email,
        status: true,
        wantsEmails,
        language,
        wantsFree,
        currencyCode,
        countryCode,
        isIOS,
        isAndroid,
        mobile,
        isOptIn,
      };
      if (promocode) userData.promoCode = promocode;
      result = await authAppSync.client.mutate({
        mutation: createUser,
        variables: {
          data: userData,
        },
      });
      const { id, authId, settings } = result.data.createUser;
      result = {
        message: 'success',
        success: true,
        data: {
          ...user,
          id,
          authId,
          requiredCard: settings.requiredCard,
        },
      };
    } catch (err) {
      Sentry.captureException(err);
      result = {
        message: err.message,
        success: false,
        data: null,
      };
    }
    return result;
  }

  static async fetch(attr = null, cached = true) {
    let result = responseFactory();
    let response;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: attr ? getUserInformation(attr) : getUserInformation(),
          ...(!cached && { fetchPolicy: 'network-only' }),
        });
      } else {
        response = meMock;
      }
      const { connectedAccounts: accounts } = response.data.me;
      const connectedAccounts = pickBy(accounts);
      result = {
        message: 'success',
        success: true,
        data: {
          ...response.data.me,
          connectedAccounts,
        },
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchConnectedAccounts(cached = true) {
    let result = responseFactory();
    let response;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: getConnectedAccounts,
          ...(!cached && { fetchPolicy: 'network-only' }),
        });
      } else {
        response = connectedAccountsMock;
      }
      const { __typename, ...accounts } = response.data.connectedAccounts;
      const connectedAccounts = pickBy(accounts);
      result = {
        message: 'success',
        success: true,
        data: connectedAccounts,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchSettings(attr = null, cached = true) {
    let result = responseFactory();
    let response;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: attr ? getUserSettings(attr) : getUserSettings(),
          fetchPolicy: (cached) ? 'cache-first' : 'network-only',
        });
      } else {
        response = settingsMock;
      }
      result = {
        message: 'success',
        success: true,
        data: response.data.getSettings,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async verifyEmailUser() {
    let result = responseFactory();
    let response;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: verifyEmail(),
        });
      }
      result = {
        message: 'success',
        success: true,
        data: response.data.verifyEmail,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchByEmail(email) {
    const result = responseFactory();
    try {
      const response = await authAppSync.client.query({
        query: getUserByEmail,
        variables: {
          email,
        },
      });
      if (response.data.getUserByEmail) {
        result.message = 'success';
        result.success = true;
        result.data = response.data.getUserByEmail;
      } else {
        result.message = 'Entity not found';
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async update(userData) {
    let result = responseFactory();
    try {
      const mutResult = await authAppSync.client.mutate({
        mutation: updateUser,
        variables: {
          first_name: userData.firstName,
          last_name: userData.lastName,
          email: userData.email,
        },
      });
      if (mutResult?.data) {
        const { updateUser: uUserInfo } = mutResult.data;
        result = {
          message: 'success',
          success: true,
          data: uUserInfo,
        };
      }
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async updateAuthIdbyUser(email, authId) {
    const result = responseFactory();
    try {
      await authAppSync.client.mutate({
        mutation: updateAuthIdByUserMutation,
        variables: {
          email,
          authId,
        },
      });
      result.success = true;
      result.message = 'Updated user success';
      result.data = { email, authId };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async updatePassword(password) {
    let result = responseFactory();
    try {
      await authAppSync.client.mutate({
        mutation: updateUserPassword,
        variables: {
          password,
        },
      });
      result = {
        message: 'success',
        success: true,
        data: null,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async updateUserSettings({
    language, onboardingFinished, nextOnboardingStep, statusTutorial, onboardingDateStatus,
  }) {
    let result = responseFactory();
    try {
      if (ENV !== ENVIRONMENTS.local) {
        await authAppSync.client.mutate({
          mutation: updateUserSettings,
          variables: {
            data: {
              language,
              onboardingFinished,
              nextOnboardingStep,
              statusTutorial,
              onboardingDateStatus,
            },
          },
        });
      }
      result = {
        message: 'success',
        success: true,
        data: null,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async disableAccount() {
    let result = responseFactory();
    try {
      await authAppSync.client.mutate({
        mutation: updateAuthUser,
        variables: {
          blocked: true,
        },
      });

      await authAppSync.client.mutate({
        mutation: deleteOwnUser,
        variables: {},
        // TODO: logout
        // update: () => {
        //   auth0Client.logout();
        // },
      });

      result = {
        message: 'success',
        success: true,
        data: null,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async resetPassword(email) {
    let result = responseFactory();
    let response;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        response = await publicAppSync.client.mutate({
          mutation: resetPasswordMutation,
          variables: {
            email,
          },
        });
      } else {
        response = resetPasswordMock;
      }
      result = {
        message: 'success',
        success: true,
        data: response.data.resetPassword,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async verifyAuthId() {
    const result = responseFactory();
    try {
      const response = await authAppSync.client.query({
        query: existsAuthId,
      });
      result.message = 'completed';
      result.success = response.data.existsUser;
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async getListNotification(cached = true) {
    let result = responseFactory();
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: getListNotifications,
          ...(!cached && { fetchPolicy: 'network-only' }),
        });
      } else {
        response = {
          data: {
            getListNotification: {
              data: [],
            },
          },
        };
      }
      result = {
        message: 'success',
        success: true,
        data: response.data.getListNotification.data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchCurrencies() {
    const result = responseFactory();
    if (ENV !== ENVIRONMENTS.local) {
      try {
        const response = await authAppSync.client.query({
          query: getUserCurrencies,
        });
        result.data = response.data.userCurrencies;
        result.success = true;
      } catch (err) {
        Sentry.captureException(err);
        result.message = err.message;
      }
    } else {
      result.data = userCurrencyMock.data.userCurrencies;
      result.success = true;
    }
    return result;
  }

  static async fetchCurrencyLookUp() {
    const result = responseFactory();
    if (ENV !== ENVIRONMENTS.local) {
      try {
        const response = await authAppSync.client.query({
          query: currencyLookUp,
        });
        result.data = response.data.currencyLookUp;
        result.success = true;
      } catch (err) {
        Sentry.captureException(err);
        result.message = err.message;
      }
    } else {
      result.data = currencyLookUpMock.data.currencyLookUp;
      result.success = true;
    }
    return result;
  }

  static async updateCurrency(code) {
    const result = responseFactory();
    if (ENV !== ENVIRONMENTS.local) {
      try {
        const response = await authAppSync.client.mutate({
          mutation: updateUserCurrency,
          variables: {
            currencyCode: code,
          },
        });
        result.data = response.data.updateUserCurrency;
        result.success = true;
      } catch (err) {
        Sentry.captureException(err);
        result.message = err.message;
      }
    } else {
      result.data = { currencyCode: 'MXN', usdExchangeRate: 27 };
      result.success = true;
    }
    return result;
  }
}
