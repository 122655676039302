import gql from 'graphql-tag';

export const generateImageByPiper = gql`
  query dallE(
    $n: Int = 1
    $prompt: String!
    $prevPrompt: String
    $genType: GenerationType = GENERATE
    $language: LANGUAGE!
  ) {
    dallE(
      n: $n
      prompt: $prompt
      responseFormat: B64_JSON
      size: _1024X1024
      prevPrompt: $prevPrompt
      genType: $genType
      language: $language
    ) {
      data {
        b64Json
      }
    }
  }
`;
export const generateCaptionByPiper = gql`
  query gpt(
    $prompt: String!
    $language: LANGUAGE!
    $company: BusinessAIConfig
    $appsConnected: [String]!
    $n: Int!
    $charsLimit: Int!
    $genType: GenerationType = GENERATE
    $linkedinConfig: SocialMediaAIConfig
    $facebookConfig: SocialMediaAIConfig
    $prevPrompt: String
  ) {
    gpt(
      prompt: $prompt
      language: $language
      company: $company
      appsConnected: $appsConnected
      n: $n
      charsLimit: $charsLimit
      genType: $genType
      linkedinConfig: $linkedinConfig
      facebookConfig: $facebookConfig
      prevPrompt: $prevPrompt
    ) {
      choices {
        index
        message {
          content
        }
      }
    }
  }
`;
export const socials4ai = `
  connectedAccounts {
    facebook { pageName }
    instagram { pageName }
    linkedin { pageName }
    tiktok { pageName }
    twitter { pageName }
  }
`;

export const language4ai = `
    language
`
// export const
/** @type {(socialmedias: string[]) => any} */
export const aiConfigSocialMediaQuery = (socialmedias) => gql`
  query getConf($language: LANGUAGE!) {
    ${
  socialmedias.includes('Facebook')
    ? `
      facebookConfig: getClientsInformation(
        language: $language
        socialNetwork: facebook
      ) {
          ageUpperBound
          ageLowerBound
          location
          genders
          interests {
            facebookId
            name
          }
        }
      `
    : ''
}
    ${
  socialmedias.includes('Linkedin')
    ? `
      linkedinConfig: getClientsInformation(
        language: $language
        socialNetwork: linkedin
      ) {
        linkedinInterests
        linkedinLocation
        ageUpperBound
        ageLowerBound
        genders
      }
      `
    : ''
}
  }
`;