/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import * as Sentry from '@sentry/browser';
import {
  templatesMock,
  templatesEsMock,
  recommEsMock,
  recommMock,
} from '../../mocks/templates';
import { authAppSync } from '../auth';
import { getTemplates, getRecommTemplates } from '../../graphql/queries';
import {
  ENV,
  ENVIRONMENTS,
  LANGUAGES_SUFIX,
} from '../../utils/constants/globals';

const addIsProFlag = (templateArray) => (
  templateArray.map((template) => {
    const updatedTemplate = template;
    updatedTemplate.isProResource = true;
    return updatedTemplate;
  })
);

export default class TemplatesProvider {
  static async fetchByTag(limit, offset, tagName, language = 'en') {
    let result;
    let data;
    const columnName = `name${LANGUAGES_SUFIX[language]}`;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        result = await authAppSync.client.query({
          query: getTemplates(columnName),
          fetchPolicy: 'network-only',
          variables: {
            tagName,
            language,
            pagination: {
              limit,
              offset,
            },
            production: true,
          },
        });
        data = result.data;
      } else {
        const templatesToTransform = language === 'es' ? templatesEsMock.data : templatesMock.data;
        // templatesToTransform.getTemplates.items = addIsProFlag(templatesToTransform.getTemplates.items);
        data = templatesToTransform;
      }
      result = data.getTemplates.items.map((template) => {
        let { tags } = template;
        if (tags.length > 0) {
          tags = tags
            .map((tag) => ({ ...tag, name: tag[columnName] }))
            .filter((v, i, a) => a.findIndex((t) => (t[columnName] === v[columnName])) === i);
        }
        let response= {
          isProResource: true,
          ...template,
          tags,
        };
        if(template.imageType === 'jpg') {
          response.pngUrl=template.imageUrl
          response.downloadLocation=template.imageUrl
          response.isNewTemplate=true
        }
        return response;
      });

      result = {
        message: 'success',
        success: true,
        data: {
          items: result,
          count: data.getTemplates.count,
        },
      };
    } catch (err) {
      Sentry.captureException(err);
      result = {
        message: err.message,
        success: false,
        data: null,
      };
    }
    return result;
  }

  static async fetchRecommendations(language = 'en') {
    let result;
    let data;
    const columnName = `name${LANGUAGES_SUFIX[language]}`;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        result = await authAppSync.client.query({
          query: getRecommTemplates(columnName),
          variables: { language },
          fetchPolicy: 'network-only',
        });
        data = result.data;
      } else {
        const templatesToTransform = language === 'es' ? recommEsMock.data : recommMock.data;
        templatesToTransform.contentRecommendations = addIsProFlag(templatesToTransform.contentRecommendations);
        data = templatesToTransform;
      }
      result = data.contentRecommendations.map((template) => {
        let { tags } = template;
        if (tags.length > 0) {
          tags = tags.map((tag) => ({ ...tag, name: tag[columnName] }));
        }
        return {
          ...template,
          tags,
        };
      });
      result = {
        message: 'success',
        success: true,
        data: result,
      };
    } catch (err) {
      Sentry.captureException(err);
      result = {
        message: err.message,
        success: false,
        data: null,
      };
    }
    return result;
  }
}
