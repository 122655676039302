const es = {
  'newPricing.title': 'Precios',
  'newPricing.subtitle': 'Selecciona el mejor plan para ti',
  'pricing.join.subtitle': 'La mejor plataforma <h>para manejar las redes sociales de tu negocio,</h> de manera fácil desde un mismo lugar.',
  'newPricing.litePlan.title': 'Plan Lite',
  'newPricing.plan.description.lite': 'Si estás iniciando en redes sociales',
  'newPricing.plan.free.text': 'Siempre gratuito',
  'newPricing.plan.lite.content1': 'Hasta <bold>10 publicaciones</bold> al mes',
  'newPricing.plan.lite.content2': 'Accede a imágenes listas para publicar',
  'newPricing.plan.lite.content3': 'Conecta 1 cuenta por red social',
  'newPricing.plan.lite.ctaButton': 'Inicia ahora',
  'newPricing.proPlan.title': 'Plan Individual',
  'newPricing.plan.description.pro': 'para freelance / emprendedores y administradores de cuentas de redes',
  'newPricing.plan.pro.content1': 'Posts ilimitados',
  'newPricing.plan.pro.content2': 'Plantillas y diseños listos {lineBreak}para usar',
  'newPricing.plan.pro.content3': '1 usuario por plan',
  'newPricing.plan.pro.content4': 'Creación de imágenes y textos con {lineBreak}IA ilimitadas para tus redes sociales',
  'newPricing.plan.pro.content5': 'Dashboard de métricas',
  'newPricing.plan.pro.ctaButton': 'Comienza tu prueba gratis por {daysTrial} días',
  'newPricing.businessPlan.title': 'Plan para tu equipo',
  'newPricing.full.managment.title': 'Gestión Total de Redes Sociales',
  'newPricing.plan.description.business': '¡Déjanos ser tu Community Manager!',
  'newPricing.plan.business.info': 'Este plan Incluye todos los beneficios de un community manager profesional en línea para llevar en conjunto:',
  'newPricing.plan.business.content1': 'Redacción del plan de trabajo y tu parrilla de contenido.',
  'newPricing.plan.business.content2': 'Elaboración de hasta 16 contenidos mensuales y su publicación en tus redes sociales conectadas a través de Social Piper.',
  'newPricing.plan.business.content3': 'Creación de campañas publicitarias basadas en tu presupuesto para llegar a más personas.',
  'newPricing.plan.business.content4': 'Revisión de resultados.',
  'newPricing.plan.business.content5': 'Responder a los comentarios de tu comunidad en tus redes sociales conectadas a Social Piper.Responder a los comentarios de tu comunidad en tus redes sociales conectadas a Social Piper.',
  'newPricing.plan.business.content6': 'Incluye el servicio para todas las redes sociales para las que Social Piper tiene soporte al momento de la contratación del servicio.',
  'newPricing.plan.comingSoon.text': '¡Próximamente!',
  'newPricing.card.seeDetails': 'Ve detalles',
  'newPricing.videoTour.description': 'Descubre todo lo que puedes hacer en social Piper',
  'newPricing.plan.apply.promo': 'Código promocional {code} aplicado',
  'plan.pro.without.trial': 'Crea tu cuenta',
  'newPricing.cta.button': 'Ve todas las características del plan',
  'newPricing.modal.features.title.pro': 'Características {lineBreak}del Plan Individual',
  'newPricing.modal.features.title.business': 'Características del Servicio Completo de gestión',
  'newPricing.modal.features.unlimited.text': ' - ilimitado',
  'newPricing.price.monthly.text': '/mes',
  'newPricing.price.annually.text': '/año',
  'newPricing.form.name': 'Nombre',
  'newPricing.form.email': 'Correo electrónico',
  'newPricing.form.phone': 'Número de teléfono {aterisk}',
  'newPricing.form.title': 'Por favor déjanos los siguientes datos para ponernos en contacto contigo a la brevedad:',
  'newPricing.form.successMessage': '¡Muchas gracias! Pronto nos pondremos en contacto contigo.',
};

export default es;
