const en = {
  'inAppTouch.gretting.1': 'Nos gustaría hablar contigo.',
  'inAppTouch.gretting.2': '¡Hola {firstName}!',
  'inAppTouch.gretting.3': '¡Hola {firstName}!',
  'inAppTouch.gretting.4': 'Do you have a discount coupon?',
  'inAppTouch.text.1': 'Hoy eres uno de los usuarios top, y nos interesa tener una <hl>breve charla de 15 minutos</hl> para conocer lo que no te gusta y lo que sí te gusta de Social Piper.',
  'inAppTouch.text.2': 'En las últimas horas detectamos que eres uno de los usuarios TOP en Social Piper por lo que nos interesa tener una <hl>charla de 15 minutos</hl> para conocer lo que no y sí te gusta de Social Piper.',
  'inAppTouch.text.3': 'Tu período de prueba está por terminar. <hl>Si contestas ahora</hl> nuestra encuesta recibirás',
  'inAppTouch.text.4': 'Do you have a discount coupon?',
  'inAppTouch.subText.3': '30 días más completamente gratis.',
  'inAppTouch.cta.1': 'Agenda aquí tu llamada',
  'inAppTouch.cta.2': 'Agenda aquí tu llamada',
  'inAppTouch.cta.3': 'Ir a encuesta',
  'inAppTouch.cta.4': 'Use it now!',
  'requireAttention.text': 'One or more of the social media you connected requires immediate attention.',
  'requireAttention.cta': 'Fix now',
  'requireAttention.settings': '{warni} This connection has expired; this could be due to a password change, the social network\'s connection to Social Piper has not been used in a while, etc.',
};

export default en;
