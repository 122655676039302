import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '../../../components/buttons';
import mediaQueries from '../../../utils/mediaQueries';

const ButtonPrimaryStyled = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.colors.orange02};
    box-shadow: none;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    line-height: 36px;
    padding: 10px ${({ largeText }) => (largeText ? '11px' : '36px')};
    ${({ largeTextEnglish }) => largeTextEnglish && `
      padding-left: 34px;
      padding-right: 34px;
    `}
    &:hover {
      background-color: ${({ theme }) => theme.colors.orange02};
      box-shadow: none;
      border: none;
      border-radius: 4px;
    }
    @media ${mediaQueries.lgPlusUp} {
      font-size: 22px;
    }
  }
`;

const ButtonSecondaryStyled = styled(Button)`
  && {
    font-size: 18px;
    line-height: 36px;
    padding: 10px ${({ largeText }) => (largeText ? '11px' : '24px')};
    &:hover {
      background: ${({ theme }) => theme.colors.white};
    }
    @media ${mediaQueries.lgPlusUp} {
      font-size: 22px;
    }
  }
`;

export const ButtonPrimary = ({
  children,
  largeText,
  largeTextEnglish,
  onClick,
  disabled,
  className,
}) => (
  <ButtonPrimaryStyled
    largeText={largeText}
    largeTextEnglish={largeTextEnglish}
    disabled={disabled}
    className={className}
    onClick={onClick}
  >
    {children}
  </ButtonPrimaryStyled>
);

ButtonPrimary.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  largeText: PropTypes.bool,
  largeTextEnglish: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

ButtonPrimary.defaultProps = {
  largeText: false,
  largeTextEnglish: false,
  disabled: false,
  className: '',
};

export const ButtonSecondary = ({ children, largeText, onClick }) => (
  <ButtonSecondaryStyled
    type="secondary"
    largeText={largeText}
    onClick={onClick}
  >
    {children}
  </ButtonSecondaryStyled>
);

ButtonSecondary.propTypes = {
  children: PropTypes.node.isRequired,
  largeText: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ButtonSecondary.defaultProps = {
  largeText: false,
};
