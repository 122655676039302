import gql from 'graphql-tag';

export const getUploadUrl = gql`
  query getUploadUrl($fileName: String!, $fileType: String!, $folder: String) {
    getUploadUrl(input: {
      fileName: $fileName
      fileType: $fileType
      folder: $folder
    }) {
      uploadUrl
      imageName
    }
  }
`;

export const getUploadUrls = gql`
  query getUploadUrls($inputs: [UploadUrlInput!]) {
    getUploadUrls(inputs:$inputs) {
        uploadUrl
      imageName
    }
  }
`;

export const getContent = gql`
query content ($imageId: Int!) {
  content(imageId: $imageId) {
      imageUrl
      id
    }
  }
`;

export const contentSvg = gql`
  query contentSvg ($imageUrl: String!) {
    contentSvg(imageUrl: $imageUrl)
  }
`;
