export const SELECT_PLAN_STAGE = 1;
export const PAYMENT_DETAILS_STAGE = 2;
export const CONFIRM_STAGE = 3;
export const SUBSCRIPTION_ERROR = 4;
export const SUBSCRIPTION_SUCCESS = 5;
export const STAGE_NAMES = [
  '',
  'SELECT_PLAN_STAGE',
  'PAYMENT_DETAILS_STAGE',
  'CONFIRM_STAGE',
  'SUBSCRIPTION_ERROR',
  'SUBSCRIPTION_SUCCESS',
];
export const ROUTE_BASE = '/ProOfferModal/';
