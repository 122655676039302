import styled from 'styled-components';
import colors from '../../styled/colors';

const Dot = styled.div`
  height: ${({ size }) => (size || '21px')};
  width: ${({ size }) => (size || '21px')};
  background-color: ${({ backgroundColor }) => (backgroundColor || colors.secondary)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  margin-right: 6px;
`;

export default Dot;
