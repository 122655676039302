/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Media from 'react-media';
import { container } from '../../../styled/mixins';

import useGetPiperStepsContent from '../data/useGetPiperStepsContent';
import PiperStep from './PiperStep';
import mediaQueries from '../../../utils/mediaQueries';

const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.white};
  padding-top: 18px;
  padding-bottom: 56px;
  @media ${mediaQueries.mdUp} {
    padding-top: 28px;
  }
  @media ${mediaQueries.lgPlusUp} {
    padding-top: 38px;
    padding-bottom: 92px;
  }
  @media ${mediaQueries.xlPlusUp} {
    padding-top: 60px;
    padding-bottom: 130px;
  }
`;

export const StepContainer = styled.div`
  ${container};
  position: relative;
  height: fit-content;
  max-width: 1440px;
  width: 100%;
  margin: auto;
  @media ${mediaQueries.mdUp} {
    padding-left: 48px;
    padding-right: 48px;
  }
  @media ${mediaQueries.lgPlusUp} {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media ${mediaQueries.xlPlusUp} {
    padding-left: 100px;
    padding-right: 100px;
  }
`;

const CustomLandingTitle = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  @media ${mediaQueries.mdUp} {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 26px;
  }
  @media ${mediaQueries.lgPlusUp} {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 40px;
    padding: 0 29px;
  }
  @media ${mediaQueries.xlPlusUp} {
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 66px;
    max-height: 1039px;
    margin-left: auto;
    margin-right: auto;
  }
`;
export const TextContainer = styled.div`
  width: 287px;
  margin-left: 50px;
`;

export const RightFeature = styled.img`
  width: 100%;
  margin: auto;
`;

function PiperSteps() {
  const piperContent = useGetPiperStepsContent();
  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <Wrapper>
          <StepContainer>
            <CustomLandingTitle>
              <FormattedMessage
                id="landing.piperSteps.superTitle"
                defaultMessage="Social Piper is the perfect all-in-one social media marketing solution, easy to use, thanks to artificial intelligence."
              />
            </CustomLandingTitle>
            {piperContent.map((content) => <PiperStep content={content} />)}
          </StepContainer>
        </Wrapper>
      )}
    </Media>
  );
}

export default PiperSteps;
