/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import useScrollToTop from '../../hooks/useScrollToTop';
// eslint-disable-next-line import/no-cycle
import NavBarComponent from './NavBar/index';
import FooterComponent from './Footer/FooterComponent';
import ROUTES, { FONTS_ROUTES } from '../../utils/constants/routes';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import routesDictionary from '../../utils/analytics/routesDictionary';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import OnlyProModal from '../OnlyProModal/OnlyProModal';
import useBasicRole from '../../hooks/useBasicRole';
// eslint-disable-next-line import/no-cycle
import ProOfferModal from './ProOfferModal/index';
// eslint-disable-next-line import/no-cycle
import ProFeatureModal from './ProFeatureModal';
import ReconectTokenModal from './ReconectTokenModal';
import useGetPromo from '../../views/CreateAccount/hooks/useGetPromo';
import PromoCodeContext from '../../contexts/PromoCodeContext';
import { WebViewContext } from '../../contexts/WebViewContext';
import { SocialContext } from '../../contexts/SocialContext';
import useProRole from '../../hooks/useProRole';
import GlobalTheme from '../../styled/GlobalTheme';
// eslint-disable-next-line import/no-cycle
// import BoostProvider from '../../contexts/BoostContext';
// eslint-disable-next-line import/no-cycle
// import TrialEndsModal from '../trialEnds/TrialEndsModal';

const Header = styled.nav`
  width: 100vw;
  position: fixed;
  z-index: 800;
  background-color: #FFDD00;
  ${GlobalTheme.breakpoints.down('sm')}{
    padding-top: ${(props) => (props.isAppIOS ? (props.isLogin ? '0' : '30px') : '0')};
  }
  ${({ isLogin }) => isLogin && `
    display: none;
  `}
`;

const LayoutWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  align-content: space-between;
  overflow: ${({ overFlowVisible }) => (overFlowVisible ? 'visible' : 'hidden')};
`;

const Footer = styled.footer`
  width: 100vw;
  overflow: hidden;
  position: relative;
  bottom: 0;
  ${({ isHomePage }) => isHomePage && `
    box-shadow: 2px 0px 16px rgba(0, 0, 0, 0.15);
    z-index: 2;
  `};
  ${({ isLogin }) => isLogin && `
    display: none;
  `}
`;

const Content = styled.div`
  width: 100vw;
  overflow: ${({ overFlowVisible }) => (overFlowVisible ? 'visible' : 'hidden')};
  margin-top: ${({ disableHeader }) => ((disableHeader) ? 0 : '64px')};
  ${GlobalTheme.breakpoints.down('sm')}{
    margin-top: ${(props) => (props.isAppIOS ? (props.isLogin ? '0' : '94px') : (props.isApp ? (props.isLogin ? '0' : '64px') : '64px'))};
  }
`;

const Layout = ({
  Header: HeaderProp,
  Footer: FooterProp,
  children,
  title,
  dataLayer,
  accessOnTrialEnds, // Protect from interacting when the Trial ends
  scrollToTop,
  // redirected,
  businessImage,
  endOfTrial,
  showBecomeProModal,
  setShowBecomeProModal,
  showNoLeftPostsModal,
  setShowNoLeftPostsModal,
  disableHeader,
  overFlowVisible,
}) => {
  const location = useLocation();
  const {
    PromoCode,
    setPromoCode,
    handlePromoCodeChange,
    promoLock,
    isPricingLoading,
  } = useGetPromo();
  const intl = useIntl();
  const { dataLayerPush } = useContext(AnalyticsContext);
  const { isApp, isAppIOS } = useContext(WebViewContext);
  const { setShowRequireAttention, showRequireAttention } = useContext(SocialContext);
  const { basicRole, refreshesCountAt } = useBasicRole();
  const [seoTitle, setSeoTitle] = useState('');
  const isPro = useProRole();
  const [isLogin, setIsLogin] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false);

  useDidUpdateEffect(() => {
    const { pathname } = location;
    setIsLogin((pathname === '/login-app') || (pathname === '/create-account-app'));
    setIsHomePage((pathname === '/homepage'));
    const pagePath = pathname;
    const pathTitle = title || routesDictionary[pathname];
    dataLayerPush({
      pagePath,
      pathTitle,
      ...dataLayer,
      event: 'pv',
    });
    const titleTmp = title ? `Social Piper ${title}`
      : intl.formatMessage({
        id: 'k.mainTitle',
        defaultMessage: 'Social Piper - Social Media made easy, for business',
      });
    setSeoTitle(titleTmp);
  }, [title]);
  useScrollToTop(scrollToTop);

  return (
    <PromoCodeContext.Provider value={{
      PromoCode,
      setPromoCode,
      handlePromoCodeChange,
      promoLock,
      isPricingLoading,
    }}
    >
      <Helmet>
        <meta
          name="description"
          content={intl.formatMessage({
            id: 'k.seo.description',
            defaultMessage: 'Create and schedule attractive posts, manage them and boost them! The all-in-one solution for your social media.',
          })}
        />
        <meta
          name="keywords"
          content={intl.formatMessage({
            id: 'k.seo.keywords',
            defaultMessage: '',
          })}
        />
        <title>{seoTitle}</title>
        {(location.pathname === ROUTES.DASHBOARD || FONTS_ROUTES.find(
          (route) => (location.pathname.includes(route)),
        )) && (
          <link href="/fonts/fonts.css" rel="stylesheet" />
        )}

      </Helmet>
      <LayoutWrapper overFlowVisible={overFlowVisible}>
        {/**
        * Protect from interacting when the Trial ends
        * unless the Layout
        * has the accessOnTrialEnds as true (Settings)
      */ }
        {/* {(endOfTrial && !accessOnTrialEnds) && (
          <BoostProvider>
            <TrialEndsModal open />
          </BoostProvider>
        )}
        {(isAppIOS && !isPro && !accessOnTrialEnds && <OnlyProModal open />)}
        {/**
         * Show the pro feature mofal
         */}
        {basicRole && (
          <>
            <ProFeatureModal
              open={showNoLeftPostsModal}
              setOpen={setShowNoLeftPostsModal}
              text={intl.formatMessage({
                id: 'scheduler.noleftposts.text',
                defaultMessage: 'You will no longer be able to schedule any more posts until {date}. Become a Pro and schedule without limits.',
              }, {
                date: intl.formatDate(refreshesCountAt, {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                }),
              })}
              clearBackground
            />
          </>
        )}
        {(showBecomeProModal || (endOfTrial && !accessOnTrialEnds)) && (
          <ProOfferModal
            open={showBecomeProModal || (endOfTrial && !accessOnTrialEnds)}
            setOpen={setShowBecomeProModal}
            toSettings={(endOfTrial && !accessOnTrialEnds)}
          />
        )}
        {(showRequireAttention && !accessOnTrialEnds) && (
          <ReconectTokenModal
            open={(showRequireAttention && !accessOnTrialEnds)}
            setOpen={setShowRequireAttention}
          />
        )}
        <Header isAppIOS={isAppIOS} isLogin={isLogin}>
          {HeaderProp || <NavBarComponent businessImage={businessImage} />}
        </Header>
        <Content
          disableHeader={disableHeader}
          isApp={isApp}
          isAppIOS={isAppIOS}
          isLogin={isLogin}
          onClick={window.closeMenu}
          overFlowVisible={overFlowVisible}
        >
          {children}
        </Content>
        <Footer onClick={window.closeMenu} isHomePage={isHomePage} isLogin={isLogin}>
          {FooterProp || <FooterComponent />}
        </Footer>
      </LayoutWrapper>
    </PromoCodeContext.Provider>
  );
};

Layout.propTypes = {
  Footer: PropTypes.node,
  Header: PropTypes.node,
  accessOnTrialEnds: PropTypes.bool, // Protect from interacting when the Trial ends
  children: PropTypes.node.isRequired,
  scrollToTop: PropTypes.bool,
  title: PropTypes.string,
  dataLayer: PropTypes.instanceOf(Object),
  businessImage: PropTypes.string,
  endOfTrial: PropTypes.bool,
  showBecomeProModal: PropTypes.bool,
  setShowBecomeProModal: PropTypes.func,
  showNoLeftPostsModal: PropTypes.bool,
  setShowNoLeftPostsModal: PropTypes.func,
  disableHeader: PropTypes.bool,
  overFlowVisible: PropTypes.bool,
};

Layout.defaultProps = {
  Header: undefined,
  Footer: undefined,
  title: '',
  accessOnTrialEnds: false, // Protect from interacting when the Trial ends
  scrollToTop: true,
  dataLayer: {},
  businessImage: '',
  endOfTrial: false,
  showBecomeProModal: false,
  setShowBecomeProModal: () => { },
  showNoLeftPostsModal: false,
  setShowNoLeftPostsModal: () => { },
  disableHeader: false,
  overFlowVisible: false,
};

export default Layout;
