const en = {
  'boostManager.ad.title': 'YOUR BOOST',
  'boostManager.ad.status.Deleted': 'Deleted',
  'boostManager.ad.status.deleted': 'Deleted',
  'boostManager.ad.status.Finished': 'Finished',
  'boostManager.ad.status.finished': 'Finished',
  'boostManager.ad.status.Paused': 'Paused',
  'boostManager.ad.status.paused': 'Paused',
  'boostManager.ad.status.Rejected': 'Rejected',
  'boostManager.ad.status.rejected': 'Rejected',
  'boostManager.ad.status.Review': 'Review',
  'boostManager.ad.status.review': 'Review',
  'boostManager.ad.status.Pending': 'Pending',
  'boostManager.ad.status.pending': 'Pending',
  'boostManager.ad.status.Running': 'Running',
  'boostManager.ad.status.running': 'Running',
  'boostManager.ad.status.Scheduled': 'Scheduled',
  'boostManager.ad.status.scheduled': 'Scheduled',
  'boostManager.ad.status.in_process': 'Setting up',
  'boostManager.ad.status.error': 'Error',
  'boostManager.ad.status.active': 'Activa',
  'boostManager.ad.status.failed': 'Failed',
  'boostManager.advice.sufficientBudget': 'You still have enough budget to boost your posts and reach 4,000 new customers this month.',
  'boostManager.budget.commited.defineBudget': 'Please define a monthly budget',
  'boostManager.budget.committed.title': 'Committed budget status',
  'boostManager.budget.define': 'Define budget',
  'boostManager.budget.edit': 'Edit budget',
  'boostManager.budget.editCta': 'Edit Budget',
  'boostManager.budget.monthly.resets': 'Resets on {date}',
  'boostManager.budget.monthly.title': 'Monthly budget',
  'boostManager.budget.overTime.spenUsed.facebook': 'Facebook',
  'boostManager.budget.overTime.spenUsed.linkedin': 'LinkedIn',
  'boostManager.budget.overTime.subtitle': 'How much you have spent over the last 3 months',
  'boostManager.budget.overTime.title': 'History of your budget',
  'boostManager.budget.title': 'Your monthly Boost budget',
  'boostManager.delete.message': 'Do you really want to end this Boost? {lineBreak}{lineBreak} This will stop and close the campaign. If you think you may running it again, choose pausing instead. ',
  'boostManager.delete.title': 'Delete your Boost',
  'boostManager.delete.cta': 'Yes, end this Boost',
  'boostManager.edit.title': 'Edit your Boost duration',
  'boostManager.edit.cta': 'Save Boost duration',
  'boostManager.hero.adjustBudget': 'Adjust Monthly Budget',
  'boostManager.hero.message': '"I’ll help you get the most value for your budget."',
  'boostManager.hero.reach': 'Total reach in {month}: <s>{reach}</s>',
  'boostManager.noBoosts': 'Once you start a Boost, information about when it started, reach, reactions, comments, shares, spend to date, and status will be shown here.',
  'boostManager.pause.message': 'Do you really want to pause this Boost?',
  'boostManager.pause.title': 'Pause your Boost',
  'boostManager.pause.cta': 'Yes, pause this Boost',
  'boostManager.postsTable.active': 'Active Boosts',
  'boostManager.postsTable.deleted': 'Deleted Boosts',
  'boostManager.postsTable.lastMonth': 'Last month',
  'boostManager.postsTable.paused': 'Paused Boosts',
  'boostManager.postsTable.thisMonth': 'This month',
  'boostManager.postsTable.title': 'Boosted Posts',
  'boostManager.run.message': 'Do you really want to run again this Boost?',
  'boostManager.run.title': 'Run your Boost',
  'boostManager.runningUntil': '<b>Running</b> until {date}',
  'boostManager.spendToData': 'Spend to date',
  'boostManager.editDuration': 'Edit duration',
  'boostManager.budget.tooltip.text': 'You’ve already reach your monthly budget. Edit it!',
};

export default en;
