export const ANDROID_ELEMENT_DISPACHER_ID = 'android_div_dispatcher';

const eventDispatcher = (JSONEvent) => {
  const ele = document.getElementById(ANDROID_ELEMENT_DISPACHER_ID);
  const parsedObj = JSON.parse(JSONEvent);
  const ev = new Event(parsedObj.eventName);
  ele.dispatchEvent(ev);
};

export const initAndroidEventDispacher = async () => {
  const body = document.getElementsByTagName('body')[0];
  const ele = document.createElement('div');
  ele.id = ANDROID_ELEMENT_DISPACHER_ID;
  ele.style.display = 'none';
  body.appendChild(ele);
  window.androidEventDispatcher = eventDispatcher;
};

export const getElementDispatcher = () => {
  const ele = document.getElementById(ANDROID_ELEMENT_DISPACHER_ID);
  return ele;
};
