import { css } from 'styled-components';
import { ChevronDownGrayIcon } from '../assets/icons/iconRepository';
import GlobalTheme from './GlobalTheme';
import colors from './colors';

export const container = () => css`
  width: 100%;
  max-width: 1424px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  position: static;
  padding-left: 100px;
  padding-right: 100px;
  padding-left: max(100px, env(safe-area-inset-left));
  padding-right: max(100px, env(safe-area-inset-right));
  ${GlobalTheme.breakpoints.down('lg')}{
    padding-right: 32px;
    padding-left: 32px;
    padding-left: max(32px, env(safe-area-inset-left));
    padding-right: max(32px, env(safe-area-inset-right));
  }
  ${GlobalTheme.breakpoints.down('md')}{
    padding-right: 24px;
    padding-left: 24px;
    padding-left: max(24px, env(safe-area-inset-left));
    padding-right: max(24px, env(safe-area-inset-right));
  }
`;

export const heroContainer = () => css`
  ${container}
  padding-top: ${(props) => props.theme.space * 4}px;
`;

export const flex = () => css`
  ${container}
  display: flex;
  justify-content: ${(props) => (props.justifyContent || 'space-between')};
  align-items: ${(props) => (props.alignItems || 'flex-start')};
  flex-wrap: ${(props) => (props.flexWrap || 'no-wrap')};
  flex-direction: ${(props) => (props.flexDirection || 'row')};
`;

export const spreadShadow1 = () => css`
  /* Spread shadow - 1 */
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
`;

export const lowSpreadShadow = () => css`
  /* low spread for small items */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

export const materialShadows = () => css`
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  margin:0;
  padding:0;
`;
export const materialShadowsConversation = () => css`
  box-shadow: 0px 10px 16px -5px rgba(0, 0, 0, 0.15);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  margin:0;
  padding:0;
`;
export const card = () => css`
  background: white;
  border-radius: ${(props) => (props.borderRadius || '4px')};
  padding: ${(props) => (props.padding || '24px')};
  ${spreadShadow1}
`;

export const heading = () => css`
  font-family: ${(props) => props.theme.font.font1};
  font-weight: 600;
  color: ${(props) => props.color || colors.dark};
  text-align: ${(props) => props.textAlign || 'left'};
  margin: 0;
`;

export const heroTitle = () => css`
  ${heading}
  font-size: 48px;
  margin: 0;
  text-align: left;
  ${GlobalTheme.breakpoints.down('lg')}{
    font-size: 40px;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 32px;
    margin: 0;
  }
`;

export const h1 = () => css`
  ${heading}
  font-size: 48px;
  ${GlobalTheme.breakpoints.down('md')}{
    font-size: 40px;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    font-size: 36px;
  }
`;

export const heading2 = () => css`
  ${heading}
  font-size: 32px;
  ${GlobalTheme.breakpoints.down('md')}{
    font-size: 30px;
  }
  ${GlobalTheme.breakpoints.down('md')}{
    font-size: 28px;
  }
`;

export const heading3 = () => css`
  ${heading}
  font-size: 24px;
  ${GlobalTheme.breakpoints.down('md')}{
    font-size: 22px;
  }
  ${GlobalTheme.breakpoints.down('md')}{
    font-size: 20px;
  }
`;

export const paragraph = () => css`
  ${(props) => css`
    font-family: ${props.theme.font.font1};
    font-weight: ${props.fontWeight || '400'};
    font-size: ${props.fontSize || '16px'};
    text-align: ${props.textAlign || 'left'};
    color: ${props.color || colors.black};
    margin: ${props.margin || '0'};
    ${props.fullWidth && `
      flex: 0 0 100%;
      width: 100%;
    `}
  `}
`;

export const label = () => css`
  font-family: ${(props) => props.theme.font.font1};
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => (props.fontSize || '16px')};
  margin: 0;
`;

export const flexBox = () => css`
  display: flex;
  justify-content: ${(props) => (props.justifyContent || 'space-between')};
  align-items: ${(props) => (props.alignItems || 'flex-start')};
  flex-wrap: ${(props) => (props.flexWrap || 'no-wrap')};
  flex-direction: ${(props) => (props.flexDirection || 'row')};
`;

export const input = () => css`
  height: 40px;
  padding: 7px 8px;
  font-size: 14px;
  color: #333;
  color:  ${(props) => props.theme.colors.text03};
  border: 1px solid ${(props) => props.theme.colors.gray02};
  border-radius: 4px;
  font-family: ${(props) => props.theme.font.font1};
`;

export const select = () => css`
  ${input}
  appearance: none;
  background: ${colors.white} url(${ChevronDownGrayIcon}) no-repeat 0 0;
  background-size: 20px;
  background-position: calc(100% - ${(props) => props.theme.space}px) center;
  padding-right: 35px;
  ${(props) => props.fullWidth && `
    width:100%;
  `}
  ${(props) => props.leftIcon && `
    background-position: 8px center;
    padding-right: ${props.theme.space}px;
    padding-left: 40px;
  `}
`;

export const datePicker = () => css`
  && input {
    text-transform: capitalize;
  }
  && {
    .MuiInput-root{
      &:before, &:after{
        display: none;
      }
      input {
        text-transform: capitalize;
        ${select}
        box-sizing: border-box;
        color: ${colors.black};
      }
    }
  }
`;

export const borderTop = (color) => (props) => css`
  border-top:${props.theme.space}px solid ${props.theme.colors.primaryLighter};
  ${typeof (color) === 'string' && `border-color: ${color}`};
`;

export const fullHeight = (height) => () => css`
  height:calc(100vh - ${height || 100}px);
`;

export const imageHover = () => css`
  transition: .5s ease;
  opacity: 0;
  &:hover {
    background: rgba(255, 255, 255, 0.4);
    opacity: 1;
  }
`;

export const buttonText = () => css`
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.font.font1};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: none;
  cursor: pointer;
  line-height: 100%;
  &:focus{
    outline: none;
  }
  &:hover {
    color: ${(props) => props.theme.colors.primaryDark};
  }
`;

export const removeButtonStyles = () => css`
  font-family: ${(props) => props.theme.font.font1};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: none;
  cursor: pointer;
  &:focus{
    outline: none;
  }
  &:hover {
    color: ${(props) => props.theme.colors.primaryDark};
  }
`;

export const titleSections = () => css`
  ${flexBox};
  font-weight: 600;
  font-size: 16px;
  background: ${({ color }) => (color || colors.primaryLighter)};
  padding: 0 ${(props) => props.theme.space * 6}px;
  height: 40px;
  color: ${colors.white};
  width: inherit;
  ${GlobalTheme.breakpoints.down('md')}{
    height: 31px;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    height: 40px;
  }
`;

export const elevation = () => css`
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

export const small = () => css`
  color: ${(props) => props.theme.colors.gray90};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

export const noMarginBlock = () => css`
  margin-block-start: unset;
  margin-block-end: unset;
`;

export const textLink = () => css`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.primary};
`;

export const redCircle = () => css`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  background: ${({ theme }) => (theme.colors.red)};
  border-radius: 50%;
  color: ${({ theme }) => (theme.colors.white)};
  font-size: 11.2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const icon = () => css`
  width:${(props) => props.size || '24px'};
  height:${(props) => props.size || '24px'};
  background-size:${(props) => props.size || '24px'};
  background-color: ${(props) => props.color || props.theme.colors.black};
  margin:auto;
  mask: url(${(props) => props.icon}) no-repeat center;
  mask-size:${(props) => props.size || '24px'};
  -webkit-mask: url(${(props) => props.icon}) no-repeat center;
  -webkit-mask-size:${(props) => props.size || '24px'};
`;
