const routesDictionary = {
  '/': 'Landing Page',
  '/homepage': 'Landing Page',
  '/join-pricing': 'Pricing',
  // Welcome
  // Onboarding Step 1
  // Onboarding Step 2
  // Onboarding Step 3
  '/postflow': 'Post Flow',
  // Boost Modal
  '/dashboard': 'Dashboard',
  '/scheduler': 'Scheduler (calendar)',
  '/media-library': 'Media Library',
  '/community': 'Community',
  '/boost-manager': 'Boost Manager',
  '/settings': 'Settings',
  '/login': 'Login',
  '/create-account': 'Create Account',
};

export default routesDictionary;
