import React, { useEffect, useState, useContext } from 'react';
import { Dialog as MuiDialog } from '@material-ui/core';
import propTypes from 'prop-types';
import styled from 'styled-components';
import GlobalTheme from '../../styled/GlobalTheme';
import { CloseOutlineIcon } from '../../assets/icons/iconRepository';
import { Icon } from '../globals/ColoredIcon';
import CompareTable from './CompareTable';

const StyledModal = styled(MuiDialog)`
&& {
  .MuiPaper-root{
    min-width: 90%;
  }
  ${GlobalTheme.breakpoints.down('sm')} {
    .MuiPaper-root{
      min-width: 100%;
    }
  }}`;

const Header = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;

`;

const CloseIcon = styled(Icon)`
margin-right: 15px;
margin-top: 10px;
cursor: pointer;
&& {
  color: white;
}
`;

const Container = styled.div`
  width:94%;
  padding: 0 0 10px;
  margin: auto;
  ${GlobalTheme.breakpoints.up('md')} {
    width:100%;
    padding: 0;
  }
  ${GlobalTheme.breakpoints.up('lg')} {
    width:94%;
  }
`;
const ProOfferModal = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledModal
      open={open}
      fullScreen={window.innerWidth <= GlobalTheme.breakpoints.values.sm}
    >
      <Header>
        <CloseIcon src={CloseOutlineIcon} color={GlobalTheme.colors.black} size="20px" onClick={() => handleClose()} />
      </Header>
      <Container>
        <CompareTable
          show
        />
      </Container>
    </StyledModal>
  );
};

ProOfferModal.propTypes = {
  open: propTypes.bool.isRequired,
  setOpen: propTypes.func.isRequired,
};

export default ProOfferModal;
