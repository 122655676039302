import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from '../../styled/theme';
import GlobalTheme from '../../styled/GlobalTheme';

const NavBarButton = styled(Button)`
  && {
    font-weight: 600;
    font-size: 16px;
    /* identical to box height */
    text-transform: uppercase;
    margin: 0 ${(props) => props.theme.space}px;
    color: ${colors.black};
    text-decoration: none;
    display:inline-flex;
    ${(props) => props.hideDown && `
      ${GlobalTheme.breakpoints.down(props.hideDown)} {
        display: none;
      }
    `}
  }
`;

export default NavBarButton;
