const es = {
  'adAccount.missingFundingSource': 'Para crear una campaña necesitarás <a>agregar un método de pago en tu cuenta publicitaria de Facebook</a>',
  'socialProfiles.connectionTitle': 'Conecta la cuenta de {socialNetwork} de {companyName}',
  'socialProfiles.connectionTitle.noCompanyName': 'Conecta la cuenta de {socialNetwork} de tu negocio.',
  'socialProfiles.createOne': 'Crea una ahora',
  'socialProfiles.donthaveOne': '¿Aún no tienes una cuenta?',
  'socialProfiles.facebook.adAccounts.active.note': 'Puedes cambiarla después, siempre y cuando tenga la misma divisa que la cuenta publicitaria que tenías. Si necesitas asistencia, por favor contacta a <a>support@socialpiper.com</a>',
  'socialProfiles.facebook.adAccounts.adAccountLabel': 'Cuenta publicitaria',
  'socialProfiles.facebook.adAccounts.change': 'Cambiar a otra cuenta publicitaria',
  'socialProfiles.facebook.adAccounts.note': 'Sólamente puedes cambiar a otra cuenta publicitaria en la misma divisa. Si necesitas asistencia, por favor contacta a <a>support@socialpiper.com</a>',
  'socialProfiles.facebook.adAccounts.select': 'Por favor selecciona la cuenta publicitaria que usarás para crear campañas publicitarias en Facebook a través de Social Piper',
  'socialProfiles.facebook.help': '¿Por qué debo tener una página de negocio?',
  'socialProfiles.facebook.videoModal.adAccount.title': 'Crea tu cuenta publicitaria',
  'socialProfiles.facebook.videoModal.adAccount.subtitle': 'Lee y sigue los pasos:',
  'socialProfiles.hint.linedIn.comingSoon': 'Próximamente podrás conectar tu cuenta de LinkedIn',
  'socialProfiles.hint.oneAccount': 'Por el momento sólo puedes conectar 1 cuenta',
  'socialProfiles.instagram.help': '¿Qué hay de diferencia con una cuenta personal de Instagram?',
  'socialProfiles.instagram.hint.noFacebook': 'Estará disponible una vez que conectes una página de Facebook. Por el momento sólo puedes conectar 1 cuenta.',
  'socialProfiles.instagram.hint.facebookConnected': 'Sólo puedes conectar 1 cuenta de negocio de Instagram. ¿No sabes si tu cuenta es de negocio? <TipLink>Revisa estos pasos.</TipLink>',
  'socialProfiles.title': 'Administra tus perfiles sociales',
  'socialProfiles.override.title': 'Para usar esta opción, debes conectar al menos una cuenta',
  'socialProfiles.twitter.help': '¿Por qué es importante tener una cuenta en Twitter?',
  'socialProfiles.linkedIn.modal.title': 'Selecciona la <r>cuenta de LinkedIn</r> que quieres usar:',
  'socialProfiles.linkedIn.modal.profeProfile': 'Perfiles de LinkedIn Personales:',
  'socialProfiles.linkedIn.modal.business': 'Perfiles de LinkedIn para Negocios:',
  'socialProfiles.linkedIn.modal.accountText': 'Con este tipo de cuenta, podrás:',
  'socialProfiles.linkedIn.modal.titleOnePage': 'Toma nota!',
  'socialProfiles.linkedIn.modal.option1': 'Crear publicaciones',
  'socialProfiles.linkedIn.modal.option2': 'Consultar métricas de tus publicaciones',
  'socialProfiles.linkedIn.modal.option3': 'Administrar tu comunidad',
  'socialProfiles.linkedIn.modal.option4': 'Obtener buenas recomendaciones sobre qué día y qué hora hacer tus publicaciones.',
  'socialProfiles.linkedIn.modal.option5': 'Contestar fácilmente los comentarios en tus publicaciones.',
  'socialProfiles.multiAccount.modal.title': 'Selecciona la <colored>{page}</colored> que quieres usar:',
  'socialProfiles.multiAccount.modal.facebook': 'página de Facebook',
  'socialProfiles.multiAccount.modal.instagram': 'cuenta de negocio',
  'socialProfiles.multiAccount.modal.': 'Business Profile',
  'socialProfiles.linkedIn.adAccounts.select': 'Por favor selecciona la cuenta publicitaria que usarás para crear campañas publicitarias en LinkedIn a través de Social Piper',
  'socialProfiles.linkedIn.adAccount.missingFundingSource': 'Para crear una campaña necesitarás <a>agregar un método de pago en tu cuenta publicitaria de LinkedIn</a>',
  'socialProfiles.linkedIn.createAdAccount.Modal.Tip': 'Vamos a crear tu cuenta cuenta publicitaria en LinkedIn',
  'socialProfiles.linkedIn.createAdAccount.name': 'Nombre para tu cuenta publicitaria en LinkedIn:',
  'socialProfiles.linkedIn.createAdAccount.currency': 'Elije el tipo de moneda para tu cuenta publicitaria en LinkedIn.',
  'socialProfiles.linkedIn.createAdAccount.Modal.helpCurrency': 'Para una mejor gestión, utiliza el mismo tipo de moneda para todas tus cuentas publicitarias',
  'socialProfiles.linkedIn.videoModal.payment.title': 'Ahora, agrega tu método de pago.',
  'socialProfiles.linkedIn.videoModal.payment.subtitle': 'Lee y sigue los pasos:',
  'socialProfiles.linkedIn.videoModal.payment.advice': 'Recuerda regresar después de agregar tu método de pago para tu cuenta publicitaria en LinedIn',
  'socialProfiles.linkedIn.videoModal.payment.step1': 'Inicia sesión en tu <link>Administrador de campañas.</link>',
  'socialProfiles.linkedIn.videoModal.payment.step2': 'Haz clic en el nombre de tu cuenta.',
  'socialProfiles.linkedIn.videoModal.payment.step3': 'Haz clic en el nombre de tu cuenta, en la esquina superior derecha de la página y selecciona <h>Centro de facturación</h> en el menú desplegable.',
  'socialProfiles.linkedIn.videoModal.payment.mobile.step3': 'Haz clic en el nombre de tu cuenta, en la parte superior de la página y selecciona <h>Centro de facturación</h> en el menú desplegable.',
  'socialProfiles.linkedIn.videoModal.payment.step4': 'Haz clic en <h>Añadir tarjeta de crédito.</h> ',
  'socialProfiles.linkedIn.videoModal.payment.step5': 'Recuerda <h>regresar</h> después de agregar tu método de pago para tu cuenta publicitaria en LinkedIn.',
  'socialProfiles.facebook.createAdAccount.step1': 'Ve a la pagina de tu negocio en <link>Facebook.</link> En el menú de la izquierda da clic en Herramientas de publicación, en la sección de Business Suite. ',
  'socialProfiles.facebook.createAdAccount.step1.mobile': 'Ve a la página de tu negocio en <link>Facebook</link> en tu computadora de escritorio. En el menú de la izquierda da clic en Herramientas de publicación, en la sección de Business Suite. ',
  'socialProfiles.facebook.createAdAccount.step2': 'En el menú del lado izqueirdo da clic en <h>Configuración.</h>',
  'socialProfiles.facebook.createAdAccount.step3': 'En el submenú, da clic en <h>Activos comerciales.</h>',
  'socialProfiles.facebook.createAdAccount.step4': 'En la esquina superior derecha, da clic en <h>Agregar activos.</h>',
  'socialProfiles.facebook.createAdAccount.step5': 'En la ventana que se despliega, da clic en <h>Publicidad.</h>',
  'socialProfiles.facebook.createAdAccount.step6': 'En el menú que se despliega, da clic en <h>Cuenta Publicitaria.</h>',
  'socialProfiles.facebook.createAdAccount.step7': 'En el siguiente menú de opciones, da clic en <h>Crear cuenta publicitaria.</h>',
  'socialProfiles.facebook.createAdAccount.step8': 'Llena los campos para <h>crear tu cuenta publicitaria.</h> Escribe el nombre con la que la identificarás, la zona horaria donde estás y define el tipo de moneda que utilizarás.',
  'socialProfiles.facebook.createAdAccount.step9': 'Recuerda <h>regresar</h> después de crear tu cuenta publicitaria en Facebook.',
};

export default es;
