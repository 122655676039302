import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import AnalyticsLink from '../../buttons/AnalyticsLink';
import AnalyticsAnchor from '../../buttons/AnalyticsAnchor';
import useWidth from '../../../hooks/useWidth';

const Menu = styled.div`
  background-color: rgba(0,0,0,0);
  margin-left: ${(props) => ((props.width / 2) * -1) + 8}px;
  display: ${(props) => (!props.visible && 'none')};
  position: fixed;
`;

const linkArea = (props) => css`
  padding-top: ${props.theme.space * 1}px;
  background-color: rgba(256,0,0,0);
  display: block;
  z-index: 100;
`;

const CustomAnalyticsLink = styled(AnalyticsLink)`
  ${(props) => linkArea(props)}
`;

const CustomAnalyticsAnchor = styled(AnalyticsAnchor)`
  ${(props) => linkArea(props)}
`;

const MenuBody = styled.div`
  background: ${(props) => props.theme.colors.grayE9};
  padding: 5px 5px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
`;

const MenuBox = styled.li`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent ${(props) => props.theme.colors.grayE9} transparent;
  margin-left: 16px;
  z-index: 2;
`;

const renderMenuBox = (title, bodyRef) => (
  <MenuBox>
    <Triangle />
    <MenuBody ref={bodyRef}>
      {title}
    </MenuBody>
  </MenuBox>
);

export default function HoverMenuElement({
  to,
  href,
  title,
  gaData,
  isIconHovering,
}) {
  const [buttonHover, setButtonHover] = useState(false);
  const [bodyRef, width] = useWidth([buttonHover, isIconHovering]);
  return (
    <Menu
      keepMounted
      width={width}
      onMouseLeave={() => { setButtonHover(false); }}
      onMouseEnter={() => { setButtonHover(true); }}
      visible={buttonHover || isIconHovering}
    >
      {to ? (
        <CustomAnalyticsLink
          to={to}
          gaData={gaData}
        >
          {renderMenuBox(title, bodyRef)}
        </CustomAnalyticsLink>
      ) : (
        <CustomAnalyticsAnchor
          href={href}
          gaData={gaData}
          target="_blank"
        >
          {renderMenuBox(title, bodyRef)}
        </CustomAnalyticsAnchor>
      )}
    </Menu>
  );
}

HoverMenuElement.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  gaData: PropTypes.instanceOf(Object),
  isIconHovering: PropTypes.bool,
};

HoverMenuElement.defaultProps = {
  to: '',
  href: '',
  title: '',
  gaData: null,
  isIconHovering: false,
};
