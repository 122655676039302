import { publicAppSync } from '../auth';
import {
  createBusinessLead,
} from '../../graphql/mutations';
import { ENV, ENVIRONMENTS } from '../../utils/constants/globals';

export default class BusinessLeadProvider {
  static async createBusinessLead(contactInfo) {
    let result = {
      success: false,
      message: '',
      data: null,
    };
    if (ENV === ENVIRONMENTS.local) {
      result.success = true;
      result.data = {
        success: true,
        info: 'Business lead created successfully',
        errorMessage: '',
      };
      return result;
    }
    try {
      const response = await publicAppSync.client.mutate({
        mutation: createBusinessLead,
        variables: {
          input: {
            name: contactInfo.name,
            email: contactInfo.email,
            phone: contactInfo.phone,
          },
        },
      });

      result = {
        success: true,
        message: 'success',
        data: response.data.createBusinessLead,
      };
    } catch (err) {
      result.message = err.message;
    }
    return result;
  }
}
