import { useContext } from 'react';
import useCanAccess from './useCanAccess';
import roles from '../utils/constants/roles';
import RoleContext from '../contexts/RolesContext';

const useBasicRole = () => {
  const { endDate } = useContext(RoleContext);
  const basicRole = useCanAccess([roles.BASIC]);
  return {
    basicRole,
    refreshesCountAt: endDate,
  };
};

export default useBasicRole;
