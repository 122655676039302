const en = {
  'adAccounts.modal.title': 'Select your Ad Account',
  'boost.monthlyBudget.info': 'It is the amount of money you are willing to spend on your social media networks boosts',
  'boost.monthlyBudget.inputLabel': 'Set your monthly budget',
  'boostDetails.active.description': 'You added a Boost to this post.',
  'boostDetails.active.impressions.title': 'Estimated impressions of your Post & Boost',
  'boostDetails.active.title': 'Boost details',
  'boostDetails.boostIt': 'Boost It!',
  'boostDetails.charges.chargedByFacebook': 'Charged by Facebook to your Ad Account',
  'boostDetails.charges.chargedByLinkedin': 'Charged by LinkedIn to your Ad Account',
  'boostDetails.charges.note': 'Based on {boostPerDay} per day. If you pause, end, or extend a boosted campaign, the overall total could change.',
  'boostDetails.charges.socialPiperFee': 'Social Piper Service Fee ({fee}%)',
  'boostDetails.charges.socialPiperFee2': '0% service fee, limited time offer',
  'boostDetails.charges.title': 'Estimated Charges',
  'boostDetails.description': 'Boosts turn your posts into targeted ads.',
  'boostDetails.impressions.noBoost': 'Potential reach with no boost',
  'boostDetails.impressions.sliderLabel': 'If you spend',
  'boostDetails.impressions.title': "Here's how a Boost can affect your customer impressions",
  'boostDetails.impressions.withBoost': 'Potential reach with a boost',
  'boostDetails.piperTip': '<r>{recommended}</r> is the optimal amount to spend to gain new relevant customer impressions.{lineBreak}Or you can customize it to fit your needs.',
  'boostDetails.piperTip.noAdAccount': 'Connect your Facebook business page and I will give you the recommendation about how much budget you could set for your boost',
  'boostDetails.potentialBar.boosted': 'Estimated with a Boost',
  'boostDetails.potentialBar.now': 'Your estimated reach now',
  'boostDetails.setupBoost': 'SETUP YOUR BOOST',
  'boostDetails.title': 'Boost it (optional)',
  'boostDetails.warnings.facebookAdAccount.missingAdAccount': 'Please select the Facebook Ad Account you want to use to Boost this content',
  'boostDetails.warnings.facebookAdAccount.missingPayment': 'Please add a payment method to your Social Piper account. We will charge for the Social Piper Service Fee (15%) at the end of the month.',
  'boostDetails.warnings.facebookAdAccount.missingPaymentMethod': 'Please connect a payment method to your Facebook Ad Account: <s>{adAccount}</s>',
  'boostingGoals.tips.all': 'This will display content from all the strategies.',
  'boostingGoals.tips.awareness': 'This goal means that your content will be shown to the greater amount of people on your selected potential clients.',
  'boostingGoals.tips.conversion': 'Engaging means not only reach more people but encourage them to like your content, comment it and share it.',
  'boostingGoals.tips.directions': 'Do you have a physical store? This goal will be delivered to potential clients who are near your store, and tell them how to get there.',
  'boostingGoals.tips.message': 'Want to talk directly to your potential clients? By selecting this goal, a  Send Message button will be added to your post, so they easily send you a message as soon as they see your post.',
  'boostingGoals.tips.messenger': 'Do you want to go directly to the inboxes of your audience? Select this goal to deliver the content you are creating via Facebook Messenger instead of your Facebook page. See below how it would look like',
  'boostingGoals.tips.mixAndMatch': 'This goal is useful to reach more people so the recognition of your business increases too. More people will know about you!',
  'boostingGoals.tips.pageLikes': 'Encourage more people to visit and like your Facebook Business page.',
  'boostingGoals.tips.url': 'If you have a website, this goal will help to drive more visits to it.',
  'boostingGoals.title.awareness': 'Reach more people',
  'boostingGoals.title.conversion': 'Engage with more people',
  'boostingGoals.title.directions': 'Invite people to visit your physical store',
  'boostingGoals.title.message': 'Encourage people to send you a message',
  'boostingGoals.title.messenger': 'Send your post through messenger',
  'boostingGoals.title.mixAndMatch': 'Mix & Match',
  'boostingGoals.title.pageLikes': 'Motivate people to like your Facebook page',
  'boostingGoals.title.url': 'Promote your website',
  'boostOptions.cancelBoost': 'Cancel Boost',
  'boostOptions.custom.estReach': 'Est. reach {minReach} to {maxReach}',
  'boostOptions.custom.invalidBudget': 'The minimum budget required is {minimum}',
  'boostOptions.placement.note': 'All boost are delivered via your Facebook Ad account',
  'boostOptions.resetOptions': 'Reset options',
  'boostOptions.save': 'Save Boost details',
  'boostOptions.showIn': 'Show In',
  'boostOptions.spend.estReach': '{value} Est. reach: {minReach} to {maxReach}',
  'boostOptions.spend.mobile.estReach': '{value}',
  'boostOptions.spend.mobile.recommended': '<b>{value} (recommended)</b>',
  'boostOptions.spend.recommended': '<span><b>{value} (recommended)</b> Est. reach: {minReach} to {maxReach}</span>',
  'boostOptions.startOn': 'Start on',
  'boostOptions.startedOn': 'Started on',
  'boostOptions.fromStartDate': 'from the start date',
  'boostOptions.title': 'Boost options',
  // New boosting
  'boost.specs.title': 'Your Boost',
  'boost.specs.tip': 'This is my <y>best recommendation</y> to Boost your post. If you like can change it to better suit your needs',
  'boost.specs.tip.custom': 'Great! With your settings, this is your potencial reach! Go for it!',
  'boost.specs.details.budget.custom.invalidBudget': 'According to the duration of your campaign,{space}{socialnetwork} requires a minimum budget {budget}{asterisk} per day; modify your budget or campaign duration.',
  'boost.specs.details.budget.updated': 'The campaign budget has been adjusted to meet the minimum required by {sn} according to the duration of your campaign.',
  'boost.specs.details.potential.label': 'Potential reach with this Boost',
  'boost.specs.details.potential.tooltip.label': 'Customers',
  'boost.specs.details.potential.tooltip.description': 'With the amount to spend, the audience, and the boost duration, this is the estimate of people who could see your campaign.',
  'boost.specs.details.strategy.tooltip.label': 'Your business name',
  'boost.specs.details.strategy.tooltip.description': 'Tell us the name by which your customers know your business.{linebreak}{linebreak}Later you can easily change this information in the Settings section.{linebreak}{linebreak}For example:',
  'boost.specs.details.options.showIn': 'Show In',
  'boost.specs.details.showIn.tooltip.label': 'Show In',
  'boost.specs.details.showIn.tooltip.description': 'They are the social networks where your campaign will be displayed.',
  'boost.specs.details.options.starts': 'Starts',
  'boost.specs.details.starts.tooltip.label': 'Start',
  'boost.specs.details.starts.tooltip.description': 'It is the estimated date for your campaign to start, as long as it is after your post\'s date; later, you can edit it in the "Boost manager" section.',
  'boost.specs.details.duration.tooltip.label': 'Duration',
  'boost.specs.details.duration.tooltip.description': 'It is the duration for your campaign; later, you can edit it in the "Boost manager" section.',
  'boost.specs.validations.facebookAdAccount.missingAdAccount': 'Select the Facebook Ad Account you want to use',
  'boost.specs.validations.facebookAdAccount.missingPayment': 'Add a payment method to your Facebook Ad account:{linebreak}{s}',
  'boost.specs.validations.facebookAdAccount.validated': 'Your Ad Account:{linebreak}{s}',
  'boost.specs.validations.payment.missing': 'Add a payment method to your Social Piper account',
  'boost.specs.validations.payment.validated': 'You have a payment method on your Social Piper account',
  'boost.specs.validations.note': 'All boosts are delivered via your Facebook Ad account',
  'boost.specs.becomePro': 'Unlock the potential to reach more people!{br}Follow Piper’s recommendations and become a master on social media.',
  'boost.modal.boostIt': 'Boost It!',
  'boost.specs.details.budget.exceeded': 'This amount exceeds your monthly budget! Continue if you want or <editLink>edit your budget.</editLink>',
  // boosting v3
  'boost.info.tip': 'Boosting recommendation for you:',
  'boost.info.potencialReach': 'Potential customer reach:',
  'boost.info.investment': 'Investment',
  'boost.info.recomm.split': 'Boosting recommendation split',
  'boostDetails.charges.sn.note': 'If you pause, end, or extend a boosted campaign, the overall total could change.',
  'boostDetails.help.continue.message': 'To continue, you need to Create your Ad Account for Fb and Li.',
  'boostDetails.settings': 'Customize your settings for:',
  'boost.form.reach.title': 'Potential reach',
  'boost.selector.help.defineAud.label': 'Your audience is missing',
  'boost.selector.help.defineAud.description': 'You must define an audience in order to make a boost',
  'boost.selector.help.adAccount.label': 'Ad Account',
  'boost.selector.help.adAccount.description': "Your ad account let's you promote your posts by setting your potential clients and billing information in the Business Manager at {value}.",
  'boost.selector.help.paymentMethod.label': 'Payment Method',
  'boost.selector.help.paymentMethod.description': 'This payment method is specifically for your ad account on {value}. You will be charged by {value} through it and does not relate to your Social Piper fee or membership',
  'boost.info.button.message.createAdAccount': 'To continue, you need to Create your Ad Account for {sn}.',
  'boost.info.button.message.paymentMethod': 'To continue, you need to add a payment method for {sn}.',
  'boost.info.button.message.paymentMethodSP': 'To continue, you need to <sn>add a payment method to your Social Piper account.</sn>',
  'boost.info.button.message.chooseAdAccount': 'To continue, you need to choose an Ad Account for {sn}.',
  'boost.info.button.message.chooseSn': 'To continue, select an Ad campaign ',
  'boostDetails.charges.rate.tootip': '*Estimated exchange rate as of today',
  'boostOptions.tooltip.sn.account.exchange': 'Your account for {sn} ad ({adAccountName}) uses a different currency than the one you have configured in Social Piper.{lineBreak}The commission will be charged when the campaign is running, in the currency that you have configured in Social Piper and at the exchange rate of that day.',
  'boostOptions.tooltip.both.sn.account.exchange': 'Your accounts for Facebook and LinkedIn ad ({adAccountName}, {adAccountNameTwo}) use a different currency than the one you have configured in Social Piper.{lineBreak}The commission will be charged when the campaign is running, in the currency that you have configured in Social Piper and at the exchange rate of that day.',
  'boost.cancel.boosting.link': 'No thanks. Cancel Boosting.',
  'boost.user.display.currency': 'The currency displayed is in {currency}',
  'boost.user.display.currency.tooltip': 'You can change your currency in Personal & Preferences in Settings, for better management in Social Piper. {linebreak}{lineBreak}This will not affect the currency in your ad accounts.',
  'boost.linkedin.selector.tooltip.budget.help': 'The recommendation for investment in campaigns on LinkedIn seems more expensive compared to Facebook, due to the audience on LinkedIn is more specialized. In other words, your clients on LinkedIn are more specific in terms of their interests and topics.',
  'boost.info.modal.facebook.policy.title': 'Before you proceed',
  'boost.info.modal.facebook.policy.subtitle': '<bold>Meta</bold> (Facebook) requires you to agree to their non-discrimination policy before you can start boosting.{linebreak}<italic>You will only have to do this once.</italic>',
  'boost.info.modal.facebook.policy.text.link': 'Go to Meta to agree',
  'boost.info.modal.facebook.policy.ready': 'I\'m ready! I have already agreed and I would like to continue with my boost.',
};

export default en;
