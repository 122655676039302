/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { buttonText } from '../../styled/mixins';
import ColoredIcon from '../globals/ColoredIcon';
import { colors } from '../../styled/theme';

const Button = styled.button`
  ${buttonText};
  ${(props) => props.fontSize && `font-size: ${props.fontSize}`};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  color: ${(props) => props.color || props.theme.colors.primary};
  & .coloredIcon {
    background-color: ${(props) => props.color || props.theme.colors.primary};
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  }
  &:hover {
    color: ${(props) => props.hoverColor || props.theme.colors.primaryDark};
    & .coloredIcon {
      background-color: ${(props) => props.hoverColor || props.theme.colors.primaryDark};
    }
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    ${({ danger }) => danger && css`
      .MuiCircularProgress-circle{
        stroke: ${(props) => props.theme.colors.red};
      }
    `}
  }
`;

export default function ButtonText({
  children,
  to,
  onClick,
  className,
  gaData,
  navLink,
  danger,
  isLoading,
  icon,
  fontSize,
  color,
  hoverColor,
  disabled,
  ...props
}) {
  return (
    <Button
      color={color}
      hoverColor={hoverColor}
      className={className}
      onClick={onClick}
      fontSize={fontSize}
      disabled={disabled}
      {...props}
    >
      {children}
      {isLoading && <StyledCircularProgress danger={danger} size={20} />}
      {icon && (
      <ColoredIcon
        src={icon}
        hoverColor={hoverColor}
        color={color || colors.primary}
      />
      )}
    </Button>
  );
}

ButtonText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  danger: PropTypes.bool,
  gaData: PropTypes.instanceOf(Object),
  icon: PropTypes.string,
  isLoading: PropTypes.bool,
  navLink: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
  fontSize: PropTypes.string,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  disabled: PropTypes.bool,
};

ButtonText.defaultProps = {
  children: null,
  to: '',
  className: '',
  onClick: () => {},
  gaData: {},
  navLink: false,
  danger: false,
  isLoading: false,
  icon: '',
  fontSize: '',
  color: '',
  hoverColor: '',
  disabled: false,
};
