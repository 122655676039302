const es = {
  'alert.error.boostManager.boostBudget.currency': 'Error al obtener moneda',
  'alert.error.community.markAsRead': 'Tu{plural} mensaje{plural} no {could} ser marcado{plural} como leído{plural}. Por favor, vuelve a intentarlo o ponte en contacto con support@socialpiper.com ',
  'alert.error.community.likeComment': 'No se pudo agregar me gusta al comentario. Por favor, vuelve a intentarlo o ponte en contacto con support@socialpiper.com ',
  'alert.error.community.replyComm': 'Tu comentario no pudo ser enviado.  Por favor, vuelve a intentarlo o ponte en contacto con support@socialpiper.com ',
  'alert.error.community.replyMsg': 'Tu mensaje no pudo ser enviado.  Por favor, vuelve a intentarlo o ponte en contacto con support@socialpiper.com ',
  'alert.error.delete': 'Se encontró un error al eliminar',
  'alert.error.delete.post': 'Se encontró un error al eliminar publicación',
  'alert.error.edit': 'Tus cambios no pudieron ser guardados. Por favor, intenta nuevamente o manda un correo a support@socialpiper.com',
  'alert.error.fileTooLarge': 'Tu imagen es muy grande. Por favor, agrega una imagen más chica, no mayor a 2MB.',
  'alert.error.loginEmail': 'Tu correo o contraseña son incorrectos. Por favor, intenta nuevamente. ',
  'alert.error.loginFacebook': 'No pudimos conectarte con Facebook.  Por favor, intenta nuevamente o manda un correo a support@socialpiper.com',
  'alert.error.mycreations.delete.': 'No pudimos eliminar tu imagen. Vuelve a intentarlo o comunícate con support@socialpiper.com ',
  'alert.error.recoverPassword': 'No pudimos enviarte un correo de recuperación de contraseña.  Por favor, intenta nuevamente o manda un correo a support@socialpiper.com',
  'alert.error.reschedule': 'Se encontró un error al reprogramar',
  'alert.error.set.monthlyBudget': 'Tu presupuesto mensual no pudo guardarse.  Por favor, intenta nuevamente o manda un correo a support@socialpiper.com',
  'alert.error.signInEmail': 'No pudimos ingresar con tu cuenta. Por favor, vuelve a intentarlo o ponte en contacto con support@socialpiper.com ',
  'alert.error.signUpEmail': 'Ya existe una cuenta creada con el correo electrónico que especificaste.',
  'alert.error.signInFacebook': 'No pudimos crear tu cuenta con Facebook. Vuelve a intentarlo o ponte en contacto con support@socialpiper.com ',
  'alert.error.social.connection': 'Hubo un error al conectar tu cuenta',
  'alert.error.social.disconnection': 'Hubo un error al desconectar tu cuenta',
  'alert.error.ad.paused': 'Tu campaña no pudo ser pausada.   Por favor, intenta nuevamente o manda un correo a support@socialpiper.com.',
  'alert.error.ad.running': 'La pausa no pudo ser eliminada. Por favor, intenta nuevamente o manda un correo a support@socialpiper.com.',
  'alert.error.ad.archived': 'Tu campaña no pudo ser eliminada.  Por favor, intenta nuevamente o manda un correo a support@socialpiper.com.',
  'alert.error.subscription.canceled': 'Tu suscripción no pudo ser cancelada. Por favor, intenta nuevamente o manda un correo a support@socialpiper.com.',
  'alert.error.form.incomplete': 'Por favor completa la información necesaria marcada en color rojo.',
  'alert.error.onboarding.billingSection.selectPlan': 'Por favor selecciona un plan',
  'alert.error.onboarding.billingSection.requiredInfoIncomplete': 'Por favor completa los campos obligatorios',
  'alert.error.onboarding.billingSection.invalidEmail': 'Por favor ingresa un correo electrónico válido',
  'alert.error.updateLinkedIn': 'No pudimos conectar tu cuenta de LinkedIn.  Por favor, intenta nuevamente o manda un correo a support@socialpiper.com.',
  'alert.success.ad.archived': 'Tu campaña ha sido eliminada.',
  'alert.success.ad.paused': 'Tu campaña ha sido pausada.',
  'alert.success.ad.running': 'La pausa ha sido eliminada.',
  'alert.success.edit': 'Tus cambios han sido guardados.',
  'alert.success.create': '{item} creado exitosamente',
  'alert.success.create.sn': '{sn} {item} creado exitosamente',
  'alert.success.create.female': '{item} creada exitosamente',
  'alert.success.delete': '{item} eliminado exitosamente',
  'alert.success.delete.post': 'Tu publicación ha sido eliminada.',
  'alert.success.delete.female': '{item} eliminada exitosamente',
  'alert.success.save': '{item} guardado exitosamente',
  'alert.success.save.female': '{item} guardada exitosamente',
  'alert.success.save.card': 'Tarjeta guardada exitosamente',
  'alert.success.set.monthlyBudget': 'Tu presupuesto mensual ha sido guardado.',
  'alert.success.update': '{item} actualizado exitosamente',
  'alert.success.update.monthlyBudget': 'Tu presupuesto mensual ha sido actualizado.',
  'alert.success.update.female': '{item} actualizada exitosamente',
  'alert.error.loginApple': 'No pudimos conectarte con Apple.  Por favor, intenta nuevamente o manda un correo a support@socialpiper.com',
  'alert.error.onboarding.billingSection.app.transaction': 'El proceso de pago no se pudo completar. Por favor, intenta nuevamente o manda un correo a support@socialpiper.com.',
  'alert.error.trialEnds.days.remaning': 'Tu trial termina en {days} días, agrega un método de pago {here}',
  'alert.error.trialEnds.tomorrow': 'Tu trial termina mañana, agrega un método de pago {here}',
  'alert.error.trialEnds.today': 'Tu trial termina hoy, agrega un método de pago {here}',
  'alert.error.trialEnds.expired': 'Tu trial expiro y cambiaremos tu cuenta a Lite, si crees que es un error contacta a soporte {here}',
  'alert.error.paymentMethod': 'Tu método de pago fallo, agrega una tarjeta de crédito válida {here} para mantener tu plan Pro',
  'alert.info.create.video.boost': 'En unos momentos tu campaña estará lista. En el calendario podrás conocer su estado.',
};

export default es;
