import * as Sentry from '@sentry/browser';
import { authAppSync } from '../auth';
import {
  // getPlanInformation,
  getPaymentDates,
  getPlans,
  getSubscription,
  getPlansIOS,
  getPlansAndroid,
} from '../../graphql/queries';
import {
  createSubscription,
  cancelSubscription,
  createSubscriptionIOS,
  createSubscriptionAndroid,
} from '../../graphql/mutations';
import { ENVIRONMENTS, ENV } from '../../utils/constants/globals';
import allPlansMock from '../../mocks/plans/getPlans.mock.json';
import getSubscriptionMock from '../../mocks/plans/getSubscription.mock';
import lastPaymentMock from '../../mocks/plans/lastPayment.mock.json';
import cancelSubscriptionMock from '../../mocks/plans/cancelSubscription.mock';
import { sleep } from '../../utils';

export default class PlanProvider {
  static async fetchPaymentDates(attr = null) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: attr ? getPaymentDates(attr) : getPaymentDates(),
          fetchPolicy: 'network-only',
        });
        data = response.data.lastPayment;
      } else {
        data = lastPaymentMock.data.lastPayment;
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchAllPlans(code = '') {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: getPlans,
          variables: { codePlan: code },
          fetchPolicy: 'network-only',
        });
        data = response.data.getPlans;
      } else {
        data = allPlansMock.data.getPlans;
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchSubscription() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: getSubscription,
          fetchPolicy: 'no-cache',
        });
        data = response.data.getSubscriptionInfo;
      } else {
        data = getSubscriptionMock.data.getSubscriptionInfoActive;
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async subscribeToPlan(id, promoCodeId = null, disableTrial = false, atOnboarding = false) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const varPayload = { id };
        if (promoCodeId) {
          varPayload.promoCodeId = promoCodeId;
        }
        if (disableTrial) {
          varPayload.disableTrial = disableTrial;
        }
        if (atOnboarding) {
          varPayload.atOnboarding = atOnboarding;
        }
        const response = await authAppSync.client.mutate({
          mutation: createSubscription,
          variables: varPayload,
        });
        data = response.data.createSubscriptionV2;
      } else {
        data = {
          subscriptionInfo: getSubscriptionMock.data.getSubscriptionInfoTrial,
          payment: lastPaymentMock.data.lastPayment,
        };
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async unsubscribe() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.mutate({
          mutation: cancelSubscription,
        });
        data = response.data.cancelSubscription;
      } else {
        data = cancelSubscriptionMock;
      }

      result = {
        message: 'success',
        success: data.status,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async subscribeToPlanIOS(id) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.mutate({
          mutation: createSubscriptionIOS,
          variables: {
            id,
          },
        });
        data = response.data.createSubscriptionIOS;
      } else {
        data = {
          subscriptionInfo: getSubscriptionMock.data.getSubscriptionInfoTrial,
          payment: lastPaymentMock.data.lastPayment,
        };
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async getAllPlansIOS(currencyCode, countryCode) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: getPlansIOS(),
          variables: { currencyCode, countryCode },
        });
        data = response.data.getPlansIOS;
      } else {
        data = allPlansMock.data.getPlans;
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async getAllPlansAndroid() {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: getPlansAndroid(),
        });
        data = response.data.getPlansAndroid;
      } else {
        data = allPlansMock.data.getPlans;
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async subscribeToPlanAndroid(dataPurchase) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.mutate({
          mutation: createSubscriptionAndroid,
          variables: dataPurchase,
        });
        data = response.data.createSubscriptionAndroid;
      } else {
        await sleep(10000);
        data = {};
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
