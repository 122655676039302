import React, { useState, useEffect } from 'react';
import { InboxProvider } from '../services/entities';
import { authAppSync } from '../services/auth';

/**
 * Global context implemented in App.js for fetching user data
 * @usage const {unreadComments, setUnreadComments} = useContext(UnreadCommentsContext)
 * @return {React.Context} returns a React context to be used globally
 */
export const UnreadCommentsContext = React.createContext({});

/**
 * useFetchUnreadComments
 * @summary set UnreadComments context from InboxProvider service
 * @return {void}
 * @assign Unread Comments context
*/
export const useFetchUnreadComments = () => {
  const [unreadComments, setUnreadComments] = useState({
    messagesCount: 0,
    fbCommentsCount: 0,
    twRepliesCount: 0,
    igCommentsCount: 0,
  });

  useEffect(() => {
    const fetchUnreadMessages = async () => {
      const response = await InboxProvider.fetchUnreadMessages();
      const { success, data } = response;
      if (success && data) {
        setUnreadComments(data);
      } else {
        setUnreadComments({
          messagesCount: 0,
          fbCommentsCount: 0,
          twRepliesCount: 0,
          igCommentsCount: 0,
          liCommentsCount: 0,
        });
      }
    };
    if (authAppSync.client) {
      fetchUnreadMessages();
    }
  }, [authAppSync.client]);
  return [unreadComments, setUnreadComments];
};

export default useFetchUnreadComments;
