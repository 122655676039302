import gql from 'graphql-tag';

export const myCreations = gql`
query getOwnContent($tagName: String, $pagination: Pagination, $language:LANGUAGE) {
    getOwnContent(tagName: $tagName, pagination: $pagination, language: $language) {
      items {
        id
        name
        pngUrl
        pngId
        jpgId
        jpgUrl
        tags {
          name
          nameEs
          id
        }
        imageUrl
        isNewTemplate
      }
      count
    }
  }
`;

export const getEditorState = gql`
  query getEditorState($imageId: Int) {
    getEditorState(imageId: $imageId) {
      state
      editorImages{
        urlMedia
        decorationId
        isBackground
      }
    }
  }
`;

export const getEditorSticker = gql`
  query getEditorSticker {
    getEditorSticker {
      data {
        urlMedia
      }
    }
  }
`;

export default {};
