import gql from 'graphql-tag';

export const getAdAccounts = (attr = `
  facebookId
  accountId
  currency
  accountStatus
  name
  businessName
  fundingSourceId
  isSelected
  haveBoost
`) => gql`
  query getAdAccounts {
    getAdAccounts {
      id
      ${attr}
    }
  }
`;

export const fetchLinkedinAdAccounts = (attr = `
  id
  linkedinId
  currency
  name
  fundingSourceId
  servingStatuses
  accountStatus
  isSelected
`) => gql`
  query fetchLinkedinAdAccounts {
    fetchLinkedinAdAccounts {
      id
      ${attr}
    }
  }
`;

export default {};
