import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useCallGA from '../../hooks/useCallGA';
import { lowSpreadShadow, paragraph } from '../../styled/mixins';
import { colors } from '../../styled/theme';
import BecomeProButton from '../becomePro';

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  background: rgba(0,0,0,${({ clearBackground }) => (clearBackground ? '0' : '0.6')});
  align-items: center;
  justify-content: center;
  z-index: 799;
`;

const Modal = styled.div`
  ${lowSpreadShadow}
  max-height: 90vh;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.green};
  max-width: min(400px, 90vw);
  border-radius: 6px;
`;

const Text = styled.p`
  ${paragraph}
  font-size: 22px;
  color: white;
  margin-bottom: 16px;
  text-align: center;
`;

const StyledBecomeProButton = styled(BecomeProButton)`
  && {
    margin-bottom: 16px;
  }
`;

const NotNowButton = styled.div`
  ${paragraph}
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
  color: white;
`;

const ProFeatureModal = ({
  open, setOpen, text, clearBackground, callbackFunc,
}) => {
  const callGA = useCallGA();
  const handleNotNow = () => {
    callGA({
      title: 'Pro feature modal',
      action: 'Not now',
      label: '(button)',
    });
    setOpen(false);
    setTimeout(() => { callbackFunc(); }, 100);
  };
  useEffect(() => {
    if (open) {
      callGA({
        title: 'Pro feature modal',
        event: 'pv',
      });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);
  return (
    <ModalContainer
      open={open}
      clearBackground={clearBackground}
    >
      <Modal>
        <Text>
          {text}
        </Text>
        <StyledBecomeProButton />
        <NotNowButton
          onClick={handleNotNow}
        >
          <FormattedMessage
            id="k.notNow"
            defaultMessage="Not now"
          />
        </NotNowButton>
      </Modal>
    </ModalContainer>
  );
};

ProFeatureModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  clearBackground: PropTypes.bool,
  callbackFunc: PropTypes.func,
};

ProFeatureModal.defaultProps = {
  clearBackground: false,
  callbackFunc: () => ({}),
};

export default ProFeatureModal;
