import gql from 'graphql-tag';

export const registerPlayerId = gql`
  mutation registerPlayerId($playerId: String!) {
  registerPlayerId(playerId: $playerId)
}
`;

export const deregisterPlayerId = gql`
  mutation deregisterPlayerId($playerId: String!) {
    deregisterPlayerId(playerId: $playerId)
}
`;
