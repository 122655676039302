const en = {
  'planOption.basic.details.comments': 'Answer comments on your social networks.',
  'planOption.basic.details.competitors': 'Monitor your competitors (You can add up to 1 competitor on Facebook and 1 on Instagram).',
  'planOption.basic.details.connect': 'Connect your Facebook, Instagram and Twitter business accounts (1 for each social network).',
  'planOption.basic.details.description': 'Your Basic account allows you to:',
  'planOption.basic.details.editPosts': 'Manage and edit your posts in calendar view.',
  'planOption.basic.details.messages': 'Access your Facebook Messenger messages.',
  'planOption.basic.details.recommended': 'Use graphic content suggested by Piper or upload your own content',
  'planOption.basic.details.whenToPost': 'Know when and where to post thanks to Piper.',
  'planOption.basic.features.comments': 'Reply to comments.',
  'planOption.basic.features.connect': 'Connect FB, IG and TW.',
  'planOption.basic.features.posts': '10 posts per month.',
  'planOption.basic.features.templates': 'Use recommended templates.',
  'planOption.business.details.approval': 'Content and boost review and approval.',
  'planOption.business.details.connect': 'Connect more than one Facebook, Instagram, and Twitter account.',
  'planOption.business.details.description': 'It includes everything from the Basic and Pro Plan, plus:',
  'planOption.business.details.multiuser': 'Multi-user access for your team.',
  'planOption.business.features.approval': 'Content and boost review and approval.',
  'planOption.business.features.connect': 'Connect +1 account on FB, IG, and TW.',
  'planOption.business.features.multiuser': 'Multi-user access.',
  'planOption.pro.details.ads': 'Create Facebook ad boosts.',
  'planOption.pro.details.budget': 'Keep control of your boosts budget.',
  'planOption.pro.details.competitors': 'Add as many competitors as you want.',
  'planOption.pro.details.description': 'It includes all the Lite plan features, plus you will be able to:',
  'planOption.pro.details.editor': 'Use the online graphic editor.',
  'planOption.pro.details.publish': 'Publish with no limits.',
  'planOption.pro.details.templates': 'Access thousands of templates.',
  'planOption.pro.features.boost': 'Boost your posts.',
  'planOption.pro.features.templates': 'Use and edit thousand of templates.',
  'planOption.selectThisPlan': 'Select this plan',
  'planOption.selectedPlan': 'Selected plan',
  'planOption.selectThisPlanTrial': '{trialDays}  days  free',
  'planOption.actualPlan': 'You are already on this plan',
  'planOption.price.monthly': '{cur} {price} month',
  'planOption.price.yearly': '{cur} {price} year',
  'planOption.description.app': 'Take advantage of Social Piper’s full power & features, and make your business thrive in social media.',
  'planOption.name.PRO': 'Pro plan',
  'planOption.name.FULL': 'Full managment',
};

export default en;
