import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DoggoLogo, LettersLogo } from '../../../assets/logos/logoRepository';
import GlobalTheme from '../../../styled/GlobalTheme';

export const Container = styled.div`
  height: ${({ height }) => height || '60px'};
  display: flex;
  align-items: center;
  width: fit-content;
  ${GlobalTheme.breakpoints.down('lg')}{
    align-items: baseline;
  }
  ${GlobalTheme.breakpoints.down('xs')}{
    align-items: center;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    margin: auto;
  }
`;

export const Image = styled.img`
  object-fit: none;
`;

export default function FullSizeLogo({ height, className }) {
  return (
    <Container height={height} className={className}>
      <Image src={DoggoLogo} style={{ marginRight: '10px', marginBottom: '10px' }} />
      <Image src={LettersLogo} />
    </Container>
  );
}

FullSizeLogo.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
};

FullSizeLogo.defaultProps = {
  height: '60px',
  className: '',
};
