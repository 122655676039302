import React from 'react';
import styled from 'styled-components';
import Media from 'react-media';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import routes from '../../../utils/constants/routes';
import {
  FacebookColorIcon,
  InstagramCircleColorIcon,
  TwitterCircleColorIcon,
  LinkedinCircleColorIcon,
  TiktokOriginalIcon,
} from '../../../assets/icons/iconRepository';
import mediaQueries from '../../../utils/mediaQueries';

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  padding-top: 36px;
  padding-bottom: 39px;
  @media ${mediaQueries.mdUp} {
    padding-top: 70px;
    padding-bottom: 48px;
  }
`;
const Content = styled.div`
  width: max-content;
  @media ${mediaQueries.mdUp} {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`;
const Title = styled.p`
  font-size: 19px;
  font-weight: 600;
  line-height: 26px;
  margin-top: 0;
  margin-bottom: 22px;
  text-align: center;
  @media ${mediaQueries.mdUp} {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 0;
  }
`;
const SocialIconsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, max-content);
  grid-gap: 18px;
  @media ${mediaQueries.mdUp} {
    margin-left: 62px;
  }
  @media ${mediaQueries.lgPlusUp} {
    margin-left: 76px;
    grid-gap: 30px;
    ${({ isEnglish }) => isEnglish && `
      grid-gap: 40px;
    `}
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
const SocialIconContainer = styled.div`
  display: flex;
  width: 38px;
  height: 38px;
  position: relative;
  &:nth-child(4), &:nth-child(5) {
    margin-left: 8px;
    @media ${mediaQueries.lgPlusUp} {
      margin-left: 17px;
      ${({ isEnglish }) => isEnglish && `
        margin-left: 4px;
      `}
    }
  }
  ${({ isInstagram }) => isInstagram && `
    cursor: pointer;
  `}
  ${({ isLinkedin }) => isLinkedin && `
    width: 39px;
    height: 38px;
  `}
  @media ${mediaQueries.lgPlusUp} {
    width: 48px;
    height: 48px;
    ${({ isLinkedin }) => isLinkedin && `
      width: 49px;
      height: 48px;
    `}
  }
`;
const SocialIcon = styled.img`
  width: 100%;
  height: 100%;
`;

const Description = styled(Link)`
  color: ${({ theme }) => theme.colors.red02};
  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
  margin-top: 4px;
  width: 100%;
  text-align: center;
`;

const WhereToPostSection = () => {
  const history = useHistory();
  const intl = useIntl();
  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <Wrapper>
          <Content>
            <Title>
              <FormattedMessage
                id="landing.whereToPost.title"
                defaultMessage="Within seconds, post to:"
              />
            </Title>
            <SocialIconsContainer isEnglish={intl.locale === 'en'}>
              <SocialIconContainer>
                <SocialIcon src={FacebookColorIcon} alt="Facebook Icon" />
              </SocialIconContainer>
              <Container>
                <SocialIconContainer
                  onClick={() => history.push('/instagram')}
                  isInstagram
                >
                  <SocialIcon
                    src={InstagramCircleColorIcon}
                    alt="Instagram Icon"
                  />
                </SocialIconContainer>
                <Description to={routes.LANDINGINSTAGRAM}>
                  <FormattedMessage
                    id="landing.social.instagram.description"
                    defaultMessage="+ IG Stories"
                    values={{
                      lineBreak: matches.mdDownLess ? <br /> : '',
                    }}
                  />
                </Description>
              </Container>
              <SocialIconContainer>
                <SocialIcon src={TiktokOriginalIcon} alt="Tiktok Icon" />
              </SocialIconContainer>
              <SocialIconContainer isEnglish={intl.locale === 'en'}>
                <SocialIcon src={TwitterCircleColorIcon} alt="Twitter Icon" />
              </SocialIconContainer>
              <SocialIconContainer
                isEnglish={intl.locale === 'en'}
                isLinkedin
              >
                <SocialIcon src={LinkedinCircleColorIcon} alt="Linkedin Icon" />
              </SocialIconContainer>
            </SocialIconsContainer>
          </Content>
        </Wrapper>
      )}
    </Media>
  );
};

export default WhereToPostSection;
