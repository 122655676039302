import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import { differenceInCalendarDays, differenceInMinutes } from 'date-fns';
import AuthService from '../services/auth/AuthService';
import { PlanProvider, BillingProvider } from '../services/entities';
import { AlertsContext } from './AlertsContext';
import { SEVERITY, DURATIONS } from '../utils/constants/alerts';
import { PLAN_STATUS } from '../utils/constants/settings';

const LinkTemp = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

// this function update routes withouth react router
const LinkBilling = () => {
  const updateRoute = (event, href) => {
    event.preventDefault();
    window.history.pushState({}, '', href);
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  };

  return (
    <LinkTemp
      onClick={(evt) => updateRoute(evt, '/settings/billing')}
    >
      <FormattedMessage
        id="k.here"
        defaultMessage="here"
      />
    </LinkTemp>
  );
};
const LinkIntercom = () => {
  const openItercom = (event) => {
    event.preventDefault();
    if (window.Intercom) {
      window.Intercom('show');
    }
  };
  return (
    <LinkTemp
      onClick={(evt) => openItercom(evt)}
    >
      <FormattedMessage
        id="k.here"
        defaultMessage="here"
      />
    </LinkTemp>
  );
};

const SubscriptionContext = React.createContext();
// eslint-disable-next-line react/prop-types
const SubscriptionProvider = ({ children }) => {
  const intl = useIntl();
  const { showAlert } = useContext(AlertsContext);
  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [infoFetched, setInfoFetched] = useState(false);
  const fetchSubscription = async () => {
    const response = await PlanProvider.fetchSubscription();
    if (response && response.success) {
      const subs = response.data;
      setSubscriptionInfo(subs);
      return subs;
    }
    setSubscriptionInfo({});
    return false;
  };

  const showAlertMsg = (message) => {
    showAlert(message, SEVERITY.ERROR, DURATIONS.LONG, true);
  };

  const fetchPaymentMethods = async () => {
    const response = await BillingProvider.fetchPaymentMethods();
    if (response && response.success) {
      const payMethods = response.data;
      setPaymentMethods(payMethods);
      return payMethods;
    }
    setPaymentMethods([]);
    return [];
  };

  const checkSubscriptionAndPayments = async () => {
    const subsInfo = await fetchSubscription();
    const payments = await fetchPaymentMethods();
    const now = new Date();
    if (!infoFetched) {
      setInfoFetched(true);
      if (subsInfo) {
        const {
          planStatus,
          planEndDate: endD,
        } = subsInfo;
        const planEndDate = new Date(endD);
        const countDays = differenceInCalendarDays(planEndDate, now);
        if (planStatus === PLAN_STATUS.TRIAL) {
          if (!payments || payments.length === 0) {
            let message = false;
            if (countDays < 8) {
              message = intl.formatMessage({
                id: 'alert.error.trialEnds.days.remaning',
                defaultMessage: 'Your trial ends in {days} days, add a valid payment method {here}',
              }, {
                days: countDays,
                here: (<LinkBilling />),
              });
              if (countDays < 2) {
                message = intl.formatMessage({
                  id: 'alert.error.trialEnds.tomorrow',
                  defaultMessage: 'Your trial ends tomorrow, add a valid payment method {here}',
                }, {
                  here: (<LinkBilling />),
                });
                if (countDays <= 0) {
                  message = intl.formatMessage({
                    id: 'alert.error.trialEnds.today',
                    defaultMessage: 'Your trial ends today, add a valid payment method {here}',
                  }, {
                    here: (<LinkBilling />),
                  });
                  const diffSec = differenceInMinutes(planEndDate, now);
                  if (diffSec < -1) {
                    message = intl.formatMessage({
                      id: 'alert.error.trialEnds.expired',
                      defaultMessage: 'Your payment method failed, add a valid credit card {here} to keep your PRO Plan',
                    }, {
                      here: (<LinkIntercom />),
                    });
                  }
                }
              }
            }
            if (message) {
              showAlertMsg(message);
            }
          }
        }
        if (planStatus === PLAN_STATUS.PAST_DUE || planStatus === PLAN_STATUS.INCOMPLETE) {
          const message = intl.formatMessage({
            id: 'alert.error.paymentMethod',
            defaultMessage: 'Your payment method failed, add a valid credit card {here} to keep your PRO Plan',
          }, {
            here: (<LinkBilling />),
          });
          showAlertMsg(message);
        }
      }
    }
  };

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      checkSubscriptionAndPayments();
    }
  }, []);

  return (
    <SubscriptionContext.Provider
      value={{
        checkSubscriptionAndPayments,
        subscriptionInfo,
        setSubscriptionInfo,
        fetchSubscription,
        paymentMethods,
        setPaymentMethods,
        fetchPaymentMethods,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export { SubscriptionContext, SubscriptionProvider };
