const es = {
  'subscriptionModal.covid.regularMonthly': 'El costo de suscripción mensual es {price}',
  'subscriptionModal.covid.regularYearly': 'El costo de suscripción anual es {price}/año',
  'subscriptionModal.monthly.price': 'US {price} / mes',
  'subscriptionModal.regularCost': 'Costo regular US',
  'subscriptionModal.subscribe': 'Subscribirse',
  'subscriptionModal.subtitle': 'Selecciona el periodo de facturación deseado',
  'subscriptionModal.title': 'Subscríbete a Social Piper',
  'subscriptionModal.yearly.price': 'US {price}',
};

export default es;
