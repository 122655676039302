import React from 'react';
import { FormattedMessage } from 'react-intl';

const individualFeatures = [
  {
    id: 'personalOnlineAdvisor',
    title: (
      <FormattedMessage
        id="comparePlans.AccTittle6"
      />
    ),
    onlyBiz: true,
    features: [
      {
        id: 'personalOnlineAdvisor1',
        description: (
          <FormattedMessage
            id="comparePlans.ceil19.1"
          />
        ),
        unlimited: false,
      },
      {
        id: 'personalOnlineAdvisor2',
        description: (
          <FormattedMessage
            id="comparePlans.ceil19.2"
          />
        ),
        unlimited: false,
      },
      {
        id: 'personalOnlineAdvisor3',
        description: (
          <FormattedMessage
            id="comparePlans.ceil19.3"
          />
        ),
        unlimited: false,
      },
      {
        id: 'personalOnlineAdvisor4',
        description: (
          <FormattedMessage
            id="comparePlans.ceil19.4"
          />
        ),
        unlimited: false,
      },
      {
        id: 'personalOnlineAdvisor5',
        description: (
          <FormattedMessage
            id="comparePlans.ceil19.5"
          />
        ),
        unlimited: false,
      },
    ],
  },
  {
    id: 'createContent',
    title: (
      <FormattedMessage
        id="comparePlans.AccTittle1"
        defaultMessage="Create content"
      />
    ),
    features: [
      {
        id: 'createContent1',
        description: (
          <FormattedMessage
            id="comparePlans.ceil4"
            defaultMessage="Ready to use/edit social creative templates"
          />
        ),
        unlimited: true,
      },
      {
        id: 'createContent3',
        description: (
          <FormattedMessage
            id="comparePlans.ceil4.1"
            defaultMessage="Creation of images and texts with AI for your business social media"
          />
        ),
        unlimited: false,
      },
      {
        id: 'createContent2',
        description: (
          <FormattedMessage
            id="comparePlans.ceil9"
            defaultMessage="Create awesome content for your business with our graphic editor"
          />
        ),
        unlimited: false,
      },
    ],
  },
  {
    id: 'schedule',
    title: (
      <FormattedMessage
        id="comparePlans.AccTittle2"
        defaultMessage="Schedule"
      />
    ),
    features: [
      {
        id: 'schedule1',
        description: (
          <FormattedMessage
            id="comparePlans.ceil3"
            defaultMessage="Schedule/publish your content daily, weekly, or monthly"
          />
        ),
        unlimited: true,
      },
      {
        id: 'schedule2',
        description: (
          <FormattedMessage
            id="comparePlans.ceil6"
            defaultMessage="Your Business social media posts calendar management"
          />
        ),
        unlimited: false,
      },
      {
        id: 'schedule3',
        description: (
          <FormattedMessage
            id="comparePlans.ceil7"
            defaultMessage="Recommendations for your best date and time to publish"
          />
        ),
        unlimited: false,
      },
      {
        id: 'schedule4',
        description: (
          <FormattedMessage
            id="comparePlans.ceil5"
            defaultMessage="Watch your competitor’s social media activity"
          />
        ),
        unlimited: true,
      },
    ],
  },
  {
    id: 'socialAds',
    title: (
      <FormattedMessage
        id="comparePlans.AccTittle3"
        defaultMessage="Social Advertising"
      />
    ),
    features: [
      {
        id: 'socialAds1',
        description: (
          <FormattedMessage
            id="comparePlans.ceil12"
            defaultMessage="Manage your business’ Social Media Ads"
          />
        ),
        unlimited: false,
      },
      {
        id: 'socialAds2',
        description: (
          <FormattedMessage
            id="comparePlans.ceil13"
            defaultMessage="Recomendations for boosting to maximize your campaigns"
          />
        ),
        unlimited: false,
      },
      {
        id: 'socialAds3',
        description: (
          <FormattedMessage
            id="comparePlans.ceil14"
            defaultMessage="Social Ads reports in real time"
          />
        ),
        unlimited: false,
      },
    ],
  },
  {
    id: 'extraPerks',
    title: (
      <FormattedMessage
        id="comparePlans.AccTittle4"
        defaultMessage="Extra Perks"
      />
    ),
    features: [
      {
        id: 'extraPerks1',
        description: (
          <FormattedMessage
            id="comparePlans.ceil10"
            defaultMessage="Access the educational content in our Social Piper Academy"
          />
        ),
        unlimited: false,
      },
      {
        id: 'extraPerks2',
        description: (
          <FormattedMessage
            id="comparePlans.ceil11"
            defaultMessage="Customer Success Webinars"
          />
        ),
        unlimited: false,
      },
      {
        id: 'extraPerks3',
        description: (
          <FormattedMessage
            id="comparePlans.ceil15"
            defaultMessage="Manage Social Piper from the mobile app"
          />
        ),
        unlimited: false,
      },
      {
        id: 'extraPerks4',
        description: (
          <FormattedMessage
            id="comparePlans.ceil8"
            defaultMessage="Reply to your clients comments/direct messages from one place"
          />
        ),
        unlimited: false,
      },
    ],
  },
  {
    id: 'support',
    title: (
      <FormattedMessage id="comparePlans.AccTittle5" defaultMessage="Support" />
    ),
    features: [
      {
        id: 'support1',
        description: (
          <FormattedMessage
            id="comparePlans.support.feature1"
            defaultMessage="Technical Support 24/7 - Help Center, Chat and Email"
          />
        ),
        unlimited: false,
      },
    ],
  },
];

export default individualFeatures;
