import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { StripeProvider, Elements } from 'react-stripe-elements';
import styled from 'styled-components';
import AddPaymentMethod from './AddPaymentMethod';
import { AlertsContext } from '../../../../contexts/AlertsContext';
import { BillingProvider } from '../../../../services/entities';
import CardsSelector from '../../../../views/Settings/tabs/Billing/components/CardsSelector';
import { Button, ButtonLink } from '../../../buttons';
import { heading } from '../../../../styled/mixins';
import GlobalTheme from '../../../../styled/GlobalTheme';
import { CONFIRM_STAGE, SELECT_PLAN_STAGE } from '../constants';
import CardDetails from './CardDetails';
import CardLoader from '../../../../views/Settings/tabs/Billing/components/CardLoader';

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
  height: 80%;
  justify-content: center;
  position: relative;
  ${GlobalTheme.breakpoints.up('sm')} {
    padding: 56px 0 97px;
  }
`;

const SelectionTitle = styled.h4`
  ${heading}
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 24px;
  text-align: center;
  ${GlobalTheme.breakpoints.down('sm')} {
    font-size: 20px;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  && {
    margin-top: 20px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  padding-bottom: 32px;
  ${GlobalTheme.breakpoints.down('sm')}{
    justify-content: center;
    padding: 30px 0 20px 0;
  }
`;

const ButtonStyled = styled(Button)`
  && {
    margin: 0 12px;
  }
`;

const PaymentDetails = ({
  setPaymentMethods, paymentMethods, setActiveStage, continueAction,
}) => {
  const intl = useIntl();
  const [addNewPayment, setAddNewPayment] = useState(!paymentMethods || !paymentMethods.length);
  const { showBackendError, showAlert } = useContext(AlertsContext);
  const [loading, setLoading] = useState(false);

  const handleClickCard = async (card) => {
    if (card.isDefaultPayment) return;
    setLoading(true);
    const response = await BillingProvider.updateDefaultMethod(card.id, paymentMethods);
    if (response.success) {
      setPaymentMethods(response.data);
    } else {
      showBackendError(response.message);
    }
    setLoading(false);
  };
  const handleDeleteCard = async (card) => {
    setLoading(true);
    const response = await BillingProvider.deletePaymentMethod(card.id, paymentMethods);
    if (response.success) {
      setPaymentMethods(response.data);
      showAlert(intl.formatMessage({
        id: 'alert.success.delete.female',
        defaultMessage: '{item} deleted successfully.',
      }, {
        item: intl.formatMessage({
          id: 'k.card',
          defaultMessage: 'Card',
        }),
      }));
    } else {
      showBackendError(response.message);
    }
    setLoading(false);
  };

  const handleNext = () => {
    continueAction();
    setActiveStage(CONFIRM_STAGE);
  };

  const handleBack = (fromNew) => {
    continueAction('Back Offer Modal');
    if (fromNew && paymentMethods.length) {
      setAddNewPayment(false);
      return;
    }
    setActiveStage(SELECT_PLAN_STAGE);
  };

  if (addNewPayment) {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
        <Elements
          fonts={[{ cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:wght@400' }]}
        >
          <AddPaymentMethod
            handleNext={handleNext}
            handleBack={() => handleBack(true)}
            setPaymentMethods={setPaymentMethods}
          />
        </Elements>
      </StripeProvider>
    );
  }
  const cardsLayout = paymentMethods.length > 1 ? (
    <>
      <SelectionTitle>
        <FormattedMessage
          id="proOfferModal.paymentDetails.select"
          defaultMessage="Select your payment method"
        />
        :
      </SelectionTitle>
      <CardsSelector
        paymentMethods={paymentMethods}
        handleClickCard={handleClickCard}
        handleDeleteCard={handleDeleteCard}
      />
    </>
  ) : (
    <>
      <SelectionTitle>
        <FormattedMessage
          id="proOfferModal.paymentDetails.one"
          defaultMessage="We will use your added payment method"
        />
        :
      </SelectionTitle>
      <CardDetails
        card={paymentMethods[0]}
      />
    </>
  );
  return (
    <>
      <CardsContainer>
        <CardLoader
          loading={loading}
        />
        {cardsLayout}
        <StyledButtonLink
          onClick={() => setAddNewPayment(true)}
        >
          <FormattedMessage
            id="proOfferModal.paymentDetails.addPayment"
            defaultMessage="Add another payment method"
          />
        </StyledButtonLink>
      </CardsContainer>
      <ButtonContainer>
        <ButtonStyled type="secondary" onClick={() => handleBack(false)}>
          <FormattedMessage
            id="k.back"
            defaultMessage="Back"
          />
        </ButtonStyled>
        <Button onClick={handleNext}>
          <FormattedMessage
            id="k.continue"
            defaultMessage="Continue"
          />
        </Button>
      </ButtonContainer>
    </>
  );
};

PaymentDetails.propTypes = {
  setPaymentMethods: PropTypes.func.isRequired,
  paymentMethods: PropTypes.instanceOf(Array).isRequired,
  setActiveStage: PropTypes.func.isRequired,
  continueAction: PropTypes.func.isRequired,
};

export default PaymentDetails;
