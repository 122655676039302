import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { footerButtonsWithoutAuth } from './footerDictionaries';
import FooterElement from './FooterElement';
import FullSizeLogo from '../Logos/FullSizeLogo';
import GlobalTheme, { breakpointValues } from '../../../styled/GlobalTheme';
import mediaQueries from '../../../utils/mediaQueries';

const FooterWrapper = styled.nav`
  padding-top: 70px;
  padding-bottom: 37px;
  padding-right: 70px;
  padding-left: 70px;
  display: grid;
  width: 100%;
  align-items: start;
  grid-template-columns: repeat(4, max-content);
  grid-template-areas: "logo withUsItems companyItems  social"
                        "logo withUsItems companyItems social"
                        "logo copy copy copy"
  ;
  position: relative;
  color: ${(props) => props.theme.colors.text02};
  @media ${mediaQueries.xlPlusUp} {
    column-gap: 58px;
  }
  @media ${mediaQueries.xxlUp} {
    column-gap: 120px;
  }
  ${GlobalTheme.breakpoints.down('lg')}{
    grid-template-columns: repeat(4, max-content);
    padding-right: 0;
    padding-left: 0;
    justify-content: space-between;
  }
  ${GlobalTheme.breakpoints.down('sm')}{
    grid-template-columns: 100%;
    grid-gap: ${(props) => props.theme.space * 2}px;
    text-align: center;
    grid-template-areas: "logo"
                          "withUsItems"
                          "companyItems"
                          "social"
                          "copy"
                          ;
    grid-template-rows: auto auto auto auto auto;
    width: 100%;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 36px;
  }
`;

const LogoContainer = styled.div`
  grid-area: logo;
  margin-top: -15px;
  ${GlobalTheme.breakpoints.down('lg')} {
    margin-top: auto;
  }
  ${GlobalTheme.breakpoints.down('sm')} {
    margin: 0;
    display: flex;
  }
`;

const CustomFullSizeLogo = styled(FullSizeLogo)`
  && {
    ${GlobalTheme.breakpoints.down('sm')}{
      margin: 0;
    }
  }
`;

const CopyrightContainer = styled.div`
  grid-area: copy;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 4px;
  grid-template-rows: auto;
  align-content: space-around;
  justify-content: flex-start;
  grid-gap: 4px;
  line-height: 24px;
  margin-top: 32px;
  padding-left: 20px;
  ${GlobalTheme.breakpoints.down('sm')} {
    grid-template-columns: 100%;
    max-width: 307px;
    font-size: 16px;
    margin: 0 auto;
    margin-top: 20px;
    grid-gap: 0;
    justify-content: center;
    padding-left: 0;
  }
`;

const CompanyItems = styled.ul`
  grid-area: companyItems;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 16px;
  margin: 0;
  padding-left: 20px;
  line-height: 1;
  margin-top: -6px;
  ${GlobalTheme.breakpoints.down('lg')} {
    margin-top: -12px;
  }
  ${GlobalTheme.breakpoints.down('sm')} {
    margin-top: 0;
    grid-gap: 10px;
    width: 65%;
    margin: 0 auto;
    li:first-child {
      margin-top: -12px;
    }
    padding-left: 42px;
  }
  ${GlobalTheme.breakpoints.down('xs')} {
    margin-left: 0px;
    width: 100%;
  }

  li {
    list-style-type: none;
    display: list-item;
  }
  li a {
    display: inline-flex;
    justify-items: flex-start;
    width: 100%;
    min-height: 0;
    button {
      line-height: 1.2;
    }
  }
  li::marker {
    font-size: initial;
  }
  & .elementText {
    text-align: left;
    font-size: 14px;
  }
`;

const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 16px;
`;

const WithUsItems = styled(CompanyItems)`
  grid-area: withUsItems;
  margin-bottom: 10px!important;
`;

const SocialNetworksContainer = styled.div`
  grid-area: social;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: auto;
  grid-gap: ${(props) => props.theme.space * 2}px;
  max-height: 92px;
  margin-top: 3px;
  ${GlobalTheme.breakpoints.down('md')} {
    margin-top: -1px;
  }
  ${GlobalTheme.breakpoints.down('sm')} {
    margin-top: 0px;
    grid-template-columns: repeat(4, auto);
    justify-items: baseline;
    justify-content: space-between;
    align-self: flex-start;
    height: auto;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 20px;
  }
`;

const CompanyElement = styled(FooterElement)`
  && {
    font-size: 18px;
    line-height: 26px;
    ${GlobalTheme.breakpoints.down('sm')} {
      button {
        font-size: 16px;
        line-height: 26px;
      }
      text-align: left;
    }
  }
`;

const CustomFooterElement = styled(FooterElement)`
  && {
    font-size: 18px;
    line-height: 26px;
    ${GlobalTheme.breakpoints.down('sm')} {
      button {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
`;
const FooterContainer = styled.div``;

export default function FooterWithoutAuth() {
  const {
    company,
    workWithUs,
    socialNetworks,
  } = footerButtonsWithoutAuth;
  const intl = useIntl();
  return (
    <FooterWrapper>
      <FooterContainer>
        <LogoContainer>
          <CustomFullSizeLogo />
        </LogoContainer>
      </FooterContainer>
      <CompanyItems>
        {company.map((element) => (
          <li>
            <CompanyElement element={element} />
          </li>
        ))}
      </CompanyItems>
      <WithUsItems>
        {workWithUs.map((element) => (
          <li>
            <CustomFooterElement element={element(intl.locale)} />
          </li>
        ))}
      </WithUsItems>
      <SocialNetworksContainer>
        {socialNetworks.map((element) => (
          <FooterElement element={element} />
        ))}
      </SocialNetworksContainer>
      <CopyrightContainer>
        <Paragraph>
          <FormattedMessage
            id="k.address.noAuth"
            defaultMessage="228 Hamilton Avenue, 3rd floor. Palo Alto, CA, 94301"
          />
        </Paragraph>
        <Paragraph>
          <FormattedMessage
            id="k.copyright"
            defaultMessage="© Copyright {year} All rights reserved"
            values={{
              year: new Date().getFullYear(),
              lineBreak: '',
            }}
          />
        </Paragraph>
      </CopyrightContainer>
    </FooterWrapper>
  );
}
