import gql from 'graphql-tag';

export const getAdCampaigns = (attr = `
  facebookAdId
  lifeTimeBudget
  startDateTime
  endDateTime
  type
  campaignStatus
  isExpired
  imageUrl
  postMessage
  statusFromFB
  effectiveStatus
  goal
  instagramPlacement
  attachmentType
  insights {
    likes
    comments
    shares
    reach
    spend
    reactionsFromFB
    commentsFromFB
    sharesFromFB
  }
`) => gql`
  query getAdCampaignsV2($date: AWSDateTime, $timezone: String, $by: String, $removeDeleted: Boolean, $removeFailed: Boolean, $removeProgress: Boolean){
    getAdCampaignsV2(date: $date, timezone: $timezone, by: $by, removeDeleted: $removeDeleted, removeFailed: $removeFailed, removeProgress: $removeProgress) {
      id
      ${attr}
    }
  }
`;

export const getLinkedinAdCampaigns = (attr = `
  linkedinAdId
  lifeTimeBudget
  startDateTime
  endDateTime
  type
  campaignStatus
  isExpired
  imageUrl
  postMessage
  statusFromLI
  effectiveStatus
  goal
  attachmentType
  insights {
    reactions
    comments
    shares
    reach
    spend
  }
`) => gql`
  query getLinkedinAdCampaigns($date: AWSDateTime, $timezone: String, $by: String, $removeDeleted: Boolean, $removeFailed: Boolean){
    getLinkedinAdCampaigns(date: $date, timezone: $timezone, by: $by, removeDeleted: $removeDeleted, removeFailed: $removeFailed) {
      id
      ${attr}
    }
  }
`;

export const getAdCampaignsMonths = gql`
  query getAdCampaignsMonths($socialNetwork: SOCIAL_NETWORK){
    getAdCampaignsMonths(socialNetwork: $socialNetwork)
  }
`;

export const getAdCampaignsMetrics = gql`
  query getAdCampaignsMetrics($date: AWSDateTime, $timezone: String){
    getAdCampaignsMetrics(date: $date, timezone: $timezone){
      postedCount
      boostedCount
      reach {
        boosted
        total
      }
      reactions {
        boosted
        total
      }
      comments {
        boosted
        total
      }
      shares {
        boosted
        total
      }
    }
  }
`;
