import gql from 'graphql-tag';

export const getLinkedinPosts = gql`
  query getLinkedinPosts($pagination: Pagination, $orderBy: POSTS_ORDER_BY) {
    getLinkedinPosts(pagination: $pagination, orderBy: $orderBy){
      id
      message
      publishTime
      likes
      commentsNumber
      unreadCommentsCount
      image
      attachmentType
      avatar
    }
  }
`;
export default {};
