import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import AppSync from './AppSyncConfig';

export default class PublicAppSync {
  init() {
    this.client = new AWSAppSyncClient({
      url: `${AppSync.graphqlEndpoint}`,
      region: AppSync.region,
      auth: {
        type: AUTH_TYPE.API_KEY,
        apiKey: AppSync.apiKey,
      },
      disableOffline: true,
    });
  }
}
