import { createMuiTheme } from '@material-ui/core/styles';
import theme, { colors } from './theme';

export const breakpointValues = {
  xs: 320,
  sm: 576,
  mdLess: 767,
  md: 768,
  mdPlus: 769,
  lg: 992,
  lgPlus: 1024,
  xl: 1200,
  xlPlus: 1280,
  xxl: 1440,
};

const GlobalTheme = createMuiTheme({
  ...theme,
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  typography: {
    fontFamily: theme.font.font1,
    color: colors.dark,
    button: {
      fontSize: '16px',
      textTransform: 'none',
      borderRadius: '4px',
      fontStyle: 'normal',
    },
  },
  breakpoints: { values: breakpointValues },
});

export default GlobalTheme;
