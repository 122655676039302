export const ONBOARDING_ROUTES = {
  launcher: '/onboarding/launcher',
};

export const FINALIZED_STATUS_TUTORIAL = 'finalized';
export const ONBOARDINGDATE_STATUS = {
  initial: 'initial',
  parked: 'parked',
  scheduled: 'scheduled',
  completed: 'completed',
  skipped: 'skipped',
};
export const CALENDLY_WIDGET = 'https://assets.calendly.com/assets/external/widget.js';
export const CALENDLY_EVENTS_URI = {
  en: 'https://calendly.com/socialpiper-onboarding/en?hide_landing_page_details=1&hide_event_type_details=1',
  es: 'https://calendly.com/socialpiper-onboarding/es?hide_landing_page_details=1&hide_event_type_details=1',
};

export const CALENDLY_EVENTS_TYPES = {
  viewed: 'calendly.event_type_viewed',
  selected: 'calendly.date_and_time_selected',
  scheduled: 'calendly.event_scheduled',
};
