/* eslint-disable max-len */
import * as Sentry from '@sentry/browser';
import { authAppSync } from '../auth';
import {
  getReachEstimate,
  getClientsInformation,
  getLinkedinReachEstimate,
} from '../../graphql/queries';
import { updateTargetClients } from '../../graphql/mutations';
import { getFacebookPageFanCountString } from '../../graphql/queries/facebook.query';
import { ENV, ENVIRONMENTS } from '../../utils/constants/globals';
import interestsMocked from '../../mocks/audience/interests.mock';
import reachStimateMock from '../../mocks/audience/reachEstimate.mock';

export default class AudienceProvider {
  static async fetchSize({
    ages,
    genderId,
    interests,
    locations,
  }, isLinkedin = false) {
    let result = {
      message: '',
      success: false,
      data: 0,
    };
    try {
      const queryName = isLinkedin ? 'reachEstimateLinkedin' : 'reachEstimate';
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: isLinkedin ? getLinkedinReachEstimate : getReachEstimate,
          variables: {
            ageMax: ages[1],
            ageMin: ages[0],
            genderId,
            interests,
            locations,
          },
        });
      } else {
        response = reachStimateMock(isLinkedin);
      }
      const data = response.data[queryName].targetSize;
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static formatSizeInput(info, isLinkedin = false) {
    let locations = isLinkedin ? info.linkedinLocations : info.locations;
    let interests = isLinkedin ? info.linkedinInterests : info.interests;
    if (!interests) interests = [];
    if (!locations) locations = [];
    return {
      ages: info.ages,
      genderId: info.gender,
      interests: interests.map((interest) => ({
        id: interest.id,
        name: interest.label,
      })),
      locations: locations.map((l) => ({
        id: l.id,
        type: l.type,
        ...(l.countries && { countries: l.countries }),
      })),
    };
  }

  static async fetch(intl, attr = '', isLinkedIn = false) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: attr ? getClientsInformation(attr) : getClientsInformation(),
          fetchPolicy: 'network-only',
          variables: {
            language: intl.locale,
            socialNetwork: (isLinkedIn ? 'linkedin' : 'facebook'),
          },
        });

        data = response.data.getClientsInformation;
      } else {
        // could be normal, fbEmpty, liEmpty, socialEmpty and allEmpty
        data = interestsMocked.normal;
      }
      const {
        ageLowerBound,
        ageUpperBound,
        interests,
        genders,
        location,
        linkedinLocation,
        linkedinInterests,
      } = data;

      const info = {};
      info.ages = [ageLowerBound, ageUpperBound];
      info.interests = interests.map(({
        facebookId,
        name,
      }) => ({
        id: facebookId,
        value: facebookId,
        label: name,
      }));
      const tmpGenders = genders.map(
        (gender) => JSON.parse(gender),
      );
      if (tmpGenders.length > 0) {
        info.gender = tmpGenders[0].id;
      }
      const locations = location ? JSON.parse(location) : [];
      info.locations = locations.map((l) => {
        const value = l.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const intlId = `k.${l.type}`;
        return {
          ...l,
          value,
          helper: intl.formatMessage({
            id: intlId,
            defaultMessage: intlId.split('.')[1],
          }),
        };
      });
      const liLocations = linkedinLocation ? JSON.parse(linkedinLocation) : [];
      const liInterests = linkedinInterests ? JSON.parse(linkedinInterests) : [];
      info.linkedinLocations = liLocations.map((e) => ({
        id: e.id,
        value: e.id,
        label: e.name,
      }));

      info.linkedinInterests = liInterests.map((e) => ({
        id: e.id,
        value: e.id,
        label: e.name,
      }));

      result = {
        message: 'success',
        success: true,
        data: info,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async update(audienceInfo, fetchSize = false, isLinkedin) {
    let result = {
      message: '',
      success: false,
      data: null,
    };
    try {
      const locations = audienceInfo.locations && audienceInfo.locations.map(({
        value,
        helper,
        ...l
      }) => (l));
      const linkedinLocation = audienceInfo.linkedinLocations && audienceInfo.linkedinLocations.map((e) => ({
        id: e.value,
        name: e.label,
      }));
      const linkedinInterests = audienceInfo.linkedinInterests && audienceInfo.linkedinInterests.map((e) => ({
        id: e.value,
        name: e.label,
      }));
      await authAppSync.client.mutate({
        mutation: updateTargetClients,
        variables: {
          ageLowerBound: audienceInfo.ages ? audienceInfo.ages[0].toString() : undefined,
          ageUpperBound: audienceInfo.ages ? audienceInfo.ages[1].toString() : undefined,
          location: locations ? JSON.stringify(locations) : undefined,
          genders: audienceInfo.gender ? [`${audienceInfo.gender}`] : undefined,
          linkedinLocation: linkedinLocation ? JSON.stringify(linkedinLocation) : undefined,
          linkedinInterests: linkedinInterests ? JSON.stringify(linkedinInterests) : undefined,
          ...((audienceInfo.interests && audienceInfo.interests.length > 0) && { interests: JSON.stringify(audienceInfo.interests) }),
          socialNetwork: (isLinkedin ? 'linkedin' : 'facebook'),
        },
      });

      if (fetchSize) {
        return this.fetchSize(this.formatSizeInput(audienceInfo));
      }

      result = {
        message: 'success',
        success: true,
        data: [],
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchOrganicSize() {
    let result = {
      message: '',
      success: false,
      data: 0,
    };
    try {
      let response;
      if (ENV !== ENVIRONMENTS.local) {
        response = await authAppSync.client.query({
          query: getFacebookPageFanCountString,
        });
      } else {
        response = {
          data: {
            facebookFetchFanCountString: '3000',
          }
        }
      }

      result = {
        message: 'success',
        success: true,
        data: response.data.facebookFetchFanCountString,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
