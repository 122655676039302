import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import * as QueryString from 'query-string';
import { contextObject } from '../../../contexts/PromoCodeContext';
import PromoCodeProvider from '../../../services/entities/PromoCodeProvider';
import PlanProvider from '../../../services/entities/PlanProvider';
import { UserSettingsContext } from '../../../contexts/UserSettingsContext';
import AuthService from '../../../services/auth/AuthService';

const useGetPromo = () => {
  const location = useLocation();
  const [PromoCode, setPromoCode] = useState(contextObject);
  const { subsCurrency, countryCode } = useContext(UserSettingsContext);
  const [promoLock, setPromoLock] = useState(true);
  const [isPricingLoading, setPricingLoading] = useState(true);

  const fetchPlans = async (code) => {
    setPromoLock(true);
    const response = await PlanProvider.fetchAllPlans(code);
    if (response.success) {
      const {
        plans,
        promocode,
        isValid,
        promoCodeId,
        isPromoAttached,
      } = response.data;
      setPromoCode({
        plans,
        code: promocode,
        isValid,
        promoCodeId,
        isPromoAttached,
      });
    }
    setPromoLock(false);
    setPricingLoading(false);
  };
  const fetchPromos = async (code) => {
    setPromoLock(true);
    const fetched = await PromoCodeProvider.fetchPromo(code, subsCurrency, countryCode);
    if (fetched.success) {
      const {
        plans,
        isValid,
      } = fetched.data;
      setPromoCode({
        ...PromoCode,
        plans,
        code,
        isValid,
      });
      setPromoLock(false);
      setPricingLoading(false);
    }
  };

  useEffect(() => {
    if (!AuthService.isAuthenticated()) {
      fetchPromos();
    } else {
      fetchPlans();
    }
  }, []);
  useEffect(() => {
    const validate = async () => {
      setPromoLock(true);
      if (location.search) {
        const params = QueryString.parse(location.search);
        if (params && params.promocode) {
          fetchPromos(params.promocode);
        }
      }
      setPromoLock(false);
    };
    // TODO: fix sync to validate token on first load
    if (!AuthService.isAuthenticated()) {
      setTimeout(() => { validate(); }, 500);
    }
  }, [location]);

  const handlePromoCodeChange = async (code) => {
    if (!AuthService.isAuthenticated()) {
      fetchPromos(code);
    } else {
      fetchPlans(code);
    }
  };

  return {
    promoLock,
    PromoCode,
    setPromoCode,
    handlePromoCodeChange,
    isPricingLoading,
  };
};

export default useGetPromo;
