import gql from 'graphql-tag';

const getRecentComments = gql`
  query getRecentComments($limit: Int){
    getRecentComments(limit: $limit){
      post {
        id
        type
        publishTime
        message
        socialMediaPermalink
      }
      comment {
        id
        from
        commentText
        createdTime
        avatar
      }
    }
  }
`;

export default getRecentComments;
