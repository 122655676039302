import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import Media from 'react-media';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';
import GlobalTheme from '../../styled/GlobalTheme';
import mediaQueries from '../../utils/mediaQueries';
import roles from '../../utils/constants/roles';
import routes from '../../utils/constants/routes';
import { AnalyticsContext } from '../../contexts/AnalyticsContext';
import PromoCodeContext from '../../contexts/PromoCodeContext';
import sanitizePlans from '../../views/CreateAccount/sanitizePlans';
import AccordionPricing from './AccordionPricing';
import {
  getTableValues,
  getAccordionsValues,
  getSupportValues,
} from './TableItems';
import {
  CheckIcon,
  FailIcon,
} from '../../assets/icons/iconRepository';
import { WebViewContext } from '../../contexts/WebViewContext';
import { ButtonPrimary } from '../../views/landing/components/Buttons';

const Row = styled.div`
    padding: ${({ extraPading }) => (!extraPading ? '0' : '0 5px')};
    margin:auto;
    background-color: ${GlobalTheme.colors.white};
    display: grid;
    width:100%;
    grid-template-columns: 3fr 2fr 2fr;
    justify-items: center;
    align-items: center;
    border-bottom: 1px solid ${GlobalTheme.colors.grayE9};
    height:60px;
    @media (max-width: 320px)   {
      grid-template-columns: 100px 67px 2fr;
    }
    ${GlobalTheme.breakpoints.up('md')}{
      grid-template-columns: 3fr 2fr 3fr;
      column-gap: 30px;
      padding: 0 20px;
    }
    ${GlobalTheme.breakpoints.up('lg')}{
      grid-template-columns: 4fr 2fr 2fr;
      column-gap: 30px;
      padding: ${({ extraPading }) => (!extraPading ? '0' : '0 1em')};
    }
`;

const TitleRow = styled(Row)`
  height: 40px;
  ${GlobalTheme.breakpoints.up('md')}{
    height: 82px;
  }
`;

const ButtonRow = styled(TitleRow)`
  margin: 1em 0;
  border: none;
`;

const TableRowTittle = styled.div`
  font-size: 24px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  line-height: 30px;
  margin-bottom: 20px;
  color: ${GlobalTheme.colors.lightBlue};
  ${GlobalTheme.breakpoints.up('md')}{
    text-align: left;
    display: block;
    margin-bottom: 0px;
    font-size: 20px;
  }
  ${GlobalTheme.breakpoints.up('lg')}{
    font-size: 24px;
  }
`;

const TablePlanTitle = styled.div`
  position:relative;
  font-size: 13px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  color: ${({ textColor }) => textColor};
  ${({ pricingView }) => pricingView && `
    &:nth-child(3) {
      order: 3;
    }
  `}
  ${GlobalTheme.breakpoints.up('md')}{
    font-size: 20px;
  }
  ${GlobalTheme.breakpoints.up('lg')}{
    font-size: 30px;
  }

`;

const Cell = styled.div`
  font-size: 10px;
  width: 100%;
  text-align: center;
  ${({ isBiz }) => (isBiz && `color: ${GlobalTheme.colors.lightBlue};`)}
  ${({ isBiz }) => (isBiz && 'font-weight: 600;')}
  ${({ pricingView }) => pricingView && `
    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 3;
    }
  `}
  ${GlobalTheme.breakpoints.up('md')} {
    font-size: 12px;
  }
  ${GlobalTheme.breakpoints.up('lg')} {
    font-size: 16px;
  }
`;

const RowTitle = styled(Cell)`
text-align: left;
`;

const Check = styled.div`
  display: inline-block;
  width: 24px;
  height: 18px;
  background-image: url(${CheckIcon});
  background-repeat: no-repeat;
  background-size: cover;
`;
const Fail = styled.div`
  display: inline-block;
  width: 17px;
  height: 17px;
  background-image: url(${FailIcon});
  background-repeat: no-repeat;
  background-size: cover;
`;

const RowItem = ({
  data,
  pricingView,
  exPad,
  isBiz,
}) => {
  const {
    title, isComingSoon,
  } = data;
  let {
    proValue, bizValue,
  } = data;
  if (proValue === true) {
    proValue = (<Check />);
  }
  if (proValue === false) {
    proValue = (<Fail />);
  }
  if (bizValue === true) {
    bizValue = (<Check />);
  }
  if (bizValue === false) {
    bizValue = (<Fail />);
  }
  return (
    <Row
      extraPading={exPad}
    >
      <RowTitle>{title}</RowTitle>
      <Cell pricingView={pricingView}>
        {isComingSoon ? (
          <FormattedMessage
            id="k.comingSoon"
            defaultMessage="Coming Soon"
          />
        ) : proValue}
      </Cell>
      <Cell
        pricingView={pricingView}
        isBiz={isBiz}
      >
        {isComingSoon ? (
          <FormattedMessage
            id="k.comingSoon"
            defaultMessage="Coming Soon"
          />
        ) : bizValue}
      </Cell>
    </Row>
  );
};

RowItem.propTypes = {
  data: PropTypes.instanceOf(Object),
  pricingView: PropTypes.bool,
  exPad: PropTypes.bool,
  isBiz: PropTypes.bool,
};

RowItem.defaultProps = {
  data: { title: '', isComingSoon: false },
  pricingView: false,
  exPad: false,
  isBiz: false,
};

const CompareTable = ({ show, showButtons, pricingView }) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { dataLayerPush } = useContext(AnalyticsContext);
  const { PromoCode } = useContext(PromoCodeContext);
  const [trialDays, setTrialDays] = useState(0);
  const tableValues = getTableValues(intl);
  const accordionValues = getAccordionsValues(intl);
  const supportValues = getSupportValues(intl);
  const { isApp, isAppIOS } = useContext(WebViewContext);
  const onClickHandler = (roleUse) => {
    let isMonthly = true;
    try {
      isMonthly = JSON.parse(window.localStorage.getItem('isMonthly'));
    } catch (err) {
      Sentry.captureException(err);
    }

    dataLayerPush({
      pagePath: `/homepage/compareTable/${roleUse}`,
      pathTitle: 'Landing Pricing',
      dataAction: 'Choose piper plan',
      event: 'action',
      category: (isMonthly) ? 'Monthly' : 'Yearly',
      label: '(button)',
    });
    const query = new URLSearchParams(location.search);
    const search = query.get('promocode') ? `?promocode=${query.get('promocode')}&plan=${roleUse}` : `?plan=${roleUse}`;

    let routeSignUp = routes.CREATE_ACCOUNT;
    if (location.pathname.includes(routes.JOIN_PRICING)) {
      routeSignUp = routes.SIGN_UP;
    }
    window.localStorage.setItem('planSelected', roleUse);
    if (PromoCode.code && PromoCode.code !== '') {
      window.localStorage.setItem('promoCode', PromoCode.code);
    }
    if (isApp || isAppIOS) {
      routeSignUp = routes.CREATE_ACCOUNT_APP;
    }

    history.push({
      pathname: routeSignUp,
      search,
      state: {
        plan: roleUse,
      },
    });
  };

  useEffect(() => {
    const [monthlyPlan] = sanitizePlans(PromoCode);
    setTrialDays(monthlyPlan.trialDuration);
  }, [PromoCode, trialDays]);
  return (
    (show && (
      <Media queries={mediaQueries}>
        {(matches) => (
          <>
            {matches.smDown && (
              <TableRowTittle>{intl.formatMessage({
                id: 'comparePlans.compare',
                defaultMessage: 'Compare plans side-by-side',
              })}
              </TableRowTittle>
            )}
            <TitleRow>
              <TableRowTittle>{!matches.smDown ? intl.formatMessage({
                id: 'comparePlans.compare',
                defaultMessage: 'Compare plans side-by-side',
              }) : ''}
              </TableRowTittle>
            </TitleRow>
            {tableValues.map((value) => (
              <RowItem
                data={value}
                pricingView={pricingView}
              />
            ))}
            {
              accordionValues.map(({ accTitle, content }) => (
                <AccordionPricing
                  title={accTitle}
                  hasExpanded
                >
                  {content.map((value) => (
                    <RowItem
                      data={value}
                      pricingView={pricingView}
                      exPad
                    />
                  ))}
                </AccordionPricing>
              ))
            }
            <RowItem
              data={supportValues}
              pricingView={pricingView}
            />
            {showButtons && (
              <ButtonRow>
                <Cell pricingView={pricingView} />
                <Cell pricingView={pricingView}>
                  {trialDays ? (
                    <ButtonPrimary
                      onClick={() => onClickHandler(roles.PRO)}
                      largeText
                    >{intl.formatMessage({
                      id: 'k.cta.freeTrial',
                      defaultMessage: 'Start Your Free Trial',
                    })}
                    </ButtonPrimary>
                  ) : (
                    null
                  )}
                </Cell>
                <ButtonPrimary
                  pricingView={pricingView}
                  largeText
                >
                  {intl.formatMessage({
                    id: 'k.signup.now',
                  })}
                </ButtonPrimary>
              </ButtonRow>
            )}
          </>
        )}
      </Media>
    )));
};

CompareTable.propTypes = {
  show: PropTypes.bool,
  showButtons: PropTypes.bool,
  pricingView: PropTypes.bool,
};

CompareTable.defaultProps = {
  show: false,
  showButtons: false,
  pricingView: false,
};

export default CompareTable;
