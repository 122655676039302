const es = {
  'tutorialModal.skip': 'Saltar',
  'tutorialModal.done': 'Hecho',
  'tutorialModal.slide1.title': '¡Ya estás listo!',
  'tutorialModal.slide1.text': 'Checa tu Piper Score en el Dashboard y observa cómo va tu negocio en redes sociales',
  'tutorialModal.slide2.text': 'Visita el Centro Creativo y elige entre mis recomendaciones para ti o entre los cientos de plantillas listas para usar',
  'tutorialModal.slide3.text': 'Te brindaré recomendaciones de "Los mejores momentos" para publicar o programar tus publicaciones. Así como organizar tu contenido y anuncios.',
  'tutorialModal.slide4.text': 'Da un vistazo al rendimiento de tus anuncios en redes sociales y administra tu presupuesto de publicidad',
  'tutorialModal.slide5.text': 'Al conectar tus redes sociales podrás interactuar con tus seguidores desde un solo lugar',
  'tutorialModal.piperTip': '¡Hola! Soy Piper',
  'tutorialModal.createapp.slide1.title': '¡Bienvenido!',
  'tutorialModal.createapp.slide1.text': 'Utiliza el Piper Score en el Dashboard y observa como va tu negocio en redes sociales.',
  'tutorialModal.createapp.slide2.text': 'Visita el Centro Creativo y elige entre mis recomendaciones para ti o entre los cientos de plantillas listas para usar.',
  'tutorialModal.createapp.slide3.text': 'Te brindaré recomendaciones de "Los mejores momentos" para publicar o programar tus publicaciones. Así como organizar tu contenido y anuncios.',
  'tutorialModal.createapp.slide4.text': 'Crea anuncios fácilmente, revisa su rendimiento en redes sociales y administra tu presupuesto de publidad.',
  'tutorialModal.createapp.slide5.text': 'Al conectar tus redes sociales podrás interactuar con tus seguidores desde un solo lugar.',
};

export default es;
