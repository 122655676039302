const en = {
  'adAccount.missingFundingSource': 'In order to create a Boost (a Facebook advertising campaign) you will need to <a>add a payment method to your Facebook Ad Account</a>',
  'socialProfiles.connectionTitle': "Connect {companyName}'s {socialNetwork} account",
  'socialProfiles.connectionTitle.noCompanyName': 'Connect your business\'s {socialNetwork} account.',
  'socialProfiles.createOne': 'Create one now',
  'socialProfiles.donthaveOne': "Don't have one yet?",
  'socialProfiles.facebook.adAccounts.active.note': 'You can change it later, only if it has the same currency as the Ad Account previously selected. If you need assistance, please contact <a>support@socialpiper.com</a>',
  'socialProfiles.facebook.adAccounts.adAccountLabel': 'Ad Account',
  'socialProfiles.facebook.adAccounts.change': 'Change to another Ad Account',
  'socialProfiles.facebook.adAccounts.note': 'You can only change to another Ad Account in the same currency. If you need assistance, please contact <a>support@socialpiper.com</a>',
  'socialProfiles.facebook.adAccounts.select': 'Please select the Facebook Ad Account that you will use to create your Facebook advertising campaigns from Social Piper',
  'socialProfiles.facebook.help': 'Why should I have a business page?',
  'socialProfiles.facebook.videoModal.adAccount.title': 'Create your advertising account',
  'socialProfiles.facebook.videoModal.adAccount.subtitle': 'Read and follow this steps:',
  'socialProfiles.hint.linedIn.comingSoon': 'LinkedIn business posting should be supported in the coming months.',
  'socialProfiles.hint.oneAccount': 'At the moment you can connect 1 account',
  'socialProfiles.instagram.help': 'Why is different from a personal Instagram?',
  'socialProfiles.instagram.hint.noFacebook': 'Will be available once a Facebook page is connected. At the moment you can connect 1 account',
  'socialProfiles.instagram.hint.facebookConnected': 'You can only connect 1 Instagram business account. You don\'t know if you have a business account? <TipLink>Review these steps.</TipLink>',
  'socialProfiles.title': 'Manage your Social Profiles',
  'socialProfiles.override.title': 'In order to use this feature, you must connect at least one account',
  'socialProfiles.twitter.help': 'Why should I have a Twitter account?',
  'socialProfiles.linkedIn.modal.title': 'Select the <r>LinkedIn account<r> you want to use:',
  'socialProfiles.linkedIn.modal.profeProfile': 'LindekIn Personal Profiles:',
  'socialProfiles.linkedIn.modal.business': 'LinkedIn Business profiles:',
  'socialProfiles.linkedIn.modal.accountText': 'With this type of account, you’ll be able to:',
  'socialProfiles.linkedIn.modal.titleOnePage': 'Take note!',
  'socialProfiles.linkedIn.modal.option1': 'Create posts',
  'socialProfiles.linkedIn.modal.option2': 'See your post metrics',
  'socialProfiles.linkedIn.modal.option3': 'Manage your community',
  'socialProfiles.linkedIn.modal.option4': 'Have recommendations on what day and time to publish your posts.',
  'socialProfiles.linkedIn.modal.option5': 'Easily answer the comments from your posts.',
  'socialProfiles.multiAccount.modal.title': 'Select the <colored>{page}</colored> you want to use:',
  'socialProfiles.multiAccount.modal.facebook': 'Facebook page',
  'socialProfiles.multiAccount.modal.instagram': 'Instagram business profile',
  'socialProfiles.multiAccount.modal.': 'Business Profile',
  'socialProfiles.linkedIn.adAccounts.select': 'Please select the LinkedIn Ad Account that you will use to create your LinkedIn advertising campaigns from Social Piper',
  'socialProfiles.linkedIn.adAccount.missingFundingSource': 'In order to create a Boost (a LinkedIn advertising campaign) you will need to <a>add a payment method to your LinkedIn Ad Account</a>',
  'socialProfiles.linkedIn.createAdAccount.Modal.Tip': 'Let´s create your Linkedin ad account.',
  'socialProfiles.linkedIn.createAdAccount.name': 'Name for your Linkedin Ad account:',
  'socialProfiles.linkedIn.createAdAccount.currency': 'Select the currency for you Linkedin ad account:',
  'socialProfiles.linkedIn.createAdAccount.Modal.helpCurrency': 'For better management, use the same type of currency for all your ad accounts',
  'socialProfiles.linkedIn.videoModal.payment.title': 'Next, add your payment method.',
  'socialProfiles.linkedIn.videoModal.payment.subtitle': 'Read and follow this steps:',
  'socialProfiles.linkedIn.videoModal.payment.advice': 'Remember to come back after you add a payment method for your Ad Account on LinkedIn',
  'socialProfiles.linkedIn.videoModal.payment.step1': 'Sign in to your <link>Campaign Manager.</link>',
  'socialProfiles.linkedIn.videoModal.payment.step2': 'Click on the account name you want to manage.',
  'socialProfiles.linkedIn.videoModal.payment.step3': 'Click the account name in the top right corner of the page and select <h>Billing center</h> from the dropdown.',
  'socialProfiles.linkedIn.videoModal.payment.mobile.step3': 'Tap the account name in the top of the page and select <h>Billing center</h> from the dropdown.',
  'socialProfiles.linkedIn.videoModal.payment.step4': 'Click <h>Add credit card</h> in the middle of the page.',
  'socialProfiles.linkedIn.videoModal.payment.step5': 'Remember to <h>come back</h> after you add a payment method for your Ad Account on LinkedIn.',
  'socialProfiles.facebook.createAdAccount.step1': 'Go to your business page on <link>Facebook.</link> In the menu on the left, click on Publishing tools, in the Business Suite section.',
  'socialProfiles.facebook.createAdAccount.step1.mobile': 'Go to your business page on <link>Facebook</link> on your dekstop. In the menu on the left, click on Publishing tools, in the Business section.',
  'socialProfiles.facebook.createAdAccount.step2': 'On the menu on the left, click on <h>Settings.</h>',
  'socialProfiles.facebook.createAdAccount.step3': 'In the submenu, click <h>Business Assets.</h>',
  'socialProfiles.facebook.createAdAccount.step4': 'In the upper right corner, click <h>Add assets.</h>',
  'socialProfiles.facebook.createAdAccount.step5': 'In the window that appears, click on <h>Advertising.</h',
  'socialProfiles.facebook.createAdAccount.step6': 'In the menu that appears, click on <h>Ad Account.</h>',
  'socialProfiles.facebook.createAdAccount.step7': 'In the options menu, click on <h>Create a new ad account.</h>',
  'socialProfiles.facebook.createAdAccount.step8': 'Fill out the information to create your <h>Ad Account.</h> Write the name of the acocunt (for example your business name), select the time zone that best apply for your business, and define the currencu you want to use when advertising.',
  'socialProfiles.facebook.createAdAccount.step9': 'Remember to <h>come back</h> after you create your ad account on Facebook.',
};

export default en;
