import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Media from 'react-media';
import TinySliderCarousel from '../../../components/globals/TinySliderCarousel';
import { ButtonPrimary } from '../components/Buttons';
import mediaQueries from '../../../utils/mediaQueries';
import { ToolsImg } from '../../../assets/images/imageRepository';
import Tool from '../components/Tool';
import { landing } from '../../../assets/illustrations/illustrationRepository';
import routes from '../../../utils/constants/routes';
import { breakpointValues } from '../../../styled/GlobalTheme';

const Section = styled.section`
  background: ${({ theme }) => theme.colors.lightBlue};
  padding: 30px 18px 40px 18px;
  position: relative;
  @media ${mediaQueries.xlPlusUp} {
    display: grid;
    padding: 86px 70px 100px 70px;
  }
`;
const Title = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-size: 28px;
  font-weight: bold;
  line-height: 38px;
  text-align: center;
  @media ${mediaQueries.xlPlusUp} {
    font-size: 40px;
    line-height: 54px;
  }
`;
const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  margin: 0;
  margin-top: 18px;
  @media ${mediaQueries.xlPlusUp} {
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    margin-top: 4px;
  }
`;
const SlidersContainer = styled.div`
  margin-top: 38px;
`;
const ListTools = styled.div`
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(312px, 1fr)); */
  column-gap: 50px;
  row-gap: 48px;
  justify-self: center;
  margin-top: 82px;
  width: fit-content;
  position: relative;
  @media ${mediaQueries.xxlUp} {
    column-gap: 98px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 90px;
  @media ${mediaQueries.xlPlusUp} {
    margin-top: 0;
  }
`;
const IllustrationContainer = styled.div`
  position: absolute;
  top: ${({ top }) => top || 'auto'};
  bottom: ${({ bottom }) => bottom || 'auto'};
  right: ${({ right }) => right};
`;
const Illustration = styled.img``;

const ToolsSection = () => {
  const intl = useIntl();
  const history = useHistory();
  const tools = [
    {
      id: 'tool1',
      image: {
        mobile: '',
        desktop: ToolsImg.tool1.desktop,
      },
      title: (
        <FormattedMessage
          id="landing.tools.tool1.title"
          defaultMessage="Post perfection."
        />
      ),
      description: (
        <FormattedMessage
          id="landing.tools.tool1.description"
          defaultMessage="Every time. {lineBreak}Images and templates await. {lineBreak}No hunting, no headaches."
          values={{
            lineBreak: <br />,
          }}
        />
      ),
    },
    {
      id: 'tool2',
      image: {
        mobile: '',
        desktop: ToolsImg.tool2.desktop,
      },
      title: (
        <FormattedMessage
          id="landing.tools.tool2.title"
          defaultMessage="Scheduling is simple."
        />
      ),
      description: (window.innerWidth < breakpointValues.xlPlus) ? (
        <FormattedMessage
          id="landing.tools.tool2.description.mobile"
          defaultMessage="Use one clever calendar {lineBreak}for targeted posts {lineBreak}across all platforms."
          values={{
            lineBreak: <br />,
          }}
        />
      ) : (
        <FormattedMessage
          id="landing.tools.tool2.description"
          defaultMessage="Use one clever calendar {lineBreak}for targeted posts {lineBreak}across all platforms."
          values={{
            lineBreak: <br />,
          }}
        />
      ),
    },
    {
      id: 'tool3',
      image: {
        mobile: '',
        desktop: ToolsImg.tool3.desktop,
      },
      title: (
        <FormattedMessage
          id="landing.tools.tool3.title"
          defaultMessage="Engaging is effortless."
        />
      ),
      description: (
        <FormattedMessage
          id="landing.tools.tool3.description"
          defaultMessage="No jumping around. {lineBreak}Reply to all comments {lineBreak}from one dashboard."
          values={{
            lineBreak: <br />,
          }}
        />
      ),
    },
    {
      id: 'tool4',
      image: {
        mobile:
          intl.locale === 'es'
            ? ToolsImg.tool4.mobileEs
            : ToolsImg.tool4.mobile,
        desktop:
          intl.locale === 'es'
            ? ToolsImg.tool4.desktopEs
            : ToolsImg.tool4.desktop,
      },
      title: (
        <FormattedMessage
          id="landing.tools.tool4.title"
          defaultMessage="Boost for success!"
        />
      ),
      description: (
        <FormattedMessage
          id="landing.tools.tool4.description"
          defaultMessage="Piper helps you manage your ad spending to reach your biggest audience."
          values={{
            lineBreak: <br />,
          }}
        />
      ),
    },
  ];
  return (
    <Media queries={mediaQueries}>
      {(matches) => (
        <Section>
          <Title>
            <FormattedMessage
              id={
                matches.xlPlusDownLess
                  ? 'landing.tools.title.mobile'
                  : 'landing.tools.title'
              }
              defaultMessage="You’ve got big dreams. We’ve got smart tools to pursue them."
              values={{
                lineBreak: <br />,
              }}
            />
          </Title>
          <Subtitle>
            <FormattedMessage
              id={
                matches.xlPlusDownLess
                  ? 'landing.tools.subtitle.mobile'
                  : 'landing.tools.subtitle'
              }
              defaultMessage="Easy to use, all in one place. Piper shows you how."
            />
          </Subtitle>
          {matches.xlPlusDownLess && (
            <SlidersContainer>
              <TinySliderCarousel bottomPagination="-48px">
                {tools.map((tool) => (
                  <Tool tool={tool} key={tool.id} />
                ))}
              </TinySliderCarousel>
            </SlidersContainer>
          )}
          {matches.xlPlusUp && (
            <ListTools>
              <>
                {tools.map((tool) => (
                  <Tool tool={tool} key={tool.id} />
                ))}
                {matches.xlPlusUp && (
                  <IllustrationContainer top="calc(0% - 110px)" right="calc(0% - 160px)">
                    <Illustration src={landing.dogFootprints} />
                  </IllustrationContainer>
                )}
              </>
            </ListTools>
          )}
          <ButtonContainer>
            <ButtonPrimary
              onClick={() => history.push(routes.TOOLS)}
              gaData={{
                action: 'Learn more',
                label: '(button)(hero)',
              }}
            >
              <FormattedMessage id="k.learnMore" defaultMessage="Learn more" />
            </ButtonPrimary>
          </ButtonContainer>
          {matches.xlPlusUp && (
            <IllustrationContainer bottom="-50px" right="-50px">
              <Illustration src={landing.dogFootprints2} />
            </IllustrationContainer>
          )}
        </Section>
      )}
    </Media>
  );
};

export default ToolsSection;
