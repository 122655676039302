import React, { useState, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { GLOBAL_DEBOUNCE_DELAY } from '../../utils/constants/globals';
import { InputStyled, LabelInput, Required } from '../globals/FormController';
import GlobalTheme from '../../styled/GlobalTheme';

const PromoCodeCont = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  flex-flow: row;
  ${GlobalTheme.breakpoints.down('sm')} {
    flex-flow: column;
  };
`;

const LabelInvalid = styled(Required)`
  margin-top: 10px;
  position: absolute;
  left: 5px;
  top: 35px;
  ${GlobalTheme.breakpoints.down('sm')} {
    left: 0;
    top: 4em;
  };
`;

const LabelValid = styled(LabelInvalid)`
  color: ${(props) => props.theme.colors.lightBlue};
  position: absolute;
  left: 5px;
  top: 35px;
  ${GlobalTheme.breakpoints.down('sm')} {
    left: 0;
    top: 4em;
  };
`;
const LabelLoading = styled(LabelInvalid)`
  color: ${(props) => props.theme.colors.gray01};
  position: absolute;
  left: 5px;
  top: 30px;
  ${GlobalTheme.breakpoints.down('sm')} {
    left: 0;
    top: 40px;
  };
`;

const CustomLabelInput = styled(LabelInput)`
  && {
    font-size: 14px;
    margin: 0 8px 0 0;

    ${GlobalTheme.breakpoints.down('md')} {
      width: 140px;
    };
  }
`;

const Input = styled(InputStyled)`
  width: auto;
  margin-left: 8px;
  ${GlobalTheme.breakpoints.down('md')} {
    width: 97%;
  };
  ${GlobalTheme.breakpoints.down('sm')} {
    margin: 8px 0 0;
    width: 100%;
  };
  &[disabled] {
    background: ${(props) => props.theme.colors.gray01};
    cursor: not-allowed;
  }
  @media (max-width: 320px)   {
    width: 85%;
  }
`;
/** Methods for hide a show the placeholder
 *  I think this can be optimized, with states like components/globals/FormController
 */
const handleBlur = (e) => {
  e.target.placeholder = 'ABCD1234';
};

const handleFocus = (e) => {
  e.target.placeholder = '';
};

const PromoCodeContainer = ({
  isLoading,
  promoCode,
  onChangeHandler,
  trialEnabled,
}) => {
  const [code, setCode] = useState('');
  const [needLookup, setNeedLookup] = useState(0);
  useEffect(() => {
    onChangeHandler(code);
  }, [needLookup]);

  useEffect(() => {
    setCode(promoCode.code);
  }, [promoCode]);

  const debouceChange = useCallback(debounce((flag) => {
    setNeedLookup(flag);
  }, GLOBAL_DEBOUNCE_DELAY), []);

  const handleChange = ({ target }) => {
    setCode(target.value);
    debouceChange(new Date().getTime());
  };
  return (
    <PromoCodeCont>
      <CustomLabelInput>
        <FormattedMessage
          id="onboarding.creteAccount.doyouhavecode"
          defaultMessage="Do you have a promotional code?"
        />
      </CustomLabelInput>
      <div style={{ position: 'relative' }}>
        <Input
          onChange={(e) => handleChange(e)}
          onkeyUp={(e) => handleChange(e)}
          onPaste={(e) => handleChange(e)}
          value={code}
          placeholder="ABCD1234"
          id="promocode"
          onFocus={handleFocus}
          onBlur={handleBlur}
          type="text"
          disabled={isLoading}
        />
        {(isLoading && (
          <LabelLoading>
            <FormattedMessage
              id="k.loading"
              defaultMessage="Loading"
            />
          </LabelLoading>
        ))}
        {(!isLoading && !promoCode.isValid && promoCode.code !== '') && (
          <LabelInvalid>
            <FormattedMessage
              id="onboarding.creteAccount.invalidcode"
              defaultMessage="Invalid Code"
            />
          </LabelInvalid>
        )}
        {!isLoading && promoCode.isValid && (
          <LabelValid>
            <FormattedMessage
              id={`onboarding.creteAccount.${trialEnabled ? 'validcode' : 'validcode2'}`}
              defaultMessage="Valid Code"
            />
          </LabelValid>
        )}
      </div>
    </PromoCodeCont>
  );
};

PromoCodeContainer.propTypes = {
  isLoading: PropTypes.bool,
  promoCode: PropTypes.instanceOf(Object),
  onChangeHandler: PropTypes.func,
  trialEnabled: PropTypes.bool,
};

PromoCodeContainer.defaultProps = {
  isLoading: false,
  promoCode: {
    isValid: true,
    code: '',
  },
  onChangeHandler: () => {},
  trialEnabled: false,
};

export default PromoCodeContainer;
