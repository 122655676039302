import Auth0Lock from 'auth0-lock';
import { LoginLogo } from '../../assets/logos/logoRepository';
import colors from '../../styled/colors';
import removeLanguageRegion from '../../utils/removeLanguageRegion';

export default class LockApple {
  init(language = 'en') {
    const lockOptions = {
      theme: {
        logo: LoginLogo,
        primaryColor: colors.primary,
      },
      auth: {
        //redirect: false,
        redirectUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
        responseType: process.env.REACT_APP_AUTH0_RESPONSE_TYPE,
        connectionScopes: {
          apple: ['name email']
        }
      },
      allowedConnections: ['apple'],
      configurationBaseUrl: 'https://cdn.auth0.com',
      language: removeLanguageRegion(language),
    };
    this.lock = new Auth0Lock(
      process.env.REACT_APP_AUTH0_CLIENT_ID,
      process.env.REACT_APP_AUTH0_DOMAIN,
      lockOptions,
    );
  }
}