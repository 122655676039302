export const AD_STATUS = {
  ALL: 'all',
  DELETED: 'Deleted',
  EXPIRED: 'Finished',
  RUNNING: 'Running',
  REVIEW: 'Review',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  PAUSED: 'Paused',
  ARCHIVED: 'archived',
  SCHEDULED: 'Scheduled',
  FAILED: 'Failed',
};

export default {};
