import gql from 'graphql-tag';

export const fetchFacebookData = gql`
  mutation fetchFacebookData {
    fetchFacebookData
  }
`;

export const createFacebookPost = gql`
  mutation createFacebookPost ($message: String, $mediaType: MEDIA_TYPE, $mediaId: String, $published: Boolean!, $scheduleTime: AWSDateTime, $boostingSettings: BoostingSettingsInput, $mediaIds: [String], $sessionId: String) {
    createFacebookPost( data: {
      message: $message,
      mediaType: $mediaType,
      mediaId: $mediaId,
      published: $published,
      scheduleTime: $scheduleTime,
      boostingSettings: $boostingSettings,
      mediaIds: $mediaIds
      sessionId: $sessionId
    }){
      id
      published
      boosting {
        campaignStatus
      }
      errors {
        title
        message
      }
    }
  }
`;

export const deleteFacebookPost = gql`
  mutation deleteFacebookPost ($contentId: Int!) {
    deleteFacebookPost(contentId: $contentId, force: true){
      success
      message
      data
    }
  }
`;

export const rescheduleFacebookPost = gql`
  mutation rescheduleFacebookPost ($contentId: Int!, $scheduleTime: AWSDateTime, $postNow: Boolean) {
    rescheduleFacebookPost(contentId: $contentId, scheduleTime: $scheduleTime, postNow: $postNow)
  }
`;

export const editFacebookPost = gql`
  mutation editFacebookPost($contentId: ID!, $message: String, $scheduleTime: AWSDateTime, $imageUrl: String) {
    editFacebookPost(contentId: $contentId, message: $message, scheduleTime: $scheduleTime, imageUrl: $imageUrl)
  }
`;

export const updateFacebookCompetitorsPage = gql`
  mutation updateFacebookCompetitorsPage ($facebookCompetitors: [FacebookcompetitorpageInput]!, $instagramCompetitors: [InstagramCompetitorPageInput]!) {
    updateFacebookCompetitorsPage(
      facebookCompetitors: $facebookCompetitors,
      instagramCompetitors: $instagramCompetitors,
    )
  }
`;

export const createFacebookMessage = gql`
  mutation createFacebookMessage ($input: FacebookMessageInput!) {
    createFacebookMessage(input: $input) {
      id
      message
      from
      createdTime
      userImage
    }
  }
`;

export const replyFacebookComment = gql`
  mutation replyFacebookComment ($input: CommentInput!) {
    replyFacebookComment(input: $input) {
      id
      from
      commentText
      createdTime
      replyComments {
        id
        from
        commentText
        createdTime
        read
        userImage
      }
      read
      userImage
    }
  }
`;

export const deleteFacebookComment = gql`
  mutation deleteFacebookComment ($commentId: Int!) {
    deleteFacebookComment(commentId: $commentId)
  }
`;

export const updateFacebookPage = gql`
  mutation updateFacebookPage ($userId: ID!, $selectedAdAccount: String!) {
    updateFacebookPage (userId: $userId, selectedAdAccount:$selectedAdAccount ){
      id,
    }
  }
`;

export const createFacebookCompetitorPage = gql`
mutation createFacebookCompetitorpage($facebookId: String!, $name: String!, $link: String!) {
  createFacebookCompetitorPage(
    input: {
      data: {
        facebookId: $facebookId,
        name: $name,
        link: $link
      }
    }
  ){
    facebookCompetitorPage {
      id
      facebookId
      name
      link
      picture
    }
  }
}
`;

export const deleteFacebookCompetitorPage = gql`
  mutation deleteFacebookCompetitorPage($id: ID!) {
    deleteFacebookCompetitorPage(
      input: {
        where: {
          id: $id
        }
      }
    ){
      facebookCompetitorPage {
        id
        name
        link
      }
    }
  }
`;

export const markAsRead = gql`
  mutation markAsRead ($ids: [Int], $type: INTERACTION_TYPE!) {
    markAsRead(ids: $ids, type: $type) {
      success
    }
  }
`;

export const markAsReadBySocialNetwork = gql`
  mutation markAsReadBySocialNetwork ($socialNetwork: SOCIAL_NETWORK!) {
    markAsReadBySocialNetwork(socialNetwork: $socialNetwork)
  }
`;

export const likeFBComment = gql`
  mutation likeFBComment($commentId: ID!) {
    likeFBComment(commentId: $commentId)
  }
`;

export const saveMediaPostVideo = gql`
  mutation saveMediaPostVideo ($urlVideo: String, $nameFile: String, $duration: String, $dimension: Dimesion ) {
    saveMediaPostVideo( data: {
      urlVideo: $urlVideo,
      nameFile: $nameFile,
      duration: $duration,
      dimension: $dimension,
    }){
      id
    }
  }
`;

export const publishVideoFacebook = gql`
  mutation createFacebookPostVideo ($id: Int, $message: String, $publishNow: Boolean, $scheduleTime: AWSDateTime, $nameFile: String, $dimension: Dimesion, $previewImg: String, $namePreviewImg: String) {
    createFacebookPostVideo( data: {
      id: $id,
      message: $message
      publishNow: $publishNow
      scheduleTime: $scheduleTime
      nameFile: $nameFile
      dimension: $dimension
      previewImg: $previewImg
      namePreviewImg: $namePreviewImg
    }){
      isValid
      socialMediaContentId
      containerId
    }
  }
`;

export const createFacebookVideoBoost = gql`
  mutation createFacebookVideoBoost($socialMediaContentId: ID!, $boostingSettings: BoostingSettingsInput, $adCreativeId:ID!) {
    createFacebookVideoBoost(
    socialMediaContentId: $socialMediaContentId,
    boostingSettings: $boostingSettings,
    adCreativeId: $adCreativeId
  ) {
    success
    }
  }
`;

export const createFacebookAdCreativeVideo = gql`
  mutation createFacebookAdCreativeVideo($minImgURL: String!, $adVideoId: ID!) {
    createFacebookAdCreativeVideo(
        minImgURL: $minImgURL,
        adVideoId: $adVideoId,
    ){
      id
    }
  }
`;

export const createFacebookAdVideo = gql`
  mutation createFacebookAdVideo($urlVideo: String!) {
    createFacebookAdVideo(
        urlVideo: $urlVideo,
    ){
      id
    }
  }
`;

export const activateFacebookPage = gql`
  mutation activateFacebookPage($facebookPageId: Int!) {
    activateFacebookPage(facebookPageId: $facebookPageId){
        success
        info
        errorMessage
    }
  }
`;
