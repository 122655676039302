import React, { useContext } from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { SUBSCRIPTION_ERROR, SUBSCRIPTION_SUCCESS, PAYMENT_DETAILS_STAGE } from '../constants';
import { Button } from '../../../buttons';
import { Icon } from '../../ColoredIcon';
import { CloseOutlineIcon } from '../../../../assets/icons/iconRepository';
import colors from '../../../../styled/colors';
import Pipers from '../../../Pipers/Pipers';
import GlobalTheme from '../../../../styled/GlobalTheme';
import RoleContext from '../../../../contexts/RolesContext';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0;
  ${GlobalTheme.breakpoints.down('sm')}{
    justify-content: center;
  }
`;

const Subtitle = styled.p`
  width: 90%;
  margin: 1em auto 0;
  font-weight: 600;
  line-height: 32px;
  font-size: 20px;
  ${GlobalTheme.breakpoints.up('sm')}{
    font-size: 22px;
  }
  ${GlobalTheme.breakpoints.up('md')}{
    font-size: 24px;
  }
`;

const Text = styled.div`
  width: 90%;
  margin: 0 auto;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: ${({ theme }) => (theme.colors.text02)};
  font-size: 16px;
  ${GlobalTheme.breakpoints.up('sm')}{
    font-size: 18px;
  }
  ${GlobalTheme.breakpoints.up('md')}{
    font-size: 20px;
  }
`;

const MessageBox = styled.div`
  width: 100%;
  text-align: center;
`;

const ButtonContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
margin-top: 1em;
`;

const CloseIcon = styled(Icon)`
&& {
  position: absolute;
  right: 16px;
  top: 30px;
  transform: translateY(-50%);
}
`;
const PiperContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const PiperImg = styled.div`
  width: 150px;
  height: auto;
  ${GlobalTheme.breakpoints.up('sm')}{
    width: 200px;
  }
`;
const ErrorLegend = styled.p`
  text-align: center;
  width: 90%;
  margin: 2em auto 0;
  && > span { 
    color: ${({ theme }) => (theme.colors.primary)};
  }
  font-size: 12px;
  ${GlobalTheme.breakpoints.up('sm')}{
    font-size: 14px;
  }

`;

const ListBullet = styled.ul`
  max-width: 375px;
  margin: auto;
  display: flex;
  text-align: justify;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  ${GlobalTheme.breakpoints.up('sm')}{
    font-size: 18px;
  }
  ${GlobalTheme.breakpoints.up('md')}{
    font-size: 20px;
  }
`;
const SubscriptionConfirmation = ({ activeStage, handleClose, continueAction, setActiveStage }) => {
  const { fetchAndUpdate } = useContext(RoleContext);

  const updatePermissionAndClose = async (action) => {
    if (action === SUBSCRIPTION_ERROR) {
      setActiveStage(PAYMENT_DETAILS_STAGE);
    }
    if (action === SUBSCRIPTION_SUCCESS) {
      continueAction('Subscription Created');
      await fetchAndUpdate();
      handleClose(true);
    }
  };

  return (
    <Container>
      {activeStage === SUBSCRIPTION_ERROR && (
        <CloseIcon src={CloseOutlineIcon} color={colors.black} size="20px" onClick={() => handleClose()} />
      )}
      <PiperContainer>
        <PiperImg>
          {activeStage === SUBSCRIPTION_SUCCESS ? (
            <Pipers.CelebrateConfetti />
          ) : (
            <Pipers.PiperPublishFail />
          )}
        </PiperImg>
      </PiperContainer>
      <MessageBox>
        <Subtitle>
          <FormattedMessage
            id={activeStage === SUBSCRIPTION_SUCCESS ? 'proOfferModal.success.subtitle' : 'proOfferModal.error.subtitle'}
            defaultMessage={activeStage === SUBSCRIPTION_SUCCESS
              ? 'Congrats! You have succesfully suscribe to Professional Plan!'
              : 'Thee was a problem adding your payment method'}
          />
        </Subtitle>
        <Text>
          {(activeStage === SUBSCRIPTION_SUCCESS) && (
            <FormattedMessage
              id="proOfferModal.success.text"
              defaultMessage=""
            />
          )}
          {(activeStage === SUBSCRIPTION_ERROR) && (
            <>
              <FormattedMessage
                id="proOfferModal.error.text"
                defaultMessage=""
                tagName="p"
              />
              <ListBullet>
                <FormattedMessage
                  id="proOfferModal.error.b1"
                  defaultMessage=""
                  tagName="li"
                />
                <FormattedMessage
                  id="proOfferModal.error.b2"
                  defaultMessage=""
                  tagName="li"
                />
                <FormattedMessage
                  id="proOfferModal.error.b3"
                  defaultMessage=""
                  tagName="li"
                />
              </ListBullet>
            </>
          )}
        </Text>
      </MessageBox>
      <ButtonContainer>
        <Button onClick={() => updatePermissionAndClose(activeStage)}>
          <FormattedMessage
            id="k.ok"
            defaultMessage="ok"
          />
        </Button>
      </ButtonContainer>

      {(activeStage === SUBSCRIPTION_ERROR) && (
        <FormattedMessage
          id="proOfferModal.error.legend"
          values={{
            bold: (chunks) => (<b>{chunks}</b>),
            sp: (chunks) => (<span>{chunks}</span>),
          }}
          tagName={ErrorLegend}
        />
      )}

    </Container>
  );
};

SubscriptionConfirmation.propTypes = {
  activeStage: propTypes.number.isRequired,
  handleClose: propTypes.func.isRequired,
  continueAction: propTypes.func.isRequired,
  setActiveStage: propTypes.func.isRequired,
};

export default SubscriptionConfirmation;
