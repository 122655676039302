import { isToday, isTomorrow, addMinutes, isAfter } from "date-fns";
import { STORIES_IG, FACEBOOK, LINKEDIN } from "./constants/globals";

export const formatNumberToCurrency = (num = 0, fractionDigits = 2) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: fractionDigits,
  });
  return formatter.format(num);
};

export const formatCurrencyToNumber = (currency = "$0.00") =>
  Number(currency.replace(/[^0-9.-]+/g, ""));
export const roundToTwo = (num) =>
  Math.round((num + Number.EPSILON) * 100) / 100;
export const roundToOne = (num) => Math.round((num + Number.EPSILON) * 10) / 10;
export const formatNumerToAbbr = (num = 0) => {
  const ONE_THOUSAND = 1000;
  const ONE_MILLION = 1000000;
  const TEN = 10;
  if (num < ONE_THOUSAND) return `${num}`;
  if (num < ONE_MILLION) return `${parseInt(num / ONE_THOUSAND, TEN)}k`;
  const mill = roundToOne(num / ONE_MILLION);
  return `${mill}M`;
};
export const formatAbbrToNumber = (abbr = "0") => {
  const ONE_THOUSAND = 1000;
  const ONE_MILLION = 1000000;
  const TEN = 10;
  if (abbr.toLowerCase().indexOf("k") > -1)
    return parseInt(abbr, TEN) * ONE_THOUSAND;
  if (abbr.toLowerCase().indexOf("m") > -1)
    return parseInt(abbr, TEN) * ONE_MILLION;
  return parseInt(abbr, TEN);
};
export const capitalize = (s) =>
  typeof s !== "string" ? "" : s.charAt(0).toUpperCase() + s.slice(1);
export const formatNumberAndUnit = (num = 0) => {
  let number = num;
  let unit = "";

  const ONE_THOUSAND = 1000;
  const ONE_MILLION = 1000000;
  const TEN = 10;
  if (number >= ONE_MILLION) {
    number = roundToOne(num / ONE_MILLION);
    unit = "million";
  } else if (number >= ONE_THOUSAND) {
    number = parseInt(num / ONE_THOUSAND, TEN);
    unit = "thousand";
  }

  return [number, unit];
};
export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const getRelativeTimeDay = (date, intl) => {
  if (isToday(date)) {
    return intl.formatMessage({
      id: "k.today",
      defaultMessage: "Today",
    });
  }
  if (isTomorrow(date)) {
    return intl.formatMessage({
      id: "k.tomorrow",
      defaultMessage: "Tomorrow",
    });
  }
  return intl.formatDate(date);
};

export const generateRandomString = (length = 16) => {
  const charset =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._";
  let randomStringKey = "";
  let len = length;
  while (len > 0) {
    const bytes = new Uint8Array(16);
    const random = window.crypto.getRandomValues(bytes);
    // eslint-disable-next-line no-loop-func
    random.forEach((c) => {
      if (len === 0) {
        return;
      }
      if (c < charset.length) {
        randomStringKey += charset[c];
        len -= 1;
      }
    });
  }
  return randomStringKey;
};

export const filterMultiPosts = ({ weekEvents, connected, displayContent }) => {
  let filtered = [[], [], [], [], [], [], []];
  for (let dayIndex = 0; dayIndex < weekEvents.length; dayIndex += 1) {
    const day = weekEvents[dayIndex];
    for (let index = 0; index < day.length; index += 1) {
      if (
        !(
          day[index].isPost &&
          (!connected.includes(day[index].type) ||
            (!displayContent[0] &&
              new Date(day[index].publishTime) > new Date()) ||
            (!displayContent[1] &&
              new Date(day[index].publishTime) < new Date()))
        ) ||
        day[index].type === STORIES_IG
      ) {
        filtered[dayIndex].push({ ...day[index] });
      }
    }
  }

  return filtered;
};

export const filterNullValues = (values) => values.filter((x) => !!x);

// schedule view
export const connectedAccountFormat = (connectedAccounts) => {
  const data = [];
  const connectedAccountsKeys = Object.keys(connectedAccounts);
  for (let index = 0; index < connectedAccountsKeys.length; index += 1) {
    const account = connectedAccountsKeys[index];
    if (capitalize(account) === "Linkedin") {
      data.push({
        type: capitalize(account),
        name: connectedAccounts[account].pageName,
        selected: true,
        isOrganization: connectedAccounts[account].isOrganization,
      });
    } else {
      data.push({
        type: capitalize(account),
        name: connectedAccounts[account].pageName,
        selected: !!connectedAccounts[account].pageName,
      });
    }
  }
  return data;
};

export const getConnectedAccountoObject = (acc) => ({
  ...(acc.facebook.pageName && { facebook: acc.facebook }),
  ...(acc.instagram.pageName && { instagram: acc.instagram }),
  ...(acc.twitter.pageName && { twitter: acc.twitter }),
  ...(acc.linkedin.pageName && { linkedin: acc.linkedin }),
  ...(acc.tiktok.pageName && { tiktok: acc.tiktok }),
});

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export { default as sortSocialNetworks } from "./sortSocialNetworks";
export const getAdsSocialNetwork = (socialNetwork) => {
  if (socialNetwork === LINKEDIN) {
    return LINKEDIN;
  }
  return FACEBOOK;
};

export const getMaxMinBoostedPotential = (
  socialNetworks,
  fbBoostedPotential,
  fbVarianceBoostedPotential,
  liBoostedPotential,
  liVarianceBoostedPotential
) => {
  let min = 0;
  let max = 0;
  if (socialNetworks.indexOf(FACEBOOK) >= 0) {
    const fbMin = fbBoostedPotential - fbVarianceBoostedPotential;
    const fbMax = fbBoostedPotential;
    if (fbMin) {
      min = fbMin;
    }
    if (fbMax) {
      max = fbMax;
    }
  }
  if (socialNetworks.indexOf(LINKEDIN) >= 0) {
    const liMin = liBoostedPotential - liVarianceBoostedPotential;
    const liMax = liBoostedPotential;
    if (min === 0 || (liMin && liMin < min)) {
      min = liMin;
    }
    if (liMax > max) {
      max = liMax;
    }
  }
  return { min, max };
};

export const getTotalInvestment = (
  socialNetworks,
  fbInvestment,
  liInvestment
) => {
  let result = 0;
  if (socialNetworks.indexOf(FACEBOOK) >= 0) {
    result = fbInvestment;
  }
  if (socialNetworks.indexOf(LINKEDIN) >= 0) {
    result += liInvestment;
  }
  return result;
};

export const getStartBoostDate = (socialNetworks, fbStartDate, liStartDate) => {
  let date = "";
  if (socialNetworks.indexOf(FACEBOOK) >= 0) {
    date = fbStartDate;
  }
  if (socialNetworks.indexOf(LINKEDIN) >= 0) {
    if (date) {
      const year1 = fbStartDate.getFullYear();
      const year2 = liStartDate.getFullYear();
      const day1 = fbStartDate.getDay();
      const day2 = liStartDate.getDay();
      const hour1 = fbStartDate.getHours();
      const hour2 = liStartDate.getHours();
      const min1 = fbStartDate.getMinutes();
      const min2 = liStartDate.getMinutes();
      const equals =
        year1 === year2 && day1 === day2 && hour1 === hour2 && min1 === min2;
      date = equals ? date : "";
    } else {
      date = liStartDate;
    }
  }
  return date;
};

export const currencyExchange = (
  fromUsdExchangeRate,
  toUsdExchangeRate,
  value
) => {
  const usdValue = value / fromUsdExchangeRate;
  const exchangeValue = usdValue * toUsdExchangeRate;
  return exchangeValue;
};

export const boostMinDate = (publishTime) => {
  const now = new Date();
  if (publishTime) {
    const publishDate = new Date(publishTime);
    let minBoostStartDate;
    if (isAfter(publishDate, now)) {
      minBoostStartDate = addMinutes(publishDate, 15);
    } else {
      minBoostStartDate = addMinutes(now, 15);
    }
    return minBoostStartDate;
  }
  return addMinutes(now, 15);
};
