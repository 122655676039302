import * as Sentry from '@sentry/browser';
import { authAppSync } from '../auth';
import { unreadInboxMessages } from '../../mocks/dashboard/dashboard.mock';
import {
  countNewCommentsMessagesMock,
  createFacebookMessageMock,
  facebookConversationMsgMock,
} from '../../mocks/inbox';
import {
  facebookConversations,
  facebookConversationMessages,
  countNewCommentsMessages,
} from '../../graphql/queries';
import { createFacebookMessage, markAsRead } from '../../graphql/mutations';
import { ENVIRONMENTS, ENV } from '../../utils/constants/globals';
import { ProfilePlaceholder } from '../../assets/icons/iconRepository';

export default class InboxProvider {
  static async fetchConversations(pagination) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: facebookConversations,
          fetchPolicy: 'network-only',
          variables: {
            pagination,
          },
        });
        const conversations = response.data.facebookConversations || [];
        data = conversations.map((conversation) => ({
          from: {
            avatar: conversation.fromImage ? conversation.fromImage.replace(/&amp;/g, '&') : ProfilePlaceholder,
            name: conversation.from,
          },
          fromMe: false,
          id: conversation.id,
          text: conversation.previewMessage.message,
          timestamp: conversation.previewMessage.createdTime,
          read: conversation.previewMessage.read,
        }));
      } else {
        data = unreadInboxMessages;
      }
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchConversationMessages(conversationId, pagination) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        const response = await authAppSync.client.query({
          query: facebookConversationMessages,
          fetchPolicy: 'network-only',
          variables: {
            conversationId,
            pagination,
          },
        });
        data = response;
      } else {
        data = facebookConversationMsgMock;
      }
      data = data.data.facebookConversationMessages;
      data = data.map((msg) => ({
        ...msg,
        userImage: msg.userImage || ProfilePlaceholder,
      }));
      result = {
        message: 'success',
        success: true,
        data,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async replyBy(conversationId, message) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    let data;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        data = await authAppSync.client.mutate({
          mutation: createFacebookMessage,
          variables: {
            input: {
              conversationId,
              message,
            },
          },
        });
      } else {
        data = createFacebookMessageMock;
      }
      result = {
        message: 'success',
        success: true,
        data: data.data.createFacebookMessage,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async fetchUnreadMessages() {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    let data;
    try {
      if (ENV !== ENVIRONMENTS.local) {
        data = await authAppSync.client.query({
          query: countNewCommentsMessages,
        });
      } else {
        data = countNewCommentsMessagesMock;
      }
      result = {
        message: 'success',
        success: true,
        data: data.data.countNewCommentsMessages,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }

  static async markAsRead(id) {
    let result = {
      message: '',
      success: false,
      data: [],
    };
    try {
      let data;
      if (ENV !== ENVIRONMENTS.local) {
        data = await authAppSync.client.mutate({
          mutation: markAsRead,
          fetchPolicy: 'no-cache',
          variables: {
            ids: [id],
            type: 'message',
          },
        });
      } else {
        data = { data: { markAsRead: { success: true } } };
      }
      result = {
        message: 'success',
        success: true,
        data: data.data.markAsRead.success,
      };
    } catch (err) {
      Sentry.captureException(err);
      result.message = err.message;
    }
    return result;
  }
}
