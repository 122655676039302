const es = {
  'addPayment.advice': 'No te preocupes, tu información está segura.',
  'addPayment.button': 'Agregar tarjeta',
  'addPayment.cardDetails.addressLine': 'Dirección {number}',
  'addPayment.cardDetails.advice': 'CVC son los tres números detrás de tu tarjeta.',
  'addPayment.cardDetails.cardHolderName': 'Nombre',
  'addPayment.cardDetails.cardNumber': 'Número de la tarjeta de crédito',
  'addPayment.cardDetails.cardNumberShort': 'Número de Tarjeta',
  'addPayment.cardDetails.city': 'Ciudad',
  'addPayment.cardDetails.country': 'País',
  'addPayment.cardDetails.expirationDateShort': 'Exp',
  'addPayment.cardDetails.expirationDate': 'Día de expiración',
  'addPayment.cardDetails.state': 'Estado',
  'addPayment.cardDetails.zipCode': 'Código postal',
  'addPayment.contactDetails.title': 'Dirección',
  'addPayment.title': 'Agregar una tarjeta',
  'settings.modal.only.credit.text': 'Solo tarjetas de crédito',
  'settings.modal.only.credit.text.sm': 'Solo tarjetas {lineBreak}de crédito',
};

export default es;
