import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dialog as MuiDialog } from '@material-ui/core';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import GlobalTheme from '../../styled/GlobalTheme';
import IconButton from '../buttons/IconButton';
import { Button } from '../buttons';
import { DeleteXIcon } from '../../assets/icons/iconRepository';
import routes from '../../utils/constants/routes';
import Pipers from '../Pipers/Pipers';


const StyledModal = styled(MuiDialog)`
  && {
    .MuiPaper-root {
      padding: 16px 24px;
      width: 100%;
      max-width: 728px;
      max-height: 600px;
      ${GlobalTheme.breakpoints.down('md')} {
        min-width: 0;
        width: 100%;
        max-width: 600px;
      }
      ${GlobalTheme.breakpoints.down('sm')} {
        max-width: 100vw;
        max-height: 350px;
      }
      margin: 0;
      padding: 0;
    }
  }
`;

const Body = styled.div`
  width: 100%;
  margin: 0;
  box-sizing: border-box;
`;
const Header = styled.header`
  width:100%;
  display: flex;
  padding: 10px;
  justify-content: flex-end;
`;
const ContentWrapper = styled.div`
  padding: 0 1em;
  text-align: center;
`;
const PiperWrap = styled.div`
    margin-bottom: 1em;
    width 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 48px;
`;
const Gretting = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  ${GlobalTheme.breakpoints.up('md')} {
    font-size: 24px;
  }
`;

const CTA = styled(Button)`
  && { 
    margin: 1em auto 1.5em;
  }
`;

const ReconectTokenModal = ({
  open, setOpen,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const cta = () => {
    history.push({
      pathname: routes.SETTINGS_SOCIAL,
    });
    setOpen(false);
  };

  return (
    <StyledModal
      fullScreen={window.innerWidth < GlobalTheme.breakpoints.values.md}
      open={open}
    >
      <Header>
        <IconButton
          icon={DeleteXIcon}
          onClick={() => setOpen(false)}
          color={GlobalTheme.colors.black}
        />
      </Header>
      <Body>
        <PiperWrap>
          <Pipers.PiperPublishFail width="180px" />;
        </PiperWrap>
        <ContentWrapper>
          <Gretting>
            {intl.formatMessage({
              id: 'requireAttention.text',
            })}
          </Gretting>
          <CTA
            onClick={() => cta()}
          >
            {intl.formatMessage({
              id: 'requireAttention.cta',
            })}
          </CTA>

        </ContentWrapper>
      </Body>
    </StyledModal>
  );
};

ReconectTokenModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

ReconectTokenModal.defaultProps = {
  open: false,
  setOpen: () => { },
};

export default ReconectTokenModal;
