const en = {
  'dashboard.competitors.filters.all': 'All competitors',
  'dashboard.competitors.filters.instagram': 'Instagram competitors',
  'dashboard.competitors.filters.facebook': 'Facebook competitors',
  'dashboard.competitors.filters.images': 'Posts with images',
  'dashboard.boostBudget.monthlyBudget': 'MONTHLY BUDGET',
  'dashboard.hero.welcomeMessage': 'Welcome to your dashboard. You reached lots of customers this week. Your followers went up by 5%. Keep up the good work!',
  'dashboard.freeTrial.timeLeft': 'You have {daysLeft} days left of your Free Trial',
  'dashboard.hero.createBoost': 'Create a boost',
  'dashboard.hero.respondComments': 'Respond to comments',
  'dashboard.hero.schedulePost': 'Schedule a post this week',
  'dashboard.hero.description': 'The Piper Score takes into account how often you log in, create and schedule posts, boost them, answer messages and comments, and keep up to date your business and audience information.',
  'dashboard.hero.whatPiperScore': '<p>Understanding your <strong>Piper Score</strong></p>',
  'dashboard.competitors.noData': 'Tell me who your competitors are, or anybody you would like to watch for inspiration and I will show you a feed of their latest post.',
  'dashboard.hero.piperScore.footer': 'out of 100',
  'dashboard.competitors.connect': 'Add more competitors',
  'dashboard.competitors.connect.sm': 'Add more',
  'dashboard.boostBudget.noData': 'Boosts turn your posts into ads. They can help you reach more people, gain more followers and reactions.',
  'dashboard.boostBudget.noData.cta': 'Setup your budget',
  'dashboard.metrics.noData': 'Once you start posting, I will tell you how you are performing including how many customer see your posts, how many reactions (likes, shares, etc.) and how many comments you have.',
  'dashboard.mostActivePost.noData': 'Once you start posting, I will detect your most active post, so you can boost it and reach more people.',
  'dashboard.respondToComments.noData': 'Connect your business social media profiles to see a feed of all of your latest comments.',
  'dashboard.respondToComments.connect': 'Connect your social networks',
  'dashboard.postCard.interactions.description': '{interactions} interactions',
  'dashboard.respondToComments.caughtUp': 'Great! You have no pending comments to respond. Keep posting to create new reactions and comments.',
  'dashboard.respondToMessages.caughtUp': 'Great! You have no pending direct messages to respond to. Keep posting to create new reactions and comments.',
  'dashboard.mostActivePost.title': 'Most active post',
  'dashboard.respondToMessages.title': 'Respond to messages',
  'dashboard.respondToComments.title': 'Respond to comments',
  'dashboard.suggestedTemplates.connect': 'View more templates',
  'dashboard.imageReady.title': 'Image ready to post',
  'dashboard.suggestedTemplates.title.long': 'Suggested templates for you',
  'dashboard.competitors.upgrade.title': 'Add as many competitors as you want!',
  'dashboard.upcomingPosts.noData': 'You have no more upcoming posts. {lineBreak}{lineBreak}Go ahead and create new content!',
  'dashboard.piperScoreModal.tip': 'The Piper Score is your best guide to know how good is your activity on social media.',
  'dashboard.piperScoreModal.pargraph1': 'The  <strongBlue>Piper Score</strongBlue> takes into account how often you:',
  'dashboard.piperScoreModal.pargraph2': 'If you follow our AI recommendations, powered by Piper, your Score can increase faster and with easy steps.',
  'dashboard.piperScoreModal.list1': 'Login to Social Piper',
  'dashboard.piperScoreModal.list2': 'Create and schedule posts',
  'dashboard.piperScoreModal.list3': 'Reply to messages in your inbox and comments in your posts.',
  'dashboard.piperScoreModal.list4': 'The amount of interactions your posts gets',
  'dashboard.piperScoreModal.list5': 'Keep your business information up to date.',
  'dashboard.budget.tooltip.text': 'You’ve already reach your monthly budget. <s>{edit}</s>',
  'dashboard.snackbar': 'You haven’t verified your email yet. If you haven\'t received a verification email, resend it <h>{here}</h>.',
  'dashboard.snackbar.success': 'Verification email sended. Check your email.',
  'dashboard.categoryBanner.text': '<TipTitle>What type of business do you have?</TipTitle><TipBody>This will help me find the right templates for your business.</TipBody><TipNote>Note: You can add more than one.</TipNote>',
  'dashboard.download.notification.app.message': '<bold>All the powerful tools</bold> to create and manage your business socials <bold>from the official app.</bold>',
  'dashboard.download.notification.app.download.button': 'Download the app',
  'dashboard.quickpost.modal.title': 'Quick post',
  'dashboard.quickpost.modal.label.media': 'Post image / video',
  'dashboard.quickpost.modal.upload.file.text': 'Upload an image or video',
  'dashboard.quickpost.modal.logo.checkbox.label': 'Add my logo',
  'dashboard.quickpost.modal.label.caption': 'Post caption',
  'dashboard.quickpost.modal.label.schedule': 'Post time',
  'dashboard.quickpost.modal.schedule.text': 'This is my Piper Recommendation for the best times to post on each platform based on the activity of your audience',
  'dashboard.quickpost.modal.button.publish': 'Publish',
  'dashboard.quickpost.modal.button.piperCreateOne.image': 'Let Piper create an image for you',
  'dashboard.quickpost.modal.button.piperCreateOne.caption': 'Let Piper write you a cool text',
  'dashboard.quickpost.modal.button.regenerate.image': 'Generate again',
  'dashboard.quickpost.modal.button.upload.image': 'Upload',
  'dashboard.quickpost.modal.button.regenerate.caption': 'Generate again',
  'dashboard.quickpost.modal.button.postnow': 'Post now',
  'dashboard.quickpost.modal.media.title': '“Describe the image you want to use for your post and I will create it for you.”',
  'dashboard.quickpost.modal.media.placeholder': 'I want to talk about...',
  'dashboard.quickpost.modal.media.button': 'OK',
  'dashboard.quickpost.modal.caption.title': '“Tell me want would you talk about in your post and I will create a great content for you.”',
  'dashboard.quickpost.modal.caption.placeholder': 'I want to talk about...',
  'dashboard.quickpost.modal.caption.button': 'OK',
  'dashboard.autoPostGenerator.title': 'Automatically generates a content calendar',
  'dashboard.autoPostGenerator.day': 'Today',
  'dashboard.autoPostGenerator.week': 'Next 7 days',
  'dashboard.autoPostGenerator.month': 'Next 30 days',
  'dashboard.autoPostGenerator.warning': 'It may take up to 5 minutes',
  'dashboard.postAdvancedGenerator.title': 'Create a single post',
  'dashboard.postAdvancedGenerator.manual': 'Manually',
  'dashboard.postAdvancedGenerator.automatic': 'Automatically',
  'dashboard.scheduler.modal.title':'It is possible to have a particular theme to what I am about to create, for example give it a Christmas twist.',
  'dashboard.scheduler.modal.placeholder':'Give it a Christmas theme.',
  'dashboard.scheduler.modal.submit':'OK',
  'dashboard.scheduler.modal.skip':'Skip',
};

export default en;
